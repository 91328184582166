import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Divider, Form, Modal, Radio, Typography } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdressForm from "./AdressForm";
import DeleteModal from "../CommonComponents/DeleteModal";
import { deleteAddress, selectAddress } from "../../service/AddressApi";
import { getCart } from "../../service/cartApi";

const SelectAddressForm = ({ onFinish, addressData, fetchAddress }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [detailtsToEdit, setDetailtsToEdit] = useState();
  const [addressId, setaddressId] = useState();
  const [selectAddressId, setSelectAddressId] = useState();
  const [cartData, setCartData] = useState();
  const [isHomeSampling, setIsHomeSampling] = useState(false);
  const [homeSamplePincode, setHomeSamplePincode] = useState("");
  let isServicableAddress = true;

  const sortAddressOnAvailiblity = (validPincode) => {
    let swappingIndex = 0;
    for (let i = 0; i < addressData.length; i++) {
      if (addressData[i]?.pincode == validPincode) {
        let temp = addressData[i];
        addressData[i] = addressData[swappingIndex];
        addressData[swappingIndex] = temp;
        swappingIndex++;
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    form.resetFields();
    getData();
  }, []);
  // get cart data api call-----------------------------------

  const getData = async () => {
    try {
      const response = await getCart();
      if (response) {
        setCartData(response?.data?.payload?.cart);
        response?.data?.payload?.cart?.product?.map((item) => {
          if (item.type === "test") {
            if (item.testDetails.serviceMode === "Home") {
              setIsHomeSampling(true);
              sortAddressOnAvailiblity(item.testDetails.pincode);

              setHomeSamplePincode(item.testDetails.pincode);
            }
          } else if (item.type === "package") {
            if (item.packageDetails.serviceMode === "Home") {
              setIsHomeSampling(true);
              sortAddressOnAvailiblity(item.packageDetails.pincode);
              setHomeSamplePincode(item.packageDetails.pincode);
            }
          }
        });
      }
    } catch (error) {}
  };

  const callDeleteAddress = async () => {
    try {
      const response = await deleteAddress(addressId);
    } catch (error) {}
    fetchAddress();
    setOpenDeleteModal(!openDeleteModal);
  };

  const redirectToEditPage = (data) => {
    navigate("/checkout/edit-address", {
      state: {
        data: data,
      },
    });
  };
  const showModalEdit = () => {
    setIsModalOpenEdit(!isModalOpenEdit);
  };
  const HandleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleRadio = (e) => {
    setSelectAddressId(e?.target?.value);
  };

  useEffect(() => {
    callSelectAddress();
  }, [selectAddressId]);

  const callSelectAddress = async () => {
    try {
      const response = await selectAddress(selectAddressId);
      if (response) {
        return response;
      }
    } catch (error) {}
  };

  return (
    <>
      {cartData && (
        <Form onFinish={onFinish}>
          <Radio.Group
            defaultValue={cartData?.address?._id}
            onChange={handleRadio}
            name="selects"
          >
            {addressData &&
              addressData?.map((data, index) => {
                if (!(homeSamplePincode == data.pincode)) {
                  isServicableAddress = false;
                }
                return (
                  <>
                    <div className="w-[94vw] md:w-[44vw] flex justify-between px-3 pt-2 md:pt-5 pb-3">
                      {isHomeSampling ? (
                        <>
                          <div>
                            <Radio
                              value={data?._id}
                              disabled={!(homeSamplePincode == data.pincode)}
                            >
                              <Typography
                                className={`text-[0.85rem] font-sans ml-2`}
                                style={
                                  homeSamplePincode == data.pincode
                                    ? {}
                                    : { color: "#8a8a8a" }
                                }
                              >
                                {data.area} , {data.locality}
                              </Typography>
                              <Typography
                                className="text-[0.85rem] font-sans ml-2"
                                style={
                                  homeSamplePincode == data.pincode
                                    ? {}
                                    : { color: "#8a8a8a" }
                                }
                              >
                                {data.district} - {data.pincode}
                              </Typography>
                            </Radio>
                          </div>
                        </>
                      ) : (
                        <Radio value={data?._id}>
                          <Typography className="text-[0.85rem] font-sans ml-2">
                            {data.area} , {data.locality}
                          </Typography>
                          <Typography className="text-[0.85rem] font-sans ml-2">
                            {data.district} - {data.pincode}
                          </Typography>
                        </Radio>
                      )}

                      <div className=" flex justify-between items-center">
                        <EditOutlined
                          className="text-[#0057A3] text-[1.2rem] px-2"
                          onClick={() => {
                            setDetailtsToEdit(data);
                            if (window.innerWidth > 640) {
                              showModalEdit();
                            } else {
                              redirectToEditPage(data);
                            }
                          }}
                        />

                        <DeleteOutlined
                          className="text-[#F15E7E] text-[1.2rem] p-1"
                          onClick={() => {
                            setaddressId(data?._id);
                            HandleDeleteModal();
                          }}
                        />
                      </div>
                    </div>

                    {addressData?.length - 1 == index ? (
                      <></>
                    ) : (
                      <Divider className="mt-0 mb-2 min-w-[40vw] md:w-[43vw] w-[92vw] " />
                    )}
                    <style jsx>{`
                      .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
                        border-color: #f15e7e;
                        background-color: #f15e7e;
                      }
                    `}</style>
                  </>
                );
              })}
            {!isServicableAddress && isHomeSampling && (
              <div className="w-Full pl-4 mt-4">
                <Typography className={`text-[#8a8a8a]`}>
                  The selected slot and address do not match (Service pincode :{" "}
                  {homeSamplePincode}). Change the service address and select
                  the slot again.
                </Typography>
              </div>
            )}
          </Radio.Group>
          {window.innerWidth > 640 ? (
            <div className="flex justify-end">
              <button
                className="font-sans my-[1rem] text-[white] py-[0.6rem] px-[2rem] rounded-[4px]"
                type="submit"
                // cartData?.address?._id
                style={
                  cartData?.address?._id || selectAddressId
                    ? { background: "#F15E7E" }
                    : { background: "#BFBFBF" }
                }
                disabled={
                  cartData?.address?._id || selectAddressId ? false : true
                }
              >
                Continue
              </button>
            </div>
          ) : (
            <>
              <div className="fixed bottom-0 left-0 bg-white w-[100%] flex justify-between px-3 items-center mt-2 h-[8vh] border-t">
                <div>
                  <Typography className="font-normal text-sm font-sans">
                    Total Price
                  </Typography>
                  <Typography className="font-semibold text-[1.2rem] text-[#1F1F1F] font-sans">
                    ₹{cartData?.amount?.totalAmount}{" "}
                    <span className="font-normal text-sm">
                      ({cartData?.product?.length} Item)
                    </span>
                  </Typography>
                </div>
                <Button
                  className="font-sans  !text-white !border-[#F15E7E]  my-2 h-[10vw] px-10"
                  htmlType="submit"
                  // type=""
                  style={
                    cartData?.address?._id || selectAddressId
                      ? { background: "#F15E7E" }
                      : { background: "#BFBFBF" }
                  }
                  disabled={
                    cartData?.address?._id || selectAddressId ? false : true
                  }
                >
                  Continue
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      <Modal
        open={isModalOpenEdit}
        footer={null}
        closeIcon={
          <CloseCircleOutlined
            className="text-black mr-2"
            onClick={showModalEdit}
          />
        }
        centered={true}
        title={
          <Typography className="font-sans  font-semibold text-[1.1rem] pb-2 md:border-b-[1.5px] md:border-[#D9D9D9]">
            Edit Address
          </Typography>
        }
      >
        <div className="max-h-[35rem] no-scrollbar relative">
          <AdressForm
            setIsModalOpenEdit={setIsModalOpenEdit}
            isEdit={true}
            detailtsToEdit={detailtsToEdit}
            fetchAddress={fetchAddress}
          />
        </div>
      </Modal>
      <DeleteModal
        openDeleteModal={openDeleteModal}
        HandleDeleteModal={HandleDeleteModal}
        deleteAddress={callDeleteAddress}
        isAddressDelete={true}
        addressId={addressId}
      />
    </>
  );
};

export default SelectAddressForm;
