import React from "react";
import { Typography } from "antd";
import CardComponent from "../CommonComponents/CardComponent";
import RadiologyImage from "../../assets/images/Radiology.jpg";
import CardiologyImage from "../../assets/images/Cardiology.jpg";
import PathologyImage from "../../assets/images/Pathology.jpg";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AddOnsDiagnostics = () => {
  const data = useSelector((state) => state.AddOnsDiagnostic.diagnostic);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    dotsClass: "slick-dots slick-dots-custom",
    slidesToShow: 2,
    slidesToScroll: 2,
    slidesPerRow: 2,
    autoplay: true,
    rows: 2,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          rows: 2,
          slidesToScroll: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          rows: 2,
          slidesToScroll: 1,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          rows: 2,
          slidesToScroll: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="mt-16 sm:mt- ">
        <div className="w-100 items-center flex justify-items-center flex-col">
          <Typography className="text-center font-semibold text-[1.4rem] sm:text-[2rem] font-sans">
            Recommended Diagnostics
          </Typography>
          <Typography className="text-center text-[#434343] text-[0.9rem] w-[50%] m-[auto] sm:text-[1.1rem] mb-[0.2rem] sm:mb-[1rem] font-normal font-sans">
            Important for regular health screenings
          </Typography>
        </div>

        <div className="w-full mb-20 px-4 md:px-0">
          <Slider {...settings}>
            {data?.map((e, key) => {
              return (
                <div className="mt-6 md:px-2">
                  <CardComponent
                    isLandingPage={true}
                    TestImage={
                      e?.category?.name === "Radiology"
                        ? RadiologyImage
                        : e?.category?.name === "Cardiology"
                        ? CardiologyImage
                        : PathologyImage
                    }
                    title={e?.name}
                    providers={e?.partners}
                    content={e?.description}
                    features={e?.features[0]}
                    id={e?._id}
                    duration={e?.duration}
                    route="diagnostic-tests"
                    key={key}
                    showSampling={true}
                    serviceType={e?.serviceMode}
                    reportTime={e?.reportTime}
                    requirments={e?.requirements}
                    discountPrice={e?.startingPrice}
                    inCart={e?.inCart}
                    productId={e?.productId}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AddOnsDiagnostics;
