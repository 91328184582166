import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageNo: 1,
  categoryId: "64b10b92134950c130741980",
  selectedTab: "Seasonal Packages",
};

const profileAndPackagesVarSlice = createSlice({
  name: "ProfileAndPackagesVariable",
  initialState: initialState,
  reducers: {
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setPackageCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setPackageSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { setPageNo, setPackageCategoryId, setPackageSelectedTab } =
  profileAndPackagesVarSlice.actions;

export default profileAndPackagesVarSlice.reducer;
