import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Divider, Input } from "antd";
import { ImCancelCircle } from "react-icons/im";
import { VerifyNumber, verifyOTP } from "../../service/NumberVerification";
import { toast } from "react-toastify";

const VerifyNumberModal = ({
  verifyNumberModal,
  setVerifyNumberModal,
  initializePayment,
  setIsPhoneNumberVerified,
}) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timer, setTimer] = useState(59);
  let intervalId;
  const otpInputsRefs = useRef([]);

  const handleVerify = async () => {
    try {
      const response = await VerifyNumber({ phoneNumber: mobileNumber });
      if (response) {
        setShowOtpInput(true);
        startTimer();
      }
    } catch (error) {}
  };

  const handleOtpVerify = async () => {
    let ott = "";
    otp.map((o) => {
      ott = ott + o;
    });
    try {
      const response = await verifyOTP({ phoneNumber: mobileNumber, otp: ott });
      if (response.data.success) {
        initializePayment();
        setIsPhoneNumberVerified(true);
        toast.success("Your contact details are verified.");
        setVerifyNumberModal(false);
      } else {
        toast.info("Incorrect OTP");
      }
    } catch (error) {
      toast.info("Incorrect OTP");
    }
  };

  const isMobileNumberValid =
    mobileNumber.trim() !== "" && mobileNumber.length === 10;
  const isButtonDisabled = showOtpInput
    ? otp.some((digit) => digit === "")
    : !isMobileNumberValid;

  const startTimer = () => {
    intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(intervalId);
    }
  }, [timer]);

  useEffect(() => {
    if (showOtpInput) {
      otpInputsRefs.current[0]?.focus();
    }
  }, [showOtpInput]);

  const handleOtpInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== "" && index < otpInputsRefs.current.length - 1) {
      otpInputsRefs.current[index + 1]?.focus();
    }
  };

  return (
    <Modal
      open={verifyNumberModal}
      closable={false}
      title={
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h1 className="font-bold text-mg border-gray-300">
                Verify Phone Number
              </h1>
            </div>
            <div onClick={() => setVerifyNumberModal(false)}>
              <ImCancelCircle className="cursor-pointer hover:bg-gray-100" />
            </div>
          </div>
          <Divider />
          <div className="h-40">
            {showOtpInput ? (
              <div>
                <p className="text-gray-500 pb-2">
                  Enter OTP sent to{" "}
                  <span className="underline cursor-pointer">
                    {mobileNumber}
                  </span>
                </p>
                <div className="flex gap-2">
                  {otp.map((digit, index) => (
                    <Input
                      key={index}
                      className="w-10 h-10"
                      maxLength={1}
                      value={digit}
                      onChange={(e) =>
                        handleOtpInputChange(index, e.target.value)
                      }
                      ref={(input) => (otpInputsRefs.current[index] = input)}
                    />
                  ))}
                </div>
                <div className="flex justify-between pt-2">
                  <div
                    className="cursor-pointer underline"
                    onClick={handleVerify}
                  >
                    <p>Resend OTP</p>
                  </div>
                  <div>
                    <p className="text-black font-extralight">
                      0:{timer.toString().padStart(2, "0")}
                    </p>
                  </div>
                </div>
                <div
                  className="mt-4 underline cursor-pointer text-[0.9rem]"
                  onClick={() => {
                    setShowOtpInput(false);
                  }}
                >
                  Change your Phone number
                </div>
              </div>
            ) : (
              <div>
                <p className="text-gray-500 pb-2">Phone No.</p>
                <Input
                  className="rounded-none"
                  addonBefore="+91"
                  placeholder="Enter mobile number"
                  value={mobileNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = value.replace(/\D/g, "");
                    setMobileNumber(numericValue);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      }
      onCancel={() => {
        setVerifyNumberModal(false);
      }}
      footer={null}
    >
      <div className="mt-20 border-t flex justify-end">
        <Button
          className="mt-2 bg-[#F15E7E] hover:!border-none hover:!text-white text-white w-32 h-10 rounded border border-[#F15E7E]"
          disabled={isButtonDisabled}
          onClick={showOtpInput ? handleOtpVerify : handleVerify}
        >
          {showOtpInput ? "Verify" : "Verify"}
        </Button>
      </div>
      <style jsx>
        {`
          .ant-input {
            border-radius: 4px;
            padding: 8px 11px;
          }
          .ant-select .ant-select-arrow {
            color: black;
            font-size: 10px;
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            height: 40px;
            border-radius: 4px;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 38px;
          }
          .ant-input-group .ant-input-group-addon {
            border-radius: 4px;
          }
        `}
      </style>
    </Modal>
  );
};

export default VerifyNumberModal;
