import { Modal, Typography } from "antd";
import React from "react";
import Order_Confiramtion from "../../assets/images/Order Confirmation.gif";

const CancelModal = ({ openModal }) => {
  return (
    <Modal
      open={openModal}
      centered={true}
      closeIcon={<></>}
      footer={null}
      width={window.innerWidth < 640 ? "100%" : "28rem"}
    >
      <div className="flex justify-center items-center w-[100%] flex-col py-[0.4rem] sm:py-[1rem]">
        <div className="flex relative justify-center items-center overflow-hidden w-[100%] sm:h-[15rem] h-[12rem]">
          <img src={Order_Confiramtion} alt="" className="w-[120%]" />
        </div>
        <Typography className="text-[#1F1F1F] sm:text-[1.3rem]  text-[1.2rem] font-semibold font-sans text-center">
          Your Request is initiated
        </Typography>
        <Typography className="text-[#434343] sm:text-[1.1rem] text-[0.9rem]  mb-[1rem] font-sans text-center">
          We ensure you the refund but it may take us sometime
        </Typography>
      </div>
    </Modal>
  );
};

export default CancelModal;
