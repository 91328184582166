import { Col, Row, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const CompanySponsoredDropdown = ({ isActive }) => {
  const navigate = useNavigate();
  const options = [
    {
      name: "Our Services",
      link: "https://www.healthyhcl.in/",
    },
    {
      name: "Book your Health Checks",
      link: "https://app.hclhealthcare.in/ehc/register.php",
    },
    {
      name: "Care Plans",
      link: "https://www.healthyhcl.in/managed-care-plans/",
    },
    {
      name: "Dental Services",
      link: "https://www.healthyhcl.in/dental-care-services/",
    },
    {
      name: "Life Coach Services",
      link: "https://www.healthyhcl.in/doctor-consults-life-coach/",
    },
  ];

  return (
    <div className="group">
      <Typography
        className={`hover:border-b-2 ${
          isActive && "border-b-2"
        } border-black text-md font-semibold cursor-pointer text-base group flex items-center h-10 font-sans`}
      >
        Corporate Sponsored
      </Typography>

      <div className="absolute left-0 hidden group-hover:block top-10 w-full border z-50 bg-white shadow-lg  py-4 pl-6 transition-all  ">
        {/* 1 Row */}
        <Row gutter={16} offset={20}>
          {options?.map((data) => (
            <Col span={6} key={data?._id} className="mb-4">
              <a
                className="text-[#8C8C8C] text-sm cursor-pointer hover:text-[1rem] hover:text-black font-sans transition-all "
                href={data.link}
                target="_blank"
              >
                {data.name}
              </a>
            </Col>
          ))}
          <Col span={6} key={"12381y3"} className="mb-4">
            <Typography
              className="text-[#8C8C8C] text-sm cursor-pointer hover:text-[1rem] hover:text-black font-sans transition-all"
              onClick={() => {
                navigate("/free-special-consult", {
                  state: {
                    id: process.env.REACT_APP_FREE_SPECIALIST_ID,
                    name: "Free Specialist Consultant",
                    isFreeConsultant: true,
                  },
                });
              }}
            >
              Free Specialist Consult
            </Typography>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanySponsoredDropdown;
