import React, { useEffect, useState } from "react";
import PagesHeader from "../../../components/CommonComponents/PagesHeader";
import LabCard from "../../../components/OurSolution/LabCard";
import { Col, Row } from "antd";
import { getTestById } from "../../../service/homeApi";
import { useLocation } from "react-router-dom";
import NoLab from "./NoLab";
import Footer from "../../../components/CommonComponents/Footer";
import Loader from "../../../components/CommonComponents/Loader";
import { useSelector } from "react-redux";

const SelectLab = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { state } = useLocation();
  //Select Lab Details Api
  const labInCart = useSelector((state) => state.SlotSlice.labInCart);
  const [diagnostictest, setDiagnostictest] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getDiagnosticTest = async () => {
    setIsLoading(true);
    try {
      const response = await getTestById(state.id);
      const data = response?.data;
      const labs = data?.payload?.labs;

      // Find the index of the lab to swap
      const labIndex = labs.findIndex((lab) => lab.lab?._id === labInCart);

      // If labInCart is found, perform the swap
      if (labIndex !== -1) {
        const temp = labs[labIndex];
        labs[labIndex] = labs[0];
        labs[0] = temp;
      }

      setDiagnostictest(data);
    } catch (error) {}
    setIsLoading(false);
  };

  const { pincode } = useSelector((state) => state.changePincode);

  useEffect(() => {
    getDiagnosticTest();
  }, [pincode]);

  return (
    <div>
      <PagesHeader
        title={`Select Lab for ${
          diagnostictest?.payload?.name === undefined
            ? " "
            : diagnostictest?.payload?.name
        } `}
        // route={"/diagnostic-tests"}
      />

      {diagnostictest?.payload?.labs.length > 0 ? (
        <Row className="mt-6 mb-4">
          <Col
            span={window.innerWidth < 640 ? 22 : 20}
            offset={window.innerWidth < 640 ? 1 : 2}
            className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 sm:justify-start justify-center min-h-[80vh]"
          >
            <>
              {diagnostictest?.payload?.labs?.map((el) => (
                <LabCard
                  address={`${
                    el?.lab?.address?.buildingName
                      ? el?.lab?.address?.buildingName + ","
                      : el?.lab?.name
                  } ${el?.lab?.address?.area ? el?.lab?.address?.area : ""} ${
                    el?.lab?.address?.city ? el?.lab?.address?.city : ""
                  }  ${
                    el?.lab?.address?.state ? el?.lab?.address?.state : ""
                  }  ${
                    el?.lab?.address?.pincode ? el?.lab?.address?.pincode : ""
                  } `}
                  rating={el?.lab?.rating}
                  reviewsCount={el?.lab?.reviewCount}
                  price={el?.originalPrice}
                  discountPrice={el?.price}
                  categoryId={diagnostictest?.payload?.category?._id}
                  labId={el?.lab?._id}
                  isDiagnostic={true}
                  sId={state.id}
                  distance={el?.distance}
                  destinationlatitude={
                    el?.lab?.location?.coordinates?.length > 0
                      ? el?.lab?.location?.coordinates[1]
                      : ""
                  }
                  destinationlongitude={
                    el?.lab?.location?.coordinates?.length > 0
                      ? el?.lab?.location?.coordinates[0]
                      : ""
                  }
                  image={el?.lab?.partner?.image}
                  serviceMode={el?.lab?.serviceMode}
                  title={state?.title}
                  inCart={el?.inCart}
                />
              ))}
            </>
          </Col>
        </Row>
      ) : (
        <>{!isLoading && <NoLab />}</>
      )}
      {isLoading && <Loader />}
      {!isLoading && <Footer />}
    </div>
  );
};

export default SelectLab;
