import PagesHeader from "../../components/CommonComponents/PagesHeader";
import { useState } from "react";
import OrderReviewUpcomingContent from "../../components/Profile/OrderReviewUpcomingContent";
import OrderReviewPreviousContent from "../../components/Profile/OrderReviewPreviousContent";
import { SearchOutlined } from "@ant-design/icons";
import Footer from "../../components/CommonComponents/Footer";

const OrderReview = () => {
  const [isUpcoming, setIsUpcoming] = useState(true);
  const [searchData,setSearch] = useState("");

  return (
    <>
      <PagesHeader title="Order Review" />
      <div className="flex  justify-center w-[100%] min-h-[85vh]">
        <div className="w-[100%] sm:w-[44%] mt-[1rem]">
          <div className="border flex items-center p-1 mb-2 rounded lg:w-[100%] w-[92%] lg:ml-0 ml-4">
            <input
              type="text"
              placeholder="Search for orders"
              className="pl-4 outline-none h-6 w-[90%] font-sans"
              onChange={(e)=>setSearch(e.target.value)}
            />
            <SearchOutlined className="cursor-pointer bg-white text-[#8C8C8C] w-[10%] text-xl font-sans" />
          </div>
          <div className="">
            {/*------------------------------------------ Tabs ----------------------------------- */}
            <div className="flex border-b">
              <div
                className="flex justify-center items-center text-[#8C8C8C] w-[50%] p-[0.8rem] font-normal cursor-pointer font-sans"
                onClick={() => setIsUpcoming(true)}
                style={
                  isUpcoming
                    ? {
                      background: "#F5F5F5",
                      color: "black",
                      borderBottom: "2px solid black",
                      fontWeight: "600",
                    }
                    : {}
                }
              >
                Upcoming
              </div>

              <div
                className="flex justify-center items-center text-[#8C8C8C] w-[50%] p-[0.8rem] font-normal cursor-pointer font-sans"
                onClick={() => setIsUpcoming(false)}
                style={
                  !isUpcoming
                    ? {
                      background: "#F5F5F5",
                      color: "black",
                      borderBottom: "2px solid black",
                      fontWeight: "600",

                    }
                    : {}
                }
              >
                {" "}
                Previous
              </div>
            </div>

            {/*---------------------------- Tab content accordingly ---------------------------------- */}

            {isUpcoming ? (
              <OrderReviewUpcomingContent searchData={searchData}/>
            ) : (
              <OrderReviewPreviousContent searchData={searchData}/>
            )}
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  );
};
export default OrderReview;



