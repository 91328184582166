import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
// import { getPackageByCategory } from "../../service/homeApi";
import { useNavigate } from "react-router-dom";
import { getVaccinationCategory } from "../../service/homeApi";

const VaccineDropdown = ({ isActive }) => {
  const [fetchedCategories, setfetchedCategories] = useState([]);

  const getCategorys = async () => {
    try {
      const resp = await getVaccinationCategory();
      setfetchedCategories(resp?.data?.payload[3]?.subcategories);
    } catch (error) {}
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (fetchedCategories.length === 0) {
      getCategorys();
    }
  }, []);
  return (
    <div className="group">
      <Typography
        className={`text-md font-semibold font-sans cursor-pointer text-base group flex items-center h-10 ${
          isActive && "border-b-2"
        } hover:border-b-2 border-black`}
        onClick={() => navigate("/vaccination")}
      >
        Vaccination
      </Typography>
      <div className="absolute left-0 hidden group-hover:block top-10 w-full border z-50 bg-white shadow-lg  py-4 pl-6 ">
        {/* 1 Row */}
        <Row gutter={16} offset={20}>
          {fetchedCategories?.map((data) => (
            <Col span={4} key={data?._id} className="mb-1">
              <Typography
                className="text-[#8C8C8C] text-sm cursor-pointer hover:text-[1rem] hover:text-black font-sans transition-all"
                onClick={() => {
                  navigate("/vaccination", {
                    state: { id: data._id, name: data.name },
                  });
                }}
              >
                {data?.name}
              </Typography>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default VaccineDropdown;
