import { Modal, Typography } from "antd";
import React from "react";
import Exclatmation from "../../assets/images/Exclaimation.png";
import { CloseCircleOutlined } from "@ant-design/icons";

const DifferentLabModal = ({ isOpen, setIsopen }) => {
  return (
    <Modal
      open={isOpen}
      centered={true}
      closeIcon={
        <div onClick={() => setIsopen(false)}>
          <CloseCircleOutlined />
        </div>
      }
      footer={null}
    >
      <div className="flex flex-col items-center justify-center ">
        <div className="my-[1rem]">
          <img src={Exclatmation} alt="" className="h-[8rem]" />
        </div>
        <Typography className="text-[1rem] sm:text-[1.2rem]">
          You can't add items from different labs.
        </Typography>
      </div>
    </Modal>
  );
};

export default DifferentLabModal;
