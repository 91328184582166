import React, { useState } from "react";
import {
  Button,
  Card,
  Divider,
  Modal,
  Carousel,
  Typography,
  Drawer,
  Radio,
  Form,
} from "antd";
import {
  AuditOutlined,
  CloseCircleOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  StarFilled,
  FontColorsOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { image72 } from "../../assets/images";
import { getConsultantSlots } from "../../service/slotApi";
import { useEffect } from "react";
import { addToCart, deleteFromCart } from "../../service/cartApi";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getcount, setCartData } from "../../redux/Cart/cartCountSlice";
import { getDate, formateTime } from "../CommonComponents/DateAndTime";
import SlotsLoader from "../CommonComponents/SlotsLoader";
import exper from "../../assets/images/experience.svg";
import inclinic from "../../assets/images/Inclinic.svg";
import teleConsult from "../../assets/images/teleConsult.png";
import DifferentLabModal from "../CommonComponents/DifferentLabModal";
import FreeForYouModal from "../CommonComponents/FreeForYouModal";
import ConsultantBookVisitButton from "../SpecialistConsultant/ConsultantBookVisitButton";

const SpecialistConsultCard = ({
  TestImage,
  title,
  CompanyImage,
  DoctorTitle,
  content,
  SamplingType,
  price,
  reviewCount,
  rating,
  discountPrice,
  area,
  buildingName,
  city,
  sId,
  serviceMode,
  experience,
  language,
  key,
  distance,
  inCart,
  productId,
  remove,
  setRemove,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [isWarning, setIsWarning] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewDetailsModal, SetViewDetail] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(1);
  const [service, setService] = useState(null);
  const [openChangeSlot, setOpenChangeSlot] = useState(false);
  const [dataToCart, setDataToCart] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [IsFreeForUser, setIsFreeForUser] = useState(false);

  console.log("Price: ", price, "DiscountPrice: ", discountPrice);


  const handleCancel = () => {
    setOpen(false);
    SetViewDetail(false);
  };

  const getConsultantSlot = async () => {
    setIsLoading(true);
    try {
      const response = await getConsultantSlots(sId); // send consultant Id
      setSlots(response?.data?.payload);
    } catch (error) { }
    setIsLoading(false);
  };

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <LeftOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 left-4 z-10 text-[0.7rem] border-2" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <RightOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 right-5 z-10 text-[0.7rem] border-2" />
    </button>
  );

  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const deleteFunction = async () => {
    try {
      const response = await deleteFromCart(productId);
      setRemove(!remove);
      toast.success("Your cart is updated");
      dispatch(setCartData());
      dispatch(getcount());
    } catch (error) { }
  };

  const freeCategories = [
    "General Physician",
    "Psychologist",
    "Physiotherapist",
    "Gynaecologist",
  ];

  const manageModal = () => {
    if (inCart) {
      deleteFunction();
    } else {
      const user = JSON.parse(localStorage.getItem("token"));
      if (
        freeCategories.includes(DoctorTitle) &&
        user?.user?.isHclUser &&
        !openChangeSlot
      ) {
        setIsFreeForUser(true);
      } else {
        getConsultantSlot();
        setOpenChangeSlot(!openChangeSlot);
      }
    }
  };

  ////////////// on slot change----------------------------

  const handleSlot = (dayId, slotId, startTime, endTime, date) => {
    setSelectedSlot(slotId);
    setDataToCart(null);
    setDataToCart({
      type: "consultant",
      slotTimingId: slotId,
      price: price,
      serviceId: sId,
      slotId: dayId,
      startTime: startTime,
      endTime: endTime,
      date: date,
    });
  };

  // add order to cart

  const addOrderToCart = async (data) => {
    if (service === null) {
      toast.info("Please select service mode");
    } else if (!data.product?.slotTimingId) {
      toast.info("Please select time slot.");
    } else {
      data.product.serviceMode = service;
      try {
        const response = await addToCart(data);
        if (response?.data?.success) {
          toast.success("Added to the cart");
          setService(null);
        } else {
          toast.info("Your cart could not be updated");
        }
        dispatch(setCartData());
        dispatch(getcount());
      } catch (error) {
        toast.info("Your cart could not be updated");
      }
      setOpenChangeSlot(false);
      // navigate("/special-consult");  
    }
  };

  const handleSubmit = () => {
    if (!token) {
      // setIsWarning(true);
      navigate("/login");
      setOpenChangeSlot(false);
      localStorage.setItem("cartItem", JSON.stringify(dataToCart));
      localStorage.setItem("redirectPath", location.pathname);
    } else {
      addOrderToCart({ product: dataToCart });
    }
  };

  useEffect(() => {
    if (serviceMode !== "Both") {
      setService(serviceMode);
    }
  }, [serviceMode]);

  const handleOnChangeService = (e) => {
    setService(e.target.value);
  };

  useEffect(() => {
    const cartItem = JSON.parse(localStorage.getItem("cartItem"));
    const checkItem = cartItem ? Object.keys(cartItem) : [];
    if (checkItem.length) {
      addOrderToCart({ product: cartItem });
      localStorage.removeItem("cartItem");
      localStorage.removeItem("redirectPath");
    }
  }, []);

  return (
    <Card
      bodyStyle={{ padding: "0.8rem" }}
      className={`shadow-sm xl:mr-0 mr-1 border border-[#D9D9D9] pt-4`}
      key={key}
    >
      <div className="flex items-center justify-start mb-2 gap-4 h-7 pl-2">
        <img src={TestImage} alt="Test Image" className="h-[100%] " />
        <Typography className="text-lg font-medium h-[100%] flex items-center">
          {title}
        </Typography>
      </div>
      <div>
        <div className="h-10 mb-2 mt-2 flex items-center justify-between">
          {CompanyImage ? (
            <img src={CompanyImage} alt="Company Logo" className="h-[100%]" />
          ) : (
            <Typography className="font-medium text-[0.8rem]">
              {DoctorTitle}
            </Typography>
          )}

          <div className="flex items-center gap-1">
            <StarFilled className="text-[#F15E7E]" />
            <Typography className="text-base">{rating}</Typography>
            <Typography className="text-[#8C8C8C] text-xs">
              ({reviewCount === undefined ? "0" : reviewCount}+ Reviews)
            </Typography>
          </div>
        </div>

        <div>
          <div className="relative">
            <Typography className="text-[#595959] text-[0.875rem] mb-8 w-full h-7 font-sans ">
              {content?.slice(0, 65)}
              {content?.length > 65 ? <>...</> : <></>}{" "}
            </Typography>
            <div className="absolute bottom-0 -mb-4 right-2">
              <Typography className="text-right text-xs font-semibold  text-[#F15E7E]">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    SetViewDetail(true);
                  }}
                >
                  View Details
                </span>
                {window.innerWidth > 640 ? (
                  <Modal
                    open={viewDetailsModal}
                    title={
                      <span>
                        <span className="flex items-center gap-2">
                          <img
                            src={TestImage}
                            alt="Test Image"
                            className="h-[4rem] "
                          />
                          {title}{" "}
                          <span className="text-[#595959] font-medium text-sm">
                            ({DoctorTitle})
                          </span>
                        </span>
                      </span>
                    }
                    onCancel={handleCancel}
                    closeIcon={
                      <CloseCircleOutlined style={{ color: "#000" }} />
                    }
                    footer={[]}
                    centered={true}
                  >
                    <Divider className="mt-0" />
                    <Typography className="text-sm font-sans">
                      {content} <br></br>
                    </Typography>
                    <div className="mb-2">
                      {distance && (
                        <div className=" w-[30%] -ml-2 flex justify-center items-center gap-1 text-[#F15E7E] mt-7">
                          <EnvironmentOutlined className="text-[#F15E7E] text-base" />
                          <span className="text-sm">
                            {distance.toFixed(1)}km Away
                          </span>
                        </div>
                      )}
                      <div className="flex gap-2 mt-4 ">
                        <EnvironmentOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Clinic Address:{" "}
                          <strong>
                            {buildingName} {area} {city}
                          </strong>
                        </Typography>
                      </div>
                      <div className="flex items-center gap-2 mt-4">
                        <ExperimentOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Service Type:{" "}
                          <strong>
                            {serviceMode === "Both" && discountPrice === 0 ? "In Clinic" : serviceMode === "Both"
                              ? "Tele consult and In-Clinic"
                              : serviceMode === "Virtual"
                                ? "Tele Consult"
                                : serviceMode}
                          </strong>
                        </Typography>
                      </div>
                      <div className="flex items-center gap-2 mt-4">
                        <AuditOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Experience:{" "}
                          <strong>
                            {experience ? experience + " Years" : "< 1 Years"}
                          </strong>
                        </Typography>
                      </div>
                      <div className="flex items-center gap-2 mt-4">
                        <FontColorsOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Language:{" "}
                          <strong>
                            {language?.map((e, key) => {
                              return <span key={key}>{e} </span>;
                            })}
                          </strong>
                        </Typography>
                      </div>
                    </div>
                    <div className="flex w-full justify-end">
                      <ConsultantBookVisitButton
                        manageModal={manageModal}
                        inCart={inCart}
                        caseHandling={() => SetViewDetail(false)}
                      />
                    </div>
                  </Modal>
                ) : (
                  <Drawer
                    open={viewDetailsModal}
                    title={
                      <span className="-ml-3">
                        <span className="flex items-center gap-2">
                          <img
                            src={TestImage}
                            alt="Test Image"
                            className="h-[4rem] "
                          />
                          {title}{" "}
                          <span className="text-[#595959] font-medium text-sm">
                            ({DoctorTitle})
                          </span>
                        </span>
                      </span>
                    }
                    closeIcon={
                      <CloseCircleOutlined
                        style={{ color: "#000" }}
                        className="absolute right-4 top-[2.5%] text-lg"
                        onClick={handleCancel}
                      />
                    }
                    footer={[]}
                    placement={"bottom"}
                    height={"60%"}
                    key={"right"}
                    className="rounded-t-lg"
                  >
                    <Typography className="text-xs font-sans -mt-2 mb-6 min-h-10">
                      {content} <br></br>
                    </Typography>
                    <div className="mb-2">
                      <div className="flex gap-2 mt-4">
                        <EnvironmentOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Clinic Address:{" "}
                          <strong>
                            {buildingName} {area} {city}
                          </strong>
                        </Typography>
                      </div>
                      <div className="flex items-center gap-2 mt-4">
                        <ExperimentOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Service Type:{" "}
                          <strong>
                            {serviceMode === "Both" && discountPrice === 0 ? "In Clinic" : serviceMode === "Both"
                              ? "Tele consult and In-Clinic"
                              : serviceMode === "Virtual"
                                ? "Tele Consult"
                                : serviceMode}
                          </strong>
                        </Typography>
                      </div>

                      <div className="flex items-center gap-2 mt-4">
                        <AuditOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Experience:{" "}
                          <strong>
                            {experience ? experience + " Years" : "< 1 Years"}
                          </strong>
                        </Typography>
                      </div>
                      <div className="flex items-center gap-2 mt-4">
                        <FontColorsOutlined className="text-[#0057A3] text-base" />
                        <Typography className="text-sm font-sans">
                          Language:{" "}
                          <strong>
                            {language?.map((e, key) => {
                              return <span key={key}>{e} </span>;
                            })}
                          </strong>
                        </Typography>
                      </div>
                    </div>
                    <div className="flex w-full justify-end">
                      <ConsultantBookVisitButton
                        manageModal={manageModal}
                        inCart={inCart}
                        caseHandling={() => SetViewDetail(false)}
                      />
                    </div>
                  </Drawer>
                )}
              </Typography>
            </div>
          </div>
          <Typography className="text-right text-xs font-medium underline">
            {window.innerWidth > 640 ? (
              <Modal
                open={open}
                title={title}
                onCancel={handleCancel}
                closeIcon={<CloseCircleOutlined style={{ color: "#000" }} />}
                footer={[]}
                centered={true}
              >
                <Divider className="mt-0" />
                <Typography className="text-xs">{content}</Typography>
                <div className="h-10 mb-2 mt-4 flex items-center justify-between">
                  <Typography>{DoctorTitle}</Typography>
                  <div className="flex items-center gap-1">
                    <StarFilled className="text-[#F15E7E]" />
                    <Typography className="text-base">{rating}</Typography>
                    <Typography className="text-[#8C8C8C] text-xs">
                      ({reviewCount === undefined ? "0" : reviewCount}+ Reviews)
                    </Typography>
                  </div>
                </div>
                <div className="mb-10">
                  <div className="flex items-center justify-between mt-6">
                    <div className="flex items-center gap-2">
                      <EnvironmentOutlined className="text-[#0057A3]" />
                      <Typography className="text-xs">
                        Lab Address:{" "}
                        <strong>
                          {area} , {buildingName} , {city}
                        </strong>
                      </Typography>
                    </div>
                    <Typography className="text-xs text-[#F15E7E] font-medium cursor-pointer">
                      Open In Map
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <ExperimentOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Service Type: <strong>Clinical</strong>
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <FieldTimeOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Test Duration: <strong>1+ hr</strong>
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <ExclamationCircleOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Requirement: <strong>0 - 12 hrs Fasting</strong>
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <AuditOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Report Availability: <strong>Within 24hrs</strong>
                    </Typography>
                  </div>
                </div>
              </Modal>
            ) : (
              <Drawer
                open={open}
                title={title}
                closeIcon={
                  <CloseCircleOutlined
                    style={{ color: "#000" }}
                    className="absolute right-4 top-[2.5%] text-lg"
                    onClick={handleCancel}
                  />
                }
                footer={[]}
                placement={"bottom"}
                height={"60%"}
                key={"right"}
                className="rounded-t-lg"
              >
                <Typography className="text-xs">{content}</Typography>
                <div className="h-10 mb-2 mt-4 flex items-center justify-between">
                  <Typography>{DoctorTitle}</Typography>
                  <div className="flex items-center gap-1">
                    <StarFilled className="text-[#F15E7E]" />
                    <Typography className="text-base">{rating}</Typography>
                    <Typography className="text-[#8C8C8C] text-xs">
                      ({reviewCount === undefined ? "0" : reviewCount}+ Reviews)
                    </Typography>
                  </div>
                </div>
                <div className="mb-10">
                  <div className="flex items-center justify-between mt-6">
                    <div className="flex items-center gap-2">
                      <EnvironmentOutlined className="text-[#0057A3]" />
                      <Typography className="text-xs">
                        Lab Address:{" "}
                        <strong>Jayanti Kendra, Delhi 110094</strong>
                      </Typography>
                    </div>
                    <Typography className="text-xs text-[#F15E7E] font-medium cursor-pointer">
                      Open In Map
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <ExperimentOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Service Type: <strong>{SamplingType} Sampling</strong>
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <FieldTimeOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Test Duration: <strong>1+ hr</strong>
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <ExclamationCircleOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Requirement: <strong>0 - 12 hrs Fasting</strong>
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <AuditOutlined className="text-[#0057A3]" />
                    <Typography className="text-xs">
                      Report Availability: <strong>Within 24hrs</strong>
                    </Typography>
                  </div>
                </div>
              </Drawer>
            )}
          </Typography>
        </div>

        <div className="flex w-full overflow-x-scroll no-scrollbar ">
          {(serviceMode === "Virtual" || serviceMode === "Both") && (
            <div
              className={`text-[0.74rem] sm:text-[0.8rem] flex items-center justify-center pr-2 text-[#595959] rounded-none my-1  font-sans h-4`}
            >
              <img src={teleConsult} className="mr-2 h-[1.2rem]" />
              Tele-consult
            </div>
          )}
          {(serviceMode === "InClinic" || serviceMode === "Both") && (
            <div
              className={` ${serviceMode === "Both" ? "border-l border-l-[#6e6e6e] px-2" : ""
                } text-[0.74rem] sm:text-[0.8rem] flex items-center justify-center  my-1 text-[#595959] rounded-none  font-sans h-4`}
            >
              <img src={inclinic} className="mr-2 h-[1.2rem]" />
              In-Clinic
            </div>
          )}
          <div
            className={`text-[0.74rem] border-l border-l-[#6e6e6e] px-2 sm:text-[0.8rem] flex items-center justify-center  my-1 text-[#595959] rounded-none  font-sans h-4`}
          >
            <img src={exper} className="mr-2 h-[1.2rem]" />
            {experience ? experience + " Yrs Exp." : "< 1 Yrs Exp."}
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-5">
        <Typography className="font-semibold text-xl font-sans">
          <span>₹{discountPrice}</span>
          <sup className="text-xs md:text-sm font-normal text-[#8C8C8C] pl-1">
            <del>₹{price}</del>
          </sup>
        </Typography>
        <ConsultantBookVisitButton manageModal={manageModal} inCart={inCart} />
      </div>
      {window.innerWidth > 640 ? (
        <Modal
          title={`Choose Slot for ${title}`}
          open={openChangeSlot}
          centered={true}
          closeIcon={<img src={image72} onClick={manageModal} alt="Close" />}
          footer={
            <button
              className="font-sans my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px]"
              onClick={handleSubmit}
            >
              Save
            </button>
          }
          className="z-30"
        >
          <div className="border-t-2 overflow-y-scroll no-scrollbar h-[24rem] ">
            <Form id="myForm">
              <Form.Item
                className="mb-3 font-sans"
                name="serviceMode"
                rules={
                  serviceMode === "Both"
                    ? [
                      {
                        required: true,
                        message: "please select service mode",
                      },
                    ]
                    : []
                }
              >
                <div className="mt-3 w-full ">
                  <div className="flex justify-between items-center mt-4 mb-1">
                    <Typography className="font-bold ">
                      Select Service Mode
                    </Typography>
                    <hr className="border-[1px] w-[70%] sm:w-[70%]" />
                  </div>
                  <Radio.Group
                    className="w-full"
                    onChange={handleOnChangeService}
                    value={serviceMode === "Both" ? service : serviceMode}
                  >
                    <div className="flex justify-between items-center py-3 w-full px-4 ">
                      <Radio
                        value="Clinic"
                        disabled={serviceMode === "Both" ? false : true}
                      >
                        {" "}
                        <Typography className="text-[0.95rem] font-sans">
                          In-Clinic
                        </Typography>{" "}
                      </Radio>
                      {
                        discountPrice !== 0 && <Radio
                          value="Virtual"
                          disabled={serviceMode === "Both" ? false : true}
                        >
                          <Typography className="text-[0.95rem] font-sans">
                            Tele consult
                          </Typography>{" "}
                        </Radio>
                      }
                    </div>
                  </Radio.Group>
                </div>
              </Form.Item>
            </Form>

            <div className="flex justify-between items-center mt-4">
              <Typography className="font-bold ">
                Select Service Slot
              </Typography>
              <hr className="border-[1px] w-[70%] sm:w-[73%]" />
            </div>
            {slots?.consultantSlots?.length > 0 ? (
              <>
                <Carousel slidesToShow={1} {...carouselSettings}>
                  {slots?.consultantSlots?.map((day) => (
                    <div className="h-[20rem]">
                      <div className="flex justify-center items-center text-[1rem] font-semibold">
                        {getDate(day.date)}
                      </div>
                      <div className=" mt-6 w-[100%] flex flex-wrap gap-5 ">
                        {day.timeSlots.map((slot) => {
                          return (
                            <>
                              {selectedSlot === slot._id ? (
                                <button
                                  className="font-sans w-[30%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E]"
                                  id={`${slot._id}`}
                                >
                                  {formateTime(slot.startTime)}
                                </button>
                              ) : (
                                <button
                                  className={`font-sans w-[30%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots  ${slot?.isBooked &&
                                    "bg-[#E5E7EB] cursor-not-allowed line-through"
                                    }`}
                                  onClick={() =>
                                    handleSlot(
                                      day._id,
                                      slot._id,
                                      slot.startTime,
                                      slot.endTime,
                                      day.date
                                    )
                                  }
                                  id={`${slot._id}`}
                                  disabled={slot?.isBooked}
                                >
                                  {formateTime(slot.startTime)}
                                </button>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </Carousel>
              </>
            ) : (
              <div className="flex flex-col w-full h-[20rem] justify-center items-center">
                <Typography className="text-[1.4rem] ">
                  No result available
                </Typography>
                <Typography>Please try again later!</Typography>
              </div>
            )}
            {isLoading && <SlotsLoader />}
          </div>
        </Modal>
      ) : (
        <Drawer
          title="Choose Slot"
          open={openChangeSlot}
          placement={"bottom"}
          closeIcon={
            <img
              src={image72}
              onClick={manageModal}
              alt="Close"
              className="absolute right-4 top-[3%] w-[1.6rem] sm:block sm:w-[auto]"
            />
          }
          height={"60%"}
          key={"right"}
          footer={
            <div className="w-[100%] flex flex-row-reverse">
              <button
                className="font-sans my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px]"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          }
          className="rounded-t-lg"
        >
          <div>
            <Form id="myForm">
              <Form.Item
                className="mb-3 font-sans"
                name="serviceMode"
                rules={
                  serviceMode === "Both"
                    ? [
                      {
                        required: true,
                        message: "please select service mode",
                      },
                    ]
                    : []
                }
              >
                <div className=" w-full ">
                  <div className="flex justify-between items-center mb-1">
                    <Typography className="font-bold ">
                      Select Service Mode
                    </Typography>
                    <hr className="border-[1px] w-[55%] sm:w-[70%]" />
                  </div>
                  <Radio.Group
                    className="w-full"
                    onChange={handleOnChangeService}
                    value={serviceMode === "Both" ? service : serviceMode}
                  >
                    <div className="flex justify-between items-center py-3 w-full px-4 ">
                      <Radio
                        value="Clinic"
                        disabled={serviceMode === "Both" ? false : true}
                      >
                        {" "}
                        <Typography className="text-[0.95rem] font-sans">
                          In-Clinic
                        </Typography>{" "}
                      </Radio>
                      <Radio
                        value="Virtual"
                        disabled={serviceMode === "Both" ? false : true}
                      >
                        <Typography className="text-[0.95rem] font-sans">
                          Tele consult
                        </Typography>{" "}
                      </Radio>
                    </div>
                  </Radio.Group>
                </div>
              </Form.Item>
            </Form>
            <div className="flex justify-between items-center mt-4">
              <Typography className="font-bold ">
                Select Service Slot
              </Typography>
              <hr className="border-[1px] w-[60%] sm:w-[73%]" />
            </div>
            {slots?.consultantSlots?.length > 0 ? (
              <>
                <Carousel slidesToShow={1} {...carouselSettings}>
                  {slots?.consultantSlots?.map((day) => (
                    <div className="h-[20rem]">
                      <div className="flex justify-center items-center text-[1rem] font-semibold">
                        {getDate(day.date)}
                      </div>
                      <div className=" mt-6 w-[100%] flex flex-wrap gap-5 ">
                        {day.timeSlots.map((slot) => {
                          return (
                            <>
                              {selectedSlot === slot._id ? (
                                <button
                                  className="font-sans w-[29%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E]"
                                  id={`${slot._id}`}
                                >
                                  {formateTime(slot.startTime)}
                                </button>
                              ) : (
                                <button
                                  className={`font-sans w-[29%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots  ${slot?.isBooked &&
                                    "bg-[#E5E7EB] cursor-not-allowed line-through"
                                    } `}
                                  onClick={() =>
                                    handleSlot(
                                      day._id,
                                      slot._id,
                                      slot.startTime,
                                      slot.endTime,
                                      day.date
                                    )
                                  }
                                  id={`${slot._id}`}
                                  disabled={slot?.isBooked}
                                >
                                  {formateTime(slot.startTime)}
                                </button>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </Carousel>
              </>
            ) : (
              <div className="flex flex-col w-full h-[20rem] justify-center items-center">
                <Typography className="text-[1.4rem] ">
                  No result available
                </Typography>
                <Typography>Please try again later!</Typography>
              </div>
            )}
            {isLoading && <SlotsLoader />}
          </div>
        </Drawer>
      )}

      {isWarning && (
        <>
          <div className="w-[22rem] h-[8rem] shadow-lg bg-white fixed top-[10%] right-8 p-[1rem] border-2 rounded-md z-50">
            <Typography
              className="text-[1.2rem] cursor-pointer absolute right-2 top-0 font-semibold text-[#F15E7E]"
              onClick={() => {
                setIsWarning(false);
              }}
            >
              x
            </Typography>
            <Typography className="text-[1rem]">
              You are not logged in.
            </Typography>
            <Typography className="text-[0.8rem]">
              Please log in to add items in Cart{" "}
            </Typography>
            <Typography
              className="text-[0.9rem] text-[#F15E7E] mt-2 cursor-pointer underline"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login{" "}
            </Typography>
          </div>
        </>
      )}

      <FreeForYouModal
        isOpen={IsFreeForUser}
        setIsopen={setIsFreeForUser}
        onConfirm={() => {
          setIsFreeForUser(false);
          getConsultantSlot();
          setOpenChangeSlot(!openChangeSlot);
        }}
      />

      <style jsx>{`
        .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
          border-color: #f15e7e;
          background-color: #f15e7e;
        }
      `}</style>
    </Card>
  );
};

export default SpecialistConsultCard;
