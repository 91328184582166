import { axiosInstance } from "../utils/axios";

export const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/account/get-user`);
      if (response.success) {
        return response.data;
      }
      return response;
    } catch (error) {
    }
  };


// ********************** Update User Details *******************************

export const updateUserDetails = async (data) => {
    try {
        const response = await axiosInstance.patch(`api/v1/account/update-user/profile`, data);
        if (response.success) {
            return response.message;
        }
        return response;
    } catch (error) {
    }
};