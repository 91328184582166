import { Space, Spin } from 'antd'
import React from 'react'

const Loader = () => {
    return (
        <div
            className="w-[100vw] h-[100vh] fixed  z-40 top-0 left-0"
            style={{ background: "rgba(0, 0, 0, 0.40)" }}
        >
            <Space
                direction="vertical"
                className="w-[100%] h-[100%] flex justify-center"
            >
                <Spin tip="Loading" size="large">
                    <div className="content" />
                </Spin>
            </Space>

        </div>
    )
}

export default Loader