import { Typography } from "antd";
import React, { useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const [faqClick, setFaqClick] = useState();
  const FaqHandler = (data) => {
    setFaqClick(faqClick === data ? null : data);
  };

  const navigate = useNavigate();

  const goToFaqPage = () => {
    navigate("/faq");
  };

  const data = [
    {
      id: 1,
      que: "What are Value Added Services?",
      ans: "Value added services are additional pocketed health services at highly discounted rates for HCL employees & their dependents.",
    },
    {
      id: 2,
      que: "Who all are eligible for value added services?",
      ans: "The employees, their dependents and extended family members can avail themselves of Value-Added Services at highly discounted rates.",
    },
    {
      id: 3,
      que: "Is there any Discount on the services?",
      ans: "Yes, the Services are provided at discounted rates. The discount is applicable based on employee entity.  ",
    },
    {
      id: 4,
      que: " How do I place an order on HCL Healthcare's ecommerce platform",
      ans: "How do I place an order on HCL Healthcare's ecommerce platform How do I place an order on HCL Healthcare's ddhhd ecommerce platform How do I place an order on HCL Healthcare's ecommerce platform",
    },
  ];

  return (
    <div className="pt-8 mt-[5rem] sm:mt-0 mb-[8%]">
      <div className="w-100 items-center flex justify-items-center flex-col ">
        <Typography className="text-center font-semibold text-[1.2rem] sm:text-[2rem] font-sans">
          Frequently Asked Questions (FAQs)
        </Typography>
        <Typography className="text-center text-[#434343] text-[0.9rem] w-[40%] m-[auto] sm:text-[1.1rem] mb-[1rem] sm:mb-[2rem] font-sans">
          You can find all your queries here
        </Typography>
      </div>
      <div>
        {data &&
          data?.slice(0, 3)?.map((data) => {
            return (
              <div
                className="mx-[5%] md:mx-[15%] border-b border-[#BFBFBF] sm:px-4"
                key={data.id}
              >
                <div
                  className="flex justify-between my-4 "
                  onClick={() => FaqHandler(data.id)}
                >
                  <Typography className="font-normal md:text-[1rem] text-[0.875rem] w-[85%] font-sans">
                    {data.que}
                  </Typography>
                  <div>
                    {faqClick === data.id ? (
                      <MinusOutlined className="cursor-pointer" />
                    ) : (
                      <PlusOutlined className="cursor-pointer" />
                    )}
                  </div>
                </div>
                <div className="my-2">
                  {faqClick === data.id ? (
                    <Typography className="font-normal md:text-[0.95rem] text-[0.87rem] text-[#8C8C8C] font-sans">
                      {data.ans}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      <div className="mt-3 flex justify-end mx-[5%] md:mx-[15%] ">
        <Typography
          className="underline text-[0.95rem] text-[#0057A3] font-semibold cursor-pointer font-sans"
          onClick={goToFaqPage}
        >
          View More Questions
        </Typography>
        {/* log */}
      </div>
    </div>
  );
};

export default FAQ;
