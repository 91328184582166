import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./features/alertSlice";
import authSlice from "./features/auth/authSlice";
import userSlice from "./features/userSlice";
import cartSlice from "./features/cartSlice";
import OfferDetail from "./Offers/offerSlice";
import availableSlotsSlice from "./Cart/availableSlice";
import cartCount from "./Cart/cartCountSlice";
import offerNameSlice from "./Offers/offerNameSlice";
import locationSlice from "./features/locationSlice";
import whatsappToastSlice from "./Popup/whatsappToast.Slice";
import changePincodeSlice from "./Pincode/changePincode";
import BannerSlice from "./Banner/BannerSlice";
import AddOnsDiagnosticSlice from "./AddOnsDiagnostic/AddOnsDiagnosticSlice";
import diagnosticVarSlice from "./DiagnosticTests/diagnosticVarSlice";
import profileAndPackagesVarSlice from "./ProfileAndPackages/profileAndPackagesVarSlice";
import specialistConsultantVarSlice from "./SpecialistConsultant/specialistConsultantVarSlice";
import landingPageSlice from "./LandingPage/landingPageSlice";
import slotSlice from "./Slots/slot.js";
import vaccineVarSlice from "./Vaccine/vaccineVarSlice";
import AuthSlice from "./Auth/AuthSlice.js";

export const store = configureStore({
  reducer: {
    alerts: alertSlice,
    auth: authSlice,
    users: userSlice,
    cart: cartSlice,
    Offers: OfferDetail,
    availableSlots: availableSlotsSlice,
    count: cartCount,
    offerName: offerNameSlice,
    address: locationSlice,
    whatsappPop: whatsappToastSlice,
    changePincode: changePincodeSlice,
    Banner: BannerSlice,
    AddOnsDiagnostic: AddOnsDiagnosticSlice,
    diagnosticVariables: diagnosticVarSlice,
    ProfileAndPackagesVariables: profileAndPackagesVarSlice,
    SpecialistConsltantVariables: specialistConsultantVarSlice,
    LandingPageSlice: landingPageSlice,
    SlotSlice: slotSlice,
    vaccinationVariables: vaccineVarSlice,
    Auth: AuthSlice,
  },
});
