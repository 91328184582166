import React, { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import Character from "../../assets/images/Character.png";
import { Pagination, Typography } from "antd";
import { getAllOrders } from "../../service/orderReviewApi";
import {
  getDate,
  formateTime,
  formateDate,
} from "../CommonComponents/DateAndTime";

const OrderReviewUpcomingContent = ({ searchData }) => {
  const [UpcomingOrders, setUpcomingOrders] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [page, setPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState();

  useEffect(() => {
    if (searchData) {
      setPage(1);
    }
    getUpcomingOrders();
  }, [isChanged, page, searchData]);

  const getUpcomingOrders = async () => {
    try {
      const response = await getAllOrders(page, "", searchData);
      setTotalOrders(response?.data?.payload?.totalOrders);
      setUpcomingOrders(response?.data?.payload);
    } catch (error) {}
  };

  return (
    <>
      <div className="w-[100%] p-[1rem] sm:h-[60vh] overflow-y-scroll no-scrollbar">
        {UpcomingOrders &&
          UpcomingOrders?.orders?.map((data) => {
            return (
              <OrderCard
                title={data?.serviceName}
                status={data?.status?.toLowerCase()}
                dateAndTime={
                  getDate(data?.product?.slotDetails?.[0]?.date) +
                  " | " +
                  formateTime(
                    data?.product?.slotDetails?.[0]?.slotTimings?.startDate
                  )
                }
                transactionID={data?.transactionId}
                orderId={data?.orderId}
                price={data?.totalAmount}
                netPrice={data?.discountedAmount}
                serviceType={data?.product?.type}
                patients={data?.product?.slotDetails?.[0]?.patient}
                address={data?.serviceAddress}
                setIsChanged={setIsChanged}
                isCancelRequested={data?.isCancelRequested}
                isChanged={isChanged}
                getOrders={getUpcomingOrders}
                unformattedDateandTime={data?.product?.slotDetails?.[0]?.date}
                isRescheduleRequested={
                  data?.rescheduledData?.isRescheduleRequested
                }
                isRescheduled={data?.rescheduledData?.isRescheduled}
                rescheduleDateAndTime={
                  formateDate(data?.rescheduledData?.finishTime).split(", ")[0]
                    ? formateDate(data?.rescheduledData?.finishTime).split(
                        ", "
                      )[0] +
                      ", " +
                      formateDate(data?.rescheduledData?.appointmentTime).split(
                        ", "
                      )[1]
                    : ""
                }
                message={
                  data?.rescheduledData?.isRescheduleRequested
                    ? "Already Rescheduled"
                    : undefined
                }
              />
            );
          })}

        {UpcomingOrders?.orders?.length === 0 && (
          <div className="flex flex-col items-center gap-3">
            <img src={Character} alt="" className="h-[48vh]" />
            <Typography className="text-[1.1rem] text-[#1F1F1F] font-semibold font-sans">
              No Upcoming Orders
            </Typography>
          </div>
        )}
      </div>
      {UpcomingOrders?.orders?.length > 0 && (
        <Pagination
          current={page}
          onChange={(page) => {
            setPage(page);
          }}
          total={totalOrders}
          pageSize={10}
          showSizeChanger={false}
          className="flex items-center justify-end mt-0 md:mt-4"
        />
      )}
    </>
  );
};

export default OrderReviewUpcomingContent;
