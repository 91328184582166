import { Typography, Button, Form, Input, Select } from "antd";
import React, { useState } from "react";
import AdressForm from "./AdressForm";

const AddressComponent = ({
  setIsAdressAdded,
  isAddressAdded,
  fetchAddress,
}) => {
  return (
    <div className="min-h-[75vh] md:h-auto">
      <div className="w-[100%] md:border md:border-[#D9D9D9] md:shadow md:rounded-lg md:p-4 mb-10 ">
        <Typography className="font-semibold text-[1.1rem] font-sans">
          Add Address
        </Typography>
        <Typography className=" pb-2 md:border-b font-sans">
          This address will be used to complete your profile
        </Typography>
        <AdressForm
          setIsAdressAdded={setIsAdressAdded}
          isAddressAdded={isAddressAdded}
          fetchAddress={fetchAddress}
        />
      </div>
    </div>
  );
};

export default AddressComponent;
