import axios from "axios";
import { BASE_URL } from "../utils/globalVariable";
import { axiosInstance } from "../utils/axios";

/********************Get all the test (GET) **********************/
export const getAllTest = async (
  pageNo,
  categoryId,
  sampling,
  limit,
  subCategory
) => {
  try {
    const longitude = localStorage.getItem("longitude");
    const latitude = localStorage.getItem("latitude");
    const pincode = localStorage.getItem("pincode");
    const response = await axiosInstance.get(
      `${BASE_URL}/api/v1/test/?page=${pageNo || ""}&category=${
        categoryId || ""
      }&homeSampling=${(sampling === "home" && true) || ""}&labSampling=${
        (sampling === "lab" && true) || ""
      }&limit=${limit || ""}&subcategory=${
        subCategory || ""
      }&longitude=${longitude}&latitude=${latitude}&pincode=${pincode}`
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

// /********************Get all the test (GET) **********************/
export const getdiagnosticNavbar = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/test/?navBar=true`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

export const getVaccinationCategory = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1//test/category`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/********************Get test by Id (GET) **********************/
export const getTestById = async (id) => {
  try {
    const longitude = localStorage.getItem("longitude");
    const latitude = localStorage.getItem("latitude");
    const pincode = localStorage.getItem("pincode");
    const params = {};
    if (latitude) params["latitude"] = latitude;
    if (longitude) params["longitude"] = longitude;
    if (pincode) params["pincode"] = pincode;
    const response = await axiosInstance.get(`${BASE_URL}/api/v1/test/${id}`, {
      params: params,
    });
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Get test by Category (GET) **********************/
export const getTestByCategory = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/test/category`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Get test by Category (GET) **********************/
export const getAllConsultant = async (
  pageNo,
  categoryId,
  sampling,
  limit,
  type
) => {
  const longitude = localStorage.getItem("longitude");
  const latitude = localStorage.getItem("latitude");
  const pincode = localStorage.getItem("pincode");
  try {
    if (!categoryId) {
      const response = await axiosInstance.get(
        `${BASE_URL}/api/v1/consultant?page=${
          pageNo || ""
        }&limit=${limit}&inClinic=${
          (sampling === "inclinic" && true) || ""
        }&virtual=${(sampling === "virtual" && true) || ""}&both=${
          (sampling === "both" && true) || ""
        }&type=${type}&longitude=${longitude}&latitude=${latitude}&pincode=${pincode}`
      );
      if (response.success) {
        return response.data;
      }
      return response;
    } else {
      const response = await axios.get(
        `${BASE_URL}/api/v1/consultant?page=${
          pageNo || ""
        }&limit=${limit}&category=${categoryId || ""}&inClinic=${
          (sampling === "inclinic" && true) || ""
        }&virtual=${
          (sampling === "virtual" && true) || ""
        }&longitude=${longitude}&latitude=${latitude}&pincode=${pincode}&type=${type}`
      );
      if (response.success) {
        return response.data;
      }
      return response;
    }
  } catch (error) {
    return error;
  }
};

/********************Get all the Packages (GET) **********************/
export const getAllPackages = async (pageNo, categoryId, sampling, limit) => {
  try {
    const longitude = localStorage.getItem("longitude");
    const latitude = localStorage.getItem("latitude");
    const pincode = localStorage.getItem("pincode");
    const response = await axiosInstance.get(
      `${BASE_URL}/api/v1/package/?page=${
        pageNo || ""
      }&limit=${limit}&category=${categoryId || ""}&homeSampling=${
        (sampling === "home" && true) || ""
      }&labSampling=${
        (sampling === "lab" && true) || ""
      }&longitude=${longitude}&latitude=${latitude}&pincode=${pincode}`
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};
/********************Get all the Packages (GET) **********************/
export const getAllPopularPackages = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/package/?page=1&mostPopular=true`
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Get Package by Id (GET) **********************/

export const getPackageById = async (id) => {
  try {
    const longitude = localStorage.getItem("longitude");
    const latitude = localStorage.getItem("latitude");
    const pincode = localStorage.getItem("pincode");
    const params = {};
    if (latitude) params["latitude"] = latitude;
    if (longitude) params["longitude"] = longitude;
    if (pincode) params["pincode"] = pincode;
    const response = await axiosInstance.get(`/api/v1/package/${id}`, {
      params: params,
    });
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Get Package Category (GET) **********************/
export const getPackageByCategory = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/package/category`);
    if (response.success) {
      return response;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Search (GET) **********************/
export const getGlobalSearch = async (payload) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/global?keyword=${payload.keyword}&pincode=${payload.pincode}`
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Banner (GET) **********************/
export const getLandingBanner = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/banner`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Partner (GET) **********************/
export const getLandingPartner = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/partner`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/********************Offer (GET) **********************/
export const getLandingOffer = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/adminRoutes/campaign?isActive=true`
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/******************** Consultant (GET) **********************/
export const getLandingConsultant = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/consultant/category`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/******************** Consultant (GET) **********************/
export const subscribeUser = async (payload) => {
  const response = await axios.post(`${BASE_URL}/api/v1/subscribe`, payload);
  return response;
};

/********************Get all the Blogs (GET) **********************/
export const getAllBlogs = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/blog`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};
