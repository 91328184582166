import React, { useState } from "react";
import { Modal, Typography } from "antd";
import { Space, Input, Button } from "antd";
import Subscribe_web from "../../assets/images/Subsribe_Web.png";
import { subscribeUser } from "../../service/homeApi";
import { toast } from "react-toastify";
import Loader from "../CommonComponents/Loader";
import { CloseCircleOutlined } from "@ant-design/icons";
import { OfferAppliedgif } from "../../assets/images";
import thumbnailTick from "../../assets/images/thumbnailTick.png";

const Subscribe = () => {
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsopen] = useState(false);

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      const payload = {
        email: userEmail,
      };
      const response = await subscribeUser(payload);
      if (response?.data?.message === "You have already subscribed") {
        toast.info("You have already Subscribed. Book your services now.");
      }
      else {
        setIsopen(true);
      }
    } catch (error) {
      toast.info("Please provide a valid Email-Id");
    }
    setIsLoading(false);
    setUserEmail("");
  };
  return (
    <>
      <div className="mt-[15%] mb-[10%] sm:mb-[5%] 'h-[5%] md:h-[27vh] rounded-lg flex flex-col  md:flex-row md:items-center justify-between sm:mt-[5%]">
        <img
          src={Subscribe_web}
          alt="background"
          className="h-[5%] md:h-[27vh] w-[100%] object-fill  rounded-lg z-[-1] absolute"
        />

        <div className="px-4 md:px-12 relative pt-[7%] md:pt-0">
          <Typography className="text-xl md:text-[1.65rem] font-semibold text-white font-sans">
            Subscribe
          </Typography>
          <Typography className="mt-2 text-sm md:text-[0.9rem] font-light text-[#F0F0F0] font-sans">
            To get updates on discounts and offers on Healthcare services
          </Typography>
        </div>
        <Space.Compact className="w-100 md:w-[45%]  md:px-12 h-40vh my-[1rem] w-[100%] px-4">
          <Input
            placeholder="Enter email ID"
            className="bg-transparent bg-opacity-10 !border-white text-white placeholder-gray-100 font-light font-sans"
            value={userEmail}
            onChange={(e) => setUserEmail(e?.target?.value)}
          />
          <Button
            className="!text-[#0057A3] !border-white bg-white h-10 font-bold px-5 font-sans"
            onClick={() => handleSubscribe()}
          >
            Subscribe
          </Button>
        </Space.Compact>
        {isLoading && <Loader />}
      </div>
      <Modal
        open={isOpen}
        centered={true}
        closeIcon={
          <div onClick={() => setIsopen(false)}>
            <CloseCircleOutlined />
          </div>
        }
        footer={null}
      >
        <div className="flex flex-col items-center justify-center pb-10">
          <div className="flex relative justify-center items-center overflow-hidden w-[100%] sm:h-[15rem] h-[12rem] sm:my-10">
            <img src={OfferAppliedgif} alt="" className="w-[120%]" />
            <img src={thumbnailTick} alt="" className="w-[25%] absolute" />
          </div>
          <Typography className="text-[1rem] sm:text-[1.2rem] font-semibold">
            Thank you for registering.
          </Typography>
          <Typography className="text-[0.8rem] sm:text-[1rem] text-center">
            We have sent a confirmation email to your email address.
          </Typography>
        </div>
      </Modal>
    </>
  );
};

export default Subscribe;
