import {
  Col,
  Row,
  Typography,
  Collapse,
  Button,
  Checkbox,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/CommonComponents/Footer";
import ContainerWithShadow from "../../components/CommonComponents/ContainerWithShadow";
import { image68, image74 } from "../../assets/images";
import AddPatientFom from "../../components/CartComponents/AddPatientFom";
import DeleteModal from "../../components/CommonComponents/DeleteModal";
import BillDetail from "../../components/CartComponents/BillDetail";
import OffersAndDiscountCard from "../../components/CartComponents/OffersAndDiscountCard";
import EditPatientDetailModal from "../../components/CartComponents/EditPatientDetailModal";
import PagesHeader from "../../components/CommonComponents/PagesHeader";
import { deleteFromCart, getCart, removePatient } from "../../service/cartApi";
import Character from "../../assets/images/Character.svg";
import { getConsultantSlots, getTestSlots } from "../../service/slotApi";
import { useDispatch, useSelector } from "react-redux";
import { getOffers } from "../../redux/Offers/offerSlice";
import { offerFalse, offerTrue } from "../../redux/features/alertSlice";
import { addAvaialbleSlots } from "../../redux/Cart/availableSlice";
import { getcount } from "../../redux/Cart/cartCountSlice";
import { axiosInstance } from "../../utils/axios";
import { toast } from "react-toastify";
import {
  formateDate,
  formateTime,
} from "../../components/CommonComponents/DateAndTime";
import SearchBarMobile from "../../components/CommonComponents/SearchBarMobile";
import IscontinueDifferentLabModal from "../../components/CommonComponents/IsContinueDifferentLabModal";
import { addIsDifferentLab } from "../../redux/Offers/offerNameSlice";

const Cart = () => {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [openEditPatient, setOpenEditPatient] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [cartData, setCartData] = useState([]);
  const filteredCartData = [];
  const [isProductDelete, setIsporductDelete] = useState(true);
  const [productId, setProductId] = useState();
  const [categoryId, setcategoryId] = useState();
  const [labId, setLabId] = useState();
  const [consultantId, setconsultantId] = useState();
  const [removePatientDetail, setRemovePatientDetail] = useState();
  const [editPatientDetail, seteditPatientDetail] = useState();
  const [availableSlots, setavailableSlots] = useState([]);
  const [slotTimeId, setslotTimeId] = useState();
  const [type, setType] = useState();
  const [slotDetailsId, setSlotDetailsId] = useState();
  const [collapseKey, SetCollapseKey] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDifferentLabModalOpen, setIsDifferentLabModalOpen] = useState(false);
  let showAbove = false;
  let continueDisable = true;

  const handlePanelChange = (key) => {
    SetCollapseKey(key);
  };
  const dispatch = useDispatch();

  const isOffer = useSelector((state) => state.alerts.isOfferApplied);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    dispatch(getOffers());
  }, []);

  useEffect(() => {
    if (cartData?.amount?.discount > 0) {
      dispatch(offerTrue());
    } else {
      dispatch(offerFalse());
    }
  }, [cartData?.amount?.discount]);

  const handleEditPatientModal = () => {
    setOpenEditPatient(!openEditPatient);
  };

  const isContinue = useSelector((state) => state.offerName.isDifferentLab);
  const handleCheckout = () => {
    if (isContinue) {
      setIsDifferentLabModalOpen(true);
    } else {
      navigate("/checkout");
    }
  };
  const handleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const deleteFunction = async () => {
    if (isProductDelete) {
      try {
        const response = await deleteFromCart(productId);
        dispatch(setCartData());
        dispatch(getcount());
        dispatch(getOffers());
      } catch (error) {}
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
    getData();
  };

  // removing patient from product --------------------------

  const removePatientFromProduct = async () => {
    try {
      const response = await removePatient(removePatientDetail);
    } catch (error) {}
    handleDeleteModal();
    getData();
  };

  // get cart data api call----------------------------

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await getCart();
      if (response) {
        setCartData(response?.data?.payload?.cart);
        dispatch(addIsDifferentLab(response?.data?.payload?.cart?.isdiffLab));
        setIsLoading(false);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const getSlots = async (type, data, e) => {
    let slots = [];
    if (data.type === "test") {
      try {
        const response = await getTestSlots(data.categoryId, data?.labId);
        if (response) {
          setavailableSlots(response?.data?.payload);
          slots = response?.data?.payload;
          dispatch(addAvaialbleSlots(response?.data?.payload?.testSlot));
        }
        setLabId(data.labId);
      } catch (error) {}
    } else if (data.type === "package") {
      try {
        const response = await getTestSlots(data.categoryId, data?.labId);
        if (response) {
          setavailableSlots(response?.data?.payload);
          slots = response?.data?.payload;
          dispatch(addAvaialbleSlots(response?.data?.payload?.packageSlot));
        }
        setLabId(data.labId);
      } catch (error) {}
    } else {
      try {
        const response = await getConsultantSlots(data.consultantId);
        if (response) {
          setavailableSlots(response?.data?.payload?.consultantSlots);
          slots = response?.data?.payload?.consultantSlots;
        }
      } catch (error) {}
    }

    seteditPatientDetail(e);
    setslotTimeId(data?.slotTimeId);
    setSlotDetailsId(data?.slotDetailsId);
    setProductId(data?.productId);

    if (window.innerWidth > 640) {
      handleEditPatientModal();
    } else {
      navigate("/cart/edit-patient-detail", {
        state: {
          patientDetails: e,
          availableSlots: slots?.length > 0 ? slots : "",
          slotTimeId: data?.slotTimeId,
          slotDetailsId: data?.slotDetailsId,
          type: data?.type,
          productId: data?.productId,
          labId: data?.labId,
        },
      });
    }
  };

  //filtering cart data for mapping
  cartData?.product?.map((data) => {
    if (data.type === "package") {
      filteredCartData.push({
        productId: data?._id,
        type: data?.type,
        name: data?.packageDetails?.package?.name,
        categoryId: data?.packageDetails?.package?.category?._id,
        typeId: data?.packageDetails?._id,
        labName: data?.packageDetails?.lab?.name,
        labId: data?.packageDetails?.lab?._id,
        pincode: data?.packageDetails?.pincode
          ? data?.packageDetails?.pincode
          : "",

        slotTimeId:
          data?.packageDetails?.slotDetails[0]?.slotTimings?.slotTimingId,
        slotDetailsId: data?.packageDetails?.slotDetails[0]?._id,
        patient: data?.packageDetails?.slotDetails[0]?.patient
          ? data?.packageDetails?.slotDetails[0]?.patient
          : null,
        startTime: formateTime(
          data?.packageDetails?.slotDetails[0]?.slotTimings?.startDate
        ),
        endTime: formateTime(
          data?.packageDetails?.slotDetails[0]?.slotTimings?.endDate
        ),
        date: formateDate(data?.packageDetails?.slotDetails[0]?.date).split(
          ", "
        )[0],
        price: data?.packageDetails?.price,
        serviceMode:
          data?.packageDetails?.serviceMode === "Home"
            ? "At Home"
            : "Visit center",
      });
    } else if (data.type === "test") {
      filteredCartData.push({
        productId: data?._id,
        type: data?.type,
        name: data?.testDetails?.test?.name,
        categoryId: data?.testDetails?.test?.category?._id,
        typeId: data?.testDetails?._id,
        labName: data?.testDetails?.lab?.name,
        labId: data?.testDetails?.lab?._id,
        pincode: data?.testDetails?.pincode ? data?.testDetails?.pincode : "",
        slotTimeId:
          data?.testDetails?.slotDetails[0]?.slotTimings?.slotTimingId,
        slotDetailsId: data?.testDetails?.slotDetails[0]?._id,
        patient: data?.testDetails?.slotDetails[0]?.patient
          ? data?.testDetails?.slotDetails[0]?.patient
          : null,
        startTime: formateTime(
          data?.testDetails?.slotDetails[0]?.slotTimings?.startDate
        ),
        endTime: formateTime(
          data?.testDetails?.slotDetails[0]?.slotTimings?.endDate
        ),
        date: formateDate(data?.testDetails?.slotDetails[0]?.date).split(
          ", "
        )[0],
        price: data?.testDetails?.price,
        serviceMode:
          data?.testDetails?.serviceMode === "Home"
            ? "At Home"
            : "Visit center",
      });
    } else if (data.type === "consultant") {
      filteredCartData.push({
        productId: data?._id,
        type: data?.type,
        name: data?.consultancyDetails?.consultantId?.name,
        consultantId: data?.consultancyDetails?.consultantId?._id,
        typeId: data?.consultancyDetails?._id,
        pincode: "",
        slotTimeId:
          data?.consultancyDetails?.slotDetails[0]?.slotTimings?.slotTimingId,
        slotDetailsId: data?.consultancyDetails?.slotDetails[0]?._id,
        patient: data?.consultancyDetails?.slotDetails[0]?.patient
          ? data?.consultancyDetails?.slotDetails[0]?.patient
          : null,
        startTime: formateTime(
          data?.consultancyDetails?.slotDetails[0]?.slotTimings?.startDate
        ),
        endTime: formateTime(
          data?.consultancyDetails?.slotDetails[0]?.slotTimings?.endDate
        ),
        date: formateDate(data?.consultancyDetails?.slotDetails[0]?.date).split(
          ", "
        )[0],
        price: data?.consultancyDetails?.price,
        serviceMode:
          data?.consultancyDetails?.serviceMode === "Virtual"
            ? "Tele-Consult"
            : "In-Clinic",
      });
    }
  });

  useEffect(() => {
    dispatch(addAvaialbleSlots(availableSlots));
  }, [availableSlots]);

  //// same as above api call -------------------------------------
  const sameAsAbove = async (productId, index) => {
    setIsLoading(true);
    try {
      const resp = await axiosInstance.put(
        `/api/v1/cart/same-patient?productId=${productId}&indexValue=${index}`
      );
      if (resp.data.success) {
        getData();
      }
    } catch (error) {
      toast.info("Something went wrong.");
    }
  };

  return (
    <div>
      {/* Header */}
      <PagesHeader
        title="Cart"
        text={`(${filteredCartData.length} Items)`}
        route={"/"}
      />

      <Row className="min-h-[95vh]">
        <Col
          span={window.innerWidth < 640 ? 22 : 20}
          offset={window.innerWidth < 640 ? 1 : 2}
          className="pt-[1rem]"
        >
          <div className=" sm:hidden mb-6">
            <SearchBarMobile compId="cart-location" />
          </div>
          {filteredCartData.length > 0 ? (
            <div className="md:flex justify-between ">
              <div className="md:w-[55%]">
                {/* Test cases Added to the cart -----------------------------------------------------------*/}
                {/* {filteredCartData?.map((data) => ( */}
                {filteredCartData?.map((data, index) => {
                  if (data?.patient.length < 1) {
                    continueDisable = false;
                    if (index > 0) {
                      if (filteredCartData[index - 1].patient.length > 0) {
                        showAbove = true;
                      } else {
                        showAbove = false;
                      }
                    }
                  }
                  return (
                    <div className="mb-4">
                      <ContainerWithShadow
                        icon={data?.icon}
                        testName={data?.name}
                        w={data.w}
                        labName={
                          data
                            ? `${data.labName ? data.labName + ", " : ""}${
                                data.pincode ? data.pincode + ", " : ""
                              }${data.serviceMode || ""}`
                            : ""
                        }
                        sideText="Remove"
                        onclickF={() => {
                          setProductId(data?.productId);
                          setIsporductDelete(true);
                          handleDeleteModal();
                        }}
                      >
                        {/* Already Added Patient UI -----------------------------------------------------------*/}
                        <>
                          {data?.patient?.map((e) => {
                            return (
                              <div className="border border-[#d9d9d9] rounded-lg flex justify-between items-center px-2 py-3 mt-[1rem] ">
                                <div className="flex">
                                  <img
                                    src={image74}
                                    alt=""
                                    className="mr-[1rem]"
                                  />
                                  {window.innerWidth < 640 ? (
                                    <div>
                                      <Typography className="text-[#777777] text-[0.95rem] sm:text-[1.1rem] font-sans">
                                        {e?.firstName} {e?.lastName}
                                      </Typography>
                                      <Typography className="text-[#777777] text-[0.95rem] sm:text-[1.1rem] font-sans">
                                        Slot - {data?.startTime} to{" "}
                                        {data?.endTime}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <Typography className="text-[#777777] text-[1rem] font-sans">
                                      {e?.firstName} {e?.lastName} | Slot -{" "}
                                      {data?.startTime} to {data?.endTime}
                                    </Typography>
                                  )}
                                </div>

                                <div className=" flex justify-between items-center">
                                  <EditOutlined
                                    className="text-[#0057A3] text-[1.2rem] px-2 cursor-pointer"
                                    onClick={() => {
                                      if (data.type === "consultant") {
                                        setconsultantId(data?.consultantId);
                                      } else {
                                        setcategoryId(data?.categoryId);
                                        setLabId(data?.labId);
                                      }
                                      setType(data?.type);
                                      getSlots(data?.type, data, e);
                                    }}
                                  />{" "}
                                  <DeleteOutlined
                                    className="text-[#F15E7E] text-[1.2rem] p-1 cursor-pointer"
                                    onClick={() => {
                                      setIsporductDelete(false);
                                      setRemovePatientDetail({
                                        productId: data?.productId,
                                        patientId: e._id,
                                        typeId: data?.typeId,
                                        type: data?.type,
                                        slotDetailsId: data?.slotDetailsId,
                                      });
                                      handleDeleteModal();
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </>

                        <div className=" w-full mt-2 -mb-1  flex justify-between items-center">
                          <Typography className=" text-[#777777] text-[0.9rem] sm:text-[1rem]">
                            {data?.date && `Date : ${data?.date}`}
                          </Typography>
                          <Typography className="text-[#777777] text-[0.9rem] sm:text-[1rem]">
                            Amount : ₹{data?.price}
                          </Typography>
                          {/* <Typography>Total Price</Typography> */}
                        </div>
                        {/* Select for suggested patient -----------------------------------------------------------*/}

                        {/* Collapse for add patient -----------------------------------------------------------*/}

                        <div>
                          <Collapse
                            activeKey={collapseKey}
                            className=" w-[100%] bg-[white] "
                            bordered={false}
                            onChange={handlePanelChange}
                          >
                            <Panel
                              header={
                                <div className="flex  sm:flex-row justify-between  sm:items-center">
                                  <div className="flex py-[0.4rem] sm:w-[50%]  items-center  ">
                                    <img
                                      src={image68}
                                      alt="+"
                                      className="w-[1.5rem] mr-2"
                                    />
                                    <Typography className="font-semibold text-[0.95rem] font-sans   ">
                                      Add Patient
                                    </Typography>
                                  </div>
                                  {!(data?.patient?.length > 0) && (
                                    <div>
                                      <Tooltip
                                        title="add a patient here"
                                        color="#f386a8"
                                      >
                                        <InfoCircleOutlined className="text-[1.2rem] text-[#f386a8] " />
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                              }
                              showArrow={false}
                              key={data?.productId}
                            >
                              <>
                                <AddPatientFom
                                  isEdit={false}
                                  typeId={data?.typeId}
                                  type={data?.type}
                                  cartRendering={getData}
                                  productId={data?.productId}
                                  closeCollapse={SetCollapseKey}
                                />
                              </>
                            </Panel>
                          </Collapse>
                          <div>
                            {showAbove && (
                              <div>
                                <Checkbox
                                  onClick={() =>
                                    sameAsAbove(data.productId, index)
                                  }
                                  className="h-full ml-4"
                                >
                                  <Typography>
                                    Add patient same as above.
                                  </Typography>
                                </Checkbox>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </ContainerWithShadow>
                    </div>
                  );
                })}

                {/* Offers & Discount section -----------------------------------------------------------*/}
                <div className="mt-[1rem]">
                  <OffersAndDiscountCard getData={getData} />
                </div>

                {/* Suggested Tests -----------------------------------------Commented for now __ POPULAR ADD ONs-----------*/}
                {/* <div className="mt-[1rem]">
                  <SuggestedTestCard />
                </div> */}
              </div>

              {/* Bill Detail ------------------------------------------------------*/}
              <div className="md:w-[43%]">
                <BillDetail
                  isButtonVisible={isButtonVisible}
                  billData={cartData?.amount}
                  continueDisable={continueDisable}
                  getData={getData}
                />
              </div>
            </div>
          ) : (
            <>
              {!isLoading && (
                <div className="w-Full h-[80vh] flex justify-center flex-col items-center gap-4">
                  <img src={Character} alt="" className="h-[50vh]" />
                  <Typography className="font-semibold font-sans text-[1.2rem] mt-4 sm:my-0 sm:text-[1.8rem]">
                    No item Added to the cart
                  </Typography>
                  <button
                    className="bg-[#F15E7E] text-[white] text-[1rem] rounded-md py-3 px-5"
                    onClick={() => navigate("/")}
                  >
                    Explore Services
                  </button>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>

      <EditPatientDetailModal
        openModal={openEditPatient}
        handleModal={handleEditPatientModal}
        patientDetails={editPatientDetail}
        availableSlots={availableSlots}
        slotTimeId={slotTimeId}
        slotDetailsId={slotDetailsId}
        type={type}
        productId={productId}
        getCart={getData}
        labId={labId}
      />

      <DeleteModal
        openDeleteModal={isDeleteModalOpen}
        HandleDeleteModal={handleDeleteModal}
        handleConfirm={
          isProductDelete ? deleteFunction : removePatientFromProduct
        }
      />

      <IscontinueDifferentLabModal
        isOpen={isDifferentLabModalOpen}
        onCancel={() => {
          setIsDifferentLabModalOpen(false);
        }}
      />

      {filteredCartData.length > 0 ? <div className="h-[8rem]"></div> : <></>}
      <Footer />
      <div className="h-[4vh] sm:hidden"></div>

      {window.innerWidth < 640 && filteredCartData.length > 0 ? (
        <>
          <div className="fixed bottom-0 bg-white w-[100%] flex justify-between px-3 items-center mt-2 h-[8vh] border-t">
            <div>
              <Typography className="font-normal text-sm font-sans">
                Total Price
              </Typography>
              <Typography className="font-semibold text-[1.2rem] text-[#1F1F1F] font-sans">
                ₹{cartData?.amount?.totalAmount}{" "}
                <span className="font-normal text-sm">
                  ({filteredCartData.length} Item)
                </span>
              </Typography>
            </div>
            <Button
              className="font-sans  bg-[#F15E7E]  !text-white !border-[#F15E7E]  my-2 h-[10vw] px-10"
              htmlType="submit"
              style={
                continueDisable
                  ? { background: "#F15E7E" }
                  : { background: "#BFBFBF" }
              }
              onClick={continueDisable ? handleCheckout : null}
            >
              Continue
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
      {isLoading && (
        <div
          className="w-[100vw] h-[100vh] fixed  z-40 top-0 left-0"
          style={{ background: "rgba(0, 0, 0, 0.40)" }}
        >
          <Space
            direction="vertical"
            className="w-[100%] h-[100%] flex justify-center"
          >
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
          </Space>
        </div>
      )}
    </div>
  );
};

export default Cart;
