import { Divider, Form, Modal, Typography } from "antd";
import React from "react";
import SearchBarMobile from "./SearchBarMobile";
import { CloseCircleOutlined } from "@ant-design/icons";

const SearchLocationModal = ({ openPinCodeModal, setOpenPincodeModal }) => {
  return (
    <div>
      <Modal
        open={openPinCodeModal}
        title={
          <Typography className="text-xl font-sans">Enter Location</Typography>
        }
        centered={true}
        footer={[]}
        closeIcon={
          <CloseCircleOutlined
            className="text-black text-lg"
            onClick={() => setOpenPincodeModal(!openPinCodeModal)}
          />
        }
        width={400}
      >
        <>
          <Divider className="mt-0" />

          <div className="w-[100%] mb-10">
            <SearchBarMobile locationBlocked={true} compId="modal-location" />
          </div>

          <div className="flex justify-end">
            <button
              className="mt-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] font-sans"
              type="submit"
              onClick={() => {
                setOpenPincodeModal(!openPinCodeModal);
              }}
            >
              Continue
            </button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default SearchLocationModal;
