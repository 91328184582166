import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    userPhoneNumber : "",
}

const authSlice = createSlice({
    name: "authForLogin",
    initialState : initialState,
    reducers : {
         setUserPhonenumber : (state , action) =>{
            // console.log(action ,'adklfnalfkhasjlfh')
            state.userPhoneNumber = action.payload;
         } 
    }
})

export const {setUserPhonenumber} = authSlice.actions;

export default authSlice.reducer

