import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { suggestedTest } from "../../service/cartApi";

export const getAddOnsDiagnostic = createAsyncThunk(
  "getAddOnsDiagnostic",
  async () => {
    try {
      const resp = await suggestedTest();
      if (resp) {
        return resp?.data?.payload?.tests;
      }
    } catch (error) {}
  }
);

export const AddOnsDiagnosticSlice = createSlice({
  name: "AddOnsDiagnostic",
  initialState: {
    diagnostic: [],
    isLoading: true,
  },
  reducers: {},
  extraReducers: {
    [getAddOnsDiagnostic.pending]: (state) => {
      state.isLoading = true;
    },
    [getAddOnsDiagnostic.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.diagnostic = action?.payload;
    },
    [getAddOnsDiagnostic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action;
    },
  },
});

export default AddOnsDiagnosticSlice.reducer;
