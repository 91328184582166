import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

const initialState = {
  count: 0,
  loading: false,
  error: null,
  cartData: {},
  itemIds: [],
};

//Read Action
export const getcount = createAsyncThunk(
  "getcount",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/api/v1/cart/cart-item-count`);
      if (response.success) {
        return response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setCartData = createAsyncThunk(
  "cartData",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/api/v1/cart`);
      if (response.data.success) {
        return response?.data?.payload?.cart;
      }
    } catch (error) {}
  }
);

//cart Action Slice

export const cartCount = createSlice({
  name: "count",
  initialState,
  reducers: {
    setItemIds: (state, action) => {
      state.itemIds = action.payload;
    },
  },
  extraReducers: {
    //show user
    [getcount.pending]: (state) => {
      state.loading = true;
    },
    [getcount.fulfilled]: (state, action) => {
      state.loading = false;
      state.count = action?.payload?.data?.payload?.cartItemCount;
    },
    [getcount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.payload?.cartItemCount;
    },
    [setCartData.fulfilled]: (state, action) => {
      state.cartData = action?.payload;
    },
  },
});

export const { setItemIds } = cartCount.actions;

export default cartCount.reducer;
