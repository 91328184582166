import { BellOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";

const NotificationCard = ({ title, description, time }) => {
  return (
    <div className="flex justify-between lg:p-4 pl-2 pb-4">
      <div className="flex  items-center w-[100%]">
        <div className="sm:w-[10%] w-[18%]">
          <BellOutlined className="p-4 rounded-full bg-[#FBF0F2] text-[#F15E7E]" />
        </div>

        <div className="sm:w-[90%] w-[82%] ">
          <div className="flex justify-between items-center w-[100%]">
            <Typography className="semibold mb-1 text-base font-sans">
              {title}
            </Typography>
            <Typography className="text-[#8C8C8C] lg:text-sm text-xs font-sans ">
              {time}
            </Typography>
          </div>
          <Typography className="font-sans">{description}</Typography>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
