import { Modal, Rate, Typography } from "antd";
import React, { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { Orderfeedback } from "../../service/orderReviewApi"

const Feedback = ({ openModal, HandleClose, setOpenModal, orderId, setIsChanged, isChanged}) => {
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState();

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleRatingChange = (value) => {
    setRating(value);
  };

  const handleData = async () => {
    try {
      const payload = {
        comments: comment,
        rating: rating
      }
      const response = await Orderfeedback(payload, orderId);
      if (response.success) {
        toast.success("Thanks for your Valuable Feedback")
        setOpenModal(false);
        setIsChanged(isChanged ? false : true);
      }
    } catch (error) {
      toast.info("Something went wrong. Please try again after some time.")
    }
  }

  return (
    <Modal
      open={openModal}
      title={
        <div className="md:border-b-[1.5px] md:border-[#D9D9D9] ">
          <div className="flex justify-between">
            <div>
              <Typography className="text-[1rem] text-[#161616] font-semibold font-sans">
                Feedback
              </Typography>
              <Typography className="text-[0.8rem] text-[#595959] font-normal mb-2 font-sans">
                Help us create better services for you
              </Typography>
            </div>
            <div className="">
              <CloseCircleOutlined onClick={() => setOpenModal(false)} className="text-black text-[1.2rem] mt-[0.75rem] mr-[0.5rem] hover:bg-[#f0f0f0] cursor-pointer " />
            </div>
          </div>
        </div>
      }
      onCancel={HandleClose}
      closable={false}
      footer={[]}
      centered={true}
    >
      <div className="mt-4">
        <Typography className="text-[#161616] text-[0.8rem] font-normal font-sans">
          Rate you experience on our e-commerce portal
        </Typography>
        <Rate className="mt-2 text-[1.9rem] flex gap-[0.8rem]" onChange={handleRatingChange} />
        <Typography className="text-[#161616] text-[0.8rem] font-normal my-2 font-sans">
          Would you like to give a reason for your rating?
        </Typography>
        <TextArea
          autoSize={{ minRows: 7 }}
          className="my-2 rounded font-sans"
          onChange={handleCommentChange}
          value={comment}
        />
      </div>
      <div className="flex justify-end">
        <button
          className="my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[1rem] rounded-[4px] font-sans w-[8.2rem] h-[2.6rem] font-semibold"
          type="submit"
          onClick={handleData}
        >
          Save Feedback
        </button>
      </div>
    </Modal>
  );
};

export default Feedback;
