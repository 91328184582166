import axios from "axios";
import { axiosInstance } from "../utils/axios";
import { BASE_URL } from "../utils/globalVariable";

/********************Get all the Orders (GET) **********************/
const userData = localStorage.getItem("token");
const data = JSON.parse(userData);

export const getAllOrders = async (page, status, search) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/api/v1/order/?limit=10&page=${page}&keyword=${search || ""}&status=${status}`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
  }
};


// ******************* Feedback api **************************

export const Orderfeedback = async (Data, OrderId) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/api/v1/order/user/feedback?orderId=${OrderId}`,
      Data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

// ******************* Rescheduled api **************************

export const OrderRescheduled = async (OrderId) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/api/v1/order/reschedule/${OrderId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

// ******************* Cancelled api **************************

export const OrderCancelled = async (OrderId) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/api/v1/order/cancel/${OrderId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};