import { Typography } from "antd";
import React, { useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const VaccinationFAQ = () => {
  const [faqClick, setFaqClick] = useState();
  const FaqHandler = (data) => {
    setFaqClick(faqClick === data ? null : data);
  };

  const navigate = useNavigate();

  const goToFaqPage = () => {
    navigate("/faq");
  };

  const data = [
    {
      id: 1,
      que: "Why should I get vaccinated?",
      ans: "Vaccination helps protect you from serious illnesses and prevents the spread of diseases to others. It also contributes to community immunity, reducing the overall prevalence of a disease. ",
    },
    {
      id: 2,
      que: "Where will the vaccine be inoculated to me?",
      ans: "Vaccination is inoculated typically through intramuscular injection (Vaccinations are typically injected into the deltoid muscle (upper arm) for adults and the thigh for infants and young children. Buttocks may be used less commonly.) ",
    },
    {
      id: 3,
      que: " Is vaccination covered under insurance?",
      ans: "Yes, the Services are provided at discounted rates. The discount is applicable based on employee entity.  ",
    },
    {
      id: 4,
      que: "How can a person avail a vaccination?",
      ans: "One can avail vaccination through In Clinic Visits, home services and HCL Healthcare partner enabled centers.",
    },
    {
        id: 5,
        que: "Is vaccination covered under insurance? ",
        ans: "HPV and Flu Vaccinations are covered for employees under group insurance. Vaccination coverage under insurance may vary, and not all vaccinations may be fully covered. It is essential to check with your specific insurance provider to understand the details of vaccination coverage. ",
      },
      {
        id: 6,
        que: "How can I claim my vaccination under insurance? ",
        ans: "Please reach out to your HR SPOCs to understand vaccinations coverage and claim process.",
      },
      {
        id: 7,
        que: "Are HCL employees eligible for a discount on vaccinations provided by HCL Healthcare? ",
        ans: "Yes, HCL employees are eligible for a 10% discount on vaccinations offered by HCL Healthcare. ",
      },
      {
        id: 8,
        que: "What are the common side effects of vaccines? ",
        ans: "Most vaccines cause only mild side effects, such as soreness at the injection site, mild fever, or fatigue. Serious side effects such as allergic reactions, are very rare and which may vary depending on the specific vaccination. ",
      },
      {
        id: 9   ,
        que: "Can vaccines cause the disease they are supposed to prevent? ",
        ans: "No, vaccines do not cause the diseases they protect against. They are designed to stimulate an immune response without causing the disease itself. ",
      },
      {
        id: 10   ,
        que: "Can I get vaccinated if I am pregnant or breastfeeding?  ",
        ans: "It depends on the specific vaccine, but many vaccines are safe during pregnancy and breastfeeding. It's important to consult with your healthcare provider and doctor for personalized advice. ",
      },
      {
        id: 11   ,
        que: "How long does vaccine protection last? ",
        ans: "The duration of protection varies by vaccine. Some vaccines provide lifelong immunity, while others may require booster shots to maintain protection.",
      },



      
  ];

  return (
    <div className="pt-8 mt-[5rem] sm:mt-0 mb-[8%]">
      <div className="w-100 items-center flex justify-items-center flex-col ">
        <Typography className="text-center font-semibold text-[1.2rem] sm:text-[2rem] font-sans">
          Frequently Asked Questions (FAQs)
        </Typography>
        <Typography className="text-center text-[#434343] text-[0.9rem] w-[40%] m-[auto] sm:text-[1.1rem] mb-[1rem] sm:mb-[2rem] font-sans">
          You can find all your queries here
        </Typography>
      </div>
      <div>
        {data &&
          data  ?.map((data) => {
            return (
              <div
                className="mx-[5%] md:mx-[15%] border-b border-[#BFBFBF] sm:px-4"
                key={data.id}
              >
                <div
                  className="flex justify-between my-4 "
                  onClick={() => FaqHandler(data.id)}
                >
                  <Typography className="font-normal md:text-[1rem] text-[0.875rem] w-[85%] font-sans">
                    {data.que}
                  </Typography>
                  <div>
                    {faqClick === data.id ? (
                      <MinusOutlined className="cursor-pointer" />
                    ) : (
                      <PlusOutlined className="cursor-pointer" />
                    )}
                  </div>
                </div>
                <div className="my-2">
                  {faqClick === data.id ? (
                    <Typography className="font-normal md:text-[0.95rem] text-[0.87rem] text-[#8C8C8C] font-sans">
                      {data.ans}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      <div className="mt-3 flex justify-end mx-[5%] md:mx-[15%] ">
   
        {/* log */}
      </div>
    </div>
  );
};

export default VaccinationFAQ;
