import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import React from "react";
import Footer from "../CommonComponents/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import AdressForm from "./AdressForm";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { updateAddress } from "../../service/AddressApi";
import stateAndDistrict from "../../assets/StateAndDistrict/stateAndDistrict";

const EditAddress = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const addressDetails = state.data;

  const [form] = Form.useForm();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(addressDetails);
  }, [addressDetails]);

  const onRedirectBack = () => {
    navigate(-1);
  };

  const callUpdateAddress = async (addressId, data) => {
    try {
      const response = await updateAddress(addressId, data);
    } catch (error) {}
  };

  const editaddress = (values) => {
    callUpdateAddress(addressDetails._id, values);
    navigate(-1);
  };

  /////////////////////////////
  const [stateName, setStateName] = useState("");
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [defaultCity, setDefaultCity] = useState(null);

  const extractStates = () => {
    const stateNames = stateAndDistrict[0].states.map((item) => item.state);

    setAllStates(stateNames);
  };

  useEffect(() => {
    extractStates();
  }, []);

  const extractCities = (e) => {
    const citiesData = stateAndDistrict[0].states.find(
      (item) => item.state == e
    );
    if (citiesData) {
      setAllCities(citiesData.districts);
    } else {
      setAllCities([]);
    }
  };

  const changeStateHandler = (e) => {
    setStateName(e);
    extractCities(e);
  };

  const handleFormChange = () => {};

  return (
    <>
      <Row className="border-b border-[#BFBFBF] pb-2">
        <Col span={20} offset={window.innerWidth < 640 ? 1 : 2}>
          <div className="h-7 sm:h-10 flex items-center justify-start text-[1.2rem] md:text-[1.4rem] ">
            <ArrowLeftOutlined onClick={onRedirectBack} />
            <div className="flex items-baseline">
              <Typography className="ml-[1.4rem] md:ml-[2rem] text-[1.2rem] md:text-[1.5rem] font-semibold mr-[0.2rem] font-sans">
                Edit Address
              </Typography>
            </div>
          </div>
        </Col>
      </Row>
      <Col span={22} offset={window.innerWidth < 640 ? 1 : 2}>
        <div className="h-[80vh]">
          <Form
            form={form}
            onFinish={editaddress}
            onFieldsChange={handleFormChange}
          >
            <Typography className="text-[#595959] my-[8px] text-[0.85rem] font-sans">
              House No, Building, Street
            </Typography>
            <Form.Item
              name="area"
              className="mb-2"
              rules={[
                {
                  required: true,
                  message: "House No, Building, Street",
                },
              ]}
            >
              <Input
                placeholder="House No, Building, Street"
                className="rounded text-[0.85rem] py-2 font-sans"
              />
            </Form.Item>

            <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
              Locality
            </Typography>
            <Form.Item
              name="locality"
              className="mb-2"
              rules={[
                {
                  required: true,
                  message: "Locality",
                },
              ]}
            >
              <Input
                placeholder="Locality"
                className="rounded text-[0.85rem] py-2 font-sans"
              />
            </Form.Item>

            <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
              Landmark (Optional)
            </Typography>
            <Form.Item
              name="landmark"
              className="mb-2"
              rules={[
                {
                  required: false,
                  message: "Landmark",
                },
              ]}
            >
              <Input
                placeholder="Landmark"
                className="rounded mb-2 text-[0.85rem] py-2 font-sans"
              />
            </Form.Item>

            <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
              Pincode
            </Typography>
            <Form.Item
              className="mb-2"
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "Pincode",
                },
              ]}
            >
              <Input
                placeholder="Pincode"
                className="rounded text-[0.85rem] py-2 font-sans"
              />
            </Form.Item>
            <div className="md:flex md:justify-between">
              <div className="md:w-[48%]">
                <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
                  State
                </Typography>
                <Form.Item
                  className="mb-2 !rounded-sm font-sans"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "State",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select State"
                    className="rounded-sm text-[0.85rem]"
                    onChange={changeStateHandler}
                  >
                    {allStates &&
                      allStates.map((state_name, index) => {
                        return (
                          <>
                            <Select.Option key={index} value={state_name}>
                              {state_name}
                            </Select.Option>
                          </>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
              <div className="md:w-[48%]">
                <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
                  District
                </Typography>
                <Form.Item
                  className="mb-2 font-sans"
                  name="district"
                  rules={[
                    {
                      required: true,
                      message: "District",
                    },
                  ]}
                >
                  <Select placeholder="District" className="font-sans">
                    {allCities &&
                      allCities.map((state_name, index) => {
                        return (
                          <>
                            <Select.Option key={index} value={state_name}>
                              {state_name}
                            </Select.Option>
                          </>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="fixed bottom-0 left-0 bg-white w-[100vw] flex justify-center items-center mt-2 h-[8vh] border-t">
              <Button
                className=" font-sans bg-[#F15E7E]  !text-white !border-[#F15E7E] h-[5vh] w-[92vw] rounded-sm my-2 "
                type="default"
                htmlType="submit"
                disabled={isSubmitDisabled}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Col>
      <Footer />

      <div className="h-[8vh]"></div>
    </>
  );
};

export default EditAddress;
