import { axiosInstance } from "../utils/axios";

/*************** add or create patient***************************/

export const getPatient = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/patient`);
    if (response.success) {
      return response?.data?.payload;
    }
    return response?.data?.payload;
  } catch (error) {
  }
};

/*************** add or create patient***************************/

export const deletePatient = async (patientId) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/patient/${patientId}`);
    if (response.success) {
      return response;
    }
    return response?.data?.payload;
  } catch (error) {
  }
};
