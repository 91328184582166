import React from "react";
import Logo from "../../assets/images/Logo.png";
import { PhoneOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";

const Navbar = ({ isUp }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="z-50 w-full sticky bg-[#ffffff] top-0 left-0">
        <div className="h-16 flex items-center mx-4 justify-between">
          <div className="flex h-7">
            <img
              src={Logo}
              alt="Logo"
              className="cursor-pointer"
              onClick={
                () => navigate("/")
                // window.open("https://www.healthyhcl.in/")
              }
            />
            <div className="md:flex ml-3 items-center gap-2 hidden">
              {" "}
              <PhoneOutlined className="text-[#0057A3] rotate-90" />
              <Typography className="font-semibold text-lg font-sans">
                {" "}
                <a href="tel:7217890075" className="!text-black">
                  721-7890-075
                </a>
              </Typography>{" "}
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
