import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPop: false,
};

const whatsappPopSlice = createSlice({
  name: "isPopUp",
  initialState: initialState,
  reducers: {
    setIsPopUP: (state, action) => {
      state.isPop = action.payload;
    },
  },
});

export const { setIsPopUP } = whatsappPopSlice.actions;

export default whatsappPopSlice.reducer;
