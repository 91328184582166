import React, { useState, useEffect } from "react";
import { Button, Card, Divider, Modal, Typography, Drawer } from "antd";
import {
  AuditOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteFromCart } from "../../service/cartApi";
import { toast } from "react-toastify";
import { getcount, setCartData } from "../../redux/Cart/cartCountSlice";
import SearchLocationModal from "./SearchLocationModal";
import homeSamp from "../../assets/images/homeSam.svg";
import labSamp from "../../assets/images/labSam.svg";
import AddToCartButton from "../Diagnostic/AddToCartButton";

const CardComponent = ({
  isLandingPage,
  TestImage,
  title,
  providers,
  content,
  discountPrice,
  id,
  duration,
  reportTime,
  route,
  isPackage,
  packageData,
  suggestedTest,
  serviceType,
  requirments,
  inCart,
  productId,
  startingOriginalPrice,
  doses,
  isVaccine,
  features,
}) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteFunction = async () => {
    // if (isProductDelete) {
    try {
      const response = await deleteFromCart(productId);
      // setRemove(!remove);
      toast.success("Your cart is updated");
      dispatch(setCartData());
      dispatch(getcount());
      // dispatch(getOffers());
    } catch (error) {}
  };

  const handleRemove = () => {
    deleteFunction();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const [openPinCodeModal, setOpenPincodeModal] = useState(false);

  return (
    <Card
      bodyStyle={{ padding: "1rem" }}
      className={`shadow-md   mb-2  xl:mr-0 mr-1 border  border-[#D9D9D9] ${
        suggestedTest && ` w-[100%] `
      }`}
    >
      <div className="flex items-center justify-between h-7  mb-[0.6rem]">
        <div className="flex justify-start items-center gap-3">
          <img src={TestImage} alt="Test" className="h-[2rem]" />
          <Typography className="text-[1rem] font-medium h-[100%] flex items-center">
            <>
              {title?.slice(0, 35)}
              {title?.length > 35 ? <>...</> : <></>}
            </>
          </Typography>
        </div>
      </div>
      {!isLandingPage && (
        <div className="my-[1rem]">
          <Typography className="text-[#595959] text-[0.875rem] leading-5 my-4 h-[2.4rem] font-sans">
            {content.length > 5 && content?.slice(0, 80)}
            {content.length > 5 && content?.length > 80 ? <>...</> : <></>}{" "}
          </Typography>

          <>
            <div>
              <div className="-mt-3">
                <Typography className="text-right text-xs font-semibold  text-[#F15E7E]">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      if (isPackage) {
                        navigate("/packages/details", {
                          state: {
                            pData: packageData,
                            title: title,
                          },
                        });
                      } else {
                        showModal();
                      }
                    }}
                  >
                    View Details
                  </span>
                  {window.innerWidth > 640 ? (
                    <Modal
                      open={open}
                      title={title}
                      onCancel={handleCancel}
                      closeIcon={
                        <CloseCircleOutlined style={{ color: "#000" }} />
                      }
                      footer={[]}
                      centered={true}
                    >
                      {content && (
                        <>
                          <Divider className="mt-0" />
                          <Typography className="text-sm font-sans">
                            {content != "Z" && content} <br></br>
                          </Typography>
                        </>
                      )}
                      <div className="mt-4 mb-8">
                        <Typography className="font-semibold font-sans">
                          Available Labs
                        </Typography>
                        <div className="h-10 mb-4 mt-4 ml-4 flex items-center  overflow-y-scroll no-scrollbar">
                          {providers?.map((e, key) => {
                            return (
                              <img
                                src={e.image}
                                alt={e.name}
                                className="h-[100%] mr-4"
                                key={key}
                              />
                            );
                          })}
                        </div>
                      </div>

                      {isVaccine && (
                        <div className="flex items-center gap-2 mt-4">
                          <FieldTimeOutlined className="text-[#0057A3]" />
                          <Typography className="text-sm font-sans">
                            Doses: <strong>{doses}</strong>
                          </Typography>
                        </div>
                      )}
                      <div className="mb-6">
                        {isVaccine && (
                          <div className="flex items-center gap-2 mt-4">
                            <SolutionOutlined className="text-[#0057A3]" />
                            <Typography className="text-sm font-sans">
                              Name: <strong>{title}</strong>
                            </Typography>
                          </div>
                        )}

                        {!isVaccine && (
                          <div className="flex items-center gap-2 mt-4">
                            <ExperimentOutlined className="text-[#0057A3]" />
                            <Typography className="text-sm font-sans">
                              Service Type:{" "}
                              <strong>
                                {serviceType === "Both"
                                  ? "Home and Visit Center"
                                  : serviceType + "Sampling Only"}
                              </strong>
                            </Typography>
                          </div>
                        )}
                        {isVaccine && (
                          <div className="flex items-start gap-2 mt-4 justify-between">
                            <div className="flex gap-2 items-center ">
                              <ExperimentOutlined className="text-[#0057A3]" />
                              <p className="text-sm font-sans">Features: </p>
                            </div>
                            <div>
                              <div className="font-sans">
                                {features?.map((e) => (
                                  <strong>{e}</strong>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        {!isVaccine && duration && (
                          <div className="flex items-center gap-2 mt-4">
                            <FieldTimeOutlined className="text-[#0057A3]" />
                            <Typography className="text-sm font-sans">
                              Test Duration: <strong>{duration}</strong>
                            </Typography>
                          </div>
                        )}
                        {requirments && (
                          <div className="flex items-center gap-2 mt-4">
                            <ExclamationCircleOutlined className="text-[#0057A3]" />
                            <Typography className="text-sm font-sans">
                              Requirement: <strong>{requirments}</strong>
                            </Typography>
                          </div>
                        )}
                        {reportTime && (
                          <div className="flex items-center gap-2 mt-4">
                            <AuditOutlined className="text-[#0057A3]" />
                            <Typography className="text-sm font-sans">
                              Report Availability: <strong>{reportTime}</strong>
                            </Typography>
                          </div>
                        )}
                      </div>
                      <div className="w-full flex justify-end">
                        <AddToCartButton
                          inCart={inCart}
                          handleRemove={handleRemove}
                          id={id}
                          title={title}
                          setOpenPincodeModal={setOpenPincodeModal}
                          route={route}
                        />
                      </div>
                    </Modal>
                  ) : (
                    <Drawer
                      open={open}
                      title={title}
                      closeIcon={
                        <CloseCircleOutlined
                          style={{ color: "#000" }}
                          className="absolute right-4 top-[2.5%] text-lg"
                          onClick={handleCancel}
                        />
                      }
                      footer={[]}
                      placement={"bottom"}
                      height={"60%"}
                      key={"right"}
                      className="rounded-t-lg"
                    >
                      <Typography className="text-xs font-sans">
                        {content !== "N/A" && content} <br></br>
                      </Typography>
                      <div className="mb-8 mt-4">
                        <Typography className="font-semibold font-sans">
                          Available Labs
                        </Typography>
                        <div className="h-10 mb-4 mt-4 flex items-center justify-between overflow-y-scroll no-scrollbar">
                          {providers?.map((e, key) => {
                            return (
                              <img
                                src={e.image}
                                alt={e.name}
                                className="h-[100%] mr-4"
                                key={key}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="mb-6">
                        <div className="flex items-center gap-2 mt-4">
                          <ExperimentOutlined className="text-[#0057A3]" />
                          <Typography className="text-xs font-sans">
                            Service Type:{" "}
                            <strong>
                              {serviceType === "Both"
                                ? "Home and Visit Center"
                                : serviceType + "Sampling Only"}
                            </strong>
                          </Typography>
                        </div>
                        <div className="flex items-center gap-2 mt-4">
                          <FieldTimeOutlined className="text-[#0057A3]" />
                          <Typography className="text-xs font-sans">
                            Test Duration: <strong>{duration}</strong>
                          </Typography>
                        </div>
                        <div className="flex items-center gap-2 mt-4">
                          <ExclamationCircleOutlined className="text-[#0057A3]" />
                          <Typography className="text-xs font-sans">
                            Requirement: <strong>{requirments}</strong>
                          </Typography>
                        </div>
                        <div className="flex items-center gap-2 mt-4">
                          <AuditOutlined className="text-[#0057A3]" />
                          <Typography className="text-xs font-sans">
                            Report Availability: <strong>{reportTime}</strong>
                          </Typography>
                        </div>
                      </div>
                      <div className="w-full flex justify-end">
                        <AddToCartButton
                          inCart={inCart}
                          handleRemove={handleRemove}
                          id={id}
                          title={title}
                          setOpenPincodeModal={setOpenPincodeModal}
                          route={route}
                        />
                      </div>
                    </Drawer>
                  )}
                </Typography>
              </div>
            </div>
            <div className="flex w-100 justify-start gap-2 mt-4 ">
              <div className="flex gap-2 w-full">
                {(serviceType === "Home" || serviceType === "Both") && (
                  <div
                    className={`text-[0.9rem] flex items-center justify-center pr-4 text-[#595959] rounded-none my-1  font-sans h-4`}
                  >
                    <img src={homeSamp} className="mr-2 h-[1.5rem]" />
                    {"At Home"}
                  </div>
                )}
                {(serviceType === "Lab" || serviceType === "Both") && (
                  <div
                    className={` ${
                      serviceType === "Both"
                        ? "border-l border-l-[#6e6e6e] px-4"
                        : ""
                    }   text-[0.9rem] flex items-center justify-center  my-1 text-[#595959] rounded-none  font-sans h-4`}
                  >
                    <img src={labSamp} className="mr-2 h-[1.5rem]" />
                    {"Visit Center"}
                  </div>
                )}
              </div>
            </div>{" "}
          </>
        </div>
      )}

      <div className="flex justify-between mt-4  w-[100%] items-center">
        <div className="relative">
          <Typography className="text-xs md:text-xs font-normal text-[#8C8C8C] font-sans ">
            Starting from
          </Typography>

          <Typography className="font-semibold text-xl font-sans ">
            ₹{discountPrice}{" "}
            {startingOriginalPrice && (
              <span className="font-light text-[0.8rem]">
              {startingOriginalPrice &&
                <del>₹{startingOriginalPrice}</del>}
              </span>
            )}
          </Typography>
        </div>
        <AddToCartButton
          inCart={inCart}
          handleRemove={handleRemove}
          id={id}
          title={title}
          setOpenPincodeModal={setOpenPincodeModal}
          route={route}
        />
      </div>

      <SearchLocationModal
        openPinCodeModal={openPinCodeModal}
        setOpenPincodeModal={setOpenPincodeModal}
      />
    </Card>
  );
};

export default CardComponent;
