import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Typography } from "antd";
import { Form, Input, Select, DatePicker, Radio, Carousel } from "antd";
import { image72 } from "../../assets/images";
import AddPatientFom from "./AddPatientFom";
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../CommonComponents/Footer";
import { changeSlot, updatePatient } from "../../service/cartApi";
import moment from "moment";
import { useSelector } from "react-redux";
import { getDate, formateTime } from "../CommonComponents/DateAndTime";
import dayjs from "dayjs";

const EditPatientDetailMobile = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPatientDetails(state?.patientDetails);
    setSlotDetailsId(state?.slotDetailsId);
    setSlotTimeId(state?.slotTimeId);
    setProductId(state?.productId);
    setType(state?.type);
    setAvailableSlots(state?.availableSlots);
    setLabId(state?.labId);
  }, []);

  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState();
  const [date, setDate] = useState();
  const [newSlotId, setNewSlotID] = useState();
  const [startTime, setstartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [patientDetails, setPatientDetails] = useState();
  const [slotDetailsId, setSlotDetailsId] = useState();
  const [slotTimeId, setSlotTimeId] = useState();
  const [productId, setProductId] = useState();
  const [type, setType] = useState();
  const [slotPayload, setSlotPayload] = useState();
  const [labId, setLabId] = useState();

  useEffect(() => {
    setSlotPayload({
      date: date,
      newSlotId: newSlotId,
      newSlotTimingId: selectedSlot,
      startTime: startTime,
      endTime: endTime,
      productId: productId,
      type: type,
      labId: labId,
    });
  }, [date]);

  useEffect(() => {
    setSelectedSlot(slotTimeId);
  }, [slotTimeId]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (patientDetails) {
      patientDetails.dob = dayjs(patientDetails.dob);
    }
    form.resetFields();
    form.setFieldsValue(patientDetails);
  }, [patientDetails?.firstName]);

  const handleSlot = (dayId, slotId) => {
    setSelectedSlot(slotId);
  };

  // function to update patient api

  const callUpdatePatient = async (patientId, data) => {
    try {
      const response = await updatePatient(patientId, data);
    } catch (error) {}
  };

  const callChangeSlot = async () => {
    try {
      const response = await changeSlot(slotDetailsId, slotPayload);
    } catch (error) {}
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
          height: 50,
        }}
        defaultValue={"+91"}
        className="flex justify-center items-center font-sans"
      >
        <Select.Option value="91">+91</Select.Option>
        <Select.Option value="86">+86</Select.Option>
        <Select.Option value="87">+87</Select.Option>
      </Select>
    </Form.Item>
  );

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <LeftOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 left-4 z-10 text-[0.7rem] border-2" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <RightOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 right-5 z-10 text-[0.7rem] border-2" />
    </button>
  );

  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const submitForm = (values) => {
    callUpdatePatient(patientDetails._id, values);
    callChangeSlot();
    navigate(-1);
  };

  return (
    <>
      <div className="min-h-[98vh]">
        <div className="flex justify-start items-center px-[1rem] border-b-2 pb-2">
          <ArrowLeftOutlined onClick={() => navigate(-1)} />
          <Typography className="text-[1.2rem] font-semibold ml-3 font-sans">
            Edit Patient Detail
          </Typography>
        </div>
        <Row>
          <Col span={22} offset={1}>
            <div className="py-2  overflow-y-scroll  no-scrollbar">
              <div className="py-[0.6rem] flex items-center">
                <Typography className="font-semibold text-[1rem] sm:text-[0.8rem] font-sans">
                  Patient Detail
                </Typography>
                <div className="flex-grow h-[2px] bg-[#ececec] m-[0.5rem]"></div>
              </div>
              <Form form={form} onFinish={submitForm} id="myform">
                <div>
                  <div className="flex  w-[100%] justify-between ">
                    {/* ----------------------- First Name ----------------------*/}
                    <div className="w-[48%]">
                      <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
                        First Name
                      </Typography>

                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]}
                        className="mb-2"
                      >
                        <Input
                          placeholder="First Name"
                          className="rounded-md py-[0.5rem] font-sans"
                        />
                      </Form.Item>
                    </div>

                    {/* ----------------------- Last Name ----------------------*/}
                    <div className="w-[48%]">
                      <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
                        Last Name
                      </Typography>

                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]}
                        className="mb-2"
                      >
                        <Input
                          placeholder="Last Name"
                          className="rounded-md py-[0.5rem] font-sans"
                        />
                      </Form.Item>
                    </div>
                    {/* ----------------------------------------------------------- */}
                  </div>

                  {/* ------------------------Date picker DOB------------------------- */}
                  <div>
                    <Typography className="text-[#595959] text-[0.85rem] font-sans">
                      DOB
                    </Typography>
                    <Form.Item
                      name="dob"
                      className="mb-2"
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-[100%] py-[0.5rem] font-sans"
                        inputReadOnly={true}
                      />
                    </Form.Item>
                  </div>

                  {/*-------------------- Phone Number -------------------------- */}
                  <div>
                    <Typography className="text-[#595959] text-[0.85rem] font-sans">
                      Phone No.
                    </Typography>
                    <Form.Item name="phoneNumber" className="mb-0 font-sans">
                      <Input
                        addonBefore={prefixSelector}
                        className="w-[100%] py-[0.5rem] rounded-sm font-sans "
                        placeholder="Phone No."
                      />
                    </Form.Item>
                  </div>

                  {/*---------------------- Gender -------------------------- */}

                  <Form.Item
                    className="mb-3 font-sans"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "please select gender",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="male" defaultChecked={true}>
                        {" "}
                        <Typography className="text-[0.85rem] font-sans">
                          Male
                        </Typography>{" "}
                      </Radio>
                      <Radio value="female">
                        <Typography className="text-[0.85rem] font-sans">
                          Female
                        </Typography>{" "}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Form>
            </div>

            {availableSlots?.length > 0 && (
              <>
                <div className="pt-[0.6rem] flex items-center">
                  <Typography className="font-semibold text-[1rem] sm:text-[0.8rem] font-sans">
                    Selected Slot
                  </Typography>
                  <div className="flex-grow h-[2px] bg-[#ececec] m-[0.5rem]"></div>
                </div>
                <div>
                  <Carousel slidesToShow={1} {...carouselSettings}>
                    {availableSlots?.length > 0 &&
                      availableSlots?.map((day) => (
                        <div className="h-[20rem]">
                          <div className="flex justify-center items-center text-[1rem] font-semibold font-sans">
                            {getDate(day?.date)}
                          </div>
                          <div className=" mt-6 w-[100%] flex flex-wrap gap-5 ">
                            {day.timeSlots.map((slot) => {
                              return (
                                <>
                                  {slot?.isBooked ? (
                                    <>
                                      <button
                                        className="w-[29%] py-3  flex justify-center it ems-center h-[3rem] rounded-[8px] timeSlots font-sans bg-[#F4F4F4] text-[#d7d7d7]   "
                                        id={`${slot?._id}`}
                                      >
                                        {formateTime(slot.startTime)}
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {selectedSlot === slot?._id ? (
                                        <button
                                          className="w-[29%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E] font-sans"
                                          id={`${slot?._id}`}
                                        >
                                          {formateTime(slot.startTime)}
                                        </button>
                                      ) : (
                                        <button
                                          className="w-[29%] py-3 border-[2px]  flex justify-center it ems-center h-[3rem] rounded-[8px] timeSlots font-sans "
                                          onClick={() => {
                                            setSelectedSlot(slot?._id);
                                            setDate(day?.date);
                                            setNewSlotID(day?._id);
                                            setstartTime(slot?.startTime);
                                            setEndTime(slot?.endTime);
                                          }}
                                          id={`${slot?._id}`}
                                        >
                                          {formateTime(slot.startTime)}
                                        </button>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                  </Carousel>
                </div>
              </>
            )}
          </Col>
        </Row>
        <div className="fixed bottom-0 flex justify-center items-center w-[100%] bg-white">
          <button
            className="bg-[#F15E7E] text-white w-[92vw] rounded-sm my-2 py-[0.6rem] font-sans"
            type="submit"
            form="myform"
          >
            Save
          </button>
        </div>
      </div>
      <Footer />
      <div className="h-[4rem]"></div>
    </>
  );
};

export default EditPatientDetailMobile;
