import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageNo: 1,
  selectedTab: "All",
  categoryId: "",
};

const vaccineVarSlice = createSlice({
  name: "VaccineVariables",
  initialState: initialState,
  reducers: {
    setVaccinePageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setVaccineSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setVaccineCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
  },
});

export const {
  setVaccinePageNo,
  setVaccineSelectedTab,
  setVaccineCategoryId,
} = vaccineVarSlice.actions;

export default vaccineVarSlice.reducer;
