import { Col, Row, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const PharmacyDropdown = ({ isActive }) => {
  const navigate = useNavigate();
  const options = [
    {
      name: "Pharmacy Voucher",
      route: "/pharmacy-voucher",
    },
    {
      name: "Book your Health Checks",
      link: "https://app.hclhealthcare.in/ehc/register.php",
    },
  ];

  return (
    <div className="group">
      <Typography
        className={`text-md font-semibold cursor-pointer text-base group flex items-center h-10 hover:border-b-2 border-black font-sans ${
          isActive && "border-b-2"
        }`}
      >
        Pharmacy
      </Typography>

      <div className="absolute left-0 hidden group-hover:block top-10 w-full border z-50 bg-white shadow-lg  pt-4 pl-6 transition-all  ">
        {/* 1 Row */}
        <Row gutter={16} offset={20}>
          {options?.map((data) => (
            <>
              {data?.route ? (
                <Col span={6} key={data?._id} className="mb-4">
                  <div
                    className={`text-[#8C8C8C] text-sm cursor-pointer hover:text-[1rem] hover:text-black font-sans transition-all `}
                    onClick={() => {
                      navigate("/pharmacy-voucher", {
                        state: {
                          id: process.env.REACT_APP_PHARMACY_VOUCHER_ID,
                          name: "Pharmacy voucher",
                          isPharmacy: true,
                        },
                      });
                    }}
                  >
                    {data.name}
                  </div>
                </Col>
              ) : (
                <Col span={6} key={data?._id} className="mb-4">
                  <a
                    className="text-[#8C8C8C] text-sm cursor-pointer hover:text-[1rem] hover:text-black font-sans transition-all "
                    href={data.link}
                    target="_blank"
                  >
                    {data.name}
                  </a>
                </Col>
              )}
            </>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default PharmacyDropdown;
