import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLandingBanner } from "../../service/homeApi";

export const getBanners = createAsyncThunk("banner/getBanners", async () => {
  try {
    const response = await getLandingBanner();
    return response;
  } catch (error) {
  }
});

export const BannerSlice = createSlice({
  name: "banners",
  initialState: {
    banner: [],
    isLoading: true,
  },
  reducers: {},
  extraReducers: {
    [getBanners.pending]: (state) => {
      state.isLoading = true;
    },
    [getBanners.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.banner = action?.payload?.data;
    },
    [getBanners.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action;
    },
  },
});

export default BannerSlice.reducer;
