import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: {},
};

export const locationSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setaddress: (state, action) => {
      state.address = action.payload;
    },
  }, 
});

export const { setaddress } = locationSlice.actions;

export default locationSlice.reducer;
