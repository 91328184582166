import React, { useEffect } from "react";
import PagesHeader from "../../../components/CommonComponents/PagesHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row, Typography } from "antd";

import {
  AuditOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import Footer from "../../../components/CommonComponents/Footer";
import SearchLocationModal from "../../../components/CommonComponents/SearchLocationModal";

const ViewDetailPackagePage = () => {
  const { state } = useLocation();
  const [isViewAllTests, setIsViewAllTests] = useState(false);
  const [openPinCodeModal, setOpenPincodeModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTestView = () => {
    setIsViewAllTests(!isViewAllTests);
  };

  return (
    <div>
      <PagesHeader title="Package Details" />
      <Row className="mt-2 mb-[4rem  ] sm:mb-[12rem]">
        <Col
          span={window.innerWidth < 640 ? 22 : 20}
          offset={window.innerWidth < 640 ? 1 : 2}
          className="flex flex-wrap sm:justify-start gap-4 justify-center"
        >
          <div className="flex flex-col sm:flex-row sm:justify-between w-[100%] mt-4 min-h-[70vh] ">
            {/* Information block ----------------------------------------------------- */}
            <div className=" relative w-[100%] sm:w-[55%] border border-gray-5 p-[1rem] shadow-lg sm:min-h-[23rem] rounded-md">
              <div className="flex justify-start items-center border-b-2 pb-4">
                <Typography className="text-[1.1rem] font-medium">
                  {state?.pData?.name}
                </Typography>
              </div>
              <Typography className="text-sm my-4">
                {state?.pData?.description}
              </Typography>
              <div className=" flex items-center justify-between w-[100%]">
                <div className=" w-[100%]">
                  <Typography className="font-medium">
                    Available Labs
                  </Typography>
                  <div className=" my-2 h-[2rem] flex items-center  overflow-y-scroll no-scrollbar">
                    {state?.pData?.partners?.map((e, key) => {
                      return (
                        <img
                          src={e?.image}
                          alt="Company"
                          className="h-[100%] mr-4"
                          key={key}
                        />
                      );
                    })}
                  </div>
                </div>

                {/* <div className="flex items-center gap-1">
                  <StarFilled className="text-[#F15E7E]" />
                  <Typography className="text-base">
                    {state?.d?.lab?.rating}
                  </Typography>
                  <Typography className="text-[#8C8C8C] text-sm">
                    ({300}+ Reviews)
                  </Typography>
                </div> */}
              </div>
              <div className="mb-20">
                <div className="flex items-center gap-2 mt-4">
                  <ExperimentOutlined className="text-[#0057A3]" />
                  <Typography className="text-sm">
                    Service Type:{" "}
                    <strong>
                      {state?.pData?.serviceMode === "Both"
                        ? "Online & Digital on your registered contact  details"
                        : state?.pData?.serviceMode}{" "}
                    </strong>
                  </Typography>
                </div>
                <div className="flex items-center gap-2 mt-4">
                  <FieldTimeOutlined className="text-[#0057A3]" />
                  <Typography className="text-sm">
                    Test Duration: <strong>{state?.pData?.duration}</strong>
                  </Typography>
                </div>
                {state?.pData?.requirement && (
                  <div className="flex items-center gap-2 mt-4">
                    <ExclamationCircleOutlined className="text-[#0057A3]" />
                    <Typography className="text-sm">
                      Requirement: <strong>{state?.pData?.requirement}</strong>
                    </Typography>
                  </div>
                )}
                <div className="flex items-center gap-2 mt-4">
                  <AuditOutlined className="text-[#0057A3]" />
                  <Typography className="text-sm">
                    Report Availability:{" "}
                    <strong>{state?.pData?.reportTime}</strong>
                  </Typography>
                </div>
              </div>
              <Button
                className=" absolute right-5 bottom-5 sm:right-10 sm:bottom-10 w-[8rem] h-[2.6rem] text-center rounded-[4px] flex items-center justify-center border-[#F15E7E] text-[#F15E7E] text-[0.8rem]  font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
                onClick={() => {
                  if (
                    localStorage?.getItem("longitude") ||
                    localStorage?.getItem("locationPlace")
                  ) {
                    navigate(`/packages/select-lab`, {
                      state: {
                        id: state?.pData?._id,
                        title: state?.pData?.name,
                      },
                    });
                    localStorage.setItem("CurrentLabTitle", state?.pData?.name);
                    localStorage.setItem("CurrentLabId", state?.pData?._id);
                  } else {
                    setOpenPincodeModal(true);
                  }
                }}
              >
                Add to cart
              </Button>
            </div>

            {/* Test block ----------------------------------------------------------------- */}

            <div className="w-[100%] sm:w-[43%] border border-gray-5 p-[1rem] shadow-lg rounded-md mt-6 sm:mt-0">
              <div className="flex justify-between border-b-2 pb-4">
                <Typography className="text-[1.1rem] font-medium">
                  Total Included Tests
                </Typography>
                <Typography className="text-[1.1rem] text-[#0057A3] font-medium">
                  {state?.pData?.testArray?.length} Tests
                </Typography>
              </div>

              {isViewAllTests ? (
                <>
                  {state?.pData?.testArray.map((test) => {
                    return (
                      <>
                        <Typography className="my-2 ml-4">{test}</Typography>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {state?.pData?.testArray.slice(0, 10).map((test) => {
                    return (
                      <>
                        <Typography className="my-2 ml-4">{test}</Typography>
                      </>
                    );
                  })}
                </>
              )}

              {state?.pData?.testArray.length > 10 && !isViewAllTests ? (
                <>
                  <div className="flex items-center justify-between mt-4">
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      View All Tests
                    </Typography>
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      <DownOutlined />
                    </Typography>
                  </div>
                </>
              ) : (
                <></>
              )}

              {!isViewAllTests ? (
                <></>
              ) : (
                <>
                  <div className="flex items-center justify-between mt-4">
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      View Less Tests
                    </Typography>
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      <UpOutlined />
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>

          <SearchLocationModal
            openPinCodeModal={openPinCodeModal}
            setOpenPincodeModal={setOpenPincodeModal}
          />
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default ViewDetailPackagePage;
