import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import order_review from "../../assets/images/shopping_cart.svg";
import manage_patient from "../../assets/images/patient 1.svg";
import manage_address from "../../assets/images/home.svg";
import my_coupon from "../../assets/images/discount-tag 2.svg";
import Footer from "../../components/CommonComponents/Footer";
import { getUserDetails } from "../../service/ProfileApi";
import Loader from "../../components/CommonComponents/Loader";
import {getDate} from "../../components/CommonComponents/DateAndTime"

const ProfileSettings = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getUser = async () => {
    setIsLoading(true);
    try {
      const response = await getUserDetails();
      if (response) {
        setCurrentUser(response?.data?.payload?.dataToBeSend);
      }
    } catch (error) {
    }
    setIsLoading(false);
  }

  const data = [
    {
      id: 1,
      heading: "Full Name",
      content: currentUser ? (currentUser?.firstName + " " + currentUser?.lastName) : "",
    },
    {
      id: 2,
      heading: "Mobile Number",
      content: currentUser?.phoneNumber,
    },
    {
      id: 3,
      heading: "Email ID",
      content: currentUser?.email,
    },
    {
      id: 4,
      heading: "Gender",
      content: currentUser?.gender,
    },
    {
      id: 5,
      heading: "Date of Birth",
      content: currentUser?.dob && getDate(currentUser?.dob),
    },
    {
      id: 6,
      heading: "Location",
      content: currentUser?.location,
    },
  ];

  useEffect(() => {
    getUser()
  }, [])

  return (
    <>
      <div className="border-b-2">
        <Row>
          <Col span={20} offset={2}>
            <div className="h-10 flex items-center justify-start text-[1rem] sm:text-[1.4rem] sm:py-[2rem] -ml-[0.3rem] sm:ml-0">
              <ArrowLeftOutlined onClick={() => navigate("/")} />
              <div className="flex items-baseline">
                <Typography className=" font-sans ml-[1rem] sm:ml-[2rem] text-[1.2rem] sm:text-[1.5rem] font-semibold mr-[0.2rem] ">
                  Profile Settings
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="flex justify-center mt-3 p-3 min-h-[90vh]">
        <Col md={11} >
          <div className="w-[92vw] sm:w-[100%] border border-[#D9D9D9] shadow rounded-md px-4 py-4 pb-0 ">
            <div className="flex justify-between border-b  pb-3 ">
              <Typography className="font-semibold text-[1.1rem] text-black font-sans">
                User Detail
              </Typography>
              <Typography
                className="text-[#F15E7E] text-[0.9rem] pr-3 cursor-pointer font-semibold font-sans"
                onClick={() => navigate("/profile-setting/edit-profile-detail")}
              >
                Edit
              </Typography>
            </div>
            <div>
              {data &&
                data?.map((data, key) => {
                  return (
                    <div className="flex my-4" key={key}>
                      <Typography className="font-sans text-[0.9rem] font-semibold text-black w-[50%]">
                        {data?.heading}
                      </Typography>
                      <Typography className="font-sans text-[0.9rem] font-normal text-black w-[50%] ">
                        {data?.content}
                      </Typography>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="md:flex gap-[0.7rem] mb-[6rem] w-[100%]">
            <div className="flex gap-[0.7rem] w-[100%] md:w-[50%]">
              <div
                className="w-[50%] border border-[#D9D9D9] shadow rounded-md p-4 mt-4 flex justify-center items-center flex-col cursor-pointer"
                onClick={() => navigate("/order-review")}
              >
                <div className="bg-[#FBF0F2] !rounded-full h-[3.8rem] w-[3.8rem] flex items-center justify-center">
                  <img
                    src={order_review}
                    alt="order-review"
                    className=" object-contain "
                  />
                </div>
                <Typography className="text-[1rem] text-black mt-3 font-sans">
                  Order Review
                </Typography>
              </div>
              <div
                className="w-[50%] border border-[#D9D9D9]  shadow rounded-md p-4 mt-4 flex justify-center items-center flex-col cursor-pointer"
                onClick={() => navigate("/profile-setting/manage-patient")}
              >
                <div className="bg-[#FBF0F2] !rounded-full h-[3.8rem] w-[3.8rem] flex items-center justify-center">
                  <img
                    src={manage_patient}
                    alt="manage patient"
                    className=" object-contain"
                  />
                </div>
                <Typography className="font-sans text-[1rem] text-black mt-3 " style={{ whiteSpace: 'nowrap' }}>
                  Manage Patients
                </Typography>
              </div>
            </div>
            <div className="flex gap-[0.7rem] w-[100%] md:w-[50%]">
              <div
                className="w-[50%] border border-[#D9D9D9]  shadow rounded-md p-4 mt-4 flex justify-center items-center flex-col cursor-pointer"
                onClick={() => navigate("/profile-setting/manage-address")}
              >
                <div className="bg-[#FBF0F2] !rounded-full h-[3.8rem] w-[3.8rem] flex items-center justify-center">
                  <img
                    src={manage_address}
                    alt="order-review"
                    className=" manage-address"
                  />
                </div>
                <Typography className="font-sans text-[1rem] text-black mt-3" style={{ whiteSpace: 'nowrap' }}>
                  Manage Address
                </Typography>
              </div>
              <div
                className="w-[50%] border border-[#D9D9D9] shadow rounded-md p-4 mt-4 flex justify-center items-center flex-col cursor-pointer"
                onClick={() => navigate("/profile-setting/my-coupon")}
              >
                <div className="bg-[#FBF0F2] !rounded-full h-[3.8rem] w-[3.8rem] flex items-center justify-center">
                  <img
                    src={my_coupon}
                    alt="my-coupon"
                    className=" object-contain"
                  />
                </div>
                <Typography className="font-sans text-[1rem] text-black mt-3">
                  My Coupons
                </Typography>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {isLoading && (
        <Loader />
      )}
      <Footer />
    </>
  );
};

export default ProfileSettings;
