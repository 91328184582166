import React from "react";
import Footer from "../../components/CommonComponents/Footer";
import PatientForm from "./PatientForm";
import { Button, Col, Row, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const EditPatient = () => {
  const navigate = useNavigate();
  return (
    <>
      <Row className="border-b border-[#BFBFBF] pb-2">
          <Col span={20} offset={window.innerWidth < 640 ? 1 : 2}>
            <div className="h-7 sm:h-10 flex items-center justify-start text-[1.2rem] md:text-[1.4rem] ">
              <ArrowLeftOutlined
                onClick={() => navigate("/profile-setting/manage-patient")}
              />
              <div className="flex items-baseline">
                <Typography className="ml-[1.4rem] md:ml-[2rem] text-[1.2rem] md:text-[1.5rem] font-semibold mr-[0.2rem] font-sans">
                Edit Patient
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      <div className="p-4 min-h-[80vh]">
        <PatientForm />
      </div>
      <Footer />
      {window.innerWidth < 640 ? (
        <>
          <div className="h-[8vh] bg-white"></div>
          <div className="fixed bottom-0 bg-white w-[100vw] flex justify-center items-center mt-2 h-[8vh] border-t">
            <Button
              className="  bg-[#F15E7E]  !text-white !border-[#F15E7E] h-[5vh] w-[92vw] rounded my-2 "
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditPatient;
