import Typography from "antd/es/typography/Typography";
import React, { useEffect, useState } from "react";
import { message } from "antd";
import { getLandingOffer } from "../../service/homeApi";

const DiscountAndOffers = () => {
  const [discountData, setDiscountData] = useState([]);
  const discountOfferLanding = async () => {
    try {
      const response = await getLandingOffer();
      setDiscountData(response?.data?.payload?.campaigns);
    } catch (error) {}
  };

  useEffect(() => {
    discountOfferLanding();
  }, []);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const openMessage = (coupon) => {
    navigator?.clipboard?.writeText(coupon);
    messageApi.open({
      key,
      type: "success",
      content: "Copied!",
      duration: 2,
    });
  };

  return (
    <>
      {discountData?.length > 0 && (
        <>
          <div className="sm:mb-[5%] ">
            {contextHolder}
            <div className="text-center mt-[2rem] sm:mt-16 pb-3">
              <Typography className="text-center font-semibold text-[1.4rem] sm:text-[2rem] font-sans">
                Discounts & Offers
              </Typography>
              <Typography className="text-center text-[#434343] z w-[70%] m-[auto] sm:text-[1.1rem] mb-[1rem] sm:mb-[1.6rem] font-normal font-sans">
                Exciting offers to provide premium service at cheaper rates
              </Typography>
            </div>
            <div className="flex gap-5 relative overflow-x-scroll no-scrollbar w-full xl:justify-center">
              {discountData?.map((e, index) => {
                return (
                  <>
                    <div
                      className={`flex items-center justify-between rounded-lg m-1 md:min-w-[21rem]`}
                      key={index}
                      style={{
                        backgroundColor: `${
                          index === 1
                            ? "#F3FCFC"
                            : index === 2
                            ? "#FBF0F2"
                            : "#E5F5FF"
                        }`,
                        border: `1px solid ${
                          index === 1
                            ? "rgba(1,105,114,0.3)"
                            : index === 2
                            ? "rgba(241,94,126,0.3)"
                            : "rgba(0,87,163,0.3)"
                        }`,
                        boxShadow: `1px 1px 5px 1px ${
                          index === 1
                            ? "rgba(1,105,114,0.2)"
                            : index === 2
                            ? "rgba(241,94,126,0.2)"
                            : "rgba(0,87,163,0.2)"
                        }`,
                      }}
                    >
                      <div className="p-4 w-[50%] sm:w-[55%] ">
                        <Typography
                          className={`font-semibold text-[0.9rem] sm:text-lg font-sans`}
                          style={{
                            color:
                              index === 1
                                ? "#016972"
                                : index === 2
                                ? "#F15E7E"
                                : "#0057A3",
                          }}
                        >
                          {e?.offer?.title}
                        </Typography>
                        <Typography className="text-[0.8rem] pb-5 font-sans h-[4rem]">
                          {e?.offer?.description}
                        </Typography>
                        <div
                          className={`flex h-9 lg:w-48 w-64 mt-4 lg:items-center lg:justify-center lg:rounded-md`}
                        >
                          <Typography
                            className="lg:w-[50%] hidden h-[100%] text-center lg:flex lg:justify-center lg:items-center lg:border-r font-semibold -mr-1 rounded-l-md font-sans"
                            style={{
                              border: `1px solid ${
                                index === 1
                                  ? "#016972"
                                  : index === 2
                                  ? "#F15E7E"
                                  : "#0057A3"
                              }`,
                            }}
                          >
                            {e?.offer?.name}
                          </Typography>
                          <Typography
                            className={`lg:w-[50%] w-[40%] h-[100%] text-center flex justify-center items-center border-l text-white cursor-pointer lg:rounded-r-md rounded-md lg:rounded-l-none font-sans`}
                            onClick={() => {
                              openMessage(e?.offer?.name);
                            }}
                            style={{
                              backgroundColor:
                                index === 1
                                  ? "#016972"
                                  : index === 2
                                  ? "#F15E7E"
                                  : "#0057A3",
                              textColor: "black",
                            }}
                          >
                            Copy Code
                          </Typography>
                        </div>
                      </div>
                      <img
                        src={e?.bannerUrl}
                        alt={e?.bannerName}
                        className="w-[50%] sm:w-[35%] h-full pt-2 pr-1"
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}

      
    </>
  );
};

export default DiscountAndOffers;
