import OfferAppliedModal from "./OfferAppliedModal";
import { CloseCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Divider, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/axios";
import { useDispatch } from "react-redux";
import {
  addAppliedName,
  addDiscountedPercentage,
} from "../../redux/Offers/offerNameSlice";
import { toast } from "react-toastify";

const CardOfferAndDiscount = ({
  name,
  title,
  description,
  discountPercentage,
  width,
  isAllOffers,
  manageViewAllOfferModal,
  id,
  getData,
  tc,
  discountType,
}) => {
  const [open, setOpen] = useState(false);
  const [isCollapseTerms, setIsCollapseTerms] = useState(false);
  const [offerpaload, setOfferData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setOfferData({ couponId: id, couponName: null });
  }, []);

  const navigate = new useNavigate();

  const handleCollapse = () => {
    setIsCollapseTerms(!isCollapseTerms);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const [isAppliedOfferOpen, setIsAppliedOfferOpen] = useState(false);

  const handleAppliedOfferModal = () => {
    applyOffer(1500);
    if (isAllOffers) {
      if (window.innerWidth > 640) {
        manageViewAllOfferModal(false);
      } else {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    }
  };

  const applyOffer = async (time) => {
    try {
      const response = await axiosInstance.post(`/api/v1/offer/apply-coupon`, {
        couponId: id,
      });
      dispatch(addAppliedName(name));
      dispatch(addDiscountedPercentage(discountPercentage));
      setIsAppliedOfferOpen(!isAppliedOfferOpen);
      setTimeout(setIsAppliedOfferOpen, 2000);
      setTimeout(() => {
        if (getData) {
          getData();
        }
      }, 1500);

      return response;
    } catch (error) {
      toast.info("Something went wrong. Please try again after some time.");
    }
  };

  return (
    <div
      className={`w-${
        width ? width : "30rem"
      } border rounded-lg min-h-[10rem] flex shadow-md`}
    >
      <div className={`flex items-center`} id="offer">
        <Typography className="transform -rotate-90 text-lg -ml-9 w-[124px] text-white font-semibold font-sans">
          {discountType === "percentage"
            ? `FLAT ${discountPercentage}% OFF`
            : `FLAT ₹${discountPercentage} OFF`}
        </Typography>
      </div>
      <div className="flex flex-col items-center bg-[#FFFFFF] -ml-8 w-[90%] ]">
        <div className="px-4 py-4 w-[100%] h-[100%] flex justify-between flex-col">
          <div>
            <div className="flex items-center justify-between">
              <Typography className="text-[1rem] font-semibold font-sans">
                {name.slice(0, 18)} {name.length > 18 ? <>..</> : <></>}
              </Typography>
              <Typography
                className="text-[0.9rem] text-[#0057A3] font-semibold cursor-pointer font-sans w-[30%]"
                onClick={handleAppliedOfferModal}
              >
                Apply
              </Typography>
            </div>
            <Typography className="text-[0.85rem] font-sans mt-2">
              {title.slice(0, 28)} {title.length > 28 ? <>..</> : <></>}
            </Typography>
          </div>

          <Divider className="my-3" />

          <div>
            <Typography className="text-[#595959] h-[2.4rem] font-sans hideLongTypography2 ">
              {description.slice(0, 50)}{" "}
              {description.length > 50 ? <>..</> : <></>}
            </Typography>
            <div
              className="flex items-center justify-between mt-4 cursor-pointer"
              onClick={() => {
                if (isAllOffers) {
                  setIsCollapseTerms(!isCollapseTerms);
                } else {
                  setOpen(true);
                }
              }}
            >
              <Typography className="text-[#F15E7E] font-sans ">
                View Terms & Conditions
              </Typography>
              <RightOutlined className="text-[#F15E7E] text-xs mb-[2px]" />
            </div>
          </div>
        </div>

        {isCollapseTerms ? <div className="w-full pl-4 py-2">{tc}</div> : <></>}

        <Modal
          open={open}
          title={
            <span className="text-[1.2rem] font-sans">
              Terms and Conditions
            </span>
          }
          onCancel={handleCancel}
          closeIcon={
            <CloseCircleOutlined
              style={{ color: "#000", fontSize: "1.2rem" }}
            />
          }
          footer={[]}
          width={600}
          className="lg:p-20 lg:mt-0 mt-24"
          centered={true}
        >
          <Divider className="mt-0" />
          <Typography className="text-sm leading-6 font-sans">{tc}</Typography>
        </Modal>
      </div>

      <OfferAppliedModal
        openOfferAppliedModal={isAppliedOfferOpen}
        handleAppliedOfferModal={handleAppliedOfferModal}
      />
    </div>
  );
};

export default CardOfferAndDiscount;
