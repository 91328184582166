import { Button, DatePicker, Form, Input, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetails, updateUserDetails } from "../../service/ProfileApi";
import { toast } from "react-toastify";
import moment from "moment";
import dayjs from "dayjs";

const EditProfileForm = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [userDetails, setUserDetails] = useState();

  const getUser = async () => {
    try {
      const response = await getUserDetails();
      if (response) {
        setUserDetails(response?.data?.payload?.dataToBeSend);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUser();
  }, []);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
          height: 45,
        }}
        className="flex justify-center items-center !rounded "
        defaultValue={"+91"}
      >
        <Select.Option value="91">+91</Select.Option>
        <Select.Option value="86">+86</Select.Option>
        <Select.Option value="87">+87</Select.Option>
      </Select>
    </Form.Item>
  );

  const UpdateDetails = async (data) => {
    try {
      const response = await updateUserDetails(data);
      if (response) {
        toast.success("Your details have been updated.");
      }
    } catch (error) {
      toast.info("Something went wrong. Please try again after some time.");
    }
  };

  useEffect(() => {
    if (userDetails) {
      userDetails.dob = dayjs(userDetails.dob);
    }
    form.resetFields();
    form.setFieldsValue(userDetails);
  }, [userDetails]);

  const handleSave = () => {
    const values = form.getFieldsValue();
    setFormValues(values);
    UpdateDetails(formValues);
    navigate("/profile-setting");
  };

  return (
    <div>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) => {
          setFormValues(allValues);
        }}
        id="form"
      >
        <div className="sm:flex  w-[100%] justify-between my-2 ">
          {/* ----------------------- First Name ----------------------*/}
          <div className="sm:w-[48%]">
            <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
              First Name
            </Typography>

            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
                {
                  min: 3,
                  message: "Must be at least 3 characters long!",
                },
                {
                  max: 35,
                  message: "Must not exceed 35 characters!",
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: "cannot be empty",
                },
              ]}
              className="mb-3"
            >
              <Input
                placeholder="First Name"
                className="rounded py-[0.5rem] font-sans"
              />
            </Form.Item>
          </div>
          {/* ----------------------------------------------------------- */}

          {/* ----------------------- Last Name ----------------------*/}
          <div className="sm:w-[48%]">
            <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
              Last Name
            </Typography>

            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
                {
                  min: 3,
                  message: "Must be at least 3 characters long!",
                },
                {
                  max: 35,
                  message: "Must not exceed 35 characters!",
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: "cannot be empty",
                },
                ,
                {
                  pattern: /^(?!\s*$).+/,
                  message: "cannot be empty",
                },
              ]}
              className="mb-3"
            >
              <Input
                placeholder="Last Name"
                className="rounded py-[0.5rem] font-sans"
              />
            </Form.Item>
          </div>
          {/* ----------------------------------------------------------- */}
        </div>
        <div>
          <Typography className="text-[#595959] mb-[8px] font-sans">
            Phone No.
          </Typography>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your Phone Number!",
              },
              {
                pattern: new RegExp(/^[6-9]\d{9}$/i),
                message: "Please Enter a Valid Phone Number",
              },
            ]}
          >
            <Input
              addonBefore={prefixSelector}
              style={{
                width: "100%",
                borderRadius: "4px",
              }}
              placeholder="Phone No."
              className="font-sans !rounded"
            />
          </Form.Item>
        </div>
        <div>
          <Typography className="text-[#595959] mb-[8px] font-sans">
            Email Address
          </Typography>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              placeholder="Email Address"
              className="rounded h-10 font-sans"
              disabled
            />
          </Form.Item>
        </div>

        {/* ------------------------Date picker DOB------------------------- */}
        <div className="mb-5">
          <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
            DOB
          </Typography>
          <Form.Item name="dob" className="mb-2">
            <DatePicker
              className="w-[100%] py-[0.5rem] rounded"
              placeholder="dd/mm/yy"
              inputReadOnly={true}
            />
          </Form.Item>
        </div>

        <div className="mb-5">
          <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
            Gender
          </Typography>
          <Form.Item
            className="mb-3 !rounded"
            name="gender"
            rules={[
              {
                required: true,
                message: "State",
              },
            ]}
          >
            <Select placeholder="Gender" className="!rounded text-[0.85rem]">
              <Select.Option
                className="!text-[0.85rem] font-sans "
                value="male"
              >
                Male
              </Select.Option>
              <Select.Option
                className="!text-[0.85rem] font-sans"
                value="female"
              >
                Female
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div>
          <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
            Location
          </Typography>

          <Form.Item
            name="location"
            rules={[
              {
                required: true,
                message: "Required field",
              },
              {
                pattern: /^(?!\s*$).+/,
                message: "cannot be empty",
              },
            ]}
            className="mb-3"
          >
            <Input
              placeholder="Location"
              className="rounded py-[0.5rem] font-sans"
            />
          </Form.Item>
        </div>
        {window.innerWidth > 640 ? (
          <div className="flex justify-end mb-4">
            <Button
              htmlType="submit"
              className="my-[0.5rem] w-[8rem] h-[2.6rem] text-[1rem] font-semibold bg-[#F15E7E] text-[white] py-[0.4rem] px-[2.8rem] rounded-[4px] font-sans"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        ) : (
          <>
            <div className="fixed bottom-0 left-0 bg-white w-[100vw] flex justify-center items-center mt-2 h-[8vh] border-t">
              <Button
                className=" font-sans bg-[#F15E7E]  !text-white !border-[#F15E7E] h-[5vh] w-[92vw] rounded my-2 "
                htmlType="submit"
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </Form>
      <style jsx>
        {`
          .ant-input {
            border-radius: 4px;
            padding: 8px 11px;
          }
          .ant-select .ant-select-arrow {
            color: black;
            font-size: 10px;
          }
          .ant-select-selector {
            height: 40px !important;
            border-radius: 2px;
          }

          .ant-input-group-addon {
            border-radius: 2px;
          }
          .ant-select-single .ant-select-selector {
            font-size: 12px;
            border-radius: 4px;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 38px !important;
          }
        `}
      </style>
    </div>
  );
};

export default EditProfileForm;
