import React, { useEffect, useState } from "react";
import {
  getcount,
  setCartData,
  setItemIds,
} from "../../redux/Cart/cartCountSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLabInCart, setOtherServicesSlots } from "../../redux/Slots/slot";
import { deleteFromCart } from "../../service/cartApi";
import { getOffers } from "../../redux/Offers/offerSlice";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Tooltip } from "antd";

const CartIcon = () => {
  const location = useLocation();

  const [isShow, setIsShow] = useState(true);
  const [partnerImage, setPartnerImage] = useState("");

  const urls = [
    "/diagnostic-tests",
    "/special-consult",
    "/packages",
    "/vaccination",
    "/pharmacy-voucher"
  ];

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setIsShow(true);
      return;
    }
    const match = urls.some((truePath) => path.includes(truePath));
    setIsShow(match);
  }, [location]);

  const [labName, setLabName] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCartData());
  }, []);

  const { cartData, itemIds } = useSelector((state) => state.count);

  useEffect(() => {
    const product = cartData?.product?.filter((data) => {
      if (data?.type === "test" || data?.type === "package") {
        return data;
      }
    });
    if (product?.length === 0 || !product) {
      setLabName(null);
      dispatch(setItemIds([]));
      dispatch(setLabInCart(""));
      dispatch(setOtherServicesSlots({}));
      return;
    } else {
      const details =
        product?.[0].type === "test"
          ? product?.[0].testDetails
          : product?.[0].packageDetails;
      const labId = details?.lab?._id;

      if (!labId) return;
      const labName = details.lab.name;
      setLabName(labName);
      dispatch(setLabInCart(labId));
      const itemIds = product.map((data) => data?._id);
      dispatch(setItemIds(itemIds));

      if (details?.package?.isVoucher) return;

      const slotDetails = details.slotDetails?.[0];
      const slotTimings = slotDetails?.slotTimings;
      const fetchedPartnerImage = details?.lab?.partner?.image2
        ? details?.lab?.partner?.image2
        : "";

      setPartnerImage(fetchedPartnerImage);

      dispatch(
        setOtherServicesSlots({
          date: slotDetails.date,
          slotTimingId: slotTimings?.slotTimingId,
          startTime: slotTimings?.startDate,
          endTime: slotTimings?.endDate,
          slotId: slotDetails.slot?._id,
        })
      );
    }
  }, [cartData]);

  const deleteItem = async (productId) => {
    try {
      const response = await deleteFromCart(productId);
    } catch (error) {
      console.error(`Error deleting item ${productId}:`, error);
    }
  };

  const handleDelete = async () => {
    if (itemIds?.length > 0) {
      for (const id of itemIds) {
        await deleteItem(id);
      }
      dispatch(setCartData());
      dispatch(getcount());
      dispatch(getOffers());
    }
  };

  return (
    <>
      {isShow && labName && (
        <div className=" fixed bottom-[12vh] right-4 w-[3.6rem] h-[3.6rem] bg-[#b6d1ff] z-50 rounded-full shadow-md ">
          <Tooltip
            title={`Remove cart items`}
            color="#74A1ED"
            placement="left"
            className="transition-all"
          >
            <CloseCircleOutlined
              className="text-[1.2rem] cursor-pointer absolute top-0 right-0 text-[#02348b]"
              onClick={handleDelete}
            />
          </Tooltip>
          <div className=" w-full h-full overflow-hidden rounded-full">
            <img src={partnerImage} className="w-full h-full " alt="Service" />
          </div>
        </div>
      )}
    </>
  );
};

export default CartIcon;
