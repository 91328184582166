import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/globalVariable";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

//Read Action
export const showUser = createAsyncThunk(
  "showUser",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/cart`);
      if (response.success) {
        return response.data;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error); 
    }
  }
);

//cart Action Slice

export const cartDetail = createSlice({
  name: "userDetail",
  initialState,
  reducers: {},
  extraReducers: {
    //show user
    [showUser.pending]: (state) => {
      state.loading = true;
    },
    [showUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [showUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default cartDetail.reducer;

