import { Button, Modal, Typography } from "antd";
import React from "react";
import icFail from "../../assets/images/icFail.svg";
import { CloseCircleOutlined } from "@ant-design/icons";

const PaymentFailedModal = ({
  isOpen,
  close,
  paymentStat,
  initiliazeAgain,
}) => {
  return (
    <div>
      <Modal
        open={isOpen}
        centered={true}
        // closeIcon={<CloseCircleOutlined onClick={() => close(false)} />}
        closeIcon={<></>}
        footer={null}
      >
        <div>
          <div className="flex justify-between items-center">
            <Typography className="text-[1.6rem] text-[#ff4343]">
              Payment Failed
            </Typography>
            <img src={icFail} alt="" />
          </div>
          <div>
            <Typography className="text-[1rem] my-2">
              {paymentStat?.RESPMSG
                ? paymentStat?.RESPMSG
                : "Something went wrong!"}
            </Typography>
            <Typography>Transaction ID : {paymentStat?.TXNID}</Typography>
          </div>
          <div className="w-full flex justify-center mt-6 ">
            <button
              className="border border-black rounded-md  w-[40%] py-[0.5rem] mr-2"
              onClick={() => close(false)}
            >
              Close
            </button>
            <button
              className="bg-[#1677ff] text-white w-[40%] py-[0.5rem] rounded-md hover:bg-[#1677ffaf]"
              onClick={initiliazeAgain}
            >
              Retry
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentFailedModal;
