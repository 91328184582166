import { Button, Col, Rate, Row, Typography } from "antd";
import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import Footer from "../CommonComponents/Footer";

const FeedbackPage = () => {
  const navigate = useNavigate();
  const onRedirectBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Row className="border-b border-[#BFBFBF] pb-2">
        <Col span={20} offset={window.innerWidth < 640 ? 1 : 2}>
          <div className="h-7 sm:h-10 flex items-center justify-start text-[1.2rem] md:text-[1.4rem] ">
            <ArrowLeftOutlined onClick={onRedirectBack} />
            <div className="flex items-baseline">
              <Typography className="ml-[1.4rem] md:ml-[2rem] text-[1.2rem] md:text-[1.5rem] font-semibold mr-[0.2rem] font-sans">
                Feedback
              </Typography>
            </div>
          </div>
        </Col>
      </Row>
      <Col span={22} offset={window.innerWidth < 640 ? 1 : 2}>
        <div className="mt-4 min-h-[75vh]">
          <Typography className="text-[#161616] text-[1rem] font-normal font-sans">
            Rate you experience on our e-commerce portal
          </Typography>
          <Rate className="mt-2 text-[2rem] flex gap-[12%] " />
          <Typography className="text-[#161616] text-[1rem] font-normal my-3 font-sans">
            Would you like ro give a reason for your rating?
          </Typography>
          <TextArea autoSize={{ minRows: 4 }} className="my-2 rounded" />
        </div>
      </Col>
      <Footer />

      <div className="h-[8vh]"></div>
      <div className="fixed bottom-0 bg-white w-[100vw] flex justify-center items-center mt-2 h-[8vh] border-t">
        <Button
          className="  bg-[#F15E7E] !text-white !border-[#F15E7E] w-[92vw] h-[5vh] rounded my-2 font-sans "
          htmlType="submit"
        >
          Save Feedback
        </Button>
      </div>
    </>
  );
};

export default FeedbackPage;
