import { DatePicker, Form, Input, Select, Typography , Button} from "antd";
import React from "react";

const PatientForm = () => {
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
          height: 45,
        }}
        className="flex justify-center items-center "
        defaultValue={"+91"}
      >
        <Select.Option value="91">+91</Select.Option>
        <Select.Option value="86">+86</Select.Option>
        <Select.Option value="87">+87</Select.Option>
      </Select>
    </Form.Item>
  );
  return (
    <div className="">
      <Form id="myForm">
        <div className="sm:flex  w-[100%] justify-between my-2 ">
          {/* ----------------------- First Name ----------------------*/}
          <div className="sm:w-[48%]">
            <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
              First Name
            </Typography>

            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Enter First Name",
                },
              ]}
              className="mb-3"
            >
              <Input
                placeholder="First Name"
                className="rounded py-[0.5rem] font-sans"
              />
            </Form.Item>
          </div>
          {/* ----------------------------------------------------------- */}

          {/* ----------------------- Last Name ----------------------*/}
          <div className="sm:w-[48%]">
            <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
              Second Name
            </Typography>

            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Enter Second Name",
                },
              ]}
              className="mb-3"
            >
              <Input
                placeholder="Second Name"
                className="rounded py-[0.5rem] font-sans"
              />
            </Form.Item>
          </div>
          {/* ----------------------------------------------------------- */}
        </div>
        <div className="mb-5">
          <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
            Gender
          </Typography>
          <Form.Item
            className="mb-3 !rounded-md"
            name="gender"
            rules={[
              {
                required: true,
                message: "Please Select Gender",
              },
            ]}
          >
            <Select
              placeholder="Gender"
              className="rounded text-[0.85rem] font-sans"
            >
              <Select.Option className="!text-[0.85rem] font-sans" value="male">
                Male
              </Select.Option>
              <Select.Option className="!text-[0.85rem] font-sans" value="female">
                Female
              </Select.Option>
            </Select>
          </Form.Item>
        </div>

        {/* ------------------------Date picker DOB------------------------- */}
        <div className="mb-5">
          <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
            DOB
          </Typography>
          <Form.Item name="dob" className="mb-2" rules={[
                {
                  required: true,
                  message: "Enter DOB",
                },
              ]}>
            <DatePicker
              className="w-[100%] py-[0.5rem] rounded font-sans"
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"   
              inputReadOnly={true}
            />
          </Form.Item>
        </div>
        <div>
          <Typography className="text-[#595959] mb-[8px] font-sans">Phone No.</Typography>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your Phone Number!",
              },
              {
                pattern: new RegExp(/^[6-9]\d{9}$/i),
                message: "Please Enter a Valid Phone Number",
              },
            ]}
          >
            <Input
              addonBefore={prefixSelector}
              style={{
                width: "100%",
              }}
              placeholder="Phone No."
              className="font-sans"
            />
          </Form.Item>
        </div>
        {window.innerWidth > 640 ? (
          <div className="flex justify-end">
            <Button
             className="my-[0.5rem] w-[8rem] h-[2.6rem] text-[1rem] hover:!text-white font-semibold bg-[#F15E7E] text-[white] hover:!border-[#F15E7E] rounded-[4px] font-sans"
              type="default"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Form>
      <style jsx>
        {`
          .ant-input {
            padding: 8px 11px;
          }
          .ant-select .ant-select-arrow {
            color: black;
            font-size: 10px;
          }

          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            height: 40px;
            
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 38px;
          }
          

        `}
      </style>
    </div>
  );
};

export default PatientForm;
