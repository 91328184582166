// This card is for test showing the single center......................

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Carousel,
  Typography,
  Drawer,
  Tooltip,
} from "antd";
import {
  EnvironmentOutlined,
  ExperimentOutlined,
  HomeOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  StarFilled,
} from "@ant-design/icons";
import { image72 } from "../../assets/images";
import { getTestSlots } from "../../service/slotApi";
import { addToCart, getCart } from "../../service/cartApi";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getcount, setCartData } from "../../redux/Cart/cartCountSlice";
import DifferentLabModal from "../CommonComponents/DifferentLabModal";
import { getDate, formateTime } from "../CommonComponents/DateAndTime";
import SlotsLoader from "../CommonComponents/SlotsLoader";
import { setLabInCart, setOtherServicesSlots } from "../../redux/Slots/slot";

const LabCard = ({
  address,
  price,
  reviewsCount,
  rating,
  discountPrice,
  categoryId,
  labId,
  isDiagnostic,
  sId,
  distance,
  destinationlatitude,
  destinationlongitude,
  image,
  serviceMode,
  title,
  inCart,
  isDirectToCart,
  isVoucher,
}) => {
  const location = useLocation();
  const countOfcart = useSelector((state) => state.count.count);
  const slotsInCart = useSelector(
    (state) => state.SlotSlice.otherServicesSlots
  );
  const labInCart = useSelector((state) => state.SlotSlice.labInCart);
  const carttype = isDiagnostic ? "test" : "package";
  const [dataToCart, setDataToCart] = useState({});
  const [selectedSlot, setSelectedSlot] = useState("");
  const [slotId, setSlotId] = useState("");
  const [openChangeSlot, setOpenChangeSlot] = useState(false);
  const [slots, setSlots] = useState([]);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [date, setDate] = useState();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDifferentLabOpen, setIsDifferentLabOpen] = useState(false);

  const pincode = localStorage.getItem("pincode");

  useEffect(() => {
    setDataToCart({
      ...dataToCart,
      type: carttype,
      slotTimingId: selectedSlot,
      price: discountPrice,
      labId,
      serviceId: sId,
      startTime,
      endTime,
      date,
      serviceMode,
      pincode,
    });
  }, [selectedSlot]);

  useEffect(() => {
    setDataToCart({
      ...dataToCart,
      type: carttype,
      slotTimingId: selectedSlot,
      price: discountPrice,
      labId,
      serviceId: sId,
      slotId: slotId,
      startTime,
      endTime,
      date,
      serviceMode,
      pincode,
    });
  }, [slotId]);

  // to add item to cart after login
  useEffect(() => {
    const cartItem = JSON.parse(localStorage.getItem("cartItem"));
    const checkItem = cartItem ? Object.keys(cartItem) : [];
    if (checkItem.length) {
      dispatch(
        setOtherServicesSlots({
          slotTimingId: cartItem?.slotTimingId,
          startTime: cartItem.startTime,
          endTime: cartItem.endTime,
          date: cartItem.date,
          slotId: cartItem.slotId,
        })
      );
      dispatch(setLabInCart(cartItem.labId));
      addOrderToCart({ product: cartItem });
      localStorage.removeItem("cartItem");
      localStorage.removeItem("redirectPath");
    }
  }, []);

  useEffect(() => {
    if (countOfcart > 0) {
      getCart().then((data) => {
        const product = data?.data?.payload?.cart?.product?.filter((data) => {
          if (data?.type === "test" || data?.type === "package") {
            return data;
          }
        });
        if (product?.length === 0 || !product) {
          return;
        } else {
          const details =
            product[0].type === "test"
              ? product[0].testDetails
              : product[0].packageDetails;
          const labId = details?.lab?._id;

          if (!labId) return;

          const slotDetails = details.slotDetails[0];
          const slotTimings = slotDetails.slotTimings;
          const labName = details.lab.name;
          const itemIds = product.map((data) => data?._id);

          dispatch(setLabInCart(labId));
          dispatch(
            setOtherServicesSlots({
              date: slotDetails.date,
              slotTimingId: slotTimings?.slotTimingId,
              startTime: slotTimings?.startDate,
              endTime: slotTimings?.endDate,
              slotId: slotDetails.slot?._id,
            })
          );
        }
      });
    } else {
      dispatch(setLabInCart(""));
      dispatch(setOtherServicesSlots({}));
    }
  }, [countOfcart]);

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <LeftOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 left-4 z-10 text-[0.7rem] border-2" />
    </button>
  );

  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <RightOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 right-5 z-10 text-[0.7rem] border-2" />
    </button>
  );

  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const manageModal = () => {
    getDiagnosticSlot();
    setOpenChangeSlot(!openChangeSlot);
  };

  const getDiagnosticSlot = async () => {
    setIsLoading(true);
    let type = isDiagnostic ? "" : "package";
    try {
      const response = await getTestSlots(categoryId, labId, type);
      setSlots(response?.data?.payload);
    } catch (error) {}
    setIsLoading(false);
  };

  const navigate = useNavigate();

  const latitute = localStorage?.getItem("latitude");
  const longitude = localStorage?.getItem("longitude");
  const redirectionURL = `https://www.google.com/maps/dir/?api=1&origin=${latitute},${longitude}&destination=${destinationlatitude},${destinationlongitude}`;

  const addOrderToCart = async (data) => {
    // data.payload.serviceMode = serviceMode;
    try {
      const response = await addToCart(data);
      if (response?.data?.success) {
        toast.success("Added to the cart");
        if (isVoucher) {
          navigate("/pharmacy-voucher", {
            state: {
              id: process.env.REACT_APP_PHARMACY_VOUCHER_ID,
              name: "Pharmacy voucher",
              isPharmacy: true,
            },
          });
        } else {
          navigate(-1);
        }
      } else {
        if (
          "You can't add test/package from a different lab partner" ===
          response.response?.data?.message
        ) {
          setIsDifferentLabOpen(true);
          setTimeout(setIsDifferentLabOpen, 3000);
        } else {
          toast.info("Your cart could not be updated");
        }
      }
      dispatch(setCartData());
      dispatch(getcount());
    } catch (error) {}
    setOpenChangeSlot(false);
  };

  const manageRemove = () => {
    // remove from cart
  };

  const handleSlot = (dayId, slotId, startTime, endTime, date) => {
    setSlotId(dayId);
    setSelectedSlot(slotId);
    setStartTime(startTime);
    setEndTime(endTime);
    setDate(date);
  };

  const [isWarning, setIsWarning] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const handleSubmit = () => {
    if (!token) {
      // setIsWarning(true);
      navigate("/login");
      setOpenChangeSlot(false);
      localStorage.setItem("cartItem", JSON.stringify(dataToCart));
      localStorage.setItem("redirectPath", location.pathname);
    } else {
      if (!slotsInCart.slotTimingId) {
        addOrderToCart({ product: dataToCart });
      } else {
        let cartDataToManage = dataToCart;
        cartDataToManage = { ...cartDataToManage, ...slotsInCart };
        addOrderToCart({ product: cartDataToManage });
      }
    }
  };

  return (
    <Card
      bodyStyle={{ padding: "1rem" }}
      className={`shadow-md xl:w-[26.8vw] h-[16rem] xl:mr-0 border border-[#D9D9D9] w-full relative `}
    >
      {labInCart !== "" && labInCart !== labId && (
        <Tooltip
          title={`Remove cart items to change lab`}
          color="#74A1ED"
          className="transition-all"
        >
          <div className="w-full h-full bg-[#b6d1ff2f] absolute top-0 left-0  z-40 cursor-not-allowed"></div>
        </Tooltip>
      )}
      <div>
        <div className=" mb-2  flex items-center justify-between h-[3rem]">
          <img
            src={image}
            alt="Logo"
            className="h-[2.5rem] w-[30%] bg-slate-200 rounded-md"
          />
          <div className="flex items-center gap-1">
            <StarFilled className="text-[#F15E7E]" />
            <Typography className="text-base font-[500] font-sans">
              {rating}
            </Typography>
            <Typography className="text-[#8C8C8C] text-xs font-sans">
              ({reviewsCount}+ Reviews)
            </Typography>
          </div>
        </div>
      </div>

      <div className=" w-full my-[0.8rem] flex justify-between items-center ">
        <>
          {serviceMode === "Home" ? (
            <div className="flex items-center font-semibold">
              <HomeOutlined />
              <Typography className="mx-[0.5rem]">At Home</Typography>
            </div>
          ) : (
            <div className="flex items-center font-semibold">
              <ExperimentOutlined />
              <Typography className="mx-[0.5rem]">Visit Center</Typography>
            </div>
          )}
        </>
        <>
          {!(serviceMode === "Home") && (
            <div className="mr-3 ">
              <a href={redirectionURL} target="_blank" rel="noreferrer">
                <div className="flex items-center justify-center ml-6 w-full gap-1 ">
                  <EnvironmentOutlined className="ml-1 text-[#F15E7E]" />
                  <Typography className="text-[#F15E7E] font-sans cursor-pointer  text-center items-center flex gap-1 font-semibold">
                    {distance?.toFixed(1)} km Away
                  </Typography>
                </div>
              </a>
            </div>
          )}
        </>
      </div>

      <div className="flex justify-between items-start w-full mt-2 mb-2">
        {!(serviceMode === "Home") ? (
          <Typography className=" text-sm    font-sans h-[4.5rem] ">
            {address}
          </Typography>
        ) : (
          <Typography className=" text-sm    font-sans h-[4.5rem] ">
            Providing accurate and reliable home sampling right at your pincode:{" "}
            {pincode}.
          </Typography>
        )}
      </div>

      <div className="flex justify-between items-center">
        <Typography className="font-semibold text-xl font-sans">
          ₹{discountPrice}
          <sup className="text-xs md:text-xs font-normal text-[#8C8C8C] pl-1 font-sans">
            <del>₹{price}</del>
          </sup>
        </Typography>
        {inCart ? (
          <Button
            icon={<PlusOutlined />}
            className="my-[0.5rem]  w-[8rem] h-[2.6rem] rounded-[4px] flex items-center text-center border-[#F15E7E] text-[#F15E7E] text-[0.8rem] font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
            onClick={manageRemove}
          >
            Remove
          </Button>
        ) : (
          <Button
            icon={<PlusOutlined />}
            className="my-[0.5rem]  w-[8rem] h-[2.6rem] rounded-[4px] flex items-center text-center border-[#F15E7E] text-[#F15E7E] text-[0.8rem] font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
            onClick={() => {
              if (!slotsInCart.slotTimingId && !isDirectToCart) {
                manageModal();
              } else {
                handleSubmit();
              }
            }}
          >
            Add to Cart
          </Button>
        )}
      </div>

      {window.innerWidth > 640 ? (
        <Modal
          title={`Choose Slot for ${title === undefined ? " " : title}`}
          open={openChangeSlot}
          centered={true}
          closeIcon={<img src={image72} onClick={manageModal} alt="Close" />}
          footer={
            <button
              className="my-[0.5rem]  bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] font-sans"
              type="submit"
              form="myForm"
              onClick={handleSubmit}
            >
              Save
            </button>
          }
        >
          <div className="border-t-2 h-[24rem] ">
            {!isLoading ? (
              <>
                {slots.length > 0 ? (
                  <>
                    <Carousel slidesToShow={1} {...carouselSettings}>
                      {slots?.map((day) => (
                        <div>
                          <div className="flex justify-center items-center text-[1rem] font-semibold h-[1.2rem]">
                            {getDate(day.date)}
                          </div>
                          <div className=" mt-4 w-[100%] h-[20rem] overflow-y-scroll no-scrollbar flex flex-wrap gap-5 py-3">
                            {day.timeSlots.map((slot) => {
                              return (
                                <>
                                  {selectedSlot === slot._id ? (
                                    <button
                                      className="font-sans w-[30%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E]"
                                      id={`${slot._id}`}
                                    >
                                      {formateTime(slot.startTime)}
                                    </button>
                                  ) : (
                                    <button
                                      className={`font-sans w-[30%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots  ${
                                        slot?.isBooked &&
                                        "bg-[#E5E7EB] cursor-not-allowed line-through"
                                      }`}
                                      onClick={() =>
                                        handleSlot(
                                          day._id,
                                          slot._id,
                                          slot.startTime,
                                          slot.endTime,
                                          day.date
                                        )
                                      }
                                      id={`${slot._id}`}
                                      disabled={slot?.isBooked}
                                    >
                                      {formateTime(slot.startTime)}
                                    </button>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </>
                ) : (
                  <div className="flex flex-col w-full h-[20rem] justify-center items-center">
                    <Typography className="text-[1.4rem] ">
                      No result available
                    </Typography>
                    <Typography>Please try again later!</Typography>
                  </div>
                )}
              </>
            ) : (
              <>
                <SlotsLoader />
              </>
            )}
          </div>
        </Modal>
      ) : (
        <Drawer
          title="Choose Slot"
          open={openChangeSlot}
          placement={"bottom"}
          closeIcon={
            <img
              src={image72}
              onClick={manageModal}
              alt="Close"
              className="absolute right-4 top-[3%] w-[1.6rem] sm:block sm:w-[auto]"
            />
          }
          height={"60%"}
          key={"right"}
          footer={
            <div className="w-[100%] flex flex-row-reverse">
              <button
                className="my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] font-sans"
                type="submit"
                form="myForm"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          }
          className="rounded-t-lg"
        >
          <div>
            {!isLoading ? (
              <>
                {slots.length > 0 ? (
                  <>
                    <Carousel slidesToShow={1} {...carouselSettings}>
                      {slots?.map((day) => (
                        <div>
                          <div className="flex justify-center items-center text-[1rem] font-semibold font-sans">
                            {getDate(day?.date)}
                          </div>
                          <div className=" mt-6 w-[100%] flex flex-wrap gap-5 ">
                            {day.timeSlots.map((slot) => {
                              return (
                                <>
                                  {selectedSlot === slot._id ? (
                                    <button
                                      className="w-[28.8%] py-3 border-[1px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E] font-sans"
                                      id={`${slot._id}`}
                                    >
                                      {formateTime(slot.startTime)}
                                    </button>
                                  ) : (
                                    <button
                                      className={`w-[28.8%] py-3 border-[1.5px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots  ${
                                        slot?.isBooked &&
                                        "bg-[#E5E7EB] cursor-not-allowed line-through"
                                      }`}
                                      onClick={() =>
                                        handleSlot(
                                          day._id,
                                          slot._id,
                                          slot.startTime,
                                          slot.endTime,
                                          day.date
                                        )
                                      }
                                      id={`${slot._id}`}
                                      disabled={slot?.isBooked}
                                    >
                                      {formateTime(slot.startTime)}
                                    </button>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </>
                ) : (
                  <div className="flex flex-col w-full h-[20rem] justify-center items-center">
                    <Typography className="text-[1.4rem] ">
                      No result available
                    </Typography>
                    <Typography>Please try again later!</Typography>
                  </div>
                )}
              </>
            ) : (
              <>
                <SlotsLoader />
              </>
            )}
          </div>
        </Drawer>
      )}

      {isWarning && (
        <>
          <div className="w-[22rem] h-[8rem] shadow-lg bg-white fixed top-[10%] right-8 p-[1rem] border-2 rounded-md z-50">
            <Typography
              className="text-[1.2rem] cursor-pointer absolute right-2 top-0 font-semibold text-[#F15E7E]"
              onClick={() => {
                setIsWarning(false);
              }}
            >
              x
            </Typography>
            <Typography className="text-[1rem]">
              You are not logged in.
            </Typography>
            <Typography className="text-[0.8rem]">
              Please log in to add items in Cart{" "}
            </Typography>
            <Typography
              className="text-[0.9rem] text-[#F15E7E] mt-2 cursor-pointer underline"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login{" "}
            </Typography>
          </div>
        </>
      )}

      <DifferentLabModal
        isOpen={isDifferentLabOpen}
        setIsopen={setIsDifferentLabOpen}
      />
      <style jsx>{`
        .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
          border-color: #f15e7e;
          background-color: #f15e7e;
        }
      `}</style>
    </Card>
  );
};

export default LabCard;
