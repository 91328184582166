import { Typography } from 'antd';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const LogInWarning = ({ message, setIsWarning }) => {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = "unset";
    }, []);

    return (
        <div className="w-[22rem] h-[8rem] shadow-lg bg-white fixed top-6 right-6 p-[1rem] border-2 rounded-md">
            <Typography
                className="text-[1.2rem] cursor-pointer absolute right-2 top-0 font-semibold text-[#F15E7E]"
                onClick={() => {
                    setIsWarning(false);
                }}
            >
                x
            </Typography>
            <Typography className="text-[1rem]">
                You are not logged in.
            </Typography>
            <Typography className="text-[0.8rem]">
                {message}
            </Typography>
            <Typography
                className="text-[0.9rem] text-[#F15E7E] mt-2 cursor-pointer"
                onClick={() => {
                    navigate("/login");
                }}
            >
                Login{" "}
            </Typography>
        </div>
    )
}

export default LogInWarning