import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/CommonComponents/Footer";
import { useState } from "react";
import DeleteModal from "../../components/CommonComponents/DeleteModal";
import PatientForm from "./PatientForm";
import manage_patient from "../../assets/images/patient 1.svg";
import { deletePatient, getPatient } from "../../service/PatientApi";
import EditPatientDetailModal from "../../components/CartComponents/EditPatientDetailModal";
import AddPatientFom from "../../components/CartComponents/AddPatientFom";

const ManageAddress = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onEdit = () => {
    setIsModalOpenEdit(!isModalOpenEdit);
  };
  const redirectToEditPage = () => {
    navigate("/edit-patients");
  };
  const redirectToAddPage = () => {
    navigate("/add-new-patients");
  };
  const showModalEdit = () => {
    setIsModalOpenEdit(!isModalOpenEdit);
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const addressData = [
    {
      id: 1,
      name: "Raahat Mehra",
      Gender: "Male",
      Age: 56,
    },
    {
      id: 2,
      name: "Raahat Mehra",
      Gender: "Male",
      Age: 56,
    },
    {
      id: 3,
      name: "Raahat Mehra",
      Gender: "Male",
      Age: 56,
    },
    {
      id: 4,
      name: "Raahat Mehra",
      Gender: "Male",
      Age: 56,
    },
  ];

  /////////// get patient -------------------------

  const [patient, setPatient] = useState([]);

  const fetchPatient = async () => {
    try {
      const resp = await getPatient();
      if (resp) {
        setPatient(resp);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchPatient();
  }, []);

  ////////////// edit patient ------------------------------------
  const [openEditPatient, setOpenEditPatient] = useState(false);
  const [editPatientDetail, seteditPatientDetail] = useState();

  const handleEditPatientModal = () => {
    setOpenEditPatient(!openEditPatient);
  };

  ////// delete patient --------------------------------------------

  const [deletePatientId, setDeletePatientId] = useState();
  const HandleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const callDetelePatient = async () => {
    try {
      const resp = await deletePatient(deletePatientId);
      fetchPatient();
    } catch (error) {
    }
  };

  return (
    <div>
      <div className="border-b-2 sticky">
        <Row className="sticky">
          <Col span={20} offset={2}>
            <div className="h-10 flex items-center sticky justify-start text-[1rem] sm:text-[1.4rem] sm:py-[2rem] -ml-[0.3rem] sm:ml-0">
              <ArrowLeftOutlined onClick={() => navigate("/profile-setting")} />
              <div className="flex items-baseline ">
                <Typography className=" ml-[1rem] sm:ml-[2rem] text-[1.2rem] sm:text-[1.5rem] font-semibold mr-[0.2rem] font-sans ">
                  Manage Patients
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="flex justify-center sm:mt-3 px-2 sm:p-3 min-h-[85vh]">
        <Col md={11} sm={22}>
          <div className="w-[100%] sm:border sm:border-[#D9D9D9] sm:shadow sm:rounded-md px-4 py-4 pb-0 sm:h-[75vh] sm:overflow-y-scroll sm:overflow-x-hidden">
            {addressData.length < 1 ? (
              <div className="flex items-center flex-col justify-center pt-[2.7rem] pb-[2rem]">
                <div className="bg-[#FBF0F2] !rounded-full h-[10rem] w-[10rem] flex items-center justify-center mb-[1.5rem]">
                  <img
                    src={manage_patient}
                    alt="manage patient"
                    className=" object-contain h-[6.5rem] w-[6.5rem]"
                  />
                </div>
                <Typography className="text-[black] font-normal text-[1rem] sm:text-[1.1rem] font-sans">
                  Add patient and enjoy faster checkout
                </Typography>

                <Button
                  onClick={
                    window.innerWidth > 640 ? showModal : redirectToAddPage
                  }
                  htmlType="submit"
                  className="border-[#F15E7E] text-[#F15E7E] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] mt-[1.5rem] font-sans"
                >
                  + Add New Patient
                </Button>
                <Modal
                  open={isModalOpen}
                  footer={null}
                  closeIcon={
                    <CloseCircleOutlined
                      className="text-black mr-2 "
                      onClick={showModal}
                    />
                  }
                  centered={true}
                  title={
                    <Typography className="font-semibold text-[1.1rem] pb-2 sm:border-b-[1.5px] sm:border-[#D9D9D9] font-sans">
                      Add New Patient
                    </Typography>
                  }
                >
                  <div className="max-h-[27rem] overflow-y-scroll no-scrollbar relative">
                    <AddPatientFom isEdit={true} isManage={false} />
                  </div>
                </Modal>
              </div>
            ) : (
              <>
                {window.innerWidth < 640 ? (
                  <Button
                    onClick={redirectToAddPage}
                    htmlType="submit"
                    className="font-sans border-[#F15E7E] text-[#F15E7E] w-[88vw] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] mb-4"
                  >
                    + Add New Patients
                  </Button>
                ) : (
                  <></>
                )}
                <div className="flex justify-between sm:border-b pb-3 ">
                  <Typography className="font-semibold text-[1.1rem] text-black font-sans">
                    Saved Patients
                  </Typography>
                  {window.innerWidth > 640 ? (
                    <Button
                      onClick={showModal}
                      htmlType="submit"
                      className="font-sans h-[2.6rem] border-[#F15E7E] text-[#F15E7E] !rounded-[4px] text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E]"
                    >
                      + Add New Patients
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Modal
                    open={isModalOpen}
                    footer={null}
                    closeIcon={
                      <CloseCircleOutlined
                        className="text-black text-lg "
                        onClick={showModal}
                      />
                    }
                    centered={true}
                    title={
                      <Typography className="font-sans font-semibold text-[1.1rem] pb-2 md:border-b-[1.5px] md:border-[#D9D9D9]">
                        Add New Patient
                      </Typography>
                    }
                  >
                    <div className="font-sans max-h-[27rem] overflow-y-scroll no-scrollbar relative">
                      <AddPatientFom
                        isEdit={true}
                        isManage={false}
                        managePatientModal={showModal}
                        fetchManagePatient={fetchPatient}
                      />
                    </div>
                  </Modal>
                </div>
              </>
            )}
            {patient &&
              patient?.map((data, index) => {
                return (
                  <>
                    <div className="font-sans w-[91vw] md:w-[44vw] flex justify-between pr-3 py-2 sm:pt-2 md:pt-5 sm:pb-4  ">
                      <div>
                        <Typography className="font-sans text-[0.85rem] text-black font-normal">
                          {data?.firstName} {data?.lastName}
                        </Typography>
                        <Typography className="font-sans text-[0.85rem] text-black font-normal">
                          {data.gender.charAt(0).toUpperCase() +
                            data.gender.slice(1)}
                        </Typography>
                      </div>

                      <div className=" mr-[0.5rem] sm:mr-[1rem]">
                        <EditOutlined
                          className="text-[#0057A3] text-[1.2rem] px-2"
                          onClick={() => {
                            seteditPatientDetail(data);

                            if (window.innerWidth > 640) {
                              handleEditPatientModal();
                            } else {
                              navigate("/cart/edit-patient-detail", {
                                state: {
                                  patientDetails: data,
                                },
                              });
                            }
                          }}
                        />

                        <DeleteOutlined
                          className="text-[#F15E7E] text-[1.2rem] p-1"
                          onClick={() => {
                            setDeletePatientId(data?._id);
                            HandleDeleteModal();
                          }}
                        />
                      </div>
                    </div>
                    {patient.length - index === 1 ? (
                      <></>
                    ) : (
                      <Divider className="mt-0 mb-2 min-w-[40vw] md:w-[43vw] w-[88vw] " />
                    )}
                  </>
                );
              })}
            <Modal
              open={isModalOpenEdit}
              footer={null}
              closeIcon={
                <CloseCircleOutlined
                  className="text-black mr-2"
                  onClick={showModalEdit}
                />
              }
              centered={true}
              title={
                <Typography className=" font-sans font-semibold text-[1.1rem] pb-2 md:border-b-[1.5px] md:border-[#D9D9D9]">
                  Edit Patient
                </Typography>
              }
            >
              <div className="max-h-[27rem] no-scrollbar overflow-y-scroll  relative">
                <PatientForm />
              </div>
            </Modal>
          </div>
        </Col>
      </Row>
      <Footer />
      <DeleteModal
        openDeleteModal={openDeleteModal}
        HandleDeleteModal={HandleDeleteModal}
        handleConfirm={callDetelePatient}
      />

      <EditPatientDetailModal
        openModal={openEditPatient}
        handleModal={handleEditPatientModal}
        patientDetails={editPatientDetail}
        isManage={true}
        getPatient={fetchPatient}
      />

      <style jsx>
        {`
          .ant-modal .ant-modal-close-x {
            margin-top: -4px;
          }
        `}
      </style>
    </div>
  );
};

export default ManageAddress;
