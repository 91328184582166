import React, { Fragment, useEffect, useState } from "react";
import Banner from "../../assets/images/HealthcheckPanelBanner.jpg";
import {
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  ConfigProvider,
  Grid,
  Row,
  Tabs,
  Typography,
} from "antd";
import Footer from "../../components/CommonComponents/Footer";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import RadiologyImage from "../../assets/images/Radiology.jpg";
import CardiologyImage from "../../assets/images/Cardiology.jpg";
import PathologyImage from "../../assets/images/Pathology.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardComponent from "../../components/CommonComponents/CardComponent";
import { getAddOnsDiagnostic } from "../../redux/AddOnsDiagnostic/AddOnsDiagnosticSlice";

const HealthCheck = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { itemIds } = useSelector((state) => state.count);
  const RecommendedTest = useSelector(
    (state) => state.AddOnsDiagnostic.diagnostic
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAddOnsDiagnostic());
  }, [itemIds]);

  const [onStateChange, setOnStateChange] = useState(0);
  const activeClass =
    "text-[#1F1F1F] border-b-2 border-[#1F1F1F] z-10 text-[0.85rem] md:text-[1rem] md:w-[20vw] font-semibold font-sans pb-2 text-center min-w-fit";
  const inActiveClass =
    "text-[#1F1F1F] text-[0.85rem] md:text-[1rem] font-sans pb-2 md:w-[20vw] text-center font-normal min-w-fit";
  const [faqClick, setFaqClick] = useState();
  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <ArrowLeftOutlined className="text-[#595959] border border-[#D9D9D9] bg-[#F0F0F0] rounded-full p-[0.3rem] absolute top-[40%] left-[-4%] text-[1.4rem] invisible sm:visible" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <ArrowRightOutlined className="text-[#595959] border border-[#D9D9D9 bg-[#F0F0F0] rounded-full p-[0.3rem] absolute top-[40%] right-[-4%] text-[1.4rem] invisible sm:visible" />
    </button>
  );

  const navigate = useNavigate();
  const responsiveSettings = [
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ];
  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };
  const [viewMore, setviewMore] = useState(4);
  const [hover, setHover] = useState(null);
  const [more, setMore] = useState(null);
  const onMouseEnterCard = (data) => {
    setHover(data);
  };
  const { Panel } = Collapse;
  const onMouseLeaveCard = () => {
    setHover(null);
  };

  const data1 = [
    {
      id: 1,
      que: "Hemogram",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Hemogram.jpg",
      ans: [
        {
          data: "Red and White Blood Cell Count",
        },
        {
          data: "Basophils",
        },
        {
          data: "Eosinophils",
        },
        {
          data: "Hemoglobin",
        },
        {
          data: "Monocytes",
        },
        {
          data: "Neutrophils",
        },
        {
          data: "Platelet count",
        },
        {
          data: "+7 Others",
        },
      ],
    },
    {
      id: 2,
      que: "Lipid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_lipid.jpg",
      ans: [
        {
          data: "Total Cholesterol",
        },
        { data: "Triglycerides" },
        {
          data: "LDL Cholesterol",
        },
        { data: "HDL Cholesterol" },
        {
          data: "VLDL Cholesterol",
        },
        { data: "LDL Cholesterol-to-HDL Cholesterol Ratio" },
        { data: "Total Cholesterol-to-HDL Cholesterol Ratio" },
      ],
    },
    {
      id: 3,
      que: "Kidney Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_kidneyprofile.jpg",
      ans: [
        {
          data: "Creatinine",
        },
        { data: "Blood Urea Nitrogen (BUN)" },
        {
          data: "Uric Acid",
        },
      ],
    },
    {
      id: 4,
      que: "Diabetes Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_diabetes.jpg",
      ans: [
        {
          data: "Fasting Blood Sugar",
        },
        { data: "Glycosylated Hemoglobin (HbA1c)" },
      ],
    },
    {
      id: 5,
      que: "Liver Function Test",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_liverfunctiontest.jpg",
      ans: [
        {
          data: "SGPT",
        },
        { data: "SGOT" },
        {
          data: "Bilirubin (Total & Direct)",
        },
      ],
    },
    {
      id: 6,
      que: "Thyroid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_thyroidprofile.jpg",
      ans: [
        {
          data: "Thyroid Stimulating Hormone (TSH)",
        },
      ],
    },
    {
      id: 7,
      que: "Urine Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_urineProfile.jpg",
      ans: [
        {
          data: "Urinalysis",
        },
      ],
    },
    {
      id: 8,
      que: "Heart Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_hearthealth.jpg",
      ans: [
        {
          data: "ECG",
        },
      ],
    },
    {
      id: 9,
      que: "Eye check up",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Eyecheckup.jpg",
      ans: [
        {
          data: "Eye Screening and Retina Scan",
        },
      ],
    },
    {
      id: 10,
      que: "Consults",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_specialistconsult.jpg",
      ans: [
        {
          data: "Dental Consultation",
        },
        { data: "General Physician" },
        {
          data: "Gynaecologist for Women Employee",
        },
      ],
    },
  ];
  const data2 = [
    {
      id: 11,
      que: "Hemogram",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Hemogram.jpg",
      ans: [
        {
          data: "Red and White Blood Cell Count",
        },
        {
          data: "Basophils",
        },
        {
          data: "Eosinophils",
        },
        {
          data: "Hemoglobin",
        },
        {
          data: "Monocytes",
        },
        {
          data: "Neutrophils",
        },
        {
          data: "Platelet count",
        },
        {
          data: "+7 Others",
        },
      ],
    },
    {
      id: 12,
      que: "Lipid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_lipid.jpg",
      ans: [
        {
          data: "Total Cholesterol",
        },
        { data: "Triglycerides" },
        {
          data: "LDL Cholesterol",
        },
        { data: "HDL Cholesterol" },
        {
          data: "VLDL Cholesterol",
        },
        { data: "LDL Cholesterol-to-HDL Cholesterol Ratio" },
        { data: "Total Cholesterol-to-HDL Cholesterol Ratio" },
      ],
    },
    {
      id: 13,
      que: "Kidney Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_kidneyprofile.jpg",
      ans: [
        {
          data: "Creatinine",
        },
        { data: "Blood Urea Nitrogen (BUN)" },
        {
          data: "Uric Acid",
        },
      ],
    },
    {
      id: 14,
      que: "Diabetes Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_diabetes.jpg",
      ans: [
        {
          data: "Fasting Blood Sugar",
        },
        { data: "Glycosylated Hemoglobin (HbA1c)" },
      ],
    },
    {
      id: 15,
      que: "Liver Function Test",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_liverfunctiontest.jpg",
      ans: [
        {
          data: "SGPT",
        },
        { data: "SGOT" },
        {
          data: "Bilirubin (Total & Direct)",
        },
        {
          data: "Alkaline Phosphatase",
        },
        { data: "Total Protien" },
        {
          data: "Albumin-to-Globulin (A:G) Ratio",
        },
      ],
    },
    {
      id: 16,
      que: "Bone & Joint Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Bone.jpg",
      ans: [
        {
          data: "Vitamin D3",
        },
        {
          data: "Calcium",
        },
      ],
    },
    {
      id: 17,
      que: "Thyroid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_thyroidprofile.jpg",
      ans: [
        {
          data: "Thyroid Stimulating Hormone (TSH)",
        },
        {
          data: "Free Triiodothyronine (FT3)",
        },
        {
          data: "Free Thyroxine (FT4)",
        },
      ],
    },
    {
      id: 18,
      que: "Cancer Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_cancer.jpg",
      ans: [
        {
          data: "S. Prostate Specific Antigen (PSA) for Men/Pap Smear for Women",
        },
      ],
    },
    {
      id: 19,
      que: "Urine Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_urineProfile.jpg",
      ans: [
        {
          data: "Urinalysis",
        },
      ],
    },
    {
      id: 20,
      que: "Heart Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_hearthealth.jpg",
      ans: [
        {
          data: "ECG",
        },
        {
          data: "2D Echo/TMT",
        },
      ],
    },
    {
      id: 21,
      que: "Eye check up",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Eyecheckup.jpg",
      ans: [
        {
          data: "Eye Screening and Retina Scan",
        },
      ],
    },
    {
      id: 22,
      que: "Specialists' Consults",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_specialistconsult.jpg",
      ans: [
        {
          data: "Dental Consultation",
        },
        { data: "Internal Medicine" },
        {
          data: "Gynaecologist for Women",
        },
      ],
    },
  ];
  const data3 = [
    {
      id: 23,
      que: "Hemogram",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Hemogram.jpg",
      ans: [
        {
          data: "Red and White Blood Cell Count",
        },
        {
          data: "Basophils",
        },
        {
          data: "Eosinophils",
        },
        {
          data: "Hemoglobin",
        },
        {
          data: "Monocytes",
        },
        {
          data: "Neutrophils",
        },
        {
          data: "Platelet count",
        },
        {
          data: "+7 Others",
        },
      ],
    },
    {
      id: 24,
      que: "Lipid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_lipid.jpg",
      ans: [
        {
          data: "Total Cholesterol",
        },
        { data: "Triglycerides" },
        {
          data: "LDL Cholesterol",
        },
        { data: "HDL Cholesterol" },
        {
          data: "VLDL Cholesterol",
        },
        { data: "LDL Cholesterol-to-HDL Cholesterol Ratio" },
        { data: "Total Cholesterol-to-HDL Cholesterol Ratio" },
      ],
    },
    {
      id: 25,
      que: "Kidney Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_kidneyprofile.jpg",
      ans: [
        {
          data: "Creatinine",
        },
        { data: "Blood Urea Nitrogen (BUN)" },
        {
          data: "Uric Acid",
        },
      ],
    },
    {
      id: 26,
      que: "Diabetes Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_diabetes.jpg",
      ans: [
        {
          data: "Fasting Blood Sugar",
        },
        { data: "Glycosylated Hemoglobin (HbA1c)" },
      ],
    },
    {
      id: 27,
      que: "Liver Function Test",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_liverfunctiontest.jpg",
      ans: [
        {
          data: "SGPT",
        },
        { data: "SGOT" },
        {
          data: "Bilirubin (Total & Direct)",
        },
        {
          data: "Alkaline Phosphatase",
        },
        { data: "Total Protien" },
        {
          data: "Albumin-to-Globulin (A:G) Ratio",
        },
      ],
    },
    {
      id: 28,
      que: "Bone & Joint Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Bone.jpg",
      ans: [
        {
          data: "Vitamin D3",
        },
        {
          data: "Calcium",
        },
      ],
    },
    {
      id: 29,
      que: "Thyroid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_thyroidprofile.jpg",
      ans: [
        {
          data: "Thyroid Stimulating Hormone (TSH)",
        },
        {
          data: "Free Triiodothyronine (FT3)",
        },
        {
          data: "Free Thyroxine (FT4)",
        },
      ],
    },
    {
      id: 30,
      que: "Cancer Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_cancer.jpg",
      ans: [
        {
          data: "S. Prostate Specific Antigen (PSA) for Men/Pap Smear for Women",
        },
      ],
    },
    {
      id: 31,
      que: "Urine Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_urineProfile.jpg",
      ans: [
        {
          data: "Urinalysis",
        },
      ],
    },
    {
      id: 32,
      que: "Heart Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_hearthealth.jpg",
      ans: [
        {
          data: "ECG",
        },
        {
          data: "2D Echo/TMT",
        },
      ],
    },
    {
      id: 33,
      que: "Xray",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_X-ray.jpg",
      ans: [
        {
          data: "Chest X-Ray (PA view)",
        },
      ],
    },
    {
      id: 34,
      que: "Eye check up",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Eyecheckup.jpg",
      ans: [
        {
          data: "Eye Screening and Retina Scan",
        },
      ],
    },
    {
      id: 35,
      que: "Specialists' Consults",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_specialistconsult.jpg",
      ans: [
        {
          data: "Dental Consultation",
        },
        { data: "Internal Medicine" },
        {
          data: "Gynaecologist for Women",
        },
        {
          data: "Dietician Consultation",
        },
      ],
    },
  ];
  const data4 = [
    {
      id: 36,
      que: "Hemogram",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Hemogram.jpg",
      ans: [
        {
          data: "Red and White Blood Cell Count",
        },
        {
          data: "Basophils",
        },
        {
          data: "Eosinophils",
        },
        {
          data: "Hemoglobin",
        },
        {
          data: "Monocytes",
        },
        {
          data: "Neutrophils",
        },
        {
          data: "Platelet count",
        },
        {
          data: "+7 Others",
        },
        {
          data: "Erythrocyte Sedimentation Rate (ESR)",
        },
      ],
    },
    {
      id: 37,
      que: "Lipid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_lipid.jpg",
      ans: [
        {
          data: "Total Cholesterol",
        },
        { data: "Triglycerides" },
        {
          data: "LDL Cholesterol",
        },
        { data: "HDL Cholesterol" },
        {
          data: "VLDL Cholesterol",
        },
        { data: "LDL Cholesterol-to-HDL Cholesterol Ratio" },
        { data: "Total Cholesterol-to-HDL Cholesterol Ratio" },
      ],
    },
    {
      id: 38,
      que: "Kidney Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_kidneyprofile.jpg",
      ans: [
        {
          data: "Creatinine",
        },
        { data: "Blood Urea Nitrogen (BUN)" },
        {
          data: "Electrolytes",
        },
        {
          data: "Uric Acid",
        },
      ],
    },
    {
      id: 39,
      que: "Diabetes Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_diabetes.jpg",
      ans: [
        {
          data: "Fasting Blood Sugar",
        },
        { data: "Glycosylated Hemoglobin (HbA1c)" },
      ],
    },
    {
      id: 40,
      que: "Liver Function Test",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_liverfunctiontest.jpg",
      ans: [
        {
          data: "SGPT",
        },
        { data: "SGOT" },
        { data: "GGT" },
        {
          data: "Bilirubin (Total & Direct)",
        },
        {
          data: "Alkaline Phosphatase",
        },
        { data: "Total Protien" },
        {
          data: "Albumin-to-Globulin (A:G) Ratio",
        },
      ],
    },
    {
      id: 41,
      que: "Bone & Joint Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Bone.jpg",
      ans: [
        {
          data: "Vitamin D3",
        },
        {
          data: "Calcium",
        },
      ],
    },
    {
      id: 42,
      que: "Thyroid Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_thyroidprofile.jpg",
      ans: [
        {
          data: "Thyroid Stimulating Hormone (TSH)",
        },
        {
          data: "Free Triiodothyronine (FT3)",
        },
        {
          data: " Free Thyroxine (FT4)",
        },
      ],
    },
    {
      id: 43,
      que: "Intestinal Cancer Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_cancer.jpg",
      ans: [
        {
          data: "Stool for Ocult Blood",
        },
      ],
    },
    {
      id: 44,
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_cardiacriskmarker.jpg",
      que: "Cardiac Risk Marker",
      ans: [
        {
          data: "hs-CRP",
        },
      ],
    },
    {
      id: 45,
      que: "Cancer Screening",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_cancer.jpg",
      ans: [
        {
          data: "S. Prostate Specific Antigen (PSA) for Men/Pap Smear for Women",
        },
      ],
    },
    {
      id: 46,
      que: "Nutrition Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_nutritionhealth.jpg",
      ans: [
        {
          data: "S. Vitamin B12",
        },
      ],
    },
    {
      id: 47,
      que: "Urine Profile",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_urineProfile.jpg",
      ans: [
        {
          data: "Urinalysis",
        },
      ],
    },
    {
      id: 48,
      que: "Heart Health",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_hearthealth.jpg",
      ans: [
        {
          data: "ECG",
        },
        {
          data: "2D Echo/TMT",
        },
      ],
    },
    {
      id: 49,
      que: "Abdominal Organs Evaluation",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Abdominal.jpg",
      ans: [
        {
          data: "Ultrasound (Whole Abdomen) Screening",
        },
      ],
    },
    {
      id: 50,
      que: "Lung Function Evaluation",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_intestinalcancerscreening.jpg",
      ans: [
        {
          data: "PFT (Spirometry)",
        },
      ],
    },
    {
      id: 51,
      que: "Xray",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_X-ray.jpg",
      ans: [
        {
          data: "Chest X-Ray (PA view)",
        },
      ],
    },
    {
      id: 52,
      que: "Eye check up",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_Eyecheckup.jpg",
      ans: [
        {
          data: "Eye Screening and Retina Scan",
        },
      ],
    },
    {
      id: 53,
      que: "Specialists' Consults",
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/healthCheckPage/healthcheckpanel_specialistconsult.jpg",
      ans: [
        {
          data: "Dental Consultation",
        },
        { data: "Internal Medicine" },
        {
          data: "Gynaecologist for Women",
        },
        {
          data: "Dietician Consultation",
        },
      ],
    },
  ];

  const carouselData2 = [
    {
      id: 10,
      title: "S. Electrolytes",
      content:
        "Used to measure electrolytes imbalance in body. Abnormal levels may cause problems like kidney disease, high blood pressure and irregularity in heart rhythm",
      price: "₹ 232",
    },
    {
      id: 11,
      title: "Vitamin B12",
      content:
        "To measure the levels of Vit B12 in blood. Deranged levels may cause fatigue, muscle weakness, memory loss, nerve damage, liver diasease and diabetes.",
      price: "₹ 479",
    },
    {
      id: 12,
      title: "Cancer Screening",
      content:
        "CA 19-9 Test (it measures the amount of a protein called cancer antigen 19-9) CA-125 (Cancer Screening for women)",
      price: "900",
    },
    {
      id: 13,
      title: "Iron Deficiency",
      content:
        "To measure the levels of Iron mineral in blood stream.  Deranged levels may cause Fatigue, palpitations, brittle nails and shortness of breath.",
      price: "₹272",
    },
    {
      id: 14,
      title: "ESR",
      content:
        "Used to diagnose the cause of inflammation including, arthritis, vasculities, and infection. ",
      price: "₹ 164",
    },
    {
      id: 15,
      title: "Cortisol",
      content:
        "Used to measure cortisol levels (stress hormone) in body. Deranged levels may cause weight gain, round face, thin arms and legs, muscle weakness, fatigue, weight loss. ",
      price: "₹ 598",
    },
    {
      id: 16,
      title: "USG Whole Abdomen",
      content:
        "To see the blood vessels and organs in belly area. Helps in determining the cause of stomach pain, bloating, and to check for kidney stones, liver diseases, tumors and many other consitions. ",
      price: "₹ 1350",
    },
    {
      id: 17,
      title: "Chest x-Ray",
      content:
        "To look at the structures and organs in chest. It helps to determine the conditions like heart problems, a collapsed lung, pneumonia, broken ribs, emphysema and cancer. ",
      price: "₹ 405",
    },
  ];
  const carouselData3 = [
    {
      id: 18,
      title: "S. Electrolytes",
      content:
        "Used to measure electrolytes imbalance in body. Abnormal levels may cause problems like kidney disease, high blood pressure and irregularity in heart rhythm",
      price: "₹ 232",
    },
    {
      id: 19,
      title: "Vitamin B12",
      content:
        "To measure the levels of Vit B12 in blood. Deranged levels may cause fatigue, muscle weakness, memory loss, nerve damage, liver diasease and diabetes.",
      price: "₹479",
    },
    {
      id: 20,
      title: "Cancer Screening",
      content:
        "CA 19-9 Test (it measures the amount of a protein called cancer antigen 19-9) CA-125 (Cancer Screening for women)",
      price: "₹900",
    },
    {
      id: 21,
      title: "Iron Deficiency",
      content:
        "To measure the levels of Iron mineral in blood stream.  Deranged levels may cause Fatigue, palpitations, brittle nails and shortness of breath.",
      price: "₹272",
    },
    {
      id: 22,
      title: "ESR",
      content:
        "Used to diagnose the cause of inflammation including, arthritis, vasculities, and infection. ",
      price: "₹164",
    },
    {
      id: 23,
      title: "Cortisol",
      content:
        "Used to measure cortisol levels (stress hormone) in body. Deranged levels may cause weight gain, round face, thin arms and legs, muscle weakness, fatigue, weight loss. ",
      price: "₹598",
    },
    {
      id: 24,
      title: "RA Factor",
      content:
        "Used to diagnose Rheumatoid Arthritis. Symptoms are Pain, swelling and stiff joints. ",
      price: "₹598",
    },
    {
      id: 25,
      title: "USG Whole Abdomen",
      content:
        "To see the blood vessels and organs in belly area. Helps in determining the cause of stomach pain, bloating, and to check for kidney stones, liver diseases, tumors and many other consitions. ",
      price: "₹1350",
    },
  ];
  const carouselData4 = [
    {
      id: 26,
      title: "APOA",
      content:
        "To analyze the risk of cardiovascular disease & to detect the Levels of HDL ( good cholesterol) in blood. ",
      price: "₹720",
    },
    {
      id: 27,
      title: "Phosphorus Test",
      content:
        "To check phosphorus levels in blood. Deranged levels may cause problems related to nerve signaling, muscle contraction, bones and teeth. ",
      price: "₹90",
    },
    {
      id: 28,
      title: "ANA (Anti-Nuclear Antibody Test)",
      content:
        "To detect measure and evaluate ANA in blood. It can help in the diagnosis of autoimmune disorders. ",
      price: "₹480",
    },
    {
      id: 29,
      title: "Iron Deficiency",
      content:
        "To measure the levels of Iron mineral in blood stream.  Deranged levels may cause Fatigue, palpitations, brittle nails and shortness of breath.",
      price: "₹272",
    },
    {
      id: 30,
      title: "APOB",
      content:
        "To analyze the risk of cardiovascular disease & to detect the Levels of LDL ( Bad cholesterol) in blood. ",
      price: "₹720",
    },
    {
      id: 31,
      title: "Cortisol",
      content:
        "Used to measure cortisol levels (stress hormone) in body. Deranged levels may cause weight gain, round face, thin arms and legs, muscle weakness, fatigue, weight loss. ",
      price: "₹598",
    },
    {
      id: 32,
      title: "Cancer Screening",
      content:
        "CA 19-9 Test (it measures the amount of a protein called cancer antigen 19-9) CA-125 (Cancer Screening for women)  ",

      price: "₹900",
    },
    {
      id: 33,
      title: "RA Factor",
      content:
        "Used to diagnose Rheumatoid Arthritis. Symptoms are Pain, swelling and stiff joints. ",
      price: "₹598",
    },
    {
      id: 34,
      title: "HOMO - homocysteine",
      content:
        "To measure the homocysteine in blood. Deranged value can incraese the risk of heart attack, stroke, and other heart diseases and blood vessel disorders.",
      price: "₹900",
    },
  ];

  useEffect(() => {
    updateURL(onStateChange);
  }, [onStateChange]);

  const handleButtonClick = (stateChange) => {
    setOnStateChange(stateChange);
    setviewMore(4);
    updateURL(stateChange);
  };

  const updateURL = (stateChange) => {
    const baseUrl = "/health-check";
    let specificRoute = "";
    switch (stateChange) {
      case 0:
        specificRoute = "/EHC_<40>_Male_Female";
        break;
      case 1:
        specificRoute = "/EHC_>40_Male_Female";
        break;
      case 2:
        specificRoute = "/EHC_>60_Male_Female";
        break;
      case 3:
        specificRoute = "/Platinum";
        break;
      default:
        specificRoute = "";
        break;
    }
    const newUrl = `${baseUrl}${specificRoute}`;
    window.history.replaceState({}, null, newUrl);
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const isSmallScreen = screens.xs;
  const isLargeScreen = screens.lg;

  const onChange = (key) => {
    if (key === "2") {
      handleButtonClick(3);
    }
  };
  const items = [
    {
      key: "1",
      label: `Executive Health Check`,
      children: (
        <>
          <div className="relative flex sm:justify-start border-b-[1px]    sm: gap-2 overflow-scroll no-scrollbar sm:w-1/2 cursor-pointer">
            <Typography
              className={onStateChange === 0 ? activeClass : inActiveClass}
              onClick={() => handleButtonClick(0)}
            >
              Below 40 Years
              <br /> Male and Female
            </Typography>
            <Typography
              className={onStateChange === 1 ? activeClass : inActiveClass}
              onClick={() => handleButtonClick(1)}
            >
              Above 40 Years
              <br /> Male and Female
            </Typography>
            <Typography
              className={onStateChange === 2 ? activeClass : inActiveClass}
              onClick={() => handleButtonClick(2)}
            >
              Above 60 Years
              <br /> Male and Female
            </Typography>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: `Platinum Health Check`,
      children: (
        <>
          <div className="relative flex sm:justify-start border-b-[1px] sm:hidden gap-2 overflow-scroll no-scrollbar sm:w-1/2 cursor-pointer">
            <Typography className={inActiveClass}>
              Below 40 Years
              <br /> Male and Female
            </Typography>
            <Typography className={inActiveClass}>
              Above 40 Years
              <br /> Male and Female
            </Typography>
            <Typography className={inActiveClass}>
              Above 60 Years
              <br /> Male and Female
            </Typography>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Row className="pt-[1.4rem] border-t-[1px] min-h-[92vh] max-w-full overflow-hidden mb-[15vh]">
        <Col>
          <div className="mx-[1.5rem] md:mx-[7rem]  ">
            <img src={Banner} className=" rounded-[8px] md:w-full" />
          </div>

          <div className="mt-[1.4rem] sm:mt-[2.2rem]  ">
            <div className="px-[1.5rem] md:px-[7rem]">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#1F1F1F",
                  },
                }}
              >
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  onChange={onChange}
                  size={isLargeScreen ? "large" : "default"}
                  centered={!isSmallScreen}
                  tabBarGutter={isLargeScreen ? 200 : undefined}
                />
              </ConfigProvider>
            </div>
            {onStateChange == 0 ? (
              <div className="mb-[1rem] ">
                <div className="px-[1.5rem] md:px-[7rem] mt-10">
                  <div className="flex justify-between ">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Test Panel
                    </Typography>
                    <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                      onClick={() => {
                        const recommendedSection = document.getElementById(
                          "recommended-section"
                        );
                        if (recommendedSection) {
                          recommendedSection.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                          });
                        }
                      }}
                    >
                      View Recommended Tests
                    </Typography>
                  </div>
                  <div className="mt-[0.3rem] md:mt-[1rem] mb-2 grid grid-col-1 md:grid-cols-4 gap-6">
                    {data1 &&
                      data1?.map((data, index) => {
                        return window.innerWidth > 700 ? (
                          <div
                            onMouseEnter={() => onMouseEnterCard(data?.id)}
                            onMouseLeave={onMouseLeaveCard}
                            key={data?.id}
                          >
                            {hover === data?.id ? (
                              <div className="h-[37vh] bg-[#E5F5FF] rounded-[8px] flex flex-col px-[2rem] py-[1rem] overflow-y-auto ">
                                {data?.ans?.map((items) => (
                                  <div className="flex w-[100%] gap-[0.625rem]">
                                    <div
                                      className="mt-[0.5rem]"
                                      style={{
                                        width: "7px",
                                        height: "7px",
                                        borderRadius: "100%",
                                        backgroundColor: "black",
                                      }}
                                    ></div>
                                    <div className="font-semibold text-[1rem] text-[#1F1F1F] font-sans w-[95%]">
                                      {items.data}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <Card
                                className="h-[37vh]"
                                bodyStyle={{ padding: "0px" }}
                                cover={
                                  <img
                                    alt="example"
                                    src={data?.image}
                                    className="object-cover h-[30vh]"
                                  />
                                }
                              >
                                <Typography className="text-center flex items-center font-sans font-semibold text-[1.1rem] justify-center h-[7vh]">
                                  {data?.que}
                                </Typography>
                              </Card>
                            )}
                          </div>
                        ) : (
                          <div className="grid-col-1">
                            <Collapse
                              expandIconPosition={"end"}
                              className="bg-white mt-[0.8rem]"
                              expandIcon={() => (
                                <DownOutlined className="pr-[1rem] pt-[2rem]" />
                              )}
                            >
                              <Fragment className="bg-white">
                                <Panel
                                  className="custom"
                                  header={
                                    <div className="flex gap-2 items-center">
                                      <img
                                        src={data?.image}
                                        className="h-[4rem] w-[6rem] rounded-l-[8px]"
                                      />
                                      <Typography className=" text-[1.05rem] font-semibold font-sans">
                                        {data?.que}
                                      </Typography>
                                    </div>
                                  }
                                  key={"1"}
                                >
                                  {data?.ans?.map((items) => {
                                    return (
                                      <li className="font-normal text-[0.875rem] md:text-[0.875rem] text-[#1F1F1F] font-sans mb-1 list-disc">
                                        {items.data}
                                      </li>
                                    );
                                  })}
                                </Panel>
                              </Fragment>
                            </Collapse>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  id="recommended-section"
                  className="pt-[1.5rem] mb-0 md:pt-[2.5rem] md:mb-[2rem] "
                >
                  <div className="px-[1.5rem] md:px-[7rem] flex justify-between mb-0">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Recommended Section
                    </Typography>

                    <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                      onClick={() => {
                        navigate("/diagnostic-tests");
                      }}
                    >
                      View all Tests
                    </Typography>
                  </div>
                  <div className=" gap-4 px-[1.5rem] md:px-[9.5rem] -mt-2">
                    <Carousel
                      responsive={responsiveSettings}
                      slidesToShow={3}
                      {...carouselSettings}
                      infinite={false}
                    >
                      {RecommendedTest?.map((e, key) => {
                        return (
                          <div className="px-1 sm:px-2">
                            <CardComponent
                              TestImage={
                                e?.category?.name === "Radiology"
                                  ? RadiologyImage
                                  : e?.category?.name === "Cardiology"
                                  ? CardiologyImage
                                  : PathologyImage
                              }
                              title={e?.name}
                              providers={e?.partners}
                              content={e?.description}
                              features={e?.features[0]}
                              id={e?._id}
                              duration={e?.duration}
                              route="diagnostic-tests"
                              key={key}
                              showSampling={true}
                              serviceType={e?.serviceMode}
                              reportTime={e?.reportTime}
                              requirments={e?.requirements}
                              discountPrice={e?.startingPrice}
                              inCart={e?.inCart}
                              productId={e?.productId}
                              startingOriginalPrice={e?.startingOriginalPrice}
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {onStateChange == 1 ? (
              <div className="mb-[1rem]">
                <div className="px-[1.5rem] md:px-[7rem] mt-10">
                  <div className="flex justify-between">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Test Panel
                    </Typography>
                    <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                      onClick={() => {
                        const recommendedSection = document.getElementById(
                          "recommended-section"
                        );
                        if (recommendedSection) {
                          recommendedSection.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                          });
                        }
                      }}
                    >
                      View Recommended Tests
                    </Typography>
                  </div>
                  <div className="mt-[0.3rem] md:mt-[1rem] mb-2 grid grid-col-1 md:grid-cols-4 gap-6">
                    {data2 &&
                      data2?.map((data, index) => {
                        return window.innerWidth > 700 ? (
                          <div
                            onMouseEnter={() => onMouseEnterCard(data?.id)}
                            onMouseLeave={onMouseLeaveCard}
                            key={data?.id}
                          >
                            {hover === data?.id ? (
                              <div className="h-[37vh] bg-[#E5F5FF] rounded-[8px] flex flex-col px-[2rem] py-[1rem] overflow-y-auto ">
                                {data?.ans?.map((items) => (
                                  <div className="flex w-[100%] gap-[0.625rem]">
                                    <div
                                      className="mt-[0.5rem]"
                                      style={{
                                        width: "7px",
                                        height: "7px",
                                        borderRadius: "100%",
                                        backgroundColor: "black",
                                      }}
                                    ></div>
                                    <div className="font-semibold text-[1rem] text-[#1F1F1F] font-sans w-[95%]">
                                      {items.data}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <Card
                                className="h-[37vh]"
                                bodyStyle={{ padding: "0px" }}
                                cover={
                                  <img
                                    alt="example"
                                    src={data?.image}
                                    className="object-cover h-[30vh]"
                                  />
                                }
                              >
                                <Typography className="text-center flex items-center font-sans font-semibold text-[1.1rem] justify-center h-[7vh]">
                                  {data?.que}
                                </Typography>
                              </Card>
                            )}
                          </div>
                        ) : (
                          <div className="grid-col-1">
                            <Collapse
                              expandIconPosition={"end"}
                              className="bg-white mt-[0.8rem]"
                              expandIcon={() => (
                                <DownOutlined className="pr-[1rem] pt-[2rem]" />
                              )}
                            >
                              <Fragment className="bg-white">
                                <Panel
                                  className="custom"
                                  header={
                                    <div className="flex gap-2 items-center">
                                      <img
                                        src={data?.image}
                                        className="h-[4rem] w-[6rem] rounded-l-[8px]"
                                      />
                                      <Typography className=" text-[1.05rem] font-semibold font-sans">
                                        {data?.que}
                                      </Typography>
                                    </div>
                                  }
                                  key={"1"}
                                >
                                  {data?.ans?.map((items) => {
                                    return (
                                      <li className="font-normal text-[0.875rem] md:text-[0.875rem] text-[#1F1F1F] font-sans mb-1 list-disc">
                                        {items.data}
                                      </li>
                                    );
                                  })}
                                </Panel>
                              </Fragment>
                            </Collapse>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div
                  id="recommended-section"
                  className="pt-[1.5rem] md:pt-[2.5rem] md:mb-[2rem]"
                >
                  <div className="px-[1.5rem] md:px-[7rem] flex justify-between mb-0">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Recommended Section
                    </Typography>

                    {/* <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                    >
                      View all Tests
                    </Typography> */}
                  </div>

                  {/* <div className="px-[1.5rem] md:px-[7rem] flex justify-between">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Recommended Sectio
                    </Typography>
                  </div> */}
                  <div className=" gap-4 px-[1.5rem] md:px-[9.5rem] -mt-2">
                    <Carousel
                      responsive={responsiveSettings}
                      slidesToShow={3.5}
                      {...carouselSettings}
                      infinite={false}
                    >
                      {carouselData2 &&
                        carouselData2?.map((data) => {
                          return (
                            <div className="sm:pr-[5.5%] pr-[2%]  ">
                              <Card
                                bodyStyle={{ padding: "10px" }}
                                className="h-[11rem] md:h-[10rem] overflow-y-hidden"
                              >
                                <div className="flex justify-between">
                                  <Typography className="text-[#000000] text-[0.9rem] md:text-[1rem] font-semibold font-sans mb-[0.5rem]">
                                    {data?.title}
                                  </Typography>
                                  <Typography className="text-[#F15E7E] text-[0.9rem] md:text-[1rem] font-semibold font-sans mb-[0.5rem]">
                                    {data?.price}
                                  </Typography>
                                </div>

                                {more === data?.id ? (
                                  <Typography className="text-[#595959] text-[0.875rem] md:text-[0.875rem] font-normal font-sans">
                                    {data?.content}
                                  </Typography>
                                ) : (
                                  <Typography className="text-[#595959] text-[0.875rem] font-normal font-sans">
                                    {data?.content}
                                  </Typography>
                                )}
                              </Card>
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {onStateChange == 2 ? (
              <div className="mb-[1rem]">
                <div className="px-[1.5rem] md:px-[7rem] mt-10">
                  <div className="flex justify-between">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Test Panel
                    </Typography>
                    <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                      onClick={() => {
                        const recommendedSection = document.getElementById(
                          "recommended-section"
                        );
                        if (recommendedSection) {
                          recommendedSection.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                          });
                        }
                      }}
                    >
                      View Recommended Tests
                    </Typography>
                    {/* <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                    >
                      View All Tests
                    </Typography> */}
                  </div>
                  <div className="mt-[0.3rem] md:mt-[1rem] mb-2 grid grid-col-1 md:grid-cols-4 gap-6">
                    {data3 &&
                      data3?.map((data, index) => {
                        return window.innerWidth > 700 ? (
                          <div
                            onMouseEnter={() => onMouseEnterCard(data?.id)}
                            onMouseLeave={onMouseLeaveCard}
                            key={data?.id}
                          >
                            {hover === data?.id ? (
                              <div className="h-[37vh] bg-[#E5F5FF] rounded-[8px] flex flex-col px-[2rem] py-[1rem] overflow-y-auto ">
                                {data?.ans?.map((items) => (
                                  <div className="flex w-[100%] gap-[0.625rem]">
                                    <div
                                      className="mt-[0.5rem]"
                                      style={{
                                        width: "7px",
                                        height: "7px",
                                        borderRadius: "100%",
                                        backgroundColor: "black",
                                      }}
                                    ></div>
                                    <div className="font-semibold text-[1rem] text-[#1F1F1F] font-sans w-[95%]">
                                      {items.data}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <Card
                                className="h-[37vh]"
                                bodyStyle={{ padding: "0px" }}
                                cover={
                                  <img
                                    alt="example"
                                    src={data?.image}
                                    className="object-cover h-[30vh]"
                                  />
                                }
                              >
                                <Typography className="text-center flex items-center font-sans font-semibold text-[1.1rem] justify-center h-[7vh]">
                                  {data?.que}
                                </Typography>
                              </Card>
                            )}
                          </div>
                        ) : (
                          <div className="grid-col-1">
                            <Collapse
                              expandIconPosition={"end"}
                              className="bg-white mt-[0.8rem]"
                              expandIcon={() => (
                                <DownOutlined className="pr-[1rem] pt-[2rem]" />
                              )}
                            >
                              <Fragment className="bg-white">
                                <Panel
                                  className="custom"
                                  header={
                                    <div className="flex gap-2 items-center">
                                      <img
                                        src={data?.image}
                                        className="h-[4rem] w-[6rem] rounded-l-[8px]"
                                      />
                                      <Typography className=" text-[1.05rem] font-semibold font-sans">
                                        {data?.que}
                                      </Typography>
                                    </div>
                                  }
                                  key={"1"}
                                >
                                  {data?.ans?.map((items) => {
                                    return (
                                      <li className="font-normal text-[0.875rem] md:text-[0.875rem] text-[#1F1F1F] font-sans mb-1 list-disc">
                                        {items.data}
                                      </li>
                                    );
                                  })}
                                </Panel>
                              </Fragment>
                            </Collapse>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  id="recommended-section"
                  className="pt-[1.5rem] md:pt-[2.5rem] md:mb-[2rem]"
                >
                  <div className="px-[1.5rem] md:px-[7rem] flex justify-between mb-0">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Recommended Section
                    </Typography>

                    {/* <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                    >
                      View all Tests
                    </Typography> */}
                  </div>
                  <div className=" gap-4 px-[1.5rem] md:px-[9.5rem] -mt-2">
                    <Carousel
                      responsive={responsiveSettings}
                      slidesToShow={3.5}
                      {...carouselSettings}
                      infinite={false}
                    >
                      {carouselData2 &&
                        carouselData3?.map((data) => {
                          return (
                            <div className="sm:pr-[5.5%] pr-[2%] ">
                              <Card
                                bodyStyle={{ padding: "10px" }}
                                className="h-[11rem] md:h-[10rem] overflow-y-hidden"
                              >
                                <div className="flex justify-between">
                                  <Typography className="text-[#000000] text-[0.9rem] md:text-[1rem] font-semibold font-sans mb-[0.5rem]">
                                    {data?.title}
                                  </Typography>
                                  <Typography className="text-[#F15E7E] text-[0.9rem] md:text-[1rem] font-semibold font-sans mb-[0.5rem]">
                                    {data?.price}
                                  </Typography>
                                </div>

                                {more === data?.id ? (
                                  <Typography className="text-[#595959] text-[0.875rem] md:text-[0.875rem] font-normal font-sans">
                                    {data?.content}
                                  </Typography>
                                ) : (
                                  <Typography className="text-[#595959] text-[0.875rem] font-normal font-sans">
                                    {data?.content}
                                  </Typography>
                                )}
                              </Card>
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {onStateChange == 3 ? (
              <div className="mb-[1rem]">
                <div className="px-[1.5rem] md:px-[7rem] mt-10">
                  <div className="flex justify-between">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Test Panel
                    </Typography>
                    <Typography
                      className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                      onClick={() => {
                        const recommendedSection = document.getElementById(
                          "recommended-section"
                        );
                        if (recommendedSection) {
                          recommendedSection.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                          });
                        }
                      }}
                    >
                      View Recommended Tests
                    </Typography>
                  </div>
                  <div className="mt-[0.3rem] md:mt-[1rem] mb-2 grid grid-col-1 md:grid-cols-4 gap-6">
                    {data4 &&
                      data4?.map((data, index) => {
                        return window.innerWidth > 700 ? (
                          <div
                            onMouseEnter={() => onMouseEnterCard(data?.id)}
                            onMouseLeave={onMouseLeaveCard}
                            key={data?.id}
                          >
                            {hover === data?.id ? (
                              <div className="h-[37vh] bg-[#E5F5FF] rounded-[8px] flex flex-col px-[2rem] py-[1rem] overflow-y-auto ">
                                {data?.ans?.map((items) => (
                                  <div className="flex w-[100%] gap-[0.625rem]">
                                    <div
                                      className="mt-[0.5rem]"
                                      style={{
                                        width: "7px",
                                        height: "7px",
                                        borderRadius: "100%",
                                        backgroundColor: "black",
                                      }}
                                    ></div>
                                    <div className="font-semibold text-[1rem] text-[#1F1F1F] font-sans w-[95%]">
                                      {items.data}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <Card
                                className="h-[37vh]"
                                bodyStyle={{ padding: "0px" }}
                                cover={
                                  <img
                                    alt="example"
                                    src={data?.image}
                                    className="object-cover h-[30vh]"
                                  />
                                }
                              >
                                <Typography className="text-center flex items-center font-sans font-semibold text-[1.1rem] justify-center h-[7vh]">
                                  {data?.que}
                                </Typography>
                              </Card>
                            )}
                          </div>
                        ) : (
                          <div className="grid-col-1">
                            <Collapse
                              expandIconPosition={"end"}
                              className="bg-white mt-[0.8rem]"
                              expandIcon={() => (
                                <DownOutlined className="pr-[1rem] pt-[2rem]" />
                              )}
                            >
                              <Fragment className="bg-white">
                                <Panel
                                  className="custom"
                                  header={
                                    <div className="flex gap-2 items-center">
                                      <img
                                        src={data?.image}
                                        className="h-[4rem] w-[6rem] rounded-l-[8px]"
                                      />
                                      <Typography className=" text-[1.05rem] font-semibold font-sans">
                                        {data?.que}
                                      </Typography>
                                    </div>
                                  }
                                  key={"1"}
                                >
                                  {data?.ans?.map((items) => {
                                    return (
                                      <li className="font-normal text-[0.875rem] md:text-[0.875rem] text-[#1F1F1F] font-sans mb-1 list-disc">
                                        {items.data}
                                      </li>
                                    );
                                  })}
                                </Panel>
                              </Fragment>
                            </Collapse>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  id="recommended-section"
                  className="pt-[1.5rem] md:pt-[2.5rem] md:mb-[2rem]"
                >
                  <div className="px-[1.5rem] md:px-[7rem] flex justify-between mb-0">
                    <Typography className="font-semibold text-[#1F1F1F] text-[1rem] md:text-[1.25rem] font-sans ">
                      Recommended Section
                    </Typography>

                    {/* <Typography
                     className="text-[0.875rem] md:text-[1rem] text-[#0057A3] font-semibold cursor-pointer font-sans pt-1"
                    >
                      View all Tests
                    </Typography> */}
                  </div>
                  <div className=" gap-4 px-[1.5rem] md:px-[9.5rem] -mt-2">
                    <Carousel
                      responsive={responsiveSettings}
                      slidesToShow={3.5}
                      {...carouselSettings}
                      infinite={false}
                    >
                      {carouselData4 &&
                        carouselData4?.map((data) => {
                          return (
                            <div className="sm:pr-[5.5%] pr-[2%] ">
                              <Card
                                bodyStyle={{ padding: "10px" }}
                                className="h-[11rem] md:h-[10rem] overflow-y-hidden"
                              >
                                <div className="flex justify-between">
                                  <Typography className="text-[#000000] text-[0.9rem] md:text-[1rem] font-semibold font-sans mb-[0.5rem]">
                                    {data?.title}
                                  </Typography>
                                  <Typography className="text-[#F15E7E] text-[0.9rem] md:text-[1rem] font-semibold font-sans mb-[0.5rem]">
                                    {data?.price}
                                  </Typography>
                                </div>

                                {more === data?.id ? (
                                  <Typography className="text-[#595959] text-[0.875rem] md:text-[0.875rem] font-normal font-sans">
                                    {data?.content}
                                  </Typography>
                                ) : (
                                  <Typography className="text-[#595959] text-[0.875rem] font-normal font-sans">
                                    {data?.content}
                                  </Typography>
                                )}
                              </Card>
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {window.innerWidth > 640 ? (
            <div className="flex items-center justify-center p-10 ">
              <div className="text-center text-lg bg-[#0057A3] text-white p-4 h-14 rounded-full flex items-center justify-center w-[70%]">
                Call our helpline number
                <PhoneOutlined className="rotate-90 ml-2" />
                7217890075 to add these tests and get immediate assistance
              </div>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center p-10 ">
              <Button className="text-center justify-center text-xs bg-[#0057A3] text-white p-4 h-14 rounded-full flex items-center w-[110%]">
                <a href="tel:+917217890075">
                  Call our helpline number{" "}
                  <PhoneOutlined className="rotate-90" /> 7217890075 <br></br>{" "}
                  to add these tests and get immediate assistance
                </a>
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <style jsx>
        {`
          .custom > .ant-collapse-header {
            padding: 0px 0px !important;
          }
        `}
      </style>
      <Footer />
    </>
  );
};

export default HealthCheck;
