/*************** Cart (Get All tests) ***************************/

import { toast } from "react-toastify";
import { axiosInstance } from "../utils/axios";

export const getCart = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/cart`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/*************** Cart (Add to cart) ***************************/

export const addToCart = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/v1/cart`, data);
    if (response.success) {
      return response;
    }
    return response;
  } catch (error) {
    return error;
  }
};

/*************** Cart (Add to cart) ***************************/

export const deleteFromCart = async (productId) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/cart/${productId}`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/*************** add or create patient***************************/

export const addPatient = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/v1/patient`, data);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/*************** add or create patient***************************/

export const addSugestedPatient = async (data) => {
  try {
    const response = await axiosInstance.post(
      `/api/v1/patient/add-patient-tocart`,
      data
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/*************** add or create patient********************/

export const removePatient = async (data) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/cart/remove-patient`,
      data
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/*************** add or create patient***************************/

export const getSuggestPatient = async (productId) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/patient/?productId=${productId}`
    );
    if (response.success) {
      return response?.data?.payload;
    }
    return response?.data?.payload;
  } catch (error) {}
};

/*************** update patient ***************************/

export const updatePatient = async (patientId, data) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/patient/${patientId}`,
      data
    );
    if (response.success) {
      return response;
    }
    toast.success("Patient details updated.");
    return response;
  } catch (error) {}
};

/*************** change slot***************************/

export const changeSlot = async (slotDetailId, data) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/cart/change-slot/${slotDetailId}`,
      data
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/*************** update patient ***************************/

export const suggestedTest = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/test?recommended=true`);
    if (response.success) {
      return response;
    }
    return response;
  } catch (error) {}
};

export const createOrderZeroAmount = async (id) => {
  const data = { ORDERID: id };
  try {
    const response = await axiosInstance.post(
      "/api/v1/payment/create-order-zero-amount",
      data
    );
    if (response?.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
