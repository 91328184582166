import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTestByCategory, getdiagnosticNavbar } from "../../service/homeApi";

const DiagnosticTestsDropdown = ({ isActive }) => {
  const navigate = useNavigate();
  const [diagnosticList, setDiagnosticList] = useState([]);

  const diagnosticNavbarList = async () => {
    try {
      const response = await getdiagnosticNavbar();
      setDiagnosticList(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    diagnosticNavbarList();
  }, []);

  const types = {
    RADIOLOGY: "Radiology",
    CARDIOLOGY: "Cardiology",
    PATHOLOGY: "Pathology",
  };

  return (
    <div className="group">
      <Typography
        className={`text-md font-semibold font-sans ${
          isActive && "border-b-2"
        } cursor-pointer text-base group h-10 flex items-center hover:border-b-2 border-black`}
        onClick={() => navigate("/diagnostic-tests")}
      >
        Diagnostic Tests
      </Typography>

      <div className="absolute left-0 hidden group-hover:block top-10 w-full border z-50 bg-white shadow-lg  py-4 pl-6 pt-5 ">
        {/* 1 Row */}
        <Row gutter={16} offset={20}>
          {diagnosticList?.payload?.tests?.map((data, index) => {
            if (data?.name === "Vaccine") {
              return;
            }
            return (
              <Col span={4} key={data?._id} className="mb-1">
                <Typography
                  className="text-[#8C8C8C] text-sm cursor-pointer hover:text-[1rem] hover:text-black font-sans transition-all"
                  onClick={() => {
                    navigate("/diagnostic-tests", {
                      state: { id: data._id, name: data.name },
                    });
                  }}
                >
                  {data?.name}
                </Typography>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default DiagnosticTestsDropdown;
