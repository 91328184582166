import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { Row, Col, Steps } from "antd";
import radio_button_checked from "../../assets/images/radio_button_checked.svg";
import radio_button_unChecked from "../../assets/images/radio_button_unchecked.svg";
import CheckCircle from "../../assets/images/CheckCircle.svg";
import Address from "./Address";
import BillDetail from "../CartComponents/BillDetail";
import Summary from "./Summary";
import Footer from "../CommonComponents/Footer";
import { useNavigate } from "react-router-dom";
import { getCart } from "../../service/cartApi";

const CheckOut = () => {
  const navigate = useNavigate();
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [click, setClick] = useState(false);
  const [selectAddress, setSelectAddress] = useState();
  const [cartData, setCartData] = useState();
  const filteredCartData = [];
  const [selectedAddress, setSelectedAddress] = useState();
  const [selectedAddressId, setSelectedAddressId] = useState();


  useEffect(() => {
    setSelectedAddressId(cartData?.address?._id);
  }, [cartData]);

  useEffect(() => {
    getData();
  }, []);
  // get cart data api call-----------------------------------

  const getData = async () => {
    try {
      const response = await getCart();
      if (response) {
        setCartData(response?.data?.payload?.cart);
      }
    } catch (error) {
    }
  };

  return (
    <>
      <div className="border-b-2">
        <Row>
          <Col span={20} offset={2}>
            <div className="h-10 flex items-center justify-start text-[1rem] sm:text-[1.4rem] sm:py-[2rem] -ml-[0.3rem] sm:ml-0">
              <ArrowLeftOutlined onClick={() => navigate("/cart")} />
              <div className="flex items-baseline">
                <Typography className=" ml-[1rem] sm:ml-[2rem] text-[1.2rem] sm:text-[1.5rem] font-semibold mr-[0.2rem] font-sans">
                  Checkout
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="mt-2 !flex !flex-row">
        <Col
          span={window.innerWidth < 640 ? 22 : 11}
          offset={window.innerWidth < 640 ? 1 : 2}
        >
          <Steps
            responsive={false}
            current={current}
            items={[
              {
                title: "Address",
                icon: (
                  <img
                    src={current > 0 ? CheckCircle : radio_button_checked}
                    className="mt-[0.6rem] w-[0.85rem] h-[0.85rem]"
                  />
                ),
              },
              {
                title: "Summary",
                icon: (
                  <img
                    src={
                      current > 1
                        ? CheckCircle
                        : current < 1
                        ? radio_button_unChecked
                        : radio_button_checked
                    }
                    className="mt-[0.6rem] w-[0.85rem] h-[0.85rem]"
                  />
                ),
              },
              {
                title: "Payment",
                icon: (
                  <img
                    src={
                      current > 2
                        ? CheckCircle
                        : current < 2
                        ? radio_button_unChecked
                        : radio_button_checked
                    }
                    className="mt-[0.6rem] w-[0.85rem] h-[0.85rem]"
                  />
                ),
              },
            ]}
          />
          <style jsx>{`
            .ant-steps
              .ant-steps-item-finish
              > .ant-steps-item-container
              > .ant-steps-item-content
              > .ant-steps-item-title::after {
              background-color: black;
            }

            .ant-steps.ant-steps-item-title {
              font-size: 0.85rem;
              padding-inline-end: 0px;
            }
          `}</style>
        </Col>
      </Row>
      <Row>
        <Col
          span={window.innerWidth < 640 ? 22 : 20}
          offset={window.innerWidth < 640 ? 1 : 2}
          className="pt-[1rem] min-h-[90vh]"
        >
          <div className="md:flex md:justify-between">
            <div className="md:w-[55%] w-[100%]">
              {current === 0 ? (
                <Address
                  setCurrent={setCurrent}
                  selectAddress={selectAddress}
                  setSelectAddress={setSelectAddress}
                  setClick={setClick}
                  click={click}
                  getData={getData}
                  selectedAddressId={selectedAddressId}
                />
              ) : current === 1 ? (
                <Summary
                  selectAddress={selectedAddress}
                  setCurrent={setCurrent}
                  orders={filteredCartData}
                />
              ) : (
                ""
              )}
            </div>
            {window.innerWidth < 640 ? (
              <></>
            ) : (
              <div className="md:w-[43%]">
                <BillDetail
                  isButtonVisible={isButtonVisible}
                  billData={cartData?.amount}
                  CheckOut={true}
                />
              </div>
            )}
          </div>
        </Col>
        <Footer />
      </Row>

    </>
  );
};

export default CheckOut;
