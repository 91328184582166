import React from "react";
import { Col, Row } from "antd";
import { image76 } from "../../../assets/images";
import PopularPackageSelectLabCard from "../../../components/OurSolution/PopularPackageSelectLabCard";
import PagesHeader from "../../../components/CommonComponents/PagesHeader";
import { useLocation } from "react-router-dom";
import Footer from "../../../components/CommonComponents/Footer";

const PopularPackageSeclectLab = () => {
  const { state } = useLocation();

  return (
    <div>
      <PagesHeader title={state?.data?.name} />
      <Row className="mt-2 mb-[4rem] sm:mb-[8rem]">
        <Col
          span={window.innerWidth < 640 ? 22 : 20}
          offset={window.innerWidth < 640 ? 1 : 2}
          className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 sm:justify-start gap-6 justify-center mt-4"
        >
          {state?.data?.labs?.map((e, key) => {
            return (
              <PopularPackageSelectLabCard
                TestImage={image76}
                title={e?.lab?.name}
                content={state?.data?.description}
                price={e?.price}
                discountPrice={e?.price}
                rating={e?.lab?.rating}
                features={state?.data?.features}
                data={e}
                packageData={state?.data}
                sId = {state?.data?._id}
                key={key}
              />
            );
          })}
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default PopularPackageSeclectLab;
