import React, { useEffect, useState } from "react";
import ContainerWithShadow from "../CommonComponents/ContainerWithShadow";
import { image70 } from "../../assets/images";
import CardOfferAndDiscount from "../CartComponents/CardOfferAndDiscount";
import { Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import ViewAllOffersModal from "./ViewAllOffersModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../utils/axios";

const OffersAndDiscountCard = ({ getData }) => {
  const navigate = useNavigate();

  const offers = useSelector((state) => state.Offers.Offers);

  const isOffer = useSelector((state) => state.alerts.isOfferApplied);

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <LeftOutlined className="text-black  rounded-full p-[0.3rem] absolute top-1/2 left-[-6%] border-2 text-[0.8rem] invisible sm:visible" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <RightOutlined className="text-black  rounded-full p-[0.3rem] absolute top-1/2 right-[-6%]  border-2 text-[0.8rem] invisible sm:visible" />
    </button>
  );
  const responsiveSettings = [
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.05,
      },
    },
  ];
  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const [isOfferOpen, setIsOfferOpen] = useState(false);

  const removeOffer = async () => {
    try {
      const response = await axiosInstance.post(`/api/v1/offer/remove-coupon/`);
      if (response.success) {
        getData();
        return response.data;
      }
      getData();
      return response;
    } catch (error) {}
    getData();
  };

  const manageOfferModal = () => {
    if (!isOffer) {
      if (window.innerWidth > 640) {
        setIsOfferOpen(!isOfferOpen);
      } else {
        navigate("/cart/view-all-offers");
      }
    } else {
      removeOffer();
    }
  };

  const offerName = useSelector((state) => state.offerName.name);

  return (
    <>
      {offers.length > 0 && (
        <div>
          <ContainerWithShadow
            icon={image70}
            testName={isOffer ? offerName : "Offers & Discounts"}
            sideText={isOffer ? "Remove" : "View all"}
            onclickF={manageOfferModal}
            headerBottomBorder={isOffer && true}
          >
            {isOffer ? (
              <></>
            ) : (
              <>
                {offers.length === 1 ? (
                  <div className="w-full sm:pt-6 sm:p-4  flex justify-start items-center">
                    {offers.map((data) => (
                      <div className="w-[100%] sm:w-[75%]">
                        <CardOfferAndDiscount
                          name={data?.name}
                          title={data?.title}
                          description={data?.description}
                          discountPercentage={data?.discount}
                          id={data?._id}
                          getData={getData}
                          tc={data?.tc}
                          discountType={data?.discountType}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="sm:px-[1.8rem]">
                    <Carousel
                      responsive={responsiveSettings}
                      slidesToShow={
                        window.innerWidth > 700 && window.innerWidth < 1120
                          ? 1
                          : 1.5
                      }
                      {...carouselSettings}
                      infinite={false}
                    >
                      {offers.map((data) => (
                        <div className="sm:pr-10 pr-5">
                          <CardOfferAndDiscount
                            name={data?.name}
                            title={data?.title}
                            description={data?.description}
                            discountPercentage={data?.discount}
                            id={data?._id}
                            getData={getData}
                            tc={data?.tc}
                            discountType={data?.discountType}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                )}
              </>
            )}
          </ContainerWithShadow>
          <ViewAllOffersModal
            isOffersOpen={isOfferOpen}
            manageModal={manageOfferModal}
            getData={getData}
          />
        </div>
      )}
    </>
  );
};

export default OffersAndDiscountCard;
