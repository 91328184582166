import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageNo: 1,
  selectedTab: "All",
  categoryId: "",
};

const diagnosticVarSlice = createSlice({
  name: "DiagnosticVariables",
  initialState: initialState,
  reducers: {
    setPageNo: (state, action) => { 
      state.pageNo = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
  },
});

export const { setPageNo, setSelectedTab, setCategoryId } =
  diagnosticVarSlice.actions;

export default diagnosticVarSlice.reducer;
