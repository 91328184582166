import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Carousel, Typography, Drawer } from "antd";
import {
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  StarFilled,
} from "@ant-design/icons";
import { image72 } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { getTestSlots } from "../../service/slotApi";
import { addToCart } from "../../service/cartApi";
import { toast } from "react-toastify";
import { getDate, formateTime } from "../CommonComponents/DateAndTime";

const PopularPackageSelectLabCard = ({
  TestImage,
  title,
  CompanyImage,
  features,
  content,
  price,
  reviewsCount,
  rating,
  discountPrice,
  data,
  packageData,
  categoryId,
  labId,
  sId,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(1);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const getPackageSlot = async () => {
    try {
      const response = await getTestSlots(categoryId, labId);
      setSlots(response?.data?.payload);
    } catch (error) {}
  };

  const navigate = useNavigate();
  const [openChangeSlot, setOpenChangeSlot] = useState(false);

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <LeftOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 left-4 z-10 text-[0.7rem] border-2" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <RightOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 right-5 z-10 text-[0.7rem] border-2" />
    </button>
  );

  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const manageModal = () => {
    getPackageSlot();
    setOpenChangeSlot(!openChangeSlot);
  };

  const [dataToCart, setDataToCart] = useState({});
  const [slotId, setSlotId] = useState("");

  useEffect(() => {
    setDataToCart({
      ...dataToCart,
      type: "package",
      slotTimingId: selectedSlot,
      price,
      labId,
      serviceId: sId,
    });
  }, [selectedSlot]);

  useEffect(() => {
    setDataToCart({
      ...dataToCart,
      type: "package",
      slotTimingId: selectedSlot,
      price,
      labId,
      serviceId: sId,
      slotId: slotId,
    });
  }, [slotId]);

  const addOrderToCart = async (data) => {
    try {
      const response = await addToCart(data);
      setOpenChangeSlot(false);
      toast.success("Added to the cart");
    } catch (error) {
      toast.info("Your cart could not be updated");
    }
  };

  const handleSlot = (dayId, slotId) => {
    setSlotId(dayId);
    setSelectedSlot(slotId);
  };

  const handleSubmit = () => {
    addOrderToCart({ product: dataToCart });
  };

  return (
    <Card
      bodyStyle={{ padding: "1rem" }}
      className={`shadow-sm xl:w-[26.6vw]  mb-6  xl:mr-0 mr-1 border border-[#D9D9D9]`}
      // className={`shadow-sm h-${height} w-[${width}px] border`}
    >
      <div className="flex items-center justify-between mb-2 gap-4 h-7 pl-2">
        <div className="flex items-center gap-2">
          <img src={TestImage} alt="Test Image" className="h-[100%]" />
          <Typography className="text-[1.1rem] font-medium h-[100%] flex items-center font-sans">
            {title}
          </Typography>
        </div>
        <div>
          <Typography className="text-right text-xs text-[#F15E7E] font-sans">
            <span
              className="cursor-pointer"
              // onClick={() => {
              //   navigate("view-details"), { state: { data: data, }, };
              // }}
              onClick={() => {
                navigate("view-details", {
                  state: {
                    d: data,
                    pData: packageData,
                  },
                });
              }}
            >
              View Details
            </span>
          </Typography>
        </div>
      </div>

      <div>
        {/*___________________ lab logo plus rating section _____________ */}

        <div className="h-10 mb-2 my-6 flex items-center justify-between">
          <img src={CompanyImage} alt="Company Logo" className="h-[100%]" />

          <div className="flex items-center gap-1">
            <StarFilled className="text-[#F15E7E]" />
            <Typography className="text-base">{rating}</Typography>
            <Typography className="text-[#8C8C8C] text-xs font-sans">
              ({reviewsCount}+ Reviews)
            </Typography>
          </div>
        </div>

        <div>
          <Typography className="text-[#595959] text-[0.875rem] leading-5 h-[2.4rem] font-sans">
            {" "}
            {content?.slice(0, 80)}
            {content?.length > 80 ? <>...</> : <></>}{" "}
          </Typography>
        </div>

        <div className="flex w-full gap-2 mt-4 ">
          {features?.length > 0 ? (
            <div className="overflow-x-scroll no-scrollbar flex gap-2 w-full ">
              {features?.map((data) => (
                <Card
                  bodyStyle={{ padding: "0" }}
                  className="bg-[#F0F0F0] text-[0.8rem] flex items-center justify-center text-[#595959] !rounded py-[0.25rem] w-[33%] font-sans"
                >
                  <>
                    <Typography className="font-sans">
                      {(window.innerWidth > 1280 &&
                        window?.innerWidth < 1450) ||
                      window.innerWidth < 400 ? (
                        <>
                          {data?.slice(0, 8)}
                          {data?.length > 8 ? <>...</> : <></>}
                        </>
                      ) : (
                        data
                      )}
                    </Typography>
                  </>
                </Card>
              ))}
            </div>
          ) : (
            <>
              <Card
                bodyStyle={{ padding: "0" }}
                className="bg-[#F0F0F0]  flex items-center justify-center text-[#595959]  text-[0.8rem]  !w-[40%] !rounded font-sans"
              >
                N/A
              </Card>
              <Card
                bodyStyle={{ padding: "0" }}
                className="bg-[#F0F0F0]  flex items-center justify-center text-[#595959]  text-[0.8rem]  !w-[40%] !rounded font-sans"
              >
                N/A
              </Card>
              <Card
                bodyStyle={{ padding: "0" }}
                className="bg-[#F0F0F0] flex items-center justify-center text-[#595959]  text-[0.8rem]  !w-[40%] !rounded font-sans"
              >
                N/A
              </Card>
            </>
          )}
        </div>
      </div>

      <div className="flex justify-between mt-5">
        <Typography className="font-medium text-xl font-sans">
          ₹{discountPrice}
          <sup className="text-xs md:text-xs font-normal text-[#8C8C8C] pl-1 font-sans">
            <del>₹{price}</del>
          </sup>
        </Typography>
        <Button
          icon={<PlusOutlined />}
          className="flex items-center border-[#F15E7E] text-[#F15E7E] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
          onClick={manageModal}
        >
          Add to Cart
        </Button>
      </div>

      {window.innerWidth > 640 ? (
        <Modal
          title={`Choose Slot for ${title}`}
          open={openChangeSlot}
          centered={true}
          closeIcon={<img src={image72} onClick={manageModal} alt="Close" />}
          footer={
            <button
              className="my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] font-sans"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </button>
          }
        >
          <div className="border-t-2 ">
            <Carousel slidesToShow={1} {...carouselSettings}>
              {slots?.packageSlot?.map((day) => (
                <div className="h-[20rem]">
                  <div className="flex justify-center items-center text-[1rem] font-semibold font-sans">
                    {getDate(day?.date)}
                  </div>
                  <div className=" mt-6 w-[100%] flex flex-wrap gap-5 ">
                    {day.timeSlots.map((slot) => {
                      return (
                        <>
                          {selectedSlot === slot._id ? (
                            <button
                              className="w-[30%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E] font-sans"
                              id={`${slot._id}`}
                            >
                              {formateTime(slot.startTime)}
                            </button>
                          ) : (
                            <button
                              className="w-[30%] py-3 border-[2px] flex justify-center items-center h-[3rem] rounded-[8px] timeSlots font-sans "
                              onClick={() => handleSlot(day._id, slot._id)}
                              id={`${slot._id}`}
                            >
                              {formateTime(slot.startTime)}
                            </button>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Modal>
      ) : (
        <Drawer
          title="Choose Slot"
          open={openChangeSlot}
          placement={"bottom"}
          closeIcon={
            <img
              src={image72}
              onClick={manageModal}
              alt="Close"
              className="absolute right-4 top-[3%] w-[1.6rem] sm:block sm:w-[auto] font-sans"
            />
          }
          height={"60%"}
          key={"right"}
          footer={
            <div className="w-[100%] flex flex-row-reverse">
              <button
                className="my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] font-sans"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          }
          className="rounded-t-lg"
        >
          <div>
            <Carousel slidesToShow={1} {...carouselSettings}>
              {slots?.packageSlot?.map((day) => (
                <div className="h-[20rem]">
                  <div className="flex justify-center items-center text-[1rem] font-semibold font-sans">
                    {getDate(day?.date)}
                  </div>
                  <div className=" mt-6 w-[100%] flex flex-wrap gap-5 ">
                    {day.timeSlots.map((slot) => {
                      return (
                        <>
                          {selectedSlot === slot._id ? (
                            <button
                              className="w-[29%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E] font-sans"
                              id={`${slot._id}`}
                            >
                              {formateTime(slot.startTime)}
                            </button>
                          ) : (
                            <button
                              className="w-[29%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots font-sans "
                              onClick={() => handleSlot(day._id, slot._id)}
                              id={`${slot._id}`}
                            >
                              {formateTime(slot.startTime)}
                            </button>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Drawer>
      )}
    </Card>
  );
};

export default PopularPackageSelectLabCard;
