import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const AddToCartButton = ({
  inCart,
  handleRemove,
  id,
  title,
  setOpenPincodeModal,
  route,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {inCart ? (
        <Button
          className="my-[0.5rem] w-[8rem] h-[2.6rem] text-center rounded-[4px] flex items-center justify-center border-[#F15E7E] text-white text-[0.8rem]  font-semibold hover:!text-white bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
          onClick={handleRemove}
        >
          Remove
        </Button>
      ) : (
        <Button
          className="my-[0.5rem] w-[8rem] h-[2.6rem] text-center rounded-[4px] flex items-center justify-center border-[#F15E7E] text-[#F15E7E] text-[0.8rem]  font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
          onClick={() => {
            if (
              localStorage?.getItem("longitude") ||
              localStorage?.getItem("locationPlace")
            ) {
              navigate(`/${route}/select-lab`, {
                state: {
                  id: id,
                  title: title,
                },
              });
              localStorage.setItem("CurrentLabTitle", title);
              localStorage.setItem("CurrentLabId", id);
            } else {
              setOpenPincodeModal(true);
            }
          }}
        >
          Add to cart
        </Button>
      )}
    </>
  );
};

export default AddToCartButton;
