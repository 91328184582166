import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const ConsultantBookVisitButton = ({ inCart, manageModal, caseHandling }) => {
  return (
    <>
      {inCart ? (
        <Button
          icon={<MinusOutlined />}
          className="mb-[0.5rem] w-[8rem] h-[2.6rem] py-[0.6rem] px-[2rem] rounded-[4px] font-sans flex items-center justify-around !border-[#F15E7E] text-xs font-semibold text-white hover:!text-white bg-[#F15E7E]"
          onClick={manageModal}
        >
          Remove
        </Button>
      ) : (
        <Button
          icon={<PlusOutlined />}
          className="mb-[0.5rem] w-[8rem] h-[2.6rem] py-[0.6rem] px-[2rem] rounded-[4px] font-sans flex items-center justify-around border-[#F15E7E] text-[#F15E7E] text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E]"
          onClick={() => {
            if (caseHandling) {
              caseHandling();
            }
            manageModal();
          }}
        >
          Book Visit
        </Button>
      )}
    </>
  );
};

export default ConsultantBookVisitButton;
