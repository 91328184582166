import React, { useEffect } from 'react'
import Footer from '../../components/CommonComponents/Footer'
import { Col, Row, Typography } from 'antd'
import {
    ArrowLeftOutlined
} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

const CancellationRefund = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    return (
        <div>
            <div className="border-b-2">
                <Row>
                    <Col span={20} offset={2}>
                        <div className="flex items-center justify-start text-[1rem] sm:text-[1.4rem] sm:py-[2rem] -ml-[0.3rem] sm:ml-0 font-semibold lg:mb-0 mb-3">
                            <ArrowLeftOutlined
                                onClick={() => {
                                    navigate(-1);
                                }}
                            />
                            <Typography className='text-lg lg:text-2xl ml-4'>Cancellation & Refund Policy</Typography>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="text-lg lg:text-2xl lg:pt-[1.4rem] border-t-[1px] min-h-[50vh] max-w-full overflow-hidden mb-[15vh]">
                <Col span={window.innerWidth < 640 ? 22 : 20}
                    offset={window.innerWidth < 640 ? 1 : 2}
                    className="pt-[1rem]">
                    <Typography className='font-sans'>
                        We offer a full refund for all bookings prior to submission of specimens for testing. In case you are unable to avail the services for any reason, you can get your money back no questions asked.

                        <ul>
                            <li>
                                Lab tests where lab visit has been opted by the customers, Customers are free to cancel the booking before the start/preparation of lab tests. A full refund shall be admissible in such cases.
                            </li>
                            <li>
                                Lab tests/ health packages where home visit has been opted by the customers, Customers are free to cancel the tests prior to the start of the visit, a full refund shall be provided. However, in case of customer choose to cancel the test after the arrival of a technician at the site, then <strong>Rs 200/-</strong> shall be deducted as visit charges.
                            </li>
                            <li>
                                Please note that the refund will be made to the source account. The refund shall be provided within 10 working days wherever applicable.
                            </li>
                            <li>
                                For Cancellations please contact us at <strong>1800-103-7070</strong> or email us at <strong>customerexperience@hcl.com </strong> with booking details.
                            </li>
                        </ul>

                    </Typography>
                    <span className='font-semibold'>
                        If you have any additional questions or would like to request a refund, feel free to contact us.
                    </span>
                </Col>
            </Row>
            <Footer />
        </div>
    )
}

export default CancellationRefund;