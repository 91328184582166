import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardOfferAndDiscount from "../../components/CartComponents/CardOfferAndDiscount";
import Footer from "../../components/CommonComponents/Footer";
import { getOffers } from "../../redux/Offers/offerSlice";
import { useDispatch, useSelector } from "react-redux";
import NoLab from "../../assets/images/noLab.svg";

const MyCoupon = () => {
  const offers = useSelector((state) => state.Offers.Offers);
  const dispatch = useDispatch();
  if (offers.length < 1) {
    dispatch(getOffers());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  return (
    <div>
      <div className="border-b-2">
        <Row>
          <Col span={20} offset={2}>
            <div className="h-10 flex items-center justify-start text-[1rem] sm:text-[1.4rem] sm:py-[2rem] -ml-[0.3rem] sm:ml-0">
              <ArrowLeftOutlined onClick={() => navigate("/profile-setting")} />
              <div className="flex items-baseline">
                <Typography className=" ml-[1rem] sm:ml-[2rem] text-[1.2rem] sm:text-[1.5rem] font-semibold mr-[0.2rem] font-sans">
                  My Coupons
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="flex justify-center mt-3 p-3 min-h-[85vh]">
        <Col md={11} sm={22}>
          <div className="w-[100%] px-4 py-4 pb-0 ">
            {offers?.map((data) => (
              <>
                <CardOfferAndDiscount
                  name={data?.name}
                  title={data?.title}
                  description={data?.description}
                  discountPercentage={data?.discount}
                  id={data?._id}
                  isAllOffers={true}
                  width={`100%`}
                  tc={data?.tc}
                  discountType={data?.discountType}
                />
                <br />
              </>
            ))}
            {offers.length === 0 && (
              <div className="flex flex-col items-center justify-center">
                <img src={NoLab} alt="No result found" />
                <span className="font-semibold text-lg mt-10">
                  No Result Found
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default MyCoupon;
