import { Typography, Button, Modal } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";

import AddressComponent from "./AddressComponent";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AdressForm from "./AdressForm";
import SelectAddressForm from "./SelectAddressForm";
import { getAddress } from "../../service/AddressApi";

const Address = ({
  selectAddress,
  setSelectAddress,
  setCurrent,
  selectedAddressId,
  getData,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchAddress();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressAdded, setIsAdressAdded] = useState(false);
  const [address, setAddress] = useState([]);
  const [renrender, setrenrender] = useState(false);

  useEffect(() => {
    setrenrender(!renrender);
  }, [address]);

  const fetchAddress = async () => {
    try {
      const response = await getAddress();
      if (response) {
        setAddress(response?.data?.payload);
      }
    } catch (error) {
    }
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onClickNewAddress = (values) => {
    setIsModalOpen(!isModalOpen);
  };

  const onFinistSelectAddress = (values) => {
    setSelectAddress([values]);
    setCurrent(1);
  };

  const redirectToAddPage = () => {
    navigate("/checkout/add-new-address");
  };

  return (
    <div>
      {!address.length > 0 ? (
        <AddressComponent
          setIsAdressAdded={setIsAdressAdded}
          isAddressAdded={isAddressAdded}
          fetchAddress={fetchAddress}
        />
      ) : (
        <div className="h-[75vh] md:h-auto">
          <div className="w-[100%] md:border md:border-[#D9D9D9] md:shadow md:rounded-lg md:p-4 mb-10 ">
            {window.innerWidth < 640 ? (
              <Button
                onClick={redirectToAddPage}
                htmlType="submit"
                className="border-[#F15E7E] h-[2.5rem] text-[#F15E7E] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] w-[92vw] mb-4 font-sans"
              >
                + Add New Address
              </Button>
            ) : (
              <></>
            )}
            <div className="flex justify-between md:border-b-[1.5px] md:border-[#D9D9D9] pb-2">
              <Typography className="font-semibold text-[1.1rem] pb-2 font-sans ">
                Select Your Address
              </Typography>
              {window.innerWidth < 640 ? (
                <></>
              ) : (
                <Button
                  onClick={showModal}
                  htmlType="submit"
                  className="border-[#F15E7E] h-[2.5rem] text-[#F15E7E] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
                >
                  + Add New Address
                </Button>
              )}
              <Modal
                open={isModalOpen}
                footer={null}
                closeIcon={
                  <CloseCircleOutlined
                    className="text-black mr-2"
                    onClick={showModal}
                  />
                }
                centered={true}
                title={
                  <Typography className="font-sans font-semibold text-[1.1rem] pb-2 md:border-b-[1.5px] md:border-[#D9D9D9]">
                    Add New Address
                  </Typography>
                }
              >
                <div className="max-h-[35rem] relative">
                  <AdressForm
                    onFinish={onClickNewAddress}
                    setIsModalOpen={setIsModalOpen}
                    fetchAddress={fetchAddress}
                  />
                </div>
              </Modal>
            </div>
            <SelectAddressForm
              onFinish={onFinistSelectAddress}
              addressData={address}
              fetchAddress={fetchAddress}
              selectedAddressId={selectedAddressId}
              getData={getData}
            />
          </div>
          <div className="h-[15vh]"></div>
        </div>
      )}
    </div>
  );
};

export default Address;
