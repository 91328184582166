import { isDate, times } from "lodash";

export const getDate = (e) => {
  var date = new Date(e);
  const formatter = new Intl.DateTimeFormat("en-US");
  const formattedDate = formatter.format(date);
  date = new Date(formatter.format(date));
  const finalDate = date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return finalDate;
};

export const formateTime = (time) => {
  const date = new Date(time);

  date.setHours(date.getHours() - 5);
  date.setMinutes(date.getMinutes() - 30);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  const ISTDate = date.toLocaleDateString("en-IN", options).split(", ")[1];
  return ISTDate;
};


export const formateDate = (time) => {
  const date = new Date(time);

  date.setHours(date.getHours() - 5);
  date.setMinutes(date.getMinutes() - 30);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  const ISTDate = time
    ? date.toLocaleDateString("en-IN", options)
    : "";

  return ISTDate;
};
