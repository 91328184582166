import { Typography } from "antd";
import React, { Fragment, useEffect } from "react";
import PagesHeader from "../../components/CommonComponents/PagesHeader";
import { Collapse } from "antd";
import Footer from "../../components/CommonComponents/Footer";

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Panel } = Collapse;

  return (
    <div>
      <PagesHeader title="Frequently Asked Questions" />
      <div className="flex  justify-center w-[100%] ">
        <div className="w-[100%] sm:w-[44%] mt-[1.3rem]">
          <div className=" py-[1rem] md:py-[2rem] px-[1.6rem] md:px-0 mb-2">
            {/* ---------------------------About Value Added Services---------------------- */}
            <div>
              <Typography className="font-[600] text-[1rem] md:text-[1.3rem] font-sans">
                About Value Added Services
              </Typography>

              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        What are Value Added Services?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans  ">
                        Value added services are additional pocketed health
                        services at highly discounted rates for HCL employees &
                        their dependents.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        Who all are eligible for value added services?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        The employees, their dependents and extended family
                        members can avail themselves of Value-Added Services at
                        highly discounted rates.  
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        What all services comes under Value added services? 
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        1.Blood investigation 
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        2.X-Ray, TMT, USG, ECG, ECHO 
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        3.Vaccination (HPV, Influenza and Pneumonia)
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        4.Specialist Consult 
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        What are Value Added Services?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Value added services are additional pocketed health
                        services at highly discounted rates for HCL employees &
                        their dependents.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
            </div>

            {/* --------------------------About Our Services---------------- */}

            <div className="mt-[2rem]">
              <Typography className="font-[600] text-[1rem] md:text-[1.3rem] font-sans">
                About Our Services
              </Typography>

              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        Is there any Discount on the services?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Yes, the Services are provided at discounted rates. The
                        discount is applicable based on employee entity.  
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        Can we refer these services to our friends and extended
                        family members?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Yes, you can refer the services. 
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        What Specialist consults do you have?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Internal Medicine, Pediatrician, Psychiatrist,
                        Sexologist, ENT, Orthopedics, Pulmonologist,
                        Dermatologist, Internal Medicine, Gynecologist,
                        Physiotherapist, Dietician & General Physician. 
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        Why do I need these add-on tests?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Since Covid, a trend was observed where correlation with
                        age is going downwards, and people have started showing
                        symptoms or complications even at a younger age. This is
                        just a preventive step to ensure that we stay on the
                        right track for our health. 
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        Do I need doctor prescription to avail any of the blood
                        investigations or scans?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Routine Preventive Tests can be availed on a preventive
                        basis while specialized tests require prescription. We
                        can also help you connect with our Physicians to get
                        clarity on the suitable tests basis your health
                        condition. 
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        What is the mode to avail the services?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        You can avail it by virtual or In- clinic visit.
                        Diagnostics services can be availed at home or at in
                        clinic & Specialist consults at virtual mode.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        Can I take Paid tests for any other family members who
                        can’t avail Health Checks/aren’t registered Dependents?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className=" text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Yes. Through you, they can also avail services with HCL
                        specific discounted rates.  
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        Where can I avail services?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className=" text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        You can avail services at HCL Healthcare Centers, home
                        sample collection or through our partnered centers. 
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        What documents are required to avail Services at Partner
                        Diagnostics centers?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className=" text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        Soft copy of appointment confirmation on
                        email/WhatsApp/SMS, Employee ID copy along with
                        government approved Photo ID.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        How can I access patient portal?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        1. Please visit{" "}
                        <a
                          href="https://hclhealthcare.karexpert.com/account-management/login"
                          target="_blank"
                          className="text-[#4949aa] text-[0.875rem] md:text-[1.05rem]"
                        >
                          {" "}
                          Health Care System (karexpert.com)
                        </a>
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        2. Click on Login with OTP{" "}
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        3. Enter your registered Mobile Number{" "}
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        4. Select “First Name” from option{" "}
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        5. Enter your first name and click next{" "}
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        6. Enter the OTP receive on Mobile number or Email id{" "}
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        How will I receive my reports?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        You will receive lab reports through a link on SMS,
                        WhatsApp, and registered email. Apart from that, you can
                        also access it on our patient portal.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        When will I get my lab report?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        You will receive your reports within 48 to 72 hours of
                        blood collection.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        Whom do I contact in case of any issues or grievance?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        You can call on our 24*7 active support number
                        7217890075, or you can also connect through WhatsApp
                        chat with our team by texting “Hi” on 9311667775. 
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className="text-[0.875rem] md:text-[1.05rem] font-sans">
                        How to avail Preventive Health Check?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        <span className="font-bold">
                          {" "}
                          For HCLT and Other group company employees,
                        </span>{" "}
                        please find below the steps to avail health check:
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] ml-[2rem] mt-[0.6rem] font-sans">
                        a. Register for health check –
                        <a
                          href="https://app.hclhealthcare.in/ehc/register.php"
                          target="_blank"
                          className="text-[#4949aa] text-[0.875rem] md:text-[1.05rem]"
                        >
                          https://app.hclhealthcare.in/ehc/register.php
                        </a>
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] ml-[2rem] font-sans">
                        b. After Registration, share your preferred date for
                        appointment –
                        <a
                          href="https://app.hclhealthcare.in/ehc/selfAppointment.php"
                          target="_blank"
                          className="text-[#4949aa] text-[0.875rem] md:text-[1.05rem]"
                        >
                          https://app.hclhealthcare.in/ehc/selfAppointment.php
                        </a>
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] ml-[2rem] font-sans">
                        c. Share your documents (Employee id card & Govt. id
                        card) –
                        <a
                          href="https://app.hclhealthcare.in/ehc/uploadDocuments.php"
                          target="_blank"
                          className="text-[#4949aa] text-[0.875rem] md:text-[1.05rem]"
                        >
                          https://app.hclhealthcare.in/ehc/uploadDocuments.php
                        </a>
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] ml-[2rem] font-sans">
                        d. Respond to calls by our support team to confirm your
                        booking.
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] ml-[2rem] font-sans">
                        e. In case you miss receiving calls from HCL Healthcare,
                        please connect with us at 1800-103-7070
                      </Typography>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] ml-[2rem] font-sans">
                        f. Or chat with us on WhatsApp at 9311667775
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
            </div>

            {/* --------------------------About Value Added Services---------------- */}
            <div className="mt-[2rem]">
              <Typography className="font-[600] text-[1rem] md:text-[1.3rem] font-sans">
                Refund and Cancellation Policy
              </Typography>

              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        How can I reschedule my services?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        You can try rescheduling the services by going to my
                        orders section. Also, you can reach out to us on 24*7
                        active support number 7217890075, or you can also
                        connect through WhatsApp chat with our team by texting
                        “Hi” on 9311667775.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
              <Collapse
                expandIconPosition={"end"}
                bordered={false}
                className="bg-white mt-[0.8rem]"
              >
                <Fragment className="bg-white">
                  <Panel
                    header={
                      <Typography className=" text-[0.875rem] md:text-[1.05rem] font-sans">
                        In case of cancellation how will I get my refund?
                      </Typography>
                    }
                    key={"1"}
                  >
                    <>
                      <Typography className="text-[#918f8f] text-[0.875rem] md:text-[1.05rem] font-sans">
                        In case of cancellation, you will receive a cancellation
                        call from our team. For all modes of payment
                        (Debit/Credit Card, UPI, e-Wallet, any other), your
                        refund will be processed via NEFT in your bank account,
                        within 15-20 working days.
                      </Typography>
                    </>
                  </Panel>
                </Fragment>
              </Collapse>
              <div className="bg-[#BFBFBF] h-[0.1px]"></div>
            </div>
          </div>

          <div className="h-[5rem]"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FAQPage;
