import { Button, Typography } from "antd";
import React, { useState } from "react";
import image67 from "../../assets/images/image67.svg";
import EditPatientDetailModal from "../CartComponents/EditPatientDetailModal";
import DeleteModal from "../CommonComponents/DeleteModal";
import { useNavigate } from "react-router-dom";
import { createOrderZeroAmount, getCart } from "../../service/cartApi";
import { useEffect } from "react";
import { axiosInstance } from "../../utils/axios";
import VerifyNumberModal from "../Checkout/verifyNumberModal";
import { getUserDetails } from "../../service/ProfileApi";
import PaymentFailedModal from "../CommonComponents/PaymentFailedModal";
import { toast } from "react-toastify";

const Summary = ({ setCurrent }) => {
  const navigate = useNavigate();
  const [openEditPatient, setOpenEditPatient] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();
  const [cartData, setCartData] = useState();
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState();
  const filteredCartData = [];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await getUserDetails();
      if (response) {
        setIsPhoneNumberVerified(
          response?.data?.payload?.dataToBeSend.isPhoneVerified
        );
      }
    } catch (error) {}
  };

  const [verifyNumberModal, setVerifyNumberModal] = useState(false);

  const handleEditPatientModal = () => {
    setOpenEditPatient(!openEditPatient);
  };

  const HandleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  useEffect(() => {
    setSelectedAddress(cartData?.address);
  }, [cartData]);

  useEffect(() => {
    getData();
  }, []);
  // get cart data api call-----------------------------------

  const getData = async () => {
    try {
      const response = await getCart();
      if (response) {
        setCartData(response?.data?.payload?.cart);
      }
    } catch (error) {}
  };

  cartData?.product?.map((data) => {
    if (data.type === "package") {
      filteredCartData.push({
        name: data?.packageDetails?.package?.name,
        patient: data?.packageDetails?.slotDetails[0]?.patient
          ? data?.packageDetails?.slotDetails[0]?.patient.length
          : null,
        address: data?.packageDetails?.lab?.address,
        centerName: data?.packageDetails?.lab?.name,
        serviceMode: data?.packageDetails?.lab?.serviceMode,
      });
    } else if (data.type === "test") {
      filteredCartData.push({
        name: data?.testDetails?.test?.name,
        patient: data?.testDetails?.slotDetails[0]?.patient
          ? data?.testDetails?.slotDetails[0]?.patient.length
          : null,
        address: data?.testDetails?.lab?.address,
        centerName: data?.testDetails?.lab?.name,
        serviceMode: data?.testDetails?.lab?.serviceMode,
      });
    } else if (data.type === "consultant") {
      filteredCartData.push({
        name: data?.consultancyDetails?.consultantId?.name,
        patient: data?.consultancyDetails?.slotDetails[0]?.patient
          ? data?.consultancyDetails?.slotDetails[0]?.patient.length
          : null,
        address: data?.consultancyDetails?.consultantId?.address,
        centerName: "",
        serviceMode: data?.consultancyDetails?.serviceMode,
      });
    }
  });

  /////// address formatter ------------------------------------

  const formatAddress = (address, serviceMode) => {
    if (serviceMode === "Home") {
      return "Home Sampling";
    } else if (serviceMode === "Virtual") {
      return "Virtual";
    } else {
      let formattedAddress = address?.buildingName
        ? address?.buildingName
        : "" + address?.clinic
        ? address?.clinic
        : "" + address?.area
        ? address?.area
        : "" + address?.city
        ? address?.city
        : "" + address?.pincode
        ? address?.pincode
        : "";
      return (
        formattedAddress.charAt(0).toUpperCase() +
        formattedAddress.slice(1).toLowerCase()
      );
    }
  };

  ///////////////// phone number verified modal handle

  const handlePhoneVerifiedModal = () => {
    setVerifyNumberModal(true);
  };

  ////////////////////////////////////////////////////////////////////////
  // payment integration

  const [intiliazeData, setintiliazeData] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentStat, setPaymentStat] = useState();
  const [openFailed, setOpenFailed] = useState(false);

  useEffect(() => {
    if (paymentStat?.STATUS === "TXN_SUCCESS") {
      window.Paytm.CheckoutJS.close();
      navigate("/", {
        state: {
          data: true,
        },
      });
    } else if (paymentStat?.STATUS === "TXN_FAILURE") {
      window.Paytm.CheckoutJS.close();
      setOpenFailed(true);
    }
  }, [paymentStat]);

  useEffect(() => {
    setintiliazeData({ amount: cartData?.amount?.totalAmount });
  }, [cartData]);

  const initializePayment = async () => {
    setOpenFailed(false);
    setIsLoading(true);
    try {
      const resp = await axiosInstance.post(
        `/api/v1/payment/initiate`,
        intiliazeData
      );
      if (resp?.data) {
        if (resp?.data?.isAmountZero) {
          /// this is for zero rupee flow, when cart has net amount zero then there is no payment flow direct cart completion
          createOrderZeroAmount(resp?.data?.orderId).then((data) => {
            setLoading(false);
            if (data) {
              navigate("/", {
                state: {
                  data: true,
                },
              });
            } else {
              toast.info("Something went wrong! please try again after sometime.")
            }
          });
        } else {
          makePayment(
            resp.data.orderId,
            resp.data.token,
            resp.data.amount,
            resp.data.mid
          );
        }
      }
    } catch (error) {
      toast.info("Something went wrong, Please try again!");
      storeError(error);
      setLoading(false);
    }
  };

  const makePayment = (orderId, token, amount, mid) => {
    const config = {
      root: "",
      data: {
        orderId: orderId,
        token: token,
        tokenType: "TXN_TOKEN",
        amount: amount,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: [],
        },
        order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
      },
      website: process.env.REACT_APP_PAYTM_MODE,
      flow: process.env.REACT_APP_PAYTM_MODE,
      merchant: {
        mid: mid,
        redirect: false,
      },
      handler: {
        transactionStatus: async function transactionStatus(paymentStatus) {
          try {
            const data = paymentStatus;
            await axiosInstance.post(`/api/v1/payment/create-order`, data);
            setLoading(false);
            setPaymentStat(paymentStatus);
          } catch (error) {
            toast.info("Failed to update payment status. Please try again.");
            storeError(error);
          } finally {
            setIsLoading(false);
          }
        },
        notifyMerchant: function notifyMerchant(eventName, data) {
          setLoading(false);
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          window.Paytm.CheckoutJS.invoke();
          setIsLoading(false);
        })
        .catch(function onError(error) {
          toast.info("Something went wrong. Please try again.");
          storeError(error);
          setIsLoading(false);
        });
    }
  };

  ///// if payment js checkout throw error

  const storeError = async (paymentError) => {
    let error;
    if (typeof paymentError !== "string") {
      error = JSON.stringify(paymentError);
    } else {
      error = paymentError;
    }
    const data = { resultMsg: error };
    try {
      await axiosInstance.post(`/api/v1/payment/paymentUiFailure`, data);
    } catch (error) {}
  };

  return (
    <div className="w-[100%] md:border md:border-[#D9D9D9] md:shadow md:rounded-lg md:p-4 min-h-[75vh] sm:min-h-[10vh]">
      {isLoading && (
        <div
          className="w-[100vw] h-[100vh] fixed  z-40 top-0 left-0"
          style={{ background: "rgba(0, 0, 0, 0.40)" }}
        ></div>
      )}

      {window.innerWidth < 640 ? (
        <></>
      ) : (
        <>
          <Typography className="font-semibold text-[1.1rem] pb-2 border-b-[1.5px] border-[#D9D9D9] font-sans">
            Summary
          </Typography>
        </>
      )}
      <div className="flex items-center md:mt-5">
        <Typography className="font-semibold text-[1.1rem] pb-2 font-sans ">
          Your Address
        </Typography>
        {window.innerWidth < 640 ? (
          <div className="flex-grow h-[1.5px] bg-[#BFBFBF] m-[0.5rem]"></div>
        ) : (
          <></>
        )}
      </div>
      <div className="flex justify-between my-3">
        <div className="w-[60%] sm:w-[70%]">
          <Typography className="text-sm text-[#777777] font-sans">
            {selectedAddress?.area}, {selectedAddress?.locality},
          </Typography>
          <Typography className="text-sm text-[#777777] font-sans">
            {selectedAddress?.district}, {selectedAddress?.state},{" "}
            {selectedAddress?.pincode}
          </Typography>
        </div>

        <Typography
          onClick={() => setCurrent(0)}
          className="text-[#F15E7E] text-sm font-semibold cursor-pointer font-sans"
        >
          Change Address
        </Typography>
      </div>

      <div className="flex items-center justify-between mt-6">
        <Typography className="font-semibold text-[1.1rem] font-sans ">
          Items in order
        </Typography>
        {window.innerWidth < 640 ? (
          <div className="flex-grow h-[1.5px] bg-[#BFBFBF] m-[0.5rem]"></div>
        ) : (
          <Typography className="text-sm text-[#777777] font-sans">
            {`${filteredCartData.length} Items`}
          </Typography>
        )}
      </div>
      {filteredCartData?.map((data) => {
        return (
          <>
            <div className="flex justify-between mt-6 border-b pb-2">
              <div className="flex">
                <img
                  src={image67}
                  alt="icon"
                  className="mr-5 w-[2rem] h-[2rem]"
                />
                <div>
                  <Typography className="text-sm font-sans">
                    {data?.name}
                  </Typography>
                  <Typography className="text-sm text-[#777777] font-sans">
                    {`${data?.patient} `}
                    {data?.patient > 1 ? "Patients" : "Patient"}
                  </Typography>
                  <Typography className="mt-2  ">
                    {data?.centerName}
                    {data?.centerName.length > 0 && ", "}
                    {formatAddress(data?.address, data?.serviceMode)}
                  </Typography>
                </div>
              </div>
            </div>
          </>
        );
      })}

      {window.innerWidth < 640 ? (
        <div>
          <div className="flex items-center mt-5">
            <Typography className="font-semibold text-[1.1rem] pb-2 font-sans ">
              Bill Details
            </Typography>
            <div className="flex-grow h-[1.5px] bg-[#BFBFBF] m-[0.5rem] font-sans"></div>
          </div>
          <div className="flex justify-between items-center py-[0.6rem] font-sans">
            <Typography className="text-[1rem] font-sans">
              Cart Value
            </Typography>
            <Typography className="text-[1rem] font-sans">
              ₹{cartData?.amount?.cartValue}
            </Typography>
          </div>
          <div className="flex justify-between items-center py-[0.6rem] ">
            <Typography className="text-[1rem] font-sans">
              Home Sampling Charges
            </Typography>
            <Typography className="text-[1rem] font-sans">
              ₹{cartData?.amount?.homeSamplingCharge}
            </Typography>
          </div>
          {cartData?.amount?.discount > 0 && (
            <div className="flex justify-between items-center py-[0.6rem] ">
              <Typography className="text-[1rem] font-sans">
                Coupon Discount
              </Typography>
              <Typography className="text-[1rem] text-[#389E0D] font-sans">
                -₹{cartData?.amount?.discount}
              </Typography>
            </div>
          )}
          <div className="flex justify-between items-center py-[0.6rem] ">
            <Typography className="text-[1rem] font-sans">
              Discount on MRP
            </Typography>
            <Typography className="text-[1rem] text-[#389E0D] font-sans">
              -₹{cartData?.amount?.discountOnMRP}
            </Typography>
          </div>
          <div className="flex justify-between items-center pt-[0.8rem] sm:py-[0.6rem]">
            <Typography className="text-[1rem] font-semibold font-sans">
              Total Amount
            </Typography>
            <Typography className="text-[1rem] font-semibold font-sans">
              ₹{cartData?.amount?.totalAmount}
            </Typography>
          </div>
        </div>
      ) : (
        <></>
      )}
      {window.innerWidth > 640 ? (
        <div className="flex justify-end">
          <button
            className="font-sans my-[1rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px]"
            onClick={
              isPhoneNumberVerified
                ? initializePayment
                : handlePhoneVerifiedModal
            }
          >
            Continue
          </button>
        </div>
      ) : (
        <></>
      )}

      {window.innerWidth < 640 && (
        <div className="fixed bottom-0 left-0 bg-white w-[100%] flex justify-between px-3 items-center mt-2 h-[8vh] border-t">
          <div>
            <Typography className="font-normal text-sm font-sans">
              Total Price
            </Typography>
            <Typography className="font-semibold text-[1.2rem] text-[#1F1F1F] font-sans">
              ₹{cartData?.amount?.totalAmount}{" "}
              <span className="font-normal text-sm">
                ({filteredCartData.length} Item)
              </span>
            </Typography>
          </div>
          <Button
            className="font-sans  bg-[#F15E7E]  !text-white !border-[#F15E7E]  my-2 h-[10vw] px-10"
            onClick={
              isPhoneNumberVerified
                ? initializePayment
                : handlePhoneVerifiedModal
            }
          >
            Continue
          </Button>
        </div>
      )}
      <EditPatientDetailModal
        openModal={openEditPatient}
        handleModal={handleEditPatientModal}
      />

      <DeleteModal
        openDeleteModal={openDeleteModal}
        HandleDeleteModal={HandleDeleteModal}
      />

      <VerifyNumberModal
        verifyNumberModal={verifyNumberModal}
        setVerifyNumberModal={setVerifyNumberModal}
        initializePayment={initializePayment}
        setIsPhoneNumberVerified={setIsPhoneNumberVerified}
      />

      <PaymentFailedModal
        isOpen={openFailed}
        close={setOpenFailed}
        paymentStat={paymentStat}
        initiliazeAgain={initializePayment}
      />
    </div>
  );
};

export default Summary;
