import React, { useEffect, useState } from "react";
import { Typography, Form, Input, Select, DatePicker, Radio } from "antd";
import moment from "moment";
import "moment/locale/en-gb";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import { image72 } from "../../assets/images";
import {
  addPatient,
  addSugestedPatient,
  getSuggestPatient,
  getSuggestedPatient,
} from "../../service/cartApi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const AddPatientFom = ({
  isEdit,
  typeId,
  type,
  cartRendering,
  productId,
  closeCollapse,
  isManage,
  managePatientModal,
  fetchManagePatient,
}) => {
  const [patientDetails, setPatientDetails] = useState([]);
  const [openChangeSlot, setOpenChangeSlot] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(1);
  const [SelectValue, setSelectValue] = useState(null);
  const [SuggestedPatient, setSuggestedPatient] = useState(null);
  const [patientadded, setpatientadded] = useState(0);
  const [selectedOption, setselectedOption] = useState(null);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [phoneNo, setPhoneNo] = useState("");

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      firstName: null,
      lastName: null,
      dob: null,
      phoneNumber: null,
      gender: null,
    });
  }, [SelectValue, SuggestedPatient]);

  //// This two snippets for save button disable and enable conditions
  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    setisButtonDisabled(false);
    const values = Object.values(formValues);
    values.pop();
    values.map((value) => {
      if (value === undefined || value === null) {
        setisButtonDisabled(true);
      }
    });
  };

  useEffect(() => {
    if (SelectValue === undefined || SelectValue === null) {
      setisButtonDisabled(true);
    } else {
      setisButtonDisabled(false);
    }
  }, [SelectValue]);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
          height: 50,
        }}
        defaultValue={"+91"}
        className="flex justify-center items-center font-sans"
      >
        <Select.Option value="91">+91</Select.Option>
        {/* <Select.Option value="86">+86</Select.Option>
        <Select.Option value="87">+87</Select.Option> */}
      </Select>
    </Form.Item>
  );

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <LeftOutlined className="text-black font-semibold rounded-full w-[8rem] h-[2.6rem] text-center absolute top-5 left-4 z-10 text-[0.7rem] border-2" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <RightOutlined className="text-black font-semibold rounded-full w-[8rem] h-[2.6rem] text-center absolute top-5 right-5 z-10 text-[0.7rem] border-2" />
    </button>
  );

  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const submitPatient = async (data) => {
    try {
      const response = await addPatient(data);
      if (isManage) {
        navigate(-1);
      }
      if (managePatientModal) {
        managePatientModal();
        fetchManagePatient();
      }
    } catch (error) {}
    cartRendering();
  };

  const submitSuggestedPatient = async (data) => {
    try {
      const response = await addSugestedPatient(data);
    } catch (error) {}
    cartRendering();
  };

  // const navigate = useNavigate();

  const [isWarning, setIsWarning] = useState(false);
  const token = useSelector((state) => state.auth);

  const savePatientDetails = (values) => {
    if (!token) {
      setIsWarning(true);
      form.resetFields();
      form.setFieldsValue({
        firstName: null,
        lastName: null,
        dob: null,
        phoneNumber: null,
        gender: null,
      });
      setSelectValue(null);

      return;
    }
    if (!SelectValue) {
      setPatientDetails(values);
      values.dob = dayjs(values.dob);
      values.typeId = typeId;
      values.type = type;
      values.isCart = true;
      submitPatient(values);
    } else {
      submitSuggestedPatient({
        patientId: SelectValue,
        type: type,
        typeId: typeId,
      });
    }
    form.resetFields();
    form.setFieldsValue({
      firstName: null,
      lastName: null,
      dob: null,
      phoneNumber: null,
      gender: null,
    });
    closeCollapse("3");
    setSelectValue(null);
  };

  const callSuggestedPatient = async () => {
    try {
      const response = await getSuggestPatient(productId);
      if (response) {
        setSuggestedPatient(response);
      }
    } catch (error) {}
  };

  const handleKeyPress = (event) => {
    // Allow only numeric input (digits 0-9) and some special keys (e.g., backspace, delete)
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
    ];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const selectOptions = [];
  SuggestedPatient?.map((data, index) => {
    selectOptions.push({
      value: data?._id,
      label: `${data?.firstName} ${data?.lastName}`,
    });
  });

  const handleSelect = (e) => {
    setSelectValue(e);
  };

  return (
    <>
      {!isEdit && (
        <>
          <div className="mb-4">
            <Select
              value={SelectValue}
              placeholder="Select Patient"
              style={{
                width: "100%",
              }}
              onDropdownVisibleChange={callSuggestedPatient}
              allowClear
              options={selectOptions}
              onChange={handleSelect}
            />
          </div>
          <div className="w-[100%] flex justify-between items-center my-5">
            <hr className="border-[0.5px] w-[30%] sm:w-[40%]" />
            <Typography> Or Add New</Typography>
            <hr className="border-[0.5px] w-[30%] sm:w-[40%]" />
          </div>
        </>
      )}
      <Form
        onFinish={savePatientDetails}
        initialValues={SuggestedPatient?.[0]}
        form={form}
        onValuesChange={handleFormChange}
        id="myForm"
      >
        <div style={SelectValue && { pointerEvents: "none" }}>
          <div className="flex  w-[100%] justify-between ">
            {/* ----------------------- First Name ----------------------*/}
            <div className="w-[48%]">
              <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
                First Name
              </Typography>

              <Form.Item
                name="firstName"
                rules={[
                  !SelectValue && {
                    required: true,
                    message: "Required field",
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "cannot be empty",
                  },
                  {
                    min: 3,
                    message: "must be at least 3 characters.",
                  },
                  {
                    max: 35,
                    message: "cannot exceed 35 characters.",
                  },
                ]}
                className="mb-2"
                initialValue={SuggestedPatient?.firstName}
              >
                <Input
                  placeholder="First Name"
                  className="py-[0.5rem] font-sans rounded-[4px]"
                  disabled={SelectValue ? true : false}
                />
              </Form.Item>
            </div>
            {/* ----------------------------------------------------------- */}

            {/* ----------------------- Last Name ----------------------*/}
            <div className="w-[48%]">
              <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
                Last Name
              </Typography>

              <Form.Item
                name="lastName"
                rules={
                  !SelectValue && [
                    {
                      required: true,
                      message: "Required field",
                    },
                    {
                      pattern: /^(?!\s*$).+/,
                      message: "cannot be empty",
                    },
                    {
                      min: 3,
                      message: "must be at least 3 characters.",
                    },
                    {
                      max: 35,
                      message: "cannot exceed 35 characters.",
                    },
                  ]
                }
                className="mb-2"
              >
                <Input
                  placeholder="Last Name"
                  className="rounded-[4px] py-[0.5rem] font-sans"
                  disabled={SelectValue ? true : false}
                />
              </Form.Item>
            </div>
            {/* ----------------------------------------------------------- */}
          </div>

          {/* ------------------------Date picker DOB------------------------- */}
          <div>
            <Typography className="text-[#595959] text-[0.85rem] font-sans">
              DOB
            </Typography>
            <Form.Item
              name="dob"
              className="mb-2"
              rules={
                !SelectValue && [
                  {
                    required: true,
                    message: "Required field",
                  },
                ]
              }
            >
              <DatePicker
                className="w-[100%] py-[0.5rem] font-sans rounded-[4px]"
                // onChange={handleDateChange}
                disabled={SelectValue ? true : false}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
              />
            </Form.Item>
          </div>

          {/*-------------------- Phone Number -------------------------- */}
          <div>
            <Typography className="text-[#595959] text-[0.85rem] font-sans">
              Phone No.
            </Typography>
            <Form.Item
              name="phoneNumber"
              rules={
                !SelectValue && [
                  {
                    required: true,
                    message: "Please input your Phone Number!",
                  },
                  {
                    pattern: new RegExp(/^[6-9]\d{9}$/i),
                    message: "Please Enter a Valid Phone Number",
                  },
                ]
              }
              className="mb-0 font-sans"
            >
              <Input
                addonBefore={prefixSelector}
                className="w-[100%] py-[0.5rem] rounded-[4px] font-sans "
                placeholder="Phone No."
                disabled={SelectValue ? true : false}
                onChange={(event) => {
                  setPhoneNo(event.target.value);
                }}
                type="text"
                onKeyDown={handleKeyPress}
              />
            </Form.Item>
          </div>

          {/*---------------------- Gender -------------------------- */}

          <Form.Item
            className="mb-3 font-sans"
            name="gender"
            rules={
              !SelectValue && [
                {
                  required: true,
                  message: "please select gender",
                },
              ]
            }
          >
            <Radio.Group>
              <Radio
                value="male"
                defaultChecked={true}
                disabled={SelectValue ? true : false}
              >
                {" "}
                <Typography className="text-[0.85rem] font-sans">
                  Male
                </Typography>{" "}
              </Radio>
              <Radio value="female">
                <Typography className="text-[0.85rem] font-sans">
                  Female
                </Typography>{" "}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        {isEdit && isManage ? (
          <>
            {/* {isManage && (
              <div className="sm:flex justify-end items-center">
                <div className="flex justify-end mt-3 sm:mt-0">
                  <button
                    className="my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] w-28 flex items-center justify-center"
                    // className="py-[0.6rem] px-[2rem] text-[#BFBFBF] border-2 rounded-[4px] w-28 flex items-center justify-center"
                    type="submit"
                    style={
                      isButtonDisabled
                        ? { background: "#BFBFBF" }
                        : { background: "#F15E7E" }
                    }
                    disabled={isButtonDisabled}
                  >
                    Save
                  </button>
                </div>
              </div>
            )} */}
          </>
        ) : (
          <>
            <div className="sm:flex justify-end items-center">
              <div className="flex justify-end mt-3 sm:mt-0">
                <button
                  className="my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] w-28 flex items-center justify-center"
                  // className="py-[0.6rem] px-[2rem] text-[#BFBFBF] border-2 rounded-[4px] w-28 flex items-center justify-center"
                  type="submit"
                  style={
                    isButtonDisabled
                      ? { background: "#BFBFBF" }
                      : { background: "#F15E7E" }
                  }
                  disabled={isButtonDisabled}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </Form>

      {isWarning && (
        <>
          <div className="w-[22rem] h-[8rem] shadow-lg bg-white fixed top-6 right-8 p-[1rem] border-2 rounded-md">
            <Typography
              className="text-[1.2rem] cursor-pointer absolute right-2 top-0 font-semibold text-[#F15E7E]"
              onClick={() => {
                setIsWarning(false);
                closeCollapse("3");
              }}
            >
              x
            </Typography>
            <Typography className="text-[1rem]">
              You are not logged in.
            </Typography>
            <Typography className="text-[0.8rem]">
              Please log in before adding patient{" "}
            </Typography>
            <Typography
              className="text-[0.9rem] text-[#F15E7E] mt-2 cursor-pointer"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login{" "}
            </Typography>
          </div>
        </>
      )}
      <style jsx>
        {`
          .ant-input {
            padding: 9px 11px;
          }
          .ant-select .ant-select-arrow {
            color: black;
            font-size: 10px;
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            height: 40px;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default AddPatientFom;
