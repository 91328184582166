import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageNo: 1,
  selectedTab: "All",
  categoryId: "",
};

const specialistConsutantVarSlice = createSlice({
  name: "SpecialistConsultantVariables",
  initialState: initialState,
  reducers: {
    setConsultantPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setConsultantSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setConsultantCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
  },
});

export const {
  setConsultantPageNo,
  setConsultantSelectedTab,
  setConsultantCategoryId,
} = specialistConsutantVarSlice.actions;

export default specialistConsutantVarSlice.reducer;
