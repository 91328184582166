import React from "react";
import PagesHeader from "../../components/CommonComponents/PagesHeader";
import NotificationCard from "../../components/Profile/NotificationCard";
import Footer from "../../components/CommonComponents/Footer";

const Notification = () => {
  const NotificationData = [
    {
      title: "Order Status",
      description: "Your order ID-7856, has been confirmed for Jul 6, 2023.",
      time: "05:35PM",
    },
    {
      title: "Order Reminder",
      description:
        "You have an scheduled appointment with Dr. Aman Mehra tomorrow.",
      time: "05:35PM",
    },
    {
      title: "Order Status",
      description: "Your order ID-7856, has been confirmed for Jul 6, 2023.",
      time: "05:35PM",
    },
    {
      title: "Order Status",
      description: "Your order ID-7856, has been confirmed for Jul 6, 2023.",
      time: "05:35PM",
    },
    {
      title: "Order Reminder",
      description:
        "You have an scheduled appointment with Dr. Aman Mehra tomorrow.",
      time: "05:35PM",
    },
    {
      title: "Order Status",
      description: "Your order ID-7856, has been confirmed for Jul 6, 2023.",
      time: "05:35PM",
    },
    {
      title: "Order Status",
      description: "Your order ID-7856, has been confirmed for Jul 6, 2023.",
      time: "05:35PM",
    },
    {
      title: "Order Reminder",
      description:
        "You have an scheduled appointment with Dr. Aman Mehra tomorrow.",
      time: "05:35PM",
    },
    {
      title: "Order Status",
      description: "Your order ID-7856, has been confirmed for Jul 6, 2023.",
      time: "05:35PM",
    },
    {
      title: "Order Reminder",
      description:
        "You have an scheduled appointment with Dr. Aman Mehra tomorrow.",
      time: "05:35PM",
    },
  ];

  return (
    <>
      <PagesHeader title="Notification" />
      <div className="flex  justify-center w-[100%] min-h-[90vh]">
        <div className="sm:border-2 w-[100%] sm:w-[44%] rounded mt-[1rem] p-2 overflow-y-scroll max-h-[77vh]">
          {NotificationData.map((e, key) => {
            return (
              <NotificationCard
                title={e.title}
                description={e.description}
                time={e.time}
                key={key}
              />
            );
          })}
        </div>
      </div>
      <Footer />


      <style jsx>
        {`
          ::-webkit-scrollbar {
            width: 3px;
        `}
      </style>
    </>
  );
};

export default Notification;
