import React, { useRef } from "react";
import {
  EnvironmentOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getGlobalSearch } from "../../service/homeApi";
import { getGlobalSearchInput } from "../../service/globalSearchApi";
import locationgps from "../../assets/images/my_location.svg";
import { useDispatch } from "react-redux";
import { setIsPopUP } from "../../redux/Popup/whatsappToast.Slice";
import { updatePincode } from "../../redux/Pincode/changePincode";
import { toast } from "react-toastify";

const SearchBarMobile = ({ locationBlocked, compId }) => {
  const dispatch = useDispatch();
  // const [showSearchDropdown, setShowSearchDropDown] = useState(false);
  const navigate = useNavigate();
  const searchContainerRef = useRef(null);
  const [inputData, setInputData] = useState({
    pincode: "",
    keyword: "",
  });
  const [searchedData, setSearchedData] = useState([]);
  const [locationAddress, setLocationAddress] = useState(
    localStorage.getItem("locationPlace")
  );
  const [showLabSearchDropdown, setShowLabSearchDropdown] = useState(false);
  const [showLabs, setShowLabs] = useState([]);
  const handleOptionSelect = (data) => {
    setSearch(data?.name);
    setShowLabSearchDropdown(false);
    handleNavigation(data);
  };

  const handleGlobalSearch = (value) => {
    setShowLabSearchDropdown(true);
    setSearch(value);
    // setShowSearchDropDown(false);
  };

  function mergeUniqueWords(str1, str2) {
    let s = removeFirstComma(str2);
    const arr1 = str1.split(/\s+/);
    const arr2 = s.split(/\s+/);
    const uniqueWordsSet = new Set(arr1);
    arr2.forEach((word) => uniqueWordsSet.add(word));
    const uniqueWordsArray = Array.from(uniqueWordsSet);
    const resultString = uniqueWordsArray.join(" ");

    return resultString;
  }

  function removeFirstComma(inputString) {
    const firstCommaIndex = inputString.indexOf(",");
    if (firstCommaIndex !== -1) {
      return (
        inputString.slice(0, firstCommaIndex) +
        inputString.slice(firstCommaIndex + 1)
      );
    } else {
      return inputString;
    }
  }

  const types = {
    TEST: "test",
    PACKAGE: "package",
    CONSULT: "consultant",
  };

  let geocoder;

  const [dropdown, setDropDown] = useState(false);

  const fetchPincode = (latitude, longitude) => {
    geocoder = new window.google.maps.Geocoder();
    var latLng = {
      lat: latitude,
      lng: longitude,
    };
    geocoder.geocode(
      {
        location: latLng,
      },
      function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            var postalCode = null;
            for (var i = 0; i < results[0].address_components.length; i++) {
              var component = results[0].address_components[i];
              if (component.types.includes("postal_code")) {
                postalCode = component.short_name;
                break;
              }
            }
            if (postalCode) {
              localStorage.setItem("pincode", postalCode);
              dispatch(updatePincode(postalCode));
            }
          }
        }
      }
    );
  };

  useEffect(() => {
    let address = localStorage.getItem("locationPlace");
    setLocationAddress(address);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowLabSearchDropdown(false);
        setDropDown(false);
        setSearch("");
        setLocationAddress(localStorage.getItem("locationPlace"));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function getCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;
            geocoder = new window.google.maps.Geocoder();

            const location = new window.google.maps.LatLng(latitude, longitude);

            localStorage.setItem("latitude", latitude);
            localStorage.setItem("longitude", longitude);
            fetchPincode(latitude, longitude);

            getAddressFromLatLng(location);
            resolve(""); // Resolve with an empty string
          },
          (error) => {
            reject(error); // Reject with the error object
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser"));
      }
    });
  }

  var options = {
    componentRestrictions: { country: "IN" }, // This restricts the results to India
  };
  function initAutocomplete() {
    const input = document.getElementById(compId);
    const autocomplete = new window.google.maps.places.Autocomplete(
      input,
      options
    );
    autocomplete.addListener("place_changed", () => {
      const selectedPlace = autocomplete.getPlace();
      if (selectedPlace && selectedPlace.formatted_address) {
        localStorage.setItem(
          "locationPlace",
          mergeUniqueWords(selectedPlace.name, selectedPlace.formatted_address)
        );
        setLocationAddress(
          mergeUniqueWords(selectedPlace.name, selectedPlace.formatted_address)
        );
        // Get latitude and longitude
        const { lat, lng } = selectedPlace.geometry.location;
        const latitude = lat();
        const longitude = lng();
        fetchPincode(latitude, longitude);

        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
        setDropDown(false);
      }
    });
  }

  function getAddressFromLatLng(location) {
    geocoder.geocode({ location: location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          localStorage.setItem("locationPlace", results[0].formatted_address);
          setLocationAddress(results[0].formatted_address);
        }
      }
    });
  }

  initAutocomplete();

  useEffect(() => {
    if (localStorage.getItem("pincode")) {
    } else {
      getCurrentLocation();
    }
  }, []);

  const searchData = async () => {
    try {
      const payload = {
        keyword: inputData?.keyword,
        pincode: inputData?.pincode,
      };
      const response = await getGlobalSearch(payload);
      setSearchedData(response?.data?.payload?.data[0]?.results);
    } catch (error) {}
  };

  const navigateToGetgetpackageDetail = (id, type) => {
    navigate("/diagnostic-tests", {
      state: {
        id: id,
        type: type,
      },
    });
  };

  const [search, setSearch] = useState("");
  const [enterSearch, setEnterSearch] = useState(null);

  const globalfn = async () => {
    const response = await getGlobalSearchInput(search);
    setShowLabs(response?.data?.payload);
    if (showLabSearchDropdown && response?.data?.payload.length < 1) {
      dispatch(setIsPopUP(true));
    } else {
      dispatch(setIsPopUP(false));
    }
    if (response?.data?.payload.length > 0) {
      setEnterSearch(response?.data?.payload[0]);
    } else {
      setEnterSearch(null);
    }
  };

  useEffect(() => {
    if (!showLabSearchDropdown) {
      dispatch(setIsPopUP(false));
    }
  }, [showLabSearchDropdown]);

  useEffect(() => {
    searchData();
  }, [inputData]);

  useEffect(() => {
    if (search === "") {
      setShowLabSearchDropdown(false);
    } else {
      globalfn();
    }
  }, [search]);

  const handleNavigation = (data) => {
    switch (data?.type) {
      case types.TEST:
        navigate(`/diagnostic-tests/${data?._id}`);
        break;
      case types.PACKAGE:
        navigate(`/packages/${data?._id}`);
        break;
      case types.CONSULT:
        navigate(`/special-consult`, {
          state: { id: data?._id, name: data?.name },
        });
        break;
      default:
        navigate(`/diagnostic-tests`);
    }
  };

  return (
    <div
      className=" items-center rounded border-[#D9D9D9] border p-2 h-9 mr-4 flex w-full mt-2 mb-2"
      ref={searchContainerRef}
    >
      <div
        className={`flex items-center relative gap-2 p-1 bg-white border-[#D9D9D9] ${
          !locationBlocked ? "w-[45%] border-r" : "w-[100%]"
        } `}
      >
        <EnvironmentOutlined className="text-[#8C8C8C]" />
        <Typography
          className={`bg-white text-sm font-medium ${
            locationBlocked && "w-full"
          }`}
        >
          <input
            placeholder="Location"
            className={`outline-none placeholder:font-medium placeholder:text-base ${
              dropdown ? "w-[90%]" : "w-[100%]"
            }`}
            value={locationAddress}
            onChange={(e) => {
              setLocationAddress(e.target.value);
              setDropDown(true);
              e.target.value === "" && setDropDown(false);
            }}
            onClick={() => setSearch("")}
            id={compId}
          />
        </Typography>

        <div className="absolute right-7 top-0 -mt-[0.1rem]">
          {dropdown && (
            <CloseCircleOutlined
              className="text-[#8C8C8C]"
              onClick={() => {
                setDropDown(false);
                setLocationAddress("");
              }}
            />
          )}
        </div>

        {!locationBlocked && (
          <img
            src={locationgps}
            alt="location_place"
            className="cursor-pointer h-5"
            onClick={() => {
              getCurrentLocation()
                .then((result) => {
                  //
                })
                .catch((error) => {
                  toast.info("Kindly Allow Your Location");
                });
            }}
          />
        )}
      </div>
      {!locationBlocked && (
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => handleGlobalSearch(e.target.value)}
          className="p-2 outline-none h-6 w-[50%] placeholder:text-base"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleOptionSelect(enterSearch);
            }
          }}
        />
      )}
      {showLabSearchDropdown ? (
        <CloseCircleOutlined
          className="text-[#8C8C8C]"
          onClick={() => {
            setShowLabSearchDropdown(false);
            handleGlobalSearch("");
          }}
        />
      ) : (
        !locationBlocked && (
          <SearchOutlined className="cursor-pointer bg-white text-[#8C8C8C]" />
        )
      )}
      {showLabSearchDropdown ? (
        <>
          <div
            className="absolute  top-11 mt-2 -ml-2 border z-50 bg-white shadow-lg max-h-[10rem] overflow-scroll no-scrollbar   rounded p-4"
            style={
              window.innerWidth > 640 ? { width: "36%" } : { width: "100%" }
            }
          >
            {showLabs?.length > 0 ? (
              <>
                {showLabs?.map((el, index) => (
                  <div className="cursor-pointer ">
                    <div
                      key={index}
                      value={el}
                      className="p-1 font-sans cursor-pointer text-[.85rem] relative "
                      onClick={(e) => handleOptionSelect(el)}
                    >
                      <div>
                        {el?.type.toUpperCase()}
                        {": "}
                        {el?.name}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <Typography>
                  {" "}
                  <span className="text-[#5f5f5f] text-[0.9rem]">
                    Couldn’t find what you were looking for?{"  "}
                  </span>
                  <span className="font-semibold ">
                    <a
                      href="tel:7217890075"
                      className="!text-black cursor-none"
                    >
                      Call 7217890075
                    </a>
                  </span>
                </Typography>
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="absolute hidden group-hover:block right-30 top-12 w-[50%] border z-50 bg-white shadow-lg cursor-pointer  ">
        {" "}
        {searchedData?.map((data) => {
          return (
            <div key={data?._id}>
              <div
                onClick={() =>
                  navigateToGetgetpackageDetail(data?._id, data?.type)
                }
                className="flex justify-between align-middle"
              >
                <Typography className="text-[#8C8C8C] text-base py-2 px-6 hover:text-black">
                  {data?.name}
                </Typography>
                <Typography className="text-[#8C8C8C] text-base py-2 px-6 hover:text-black">
                  {data?.type}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchBarMobile;
