import React from "react";
import VaccinationFAQ from "./VaccinationsFAQ";
import VaccinationsCard from "./VaccinationsCard";

function VaccinationsAlldata() {
  const vaccinationsCardData = [
    {
      id: 1,
      Image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/on%20day%20of%20vacination.svg",
      Title: "On the Day of Vaccination",
      CardContent: [
        {
          imgType: true,
          content:
            "Keep yourself well-hydrated (Drink adequate water and other non-aerated drinks",
        },
        {
          imgType: true,
          content:
            "Take adequate diet as fasting is not a requirement for vaccination.",
        },
        {
          imgType: true,
          content:
            "On that day, you can do your routine physical exercise before the vaccination.",
        },
        {
          imgType: true,
          content:
            "On that day, it is better to avoid consuming alcohol before and after the vaccination.",
        },
        {
          imgType: true,
          content:
            "In case you have any allergies or past allergic reactions, please inform the vaccination staff at the Vaccination Centre.",
        },
      ],
    },
    {
      id: 2,
      Image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/after%20vaccination.svg",
      Title: "After Vaccination",
      CardContent: [
        {
          imgType: true,
          content:
            "Immediately after vaccination, please wait for 30 minutes in Post-Vaccination Observation Area.",
        },
        {
          imgType: true,
          content:
            "Check with the vaccination staff for your next vaccination date and carry the Vaccination Document for record.",
        },
        {
          imgType: true,
          content:
            "After vaccination, you may experience certain symptoms (such as pain or redness at the injection site, fever or feeling feverish, body aches, headache, generalized weakness, dizziness, nausea etc.), which are generally mild and subside within 48-72 hours. For such mild symptoms, you can take symptomatic and supportive treatment, which may include over-the-counter medicines (like Paracetamol for fever/ pain/ body aches, Calamine skin lotion for skin rash, etc.). Take adequate rest if needed.",
        },
        {
          imgType: true,
          content:
            "In case the symptoms are severe or do not subside within 72 hours, please seek medical assistance promptly from your doctor or the HCL Healthcare’s 24x7 Helpline.",
        },
        {
          imgType: true,
          content:
            "Alcohol consumption: It is not a strict contraindication after vaccination; however, the following is suggested: On that day, it is better to avoid consuming alcohol before and after the vaccination. During the next 72 hours, avoid heavy drinking.",
        },
        {
          imgType: true,
          content:
            "You can continue doing your routine physical exercise after the vaccination. If you develop any significant clinical symptoms after the vaccination, then avoid heavy exercise during the next 72 hours.",
        },
        {
          imgType: true,
          content:
            "Don’t get your subsequent dose earlier than the recommended date.",
        },
      ],
    },
    {
      id: 3,
      Image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/Mask%20group.png",
      Title: "For Pregnant women",
      CardContent: [
        {
          imgType: true,
          content:
            "Vaccines (Flu, HPV, Pneumococcal) available are safe. It depends on the specific vaccine, but most of these vaccines are safe during pregnancy and breastfeeding. It's important to consult with your healthcare provider and doctor for personalized advice.",
        },
        {
          imgType: true,
          content:
            "Like any medicine, a vaccine may have side effects which are normally mild such as mild fever, pain at injection site, or feeling unwell for a couple of days.",
        },
        {
          imgType: true,
          content:
            "Very rarely, one in 1-5 lakh persons may experience some severe symptoms.",
        },
      ],
    },
  ];
  return (
    <>
      <div className="relative mt-5">
        <div className="justify-center items-center text-center relative ">
          <div className="absolute top-0 left-0 -z-30 ">
            <img
              src="https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/Frame%202610007.png"
              alt="vacinations card background image"
              className="h-full object-cover"
            />
          </div>
          <div className="md:text-[64px] mt-[2rem] text-white">
            Dos and Dont's
          </div>
          <div className="text-white md:text-[20px]">
            Precautions to take during the vaccination process{" "}
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-[1rem] mt-8 md:mt-[5rem]">
          {vaccinationsCardData.map((data) => (
            <VaccinationsCard data={data} />
          ))}
        </div>
      </div>
      <div
        className="bg-cover bg-center rounded-md flex flex-col md:flex-row my-8 md:my-10"
        style={{
          backgroundImage:
            'url("https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/Image%20%281%29.png")',
        }}
      >
        <div className="w-full md:w-[70%]">
          <div className="flex flex-col py-[2rem] px-[2rem] gap-3">
            <div className="text-amber-900 text-[1.5rem] md:text-[2.375rem]">
              Vaccinations saves life at every stage of life
            </div>
            <div className=" text-[1rem] md:text-[1.1rem]">
              Take advantage of discounted prices on vaccination for you and
              your family at the HCL Healthcare clinic. Also, get your
              vaccinations reimbursed under the <b>Group Insurance Policy.**</b>
            </div>
            <div className="md:text-[1.20rem] bg-white text-blue-800 md:w-[16rem] py-[0.5rem] text-center rounded-md">
              Call us: +91 7217890075
            </div>
          </div>
        </div>
        <div className="w-full md:w-[30%] flex justify-center items-center p-4">
          <img
            className=""
            src="https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/Group%207464.png"
            alt="vaccines"
          />
        </div>
      </div>
      <div className="Vaccination FAQ">
        <VaccinationFAQ />
      </div>
    </>
  );
}

export default VaccinationsAlldata;
