import React, { useState } from "react";
import { Button, Card, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import SearchLocationModal from "../CommonComponents/SearchLocationModal";

const PopularPackageCard = ({ img, title, content, data, featuresVal }) => {
  const [openPinCodeModal, setOpenPincodeModal] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="mx-[0.4rem] sm:mx-[0.6rem] mb-3">
      <Col>
        <Card
          bodyStyle={{ padding: "0.8rem" }}
          bordered={false}
          className="drop-shadow"
          cover={
            <img
              src={img}
              alt="Popular card image"
              className=" sm:w-100 sm:h-[33vh] object-cover"
            />
          }
        >
          <div>
            <div className="flex justify-between items-center mb-2">
              <Typography className="text-base md:text-base font-semibold font-sans h-[1.4rem] hideLongTypography1">
                {title}
              </Typography>
            </div>
            <Typography className="text-[#595959] text-sm md:text-[0.8rem] font-normal font-sans h-[2.5rem] mb-2 hideLongTypography2">
              {content}
            </Typography>
            {/* <div className="w-full flex justify-end"> */}
            <button
              className="text-right text-[0.8rem] font-semibold mb-2 border w-full flex justify-center items-center py-2 rounded-md border-[#F15E7E] text-[#F15E7E] cursor-pointer"
              onClick={() => {
                navigate(`/packages/details`, { state: { pData: data } });
              }}
            >
              View Package
            </button>

            {/* Might use this code in future----------------------------------------------------------------- */}
            {/* </div> */}
            {/* <div className="flex h-[2rem] sm:h-[1.8rem] w-100 gap-1 mt-4 sm:mt-0 ">
              {featuresVal?.map((al) => (
                <Card
                  // bodyStyle={{ padding: "0" }}
                  className="bg-[#F0F0F0] flex items-center justify-center text-[#595959] !w-[50%] !rounded py-[0.2rem] font-sans capitalize"
                >
                  <Typography className="hideLongTypography1 text-[0.65rem] xl:text-[0.8rem]">
                    {al}
                  </Typography>
                </Card>
              ))}
            </div> */}
            {/* <div className="flex justify-between mt-3 items-center ">
              <div className="border border-[#F15E7E] w-[48.5%] rounded-[4px] ">
                <a
                  className="w-full h-10 text-center -m-[0.8px] flex items-center justify-center text-[#F15E7E] text-[0.8rem]  font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
                  href="https://api.whatsapp.com/send/?phone=9311667775&text=Hi!+I+want+to+connect+to+an+agent&type=phone_number&app_absent=0"
                  target="_blank"
                >
                  Enquire Now
                </a>
              </div>
              <Button
                className="w-[48.5%]  h-10 text-center rounded-[4px] flex items-center justify-center border-[#F15E7E] text-[#F15E7E] text-[0.8rem]  font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] font-sans"
                onClick={() => {
                  if (
                    localStorage?.getItem("longitude") ||
                    localStorage?.getItem("locationPlace")
                  ) {
                    navigate(`/packages/select-lab`, {
                      state: {
                        id: data?._id,
                        title: data?.name,
                      },
                    });
                    localStorage.setItem("CurrentLabTitle", data?.name);
                    localStorage.setItem("CurrentLabId", data?._id);
                  } else {
                    setOpenPincodeModal(true);
                  }
                }}
              >
                Add to cart
              </Button>
            </div> */}
          </div>

          <SearchLocationModal
            openPinCodeModal={openPinCodeModal}
            setOpenPincodeModal={setOpenPincodeModal}
          />
        </Card>
      </Col>
    </div>
  );
};

export default PopularPackageCard;
