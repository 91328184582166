import React, { Fragment } from "react";
import { Row, Col, Typography, Collapse } from "antd";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  YoutubeFilled,
  DownOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const Footer = () => {
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const path = useLocation();
  const [disableAll, setDisableAll] = useState(false);

  useEffect(() => {
    if (path.pathname === "/health-check") setDisableAll(true);
    else setDisableAll(false);
  }, [path]);

  const currentYear = new Date().getFullYear();

  const content = [
    {
      title: "Company Profile",
      key: "1",
      data: [
        { head: "About Us", route: "https://www.healthyhcl.in/about-us/" },
        { head: "Leadership", route: "https://www.healthyhcl.in/leadership/" },
        { head: "Location", route: "https://www.healthyhcl.in/location/" },
        { head: "Blog", route: "https://www.healthyhcl.in/blog/" },
      ],
    },
    {
      title: "Privacy & Terms",
      key: "2",
      data: [
        {
          head: "Terms & Condition",
          route: "https://www.healthyhcl.in/terms-and-use/",
        },
        {
          head: "Privacy Policy",
          route: "https://www.healthyhcl.in/privacy-policy/",
        },
        { head: " Disclaimer", route: "https://www.healthyhcl.in/disclaimer/" },
        {
          head: "Cancellation & Refund Policy",
          route: "/health-check/cancellation-and-refund-policy/",
        },
      ],
    },
    {
      title: "Value Added Service",
      key: "3",
      data: [
        { head: "Lab & Diagnostic Tests", route: "/diagnostic-tests" },
        { head: "Packages and Profiles", route: "/packages" },
        { head: " Specialist Consultant", route: "/special-consult" },
        { head: "About Healthcare Packages", route: "/health-check" },
      ],
    },
    {
      title: "Company Sponsored Service",
      key: "4",
      data: [
        { head: "Executive Health Check", route: "*" },
        { head: "Platinum Health Check", route: "*" },
        { head: "Managed Care Plan", route: "*" },
        { head: "Dental Care Service", route: "*" },
        { head: "Doctor Consult - Life Coach", route: "*" },
        { head: "Emergency Care Service", route: "*" },
      ],
    },
  ];

  const followIconClass =
    "w-10 h-10 text-[#0057A3] text-[1.5rem] bg-white items-center rounded-full flex justify-center mx-[0.4rem]";

  return (
    <div className="w-[100%] h-[75%] bg-[#30597E] mt-[3rem] sm:mt-[0rem] ">
      {window.innerWidth > 640 ? (
        <>
          <Row className="pt-[2.2%] px-[9%]">
            <Col md={6} lg={5}>
              <Typography className="text-[1.1rem] font-normal text-[#F5F5F5] pb-[8%] font-sans">
                Company Profile
              </Typography>

              <Typography
                onClick={() =>
                  window.open("https://www.healthyhcl.in/about-us/")
                }
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 hover:text-white cursor-pointer w-[85%]"
              >
                About Us
              </Typography>

              <Typography
                onClick={() =>
                  window.open("https://www.healthyhcl.in/leadership/")
                }
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 hover:text-white cursor-pointer w-[85%]"
              >
                Leadership
              </Typography>
              <Typography
                onClick={() =>
                  window.open("https://www.healthyhcl.in/location/")
                }
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 hover:text-white cursor-pointer w-[85%]"
              >
                Location
              </Typography>

              <Typography
                onClick={() => window.open("https://www.healthyhcl.in/blog/")}
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] hover:text-white cursor-pointer w-[85%]"
              >
                Blog
              </Typography>
            </Col>
            <Col md={6} lg={5}>
              <Typography className="font-sans text-[1.1rem] font-normal text-[#F5F5F5] pb-[8%]">
                Privacy & Terms
              </Typography>
              <Typography
                onClick={() =>
                  window.open("https://www.healthyhcl.in/terms-and-use/")
                }
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 hover:text-white cursor-pointer w-[85%]"
              >
                Terms & Condition
              </Typography>
              <Typography
                onClick={() =>
                  window.open("https://hclhealthcare.in/privacy-policy/")
                }
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 hover:text-white cursor-pointer w-[85%]"
              >
                Privacy Policy
              </Typography>
              <Typography
                onClick={() =>
                  window.open("https://www.healthyhcl.in/disclaimer/")
                }
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 hover:text-white cursor-pointer w-[85%]"
              >
                Disclaimer
              </Typography>
              <Typography
                onClick={() =>
                  navigate("/health-check/cancellation-and-refund-policy/")
                }
                className="font-sans h-10 text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%]  hover:text-white cursor-pointer w-[85%]"
              >
                Cancellation & Refund Policy
              </Typography>
            </Col>
            <Col md={6} lg={5}>
              <Typography className="font-sans text-[1.1rem] font-normal text-[#F5F5F5] pb-[8%]">
                Value Added Service
              </Typography>
              <Typography
                onClick={() =>
                  disableAll ? null : navigate("/diagnostic-tests")
                }
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
                className={`font-sans text-[0.85rem]  font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer h-10 hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Lab & Diagnostic Tests
              </Typography>
              <Typography
                onClick={() => (disableAll ? null : navigate("/packages"))}
                className={`font-sans h-10 text-[0.85rem]  font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
              >
                Packages and Profiles
              </Typography>
              <Typography
                // onClick={() => {
                //   navigate("/special-consult");
                // }}
                onClick={() =>
                  disableAll ? null : navigate("/special-consult")
                }
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Specialist Consultant
              </Typography>
              <Typography
                onClick={() => (disableAll ? null : navigate("/health-check"))}
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                About Healthcare Packages
              </Typography>
            </Col>
            <Col md={6} lg={5}>
              <Typography
                className="font-sans text-[1.1rem] font-normal text-[#F5F5F5] pb-[8%]"
                style={{ whiteSpace: "nowrap" }}
              >
                Company Sponsored Service
              </Typography>
              <Typography
                onClick={() =>
                  window.open(
                    "https://www.healthyhcl.in/executive-health-checks/"
                  )
                }
                // onClick={() => (disableAll ? null : navigate("*"))}
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Executive Health Check
              </Typography>
              <Typography
                onClick={() =>
                  window.open(
                    "https://www.healthyhcl.in/platinum-health-checks/"
                  )
                }
                // onClick={() => (disableAll ? null : navigate("*"))}
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Platinum Health Check
              </Typography>
              <Typography
                onClick={() =>
                  window.open("https://www.healthyhcl.in/managed-care-plans/")
                }
                // onClick={() => (disableAll ? null : navigate("*"))}
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Managed Care Plan
              </Typography>
              <Typography
                onClick={() =>
                  window.open("https://www.healthyhcl.in/dental-care-services/")
                }
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Dental Care Service
              </Typography>
              <Typography
                onClick={() =>
                  window.open(
                    "https://www.healthyhcl.in/doctor-consults-life-coach/"
                  )
                }
                // onClick={() => (disableAll ? null : navigate("*"))}
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Doctor Consult - Life Coach
              </Typography>
              <Typography
                onClick={() =>
                  window.open(
                    " https://www.healthyhcl.in/emergency-care-services/"
                  )
                }
                // onClick={() => (disableAll ? null : navigate("*"))}
                // className="font-sans text-[0.85rem] hover:text-[0.9rem] font-light text-[#D9D9D9] py-[4%] border-b border-white border-opacity-10 w-[75%] hover:text-white cursor-pointer"
                className={`font-sans text-[0.85rem] h-10 font-light text-[#D9D9D9] py-[4%] border-white border-opacity-10 w-[85%] ${
                  disableAll
                    ? "cursor-point"
                    : "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                }`}
              >
                Emergency Care Service
              </Typography>
            </Col>
            <Col md={6} lg={4}>
              <div className="ml-7">
                <Typography className="font-sans text-[1.1rem] font-normal text-[#F5F5F5] pb-[8%] pl-2">
                  Follow Us
                </Typography>
                <div className="flex justify-between  w-56">
                  <a
                    href="https://www.facebook.com/HCLHealthcareIndia/"
                    target="_blank"
                  >
                    {" "}
                    <FacebookFilled className="w-10 h-10 text-[#0057A3] text-[1.1rem] bg-white items-center rounded-full flex justify-center cursor-pointer mr-2" />
                  </a>
                  <a
                    href="https://www.instagram.com/hcl.healthcare/"
                    target="_blank"
                  >
                    <InstagramFilled className="w-10 h-10 text-[#0057A3] text-[1.1rem] bg-white items-center rounded-full flex justify-center cursor-pointer mr-2" />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/hcl-healthcare-india/"
                    target="_blank"
                  >
                    <LinkedinFilled className="w-10 h-10 text-[#0057A3] text-[1.1rem] bg-white items-center rounded-full flex justify-center cursor-pointer mr-2" />
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UChRjA_RHfVhO857Lnh_7MWQ"
                    target="_blank"
                  >
                    <YoutubeFilled className="w-10 h-10 text-[#0057A3] text-[1.1rem] bg-white items-center rounded-full flex justify-center cursor-pointer mr-2" />
                  </a>
                </div>
                <div className="mt-8">
                  <Typography className="font-sans text-[0.7rem] text-[#F5F5F5]">
                    For any Query, Contact us @
                  </Typography>
                  <a
                    href="mailto:medicalhelpline@hcl.com"
                    target="_top"
                    className="font-sans text-[0.8rem] text-blue-300 "
                  >
                    medicalhelpline@hcl.com
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <div className="flex justify-between mt-[1%] w-[100%] pb-10">
            <div className="pt-[2%] px-[9%] ">
              <Typography className="font-sans text-[0.8rem] font-normal text-white ">
                © {currentYear} HCL Healthcare. All Rights Reserved
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <div className="p-[1rem]">
          <Collapse
            expandIcon={() => <DownOutlined className="!text-white" />}
            expandIconPosition={"end"}
            bordered={false}
          >
            {content.map((content) => (
              <Fragment className="text-white ">
                <Panel
                  header={
                    <Typography className="font-sans text-[1rem]  text-white mb-[0.6rem]">
                      {content.title}
                    </Typography>
                  }
                  key={content.key}
                >
                  {content.data.map((data) => (
                    <>
                      <Typography
                        className="font-sans text-[15px] text-white mt-[1.4rem]"
                        onClick={() => {
                          data.head === "Cancellation & Refund Policy"
                            ? navigate(`${data.route}`)
                            : window.open(`${data.route}`);
                        }}
                      >
                        {data.head}
                      </Typography>
                    </>
                  ))}
                </Panel>
              </Fragment>
            ))}
          </Collapse>
          <div className="">
            <Typography className="font-sans text-sm font-normal text-[#F5F5F5]  m-[0.4rem] mt-[3.5rem] pb-[3%]">
              Follow Us
            </Typography>
            <div className="flex justify mb-[1rem] gap-2">
              <a
                href="https://www.facebook.com/HCLHealthcareIndia/"
                target="_blank"
              >
                {" "}
                <FacebookFilled className={followIconClass} />
              </a>{" "}
              <a
                href="https://www.instagram.com/hcl.healthcare/"
                target="_blank"
              >
                <InstagramFilled className={followIconClass} />
              </a>
              <a
                href="https://www.linkedin.com/company/hcl-healthcare-india/"
                target="_blank"
              >
                {" "}
                <LinkedinFilled className={followIconClass} />
              </a>
              <a
                h
                href="https://www.youtube.com/channel/UChRjA_RHfVhO857Lnh_7MWQ"
                target="_blank"
              >
                {" "}
                <YoutubeFilled className={followIconClass} />
              </a>
            </div>
            <div className="mt-8 ml-2">
              <Typography className="font-sans text-[0.7rem] text-[#F5F5F5]">
                For any Query, Contact us @
              </Typography>
              <a
                href="mailto:medicalhelpline@hcl.com"
                target="_top"
                className="font-sans text-[0.8rem] text-blue-300 "
              >
                medicalhelpline@hcl.com
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
