import React, { useEffect, useState } from "react";
import { Carousel, Typography } from "antd";
import PopularPackageCard from "./PopularPackageCard";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { getAllPopularPackages } from "../../service/homeApi";

const PopularPackage = () => {
  const [packageData, setPackageData] = useState([]);
  //Popular Package Api

  const popularPackageData = async () => {
    const response = await getAllPopularPackages();

    setPackageData(response?.data);
  };

  useEffect(() => {
    popularPackageData();
  }, []);

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <ArrowLeftOutlined className="text-[#595959] text-[1rem] bg-[#F0F0F0] rounded-full p-2 absolute top-1/2 left-2 z-10 transform -translate-y-1/2 -translate-x-1/2 invisible sm:visible" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <ArrowRightOutlined className="text-[#595959] text-[1rem] bg-[#F0F0F0] rounded-full p-2 absolute top-1/2 right-4 transform -translate-y-1/2  translate-x-3/4 invisible sm:visible" />
    </button>
  );
  const responsiveSettings = [
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.2,
      },
    },
  ];
  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const popularData = [
    {
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/landingPage/package%201.png",
    },
    {
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/landingPage/package%202.png",
    },
    {
      image:
        "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/landingPage/package%203.png",
    },
  ];

  return (
    <>
      {packageData?.payload?.packages?.length > 0 && (
        <div className="mt-[3.5rem] sm:mt-[2.4rem] ">
          <div className="">
            <div className="w-100 items-center flex justify-items-center flex-col">
              <Typography className="text-center font-semibold text-[1.4rem] sm:text-[2rem] font-sans">
                Health Packages for you and your family
              </Typography>
              <Typography className="text-center text-[#434343] text-[0.9rem] w-[50%] m-[auto] sm:text-[1.1rem] mb-[0.2rem] sm:mb-[1rem] font-normal font-sans">
                Avail discounts and offers on packages
              </Typography>
            </div>
          </div>

          <div>
            <Carousel
              responsive={responsiveSettings}
              slidesToShow={
                packageData?.payload?.packages?.length < 5
                  ? packageData?.payload?.packages?.length
                  : 5.5
              }
              {...carouselSettings}
              autoplay
              infinite={false}
            >
              {packageData?.payload?.packages?.map((data, index) => (
                <div key={data?.category?._id}>
                  <PopularPackageCard
                    img={popularData[index % popularData.length].image}
                    title={data?.name}
                    content={data?.description}
                    id={data?._id}
                    data={data}
                    featuresVal={data?.features?.map((el) => el)}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
};

export default PopularPackage;
