import { axiosInstance } from "../utils/axios";

/********************Global search Api(GET) **********************/
export const getGlobalSearchInput = async (search) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/global/?keyword=${search}`
    );
    if (response?.success) {
      return response?.data;
    }
    return response;
  } catch (error) {
    return error;
  }
};
