import React, { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import Character from "../../assets/images/Character.png";
import { Pagination, Typography } from "antd";
import { getAllOrders } from "../../service/orderReviewApi";
import {
  getDate,
  formateTime,
  formateDate,
} from "../CommonComponents/DateAndTime";

const OrderReviewPreviousContent = ({ searchData }) => {
  const [previousOrders, setPreviousOrders] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [page, setPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState();

  useEffect(() => {
    if (searchData) {
      setPage(1);
    }
    getPreviousOrders();
  }, [isChanged, page, searchData]);

  const getPreviousOrders = async () => {
    try {
      const response = await getAllOrders(page, "COMPLETED", searchData);
      setTotalOrders(response?.data?.payload?.totalOrders);
      setPreviousOrders(response?.data?.payload);
    } catch (error) {}
  };

  return (
    <>
      <div className="w-full p-4 sm:h-[60vh] overflow-y-scroll no-scrollbar ">
        {previousOrders &&
          previousOrders?.orders?.map((data, index) => (
            <OrderCard
              title={data?.serviceName}
              status={data?.status?.toLowerCase()}
              orderId={data?.orderId}
              dateAndTime={
                getDate(data?.product?.slotDetails?.[0]?.date) +
                " | " +
                formateTime(
                  data?.product?.slotDetails?.[0]?.slotTimings?.startDate
                )
              }
              transactionID={data?.transactionId}
              isPrevious={true}
              key={index}
              price={data?.totalAmount}
              netPrice={data?.discountedAmount}
              serviceType={data?.product?.type}
              patients={data?.product?.slotDetails?.[0]?.patient}
              address={data?.address}
              rating={data?.review?.rating}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
              getOrders={getPreviousOrders}
              isCancelRequested={data?.isCancelRequested}
              isRescheduled={data?.rescheduledData?.isRescheduled}
              rescheduleDateAndTime={
                formateDate(data?.rescheduledData?.finishTime).split(", ")[0]
                  ? formateDate(data?.rescheduledData?.finishTime).split(
                      ", "
                    )[0] +
                    ", " +
                    formateDate(data?.rescheduledData?.appointmentTime).split(
                      ", "
                    )[1]
                  : ""
              }
              unformattedDateandTime={data?.product?.slotDetails?.[0]?.date}
            />
          ))}

        {previousOrders?.orders?.length === 0 && (
          <div className="flex flex-col items-center gap-3">
            <img src={Character} alt="" className="h-[48vh]" />
            <Typography className="text-[1.1rem] text-[#1F1F1F] font-semibold font-sans">
              No Previous Orders
            </Typography>
          </div>
        )}
      </div>

      {previousOrders?.orders?.length > 0 && (
        <Pagination
          current={page}
          onChange={(page) => {
            setPage(page);
          }}
          total={totalOrders}
          pageSize={10}
          showSizeChanger={false}
          className="flex items-center justify-end mt-3"
        />
      )}
    </>
  );
};

export default OrderReviewPreviousContent;
