import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Typography, Space, Input, Button, Form } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardOfferAndDiscount from "./CardOfferAndDiscount";
import { useState } from "react";
import Footer from "../CommonComponents/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getOffers } from "../../redux/Offers/offerSlice";
import { axiosInstance } from "../../utils/axios";
import { addAppliedName } from "../../redux/Offers/offerNameSlice";
import { toast } from "react-toastify";

const ViewAllOffersMobile = () => {
  const offers = useSelector((state) => state.Offers.Offers);

  const dispatch = useDispatch();
  if (offers.length < 1) {
    dispatch(getOffers());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const pasteCodeApply = async (e) => {
    try {
      const response = await axiosInstance.post(`/api/v1/offer/apply-coupon`, {
        couponName: e.code,
      });

      navigate(-1);
      if (response.success) {
        dispatch(addAppliedName(e.code));
      }
    } catch (error) {
      toast.info("This offer is not applicable on these services");
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-start items-center px-[1rem] border-b-2 pb-2">
        <ArrowLeftOutlined onClick={() => navigate(-1)} />
        <Typography className="text-[1.2rem] font-semibold ml-3 font-sans">
          Offers and Discounts
        </Typography>
      </div>
      <br />
      <Row>
        <Col span={22} offset={1}>
          <Form onFinish={pasteCodeApply}>
            <div className="flex justify-between items-center">
              <div className="w-[85%]">
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: "Please paste your code." },
                  ]}
                >
                  <Input
                    placeholder="Coupon code"
                    className="bg-transparent bg-opacity-10 border-[1.5px]  placeholder-black font-light"
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  className="!text-[#0057A3] border-[1.5px] bg-white semibold px-5"
                  htmlType="submit"
                >
                  Apply
                </Button>
              </Form.Item>
            </div>
          </Form>
          {offers.map((data) => (
            <>
              <CardOfferAndDiscount
                name={data?.name}
                title={data?.title}
                description={data?.description}
                discountPercentage={data?.discount}
                id={data?._id}
                isAllOffers={true}
                width={`100%`}
                tc={data?.tc}
                discountType={data?.discountType}
                // getData={getData}
              />
              <br />
            </>
          ))}
        </Col>
      </Row>

      <br />
      <br />
      <Footer />
    </div>
  );
};

export default ViewAllOffersMobile;
