import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isClicked: false,
};

const landingPageSlice = createSlice({
  name: "landingPageSlice",
  initialState: initialState,
  reducers: {
    setIsClicked: (state, action) => {
      state.isClicked = action.payload;
    },
  },
});

export const { setIsClicked } = landingPageSlice.actions;

export default landingPageSlice.reducer;
