import { Button, Form, Input, Select, Typography } from "antd";
import React from "react";
import { addAddress, updateAddress } from "../../service/AddressApi";
import stateAndDistrict from "../../assets/StateAndDistrict/stateAndDistrict";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const AdressForm = ({
  setIsAdressAdded,
  isAddressAdded,
  setIsModalOpen,
  setIsModalOpenEdit,
  isEdit,
  detailtsToEdit,
  fetchAddress,
}) => {
  const [form] = Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const callAddAddress = async (data) => {
    try {
      const response = await addAddress(data);
      fetchAddress();
      if (response?.status === 201) {
        setIsAdressAdded(!isAddressAdded);
        setIsModalOpen(false);
      }
    } catch (error) {}
  };

  const callUpdateAddress = async (addressId, data) => {
    try {
      const response = await updateAddress(addressId, data);
      if (response) {
        fetchAddress();
        setIsAdressAdded(!isAddressAdded);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (detailtsToEdit) {
      form.resetFields();
      form.setFieldsValue(detailtsToEdit);
    }
  }, [detailtsToEdit]);

  const handleForm = (values) => {
    if (!isEdit) {
      callAddAddress(values);
      setIsModalOpen(false);
    } else {
      callUpdateAddress(detailtsToEdit?._id, values);
      setIsModalOpenEdit(false);
    }
  };

  const [stateName, setStateName] = useState("");
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);

  const extractStates = () => {
    const stateNames = stateAndDistrict[0].states.map((item) => item.state);

    setAllStates(stateNames);
  };

  useEffect(() => {
    extractStates();
  }, []);

  const extractCities = (e) => {
    const citiesData = stateAndDistrict[0].states.find(
      (item) => item.state == e
    );
    if (citiesData) {
      setAllCities(citiesData.districts);
    } else {
      setAllCities([]);
    }
  };

  const changeStateHandler = (e) => {
    setStateName(e);
    extractCities(e);
  };

  const areRequiredFieldsFilled = () => {
    const values = form.getFieldsValue();
    const requiredFields = ["area", "locality", "pincode", "state", "district"];

    return requiredFields.every((field) => {
      const value = values[field];
      return value !== undefined && value !== "";
    });
  };

  const handleFormChange = () => {
    setIsSubmitDisabled(!areRequiredFieldsFilled());
  };

  const handlePincodeKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const isNumber = /^[0-9]+$/.test(keyValue);
    if (!isNumber && keyCode !== 8) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Form form={form} onFinish={handleForm} onFieldsChange={handleFormChange}>
        <Typography className="text-[#595959] my-[8px] text-[0.85rem] font-sans">
          House No, Building, Street
          <label>
            <span style={{ color: "red" }}> *</span>
          </label>
        </Typography>
        <Form.Item
          name="area"
          className="mb-2"
          rules={[
            {
              required: true,
              message: "House No, Building, Street",
            },
            {
              pattern: /^(?!\s*$).+/,
              message: "cannot be empty",
            },
          ]}
        >
          <Input
            placeholder="House No, Building, Street"
            className="rounded text-[0.85rem] py-2 font-sans"
          />
        </Form.Item>
        <div className="relative">
          <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
            Locality
            <label>
              <span style={{ color: "red" }}> *</span>
            </label>
          </Typography>
          <Form.Item
            name="locality"
            className="mb-2"
            rules={[
              {
                required: true,
                message: "Locality",
              },
              {
                pattern: /^(?!\s*$).+/,
                message: "cannot be empty",
              },
            ]}
          >
            <Input
              placeholder="Locality"
              className="rounded text-[0.85rem] py-2 font-sans"
            />
          </Form.Item>
        </div>

        <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
          Landmark (Optional)
        </Typography>
        <Form.Item
          name="landmark"
          className="mb-2"
          rules={[
            {
              required: false,
              message: "Landmark",
            },
          ]}
        >
          <Input
            placeholder="Landmark"
            className="rounded mb-2 text-[0.85rem] py-2 font-sans"
          />
        </Form.Item>

        <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
          Pincode
          <label>
            <span style={{ color: "red" }}> *</span>
          </label>
        </Typography>
        <Form.Item
          className="mb-2"
          name="pincode"
          rules={[
            {
              required: true,
              message: "Pincode",
            },
            {
              pattern: /^(?!\s*$).+/,
              message: "cannot be empty",
            },
            {
              min: 6,
              message: "Must be at 6 characters long!",
            },
            {
              max: 6,
              message: "Must be at 6 characters long!",
            },
          ]}
        >
          <Input
            placeholder="Pincode"
            className="rounded text-[0.85rem] py-2 font-sans"
            onKeyPress={handlePincodeKeyPress}
          />
        </Form.Item>
        <div className="md:flex md:justify-between">
          <div className="md:w-[48%]">
            <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
              State
              <label>
                <span style={{ color: "red" }}> *</span>
              </label>
            </Typography>
            <Form.Item
              className="mb-2 !rounded-sm font-sans"
              name="state"
              rules={[
                {
                  required: true,
                  message: "State",
                },
              ]}
            >
              <Select
                placeholder="Select State"
                onChange={changeStateHandler}
                className="rounded-sm text-[0.85rem]"
              >
                {allStates &&
                  allStates.map((state_name, index) => {
                    return (
                      <>
                        <Select.Option key={index} value={state_name}>
                          {state_name}
                        </Select.Option>
                      </>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="md:w-[48%]">
            <Typography className="text-[#595959] mb-[8px] text-[0.85rem] font-sans">
              District
              <label>
                <span style={{ color: "red" }}> *</span>
              </label>
            </Typography>
            <Form.Item
              className="mb-2 font-sans"
              name="district"
              rules={[
                {
                  required: true,
                  message: "District",
                },
              ]}
            >
              <Select placeholder="District" className="font-sans">
                {allCities &&
                  allCities.map((state_name, index) => {
                    return (
                      <>
                        <Select.Option key={index} value={state_name}>
                          {state_name}
                        </Select.Option>
                      </>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </div>
        {window.innerWidth > 640 ? (
          <div className="flex justify-end">
            <Button
              className="font-sans font-semibold text-[1rem] bg-[#F15E7E] mt-[1rem] !text-[white] h-[2.6rem] w-[8rem] items-center rounded-[4px] !border-white"
              // type="submit"
              type="default"
              htmlType="submit"
              disabled={isSubmitDisabled}
            >
              Save
            </Button>
          </div>
        ) : (
          <>
            <div className="fixed bottom-0 left-0 bg-white w-[100%] flex justify-between px-3 items-center mt-2 h-[8vh] border-t">
              <div>
                <Typography className="font-normal text-sm font-sans">
                  Total Price
                </Typography>
                <Typography className="font-semibold text-[1.2rem] text-[#1F1F1F] font-sans">
                  ₹1000 <span className="font-normal text-sm">(1 Item)</span>
                </Typography>
              </div>
              <Button
                className="font-sans  bg-[#F15E7E]  !text-white !border-[#F15E7E]  my-2 h-[10vw] px-10"
                htmlType="submit"
              >
                Continue
              </Button>
            </div>
          </>
        )}
      </Form>
      <style jsx>{`
        .ant-select-single .ant-select-selector {
          font-size: 12px;
          border-radius: 4px;
        }
        .ant-select .ant-select-arrow {
          color: black;
          font-size: 10px;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 38px;
        }
        .ant-select-single .ant-select-selector .ant-select-selection-item,
        .ant-select-single
          .ant-select-selector
          .ant-select-selection-placeholder {
          line-height: 38px;
        }

        .ant-modal .ant-modal-close-x {
          margin-top: -7px;
          margin-left: 3px;
        }
      `}</style>
    </>
  );
};

export default AdressForm;
