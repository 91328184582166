import React, { useEffect } from "react";
import { Col, Row, Badge, Pagination } from "antd";
import NavbarTabs from "../../../components/CommonComponents/NavbarTabs";
import Footer from "../../../components/CommonComponents/Footer";
import CardComponent from "../../../components/CommonComponents/CardComponent";
import {
  getAllPackages,
  getPackageByCategory,
  getPackageById,
} from "../../../service/homeApi";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import NoLab from "./NoLab";
import Loader from "../../../components/CommonComponents/Loader";
import fitnessDeficency from "../../../assets/images/Fitness_Deficency.jpg";
import PreventiveHealth from "../../../assets/images/PreventiveHealth.jpg";
import othersImage from "../../../assets/images/Others.jpg";
import SeasonalPackage from "../../../assets/images/SeasonalPackages.jpg";
import SexualWellness from "../../../assets/images/Sexual_Wellness.jpg";
import SpecialSuggest from "../../../assets/images/Special_Suggested.jpg";
import SearchBarMobile from "../../../components/CommonComponents/SearchBarMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  setPackageCategoryId,
  setPageNo,
  setPackageSelectedTab,
} from "../../../redux/ProfileAndPackages/profileAndPackagesVarSlice";

const DiagnosticTest = () => {
  const [Categories, setCategories] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [totalPackages, setTotalPackages] = useState();
  const [packages, setPackages] = useState();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { pincode } = useSelector((state) => state.changePincode);
  const { pageNo, categoryId, selectedTab } = useSelector(
    (state) => state.ProfileAndPackagesVariables
  );
  const navigate = useNavigate();
  const [limit, setLimit] = useState(9);
  const dispatch = useDispatch();
  const { itemIds } = useSelector((state) => state.count);

  useEffect(() => {
    if (state) {
      if (!state.isPharmacy) {
        dispatch(setPackageSelectedTab(state.name));
        dispatch(setPackageCategoryId(state.id));
      }
    }
  }, [state]);

  const scrollLeft = () => {
    document.getElementById("section").scrollLeft += 100;
  };

  const scrollRight = () => {
    document.getElementById("section").scrollLeft -= 100;
  };

  const params = useParams();

  const onChange = (page, newPageSize) => {
    setLimit(newPageSize);
    dispatch(setPageNo(page));
  };

  const getPackages = async () => {
    setIsLoading(true);
    try {
      if (Object.keys(params).length === 0) {
        const response = await getAllPackages(
          pageNo,
          state?.isPharmacy ? state?.id : categoryId,
          selectedItem,
          limit
        );
        setPackages(response?.data?.payload?.packages);
        setTotalPackages(response?.data?.payload?.totalPackages);
      } else {
        const response = await getPackageById(params?.id);
        const tempPackages = [response?.data?.payload];
        setPackages(tempPackages);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPackages();
  }, [pageNo, itemIds, categoryId, selectedItem, params, limit, pincode]);

  useEffect(() => {
    getProfileCategories();
  }, []);

  const getProfileCategories = async () => {
    try {
      const resp = await getPackageByCategory();
      setCategories(resp);
    } catch (error) {}
  };

  return (
    <Row>
      <Col className="min-h-[95vh] w-full">
        <NavbarTabs />

     

        <Row className="pt-6 mb-4 border-t-2">
          {!state?.isPharmacy && (
            <Col
              span={window.innerWidth < 640 ? 22 : 20}
              offset={window.innerWidth < 640 ? 1 : 2}
            >
              <div className=" sm:hidden mb-6">
                <SearchBarMobile compId="landingpage-location" />
              </div>
              <div className="w-full flex justify-between items-center gap-2">
                <div className="lg:w-full md:w-full w-[100%] sm:w-[90%] flex justify-between items-center">
                  <div className="md:flex items-center hidden">
                    <LeftOutlined
                      onClick={scrollRight}
                      className="text-[#1C1B1F] mr-2 border-[#928f8f] border rounded-full p-1 text-[0.7rem]"
                    />
                  </div>
                  <ul
                    className="relative grid grid-flow-col text-center overflow-y-scroll no-scrollbar md:overflow-hidden sm:w-[98%]"
                    id="section"
                  >
                    <>
                      {selectedTab === "All" ? (
                        <li className="items-center justify-center flex w-max bg-[#FBF0F2] border-b-2 border-[#F15E7E] rounded-t-md cursor-pointer font-sans px-5 py-2 md:py-1">
                          <div className="text-[0.875rem] md:text-[1rem] hover:text-black text-[#595959]">
                            All
                          </div>
                        </li>
                      ) : (
                        <li
                          className="items-center justify-center flex w-max hover:border-b-2 hover:border-black cursor-pointer font-sans px-6 py-2 md:py-1"
                          onClick={() => {
                            dispatch(setPageNo(1));
                            dispatch(setPackageSelectedTab("All"));
                            dispatch(setPackageCategoryId(""));
                          }}
                        >
                          <div className="text-[0.875rem] md:text-[1rem] hover:text-black text-[#595959]">
                            All
                          </div>
                        </li>
                      )}
                    </>
                    {Categories?.data?.payload?.map((e, key) => {
                      return (
                        <>
                          <li
                            className={
                              selectedTab === e.name
                                ? "items-center justify-center flex w-max bg-[#FBF0F2] border-b-2 border-[#F15E7E] rounded-t-md cursor-pointer font-sans px-6 py-2 md:py-1"
                                : "hover:border-b-2 hover:border-black cursor-pointer font-sans px-6 py-2 md:py-1"
                            }
                            key={key}
                            style={{
                              display: "inline-block",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() => {
                              dispatch(setPageNo(1));
                              dispatch(setPackageSelectedTab(e.name));
                              dispatch(setPackageCategoryId(e._id));
                            }}
                          >
                            <div
                              className="text-[0.875rem] md:text-[1rem] hover:text-black text-[#595959]"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {e.name}
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <div className="md:flex items-center hidden">
                    <RightOutlined
                      onClick={scrollLeft}
                      className="text-[#1C1B1F] ml-2 border-[#928f8f] border rounded-full p-1 text-[0.7rem]"
                    />
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>

        {state?.isPharmacy && (
          <div className="px-4 -mt-4 mb-6 flex gap-2 text-[1.2rem] font-semibold items-center sm:hidden">
            <ArrowLeftOutlined
              onClick={() => {
                navigate("/");
              }}
            />
            <p> Pharmacy</p>
          </div>
        )}

        <Row className={`mt-2 mb-4 `}>
          <Col
            span={window.innerWidth < 640 ? 22 : 20}
            offset={window.innerWidth < 640 ? 1 : 2}
            className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 sm:justify-start justify-center"
          >
            {packages?.length !== 0 ? (
              packages?.map((e, key) => {
                return (
                  <>
                    <CardComponent
                      // selectedTab ==="Fitness, Deficiencies & physical health" ?fitnessDeficency
                      TestImage={
                        e?.category?.name ===
                        "Fitness, Deficiencies & physical health"
                          ? fitnessDeficency
                          : e?.category?.name === "Preventive Checkup Packages"
                          ? PreventiveHealth
                          : // : e?.category?.name === "Other Packages"
                          // ? othersImage
                          e?.category?.name === "Seasonal Packages"
                          ? SeasonalPackage
                          : e?.category?.name ===
                            "Sexual Wellness , Fertility, Pregnancy"
                          ? SexualWellness
                          : e?.category?.name ===
                            "Specialists' Suggested Packages"
                          ? SpecialSuggest
                          : othersImage
                      }
                      title={e?.name}
                      providers={e?.partners}
                      content={e?.description}
                      price="1500"
                      features={e?.features?.[0]}
                      id={e?._id}
                      duration={e?.duration}
                      route="packages"
                      isPackage={true}
                      packageData={e}
                      key={key}
                      serviceType={e?.serviceMode}
                      reportTime={e?.reportTime}
                      requirments={e?.requirments}
                      discountPrice={e.startingPrice}
                      inCart={e?.inCart}
                      productId={e?.productId}
                      startingOriginalPrice={e?.startingOriginalPrice}
                    />
                  </>
                );
              })
            ) : (
              <NoLab />
            )}
          </Col>
        </Row>
        {totalPackages > 9 && (
          <Row className="pt-6 mb-4">
            <Col
              span={window.innerWidth < 640 ? 22 : 20}
              offset={window.innerWidth < 640 ? 1 : 2}
            >
              {totalPackages > 0 && (
                <Pagination
                  current={pageNo}
                  onChange={onChange}
                  total={totalPackages}
                  className="flex items-end justify-end"
                  pageSizeOptions={[9, 15, 30, 60]}
                  onShowSizeChange={onChange}
                  defaultPageSize={9}
                />
              )}
            </Col>
          </Row>
        )}
      </Col>
      {isLoading && <Loader />}
      <Footer />
    </Row>
  );
};

export default DiagnosticTest;
