import { generateRefreshToken } from "../service/authApi";
import { BASE_URL } from "./globalVariable";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } 
    config.headers["Access-Control-Allow_Origin"] = `*`;
    config.headers["ngrok-skip-browser-warning"] = `*`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { accessToken, refreshToken } = await generateRefreshToken();
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      return axiosInstance(originalRequest);
    }
    // if (error?.response?.status === 401) {
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("accessToken");
    //   localStorage.removeItem("refreshToken");
    // window.location = "\login"
    // }
    return Promise.reject(error);
  }
);
