import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  slots: [],
}
const availableSlotsSlice = createSlice({
  name: 'availableSlots',
  initialState: initialState,
  reducers: {
    addAvaialbleSlots: (state, action) => {
      state.slots = action.payload;
    },
  },
});

export const { addAvaialbleSlots } = availableSlotsSlice.actions;

export default availableSlotsSlice.reducer;