import React from "react";
// import { FaWhatsapp } from 'react-icons/fa';
import { image75 } from "../../assets/images";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

const WhatsAppIcon = ({ isCart }) => {
  // const { isPop } = useSelector((state) => state.whatsappPop);

  return (
    <Tooltip
      title="Need assistance?"
      color="#60B45D"
      className="transition-all"
    >
      <a
        className="fixed cursor-pointer bottom-[9vh] right-4 w-[3.6rem] z-40"
        href="https://api.whatsapp.com/send/?phone=9311667775&text=Hi!+I+want+to+connect+to+an+agent&type=phone_number&app_absent=0"
        target="_blank"
        style={isCart ? { bottom: "9vh" } : { bottom: "2vh" }}
      >
        <img src={image75} alt="" />
      </a>
    </Tooltip>
  );
};

export default WhatsAppIcon;
