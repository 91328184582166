import { Modal, Rate } from "antd";
import Typography from "antd/es/typography/Typography";
import React, { useState } from "react";
import Details from "./Details";
import Reshedule from "./Reshedule";
import { useNavigate } from "react-router-dom";
import Feedback from "./Feedback";
import CancelModal from "./CancelModal";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { OrderCancelled, OrderRescheduled } from "../../service/orderReviewApi";
import { toast } from "react-toastify";

const OrderCard = ({
  title,
  status,
  orderId,
  dateAndTime,
  isPrevious,
  price,
  netPrice,
  serviceType,
  patients,
  address,
  rating,
  isCancelRequested,
  setIsChanged,
  isChanged,
  message,
  unformattedDateandTime,
  getOrders,
  isRescheduleRequested,
  transactionID,
  isRescheduled,
  rescheduleDateAndTime,
}) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isresModalOpen, setisresModalOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [RescheduledModal, setRescheduleModal] = useState(false);
  const [CancelledModal, setCancelledModal] = useState(false);
  const currentDateTime = new Date();
  const navigate = useNavigate();

  const RescheduledOrders = async () => {
    try {
      const response = await OrderRescheduled(orderId);
      if (response.success) {
        setisresModalOpen(true);
        getOrders();
        setTimeout(() => {
          setisresModalOpen();
        }, 3000);
      } else {
        toast.info(response.message);
      }
    } catch (error) {}
  };

  const CancelledOrders = async () => {
    try {
      const response = await OrderCancelled(orderId);
      if (response?.success) {
        setIsCancelOpen(!isCancelOpen);
        getOrders();
        setTimeout(() => {
          setIsCancelOpen();
        }, 3000);
      } else {
        toast.info("Something went wrong. Please try again after some time.");
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setisModalOpen(false);
  };

  const handleReschedule = () => {
    setRescheduleModal(false);
    RescheduledOrders();
    setIsChanged(isChanged ? false : true);
  };

  const HandleCancel = () => {
    setCancelledModal(false);
    CancelledOrders();
    setIsChanged(isChanged ? false : true);
  };

  const handleFeedback = () => {
    if (rating !== undefined) {
      toast.info("Rating is already sent !!");
      return;
    }

    if (window.innerWidth > 640) {
      setIsFeedbackOpen(!isFeedbackOpen);
    } else {
      navigate("/order-review/feedback");
    }
  };

  return (
    <>
      <div className="border border-[#D9D9D9] mb-3 w-[100%] p-[1rem] rounded">
        <div className="w-[100%]  flex justify-between items-center">
          <Typography className="font-bold text-[1rem] font-sans">
            {title}
          </Typography>
          <Typography
            className="text-[0.78rem] font-semibold text-[#F15E7E] cursor-pointer font-sans"
            onClick={() => setisModalOpen(true)}
          >
            View Details
          </Typography>
        </div>
        <div className="flex mt-[0.9rem]">
          <img src="" alt="" />

          {status === "pending" || status === "unattended" ? (
            isCancelRequested ? (
              <Typography className="text-[#D4B106] font-semibold text-[0.86rem]  font-sans flex items-center gap-[0.375rem] ">
                <InfoCircleOutlined
                  style={{ fontSize: "0.76rem", height: "0.74rem" }}
                />
                Cancellation Pending
              </Typography>
            ) : (
              <Typography className="text-[#D4B106] font-semibold text-[0.86rem]  font-sans flex items-center gap-[0.375rem] ">
                <InfoCircleOutlined
                  style={{ fontSize: "0.76rem", height: "0.74rem" }}
                />
                Confirmation Pending
              </Typography>
            )
          ) : status === "reschedule" ? (
            <Typography className="text-[#D4B106] font-semibold text-[0.86rem]  font-sans flex items-center gap-[0.375rem] ">
              <InfoCircleOutlined
                style={{ fontSize: "0.76rem", height: "0.74rem" }}
              />
              {status} pending
            </Typography>
          ) : status === "cancelled" ? (
            <Typography className="text-[#CF1322] font-semibold text-[0.86rem] font-sans flex items-center gap-[0.375rem]">
              <CloseCircleOutlined
                style={{ fontSize: "0.76rem", height: "0.74rem" }}
              />
              {status}
            </Typography>
          ) : (
            <Typography className="text-[#389E0D] font-semibold text-[0.86rem]  font-sans flex items-center gap-[0.375rem] ">
              <CheckCircleOutlined
                style={{ fontSize: "0.76rem", height: "0.74rem" }}
              />
              {status}
            </Typography>
          )}
        </div>
        <div className="sm:flex justify-between items-center ">
          <div>
            <Typography className=" text-[0.85rem] leading-6 font-sans">
              Order ID: {orderId}
            </Typography>
            <Typography className=" text-[0.85rem] leading-6 font-sans">
              {isRescheduled
                ? `Reschedule Date & Time:${rescheduleDateAndTime}`
                : `Date & Time: ${dateAndTime}`}
            </Typography>
          </div>
          {status === "confirmed" &&
            currentDateTime < new Date(unformattedDateandTime) &&
            !isRescheduleRequested && (
              <div className="flex justify-between mt-2 sm:mt-0 sm:justify-end   ">
                <button
                  className="my-[0.5rem] py-[0.6rem] px-[0.7rem] font-sans border border-[#F15E7E] text-[#F15E7E] w-[48%] sm:w-[auto] text-[0.8rem] mr-[0.5rem] rounded"
                  onClick={() => setCancelledModal(true)}
                >
                  Cancel & Refund
                </button>
                <Modal
                  open={CancelledModal}
                  centered={true}
                  closeIcon={<></>}
                  footer={null}
                  width={
                    window.innerWidth > 850
                      ? "29%"
                      : window.innerWidth > 500
                      ? "43%"
                      : "80%"
                  }
                >
                  <div className="flex flex-col items-center justify-center">
                    <InfoCircleOutlined className="text-[2.5rem] md:text-[3rem] mt-[0.2rem] md:mt-[1.2rem] rotate-180	" />
                    <Typography className="mt-1 md:mt-3 text-[1rem] md:text-[1.3rem] text-[#212121] font-semibold font-sans">
                      Confirmation
                    </Typography>
                    <Typography className="text-[0.9rem] md:text-[1.1rem] text-[#575757] font-sans">
                      Are you sure you want to Cancel the Order?
                    </Typography>

                    <div className="flex gap-4 mt-1 md:mt-6">
                      <button
                        className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-[#FFFFFF] text-[1rem] text-[#8C8C8C] border-[1.3px] border-[#8C8C8C] py-[0.5rem]  rounded-[4px]"
                        type="submit"
                        onClick={() => setCancelledModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-[#F15E7E] text-[1rem] text-[#FAFAFA] py-[0.5rem] rounded-[4px]"
                        type="submit"
                        onClick={HandleCancel}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </Modal>
                <button
                  className="my-[0.5rem] py-[0.6rem] px-[1.5rem] font-sans bg-[#F15E7E] text-[white] w-[48%] sm:w-[auto] text-[0.8rem] rounded"
                  onClick={() => setRescheduleModal(true)}
                >
                  Reschedule
                </button>
                <Modal
                  open={RescheduledModal}
                  centered={true}
                  closeIcon={<></>}
                  footer={null}
                  width={
                    window.innerWidth > 850
                      ? "29%"
                      : window.innerWidth > 500
                      ? "43%"
                      : "80%"
                  }
                >
                  <div className="flex flex-col items-center justify-center">
                    <InfoCircleOutlined className="text-[2.5rem] md:text-[3rem] mt-[0.2rem] md:mt-[1.2rem] rotate-180	" />
                    <Typography className="mt-1 md:mt-3 text-[1rem] md:text-[1.3rem] text-[#212121] font-semibold font-sans">
                      Confirmation
                    </Typography>
                    <Typography className="text-[0.9rem] md:text-[1.1rem] text-[#575757] font-sans">
                      Are you sure you want to Reschedule?
                    </Typography>

                    <div className="flex gap-4 mt-1 md:mt-6">
                      <button
                        className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-[#FFFFFF] text-[1rem] text-[#8C8C8C] border-[1.3px] border-[#8C8C8C] py-[0.5rem]  rounded-[4px]"
                        type="submit"
                        onClick={() => setRescheduleModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-[#F15E7E] text-[1rem] text-[#FAFAFA] py-[0.5rem] rounded-[4px]"
                        type="submit"
                        onClick={handleReschedule}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            )}
        </div>
        {isPrevious && status !== "cancelled" ? (
          <div className=" mt-2 border-t border-[#D9D9D9]">
            <div className="flex justify-between pt-2 items-center">
              <div onClick={handleFeedback} className="cursor-pointer">
                <Rate
                  disabled={true}
                  className="cursor-pointer"
                  value={rating}
                />
              </div>
              <Typography className="font-sans font-semibold mt-1">
                Rate Service
              </Typography>
            </div>
            <div className="flex items-center justify-between -mt-2">
              <Typography className="text-[#595959] mt-4">
                Having issues with this order
              </Typography>
              <a
                className="mt-4 flex items-center justify-between gap-2"
                href="https://api.whatsapp.com/send/?phone=917834811114&text=Hi+there&type=phone_number&app_absent=0"
                target="_blank"
              >
                <WhatsAppOutlined />
                <Typography className="mt-1">Need Help?</Typography>
              </a>
            </div>
          </div>
        ) : (
          <></>
        )}
        {message !== undefined && (
          <div className="border-t-2 mt-4 flex items-center justify-between">
            <Typography className="text-[#595959] mt-4">{message}</Typography>
            <a
              className="mt-4 flex items-center justify-between gap-2"
              href="https://api.whatsapp.com/send/?phone=7303533988&text=Hi+there&type=phone_number&app_absent=0"
              target="_blank"
            >
              <WhatsAppOutlined />
              <Typography className="mt-1">Need Help?</Typography>
            </a>
          </div>
        )}
      </div>
      <Details
        openModal={isModalOpen}
        setOpenModal={setisModalOpen}
        HandleClose={handleClose}
        orderId={orderId}
        dateAndTime={dateAndTime}
        price={price}
        address={address}
        patients={patients}
        serviceType={serviceType}
        title={title}
        transactionID={transactionID}
        netPrice={netPrice}
      />
      <Reshedule openModal={isresModalOpen} />
      <CancelModal openModal={isCancelOpen} />
      <Feedback
        openModal={isFeedbackOpen}
        HandleClose={handleFeedback}
        setOpenModal={setIsFeedbackOpen}
        orderId={orderId}
        setIsChanged={setIsChanged}
        isChanged={isChanged}
      />
    </>
  );
};

export default OrderCard;
