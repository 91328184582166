import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { getLandingConsultant } from "../../service/homeApi";

const SpecialConsult = ({ isActive }) => {
  const [consultantList, setConsultantList] = useState([]);

  const getConsultantList = async () => {
    try {
      const response = await getLandingConsultant();
      setConsultantList(response?.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    getConsultantList();
  }, []);

  
  const navigate = useNavigate();

  return (
    <div className="group">
      <Typography
        className={`hover:border-b-2 ${
          isActive && "border-b-2"
        } border-black text-md font-semibold cursor-pointer text-base group flex items-center h-10 font-sans`}
        onClick={() => navigate("/special-consult")}
      >
        Specialist Consults
      </Typography>

      <div className="absolute left-0 hidden group-hover:block top-10 w-full border z-50 bg-white shadow-lg  py-4 pl-6 ">
        <Row gutter={16} offset={20}>
          {consultantList?.payload?.map((data) => (
            <Col span={4} key={data?._id} className="mb-4">
              <Typography
                className="text-[#8C8C8C] text-sm cursor-pointer hover:text-[1rem] hover:text-black font-sans transition-all"
                onClick={() => {
                  navigate("/special-consult", {
                    state: { id: data._id, name: data.name },
                  });
                }}
              >
                {data?.name}
              </Typography>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default SpecialConsult;
