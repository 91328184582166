import React from "react";
import { Col, Row, Typography } from "antd";
import DiagnosticTestsDropdown from "../DropDown/DiagnosticTestsDropdown";
import CompanySponsoredDropdown from "../DropDown/CompanySponsoredDropdown";
import SpecialConsult from "../DropDown/SpecialConsult";
import PackageProfileDropdown from "../DropDown/PackageProfileDropdown";
import { useLocation } from "react-router-dom";
import VaccineDropdown from "../DropDown/VaccineDropdown";
import PharmacyDropdown from "../DropDown/PharmacyDropdown";

const NavbarTabs = () => {
  const location = useLocation();

  const isActive = (route) => {
    if (location.pathname.split("/").includes(route)) {
      return true;
    }
    return false;
  };

  return (
    <Row>
      <Col span={20} offset={2}>
        <div className="h-10 lg:flex items-center gap-7 justify-start hidden mb-2">
          <DiagnosticTestsDropdown isActive={isActive("diagnostic-tests")} />
          <SpecialConsult isActive={isActive("special-consult")} />
          <PackageProfileDropdown isActive={isActive("packages")} />
          <VaccineDropdown isActive={isActive("vaccine")} />
          <CompanySponsoredDropdown
            isActive={isActive("free-special-consult")}
          />
          <div className="group">
            <a
              className="text-md font-semibold font-sans cursor-pointer text-base group flex items-center h-10 hover:border-b-2 hover:text-black hover:border-black"
              href="https://hclhealthcare.karexpert.com/account-management/login"
              target="_blank"
            >
              Patient Portal
            </a>
          </div>
          <PharmacyDropdown isActive={isActive("pharmacy-voucher")} />
          {/* <OtherService /> */}
        </div>
      </Col>
    </Row>
  );
};

export default NavbarTabs;
