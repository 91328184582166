import { WarningOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <WarningOutlined className="text-red-300 text-[60px]" />
      <Typography className="h-20 text-[30px] text-red-300 font-sans">404 Not Found</Typography>
      <Button className="font-sans" onClick={() => navigate("/")}>Go Back</Button>
    </div>
  );
};

export default PageNotFound;
