import React, { useState } from "react";
function VaccinationsCard({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={`relative flex flex-col items-center  w-[90%] md:w-[30%]  bg-white shadow-md mb-10 ${
          isOpen ? "" : "h-[20rem] max-h-[20rem]"
        } transition-all `}
      >
        <div className="bg-blue-800 rounded-full h-[3.5rem] w-[3.5rem] items-center justify-center flex  mt-[-1.5rem] absolute z-50">
          <img
            src={`${data.Image}`}
            alt="card Image"
            className="h-[2rem] w-[2rem]"
          />
        </div>
        <div className="mt-[4rem] font-semibold">{`${data.Title}`}</div>
        <div className="p-4 overflow-hidden">
          {data?.CardContent?.map((e) => (
            <div className="flex gap-2 my-2">
              <div className="w-[4%] mt-[0.1rem]">
                <img
                  src={
                    e?.imgType
                      ? "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/Frame%202610637.svg"
                      : ""
                  }
                  alt="dos logo"
                  className="w-full"
                />
              </div>{" "}
              <div className="w-[96%]">
                <p className="text-[#595959]">{`${e?.content}`}</p>
              </div>
            </div>
          ))}
        </div>
        {isOpen ? (
          <p
            className="text-red-600  bg-white w-full py-4 cursor-pointer bottom-0 left-0 text-center"
            onClick={() => setIsOpen(false)}
          >
            View Less
          </p>
        ) : (
          <p
            className="text-red-600 absolute bg-white w-full py-4 cursor-pointer bottom-0 left-0 text-center"
            onClick={() => setIsOpen(true)}
          >
            Read More
          </p>
        )}
      </div>
    </>
  );
}

export default VaccinationsCard;
