import React, { useEffect, useState } from "react";
import NavbarTabs from "../../../components/CommonComponents/NavbarTabs";
import Footer from "../../../components/CommonComponents/Footer";
import SpecialistConsultCard from "../../../components/OurSolution/SpecialistConsultCard";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Pagination,
  Row,
  Space,
  Typography,
} from "antd";
import {
  CloseCircleOutlined,
  FilterOutlined,
  LeftOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";
import {
  getAllConsultant,
  getLandingConsultant,
} from "../../../service/homeApi";
import NoLab from "./NoLab";
import Loader from "../../../components/CommonComponents/Loader";
import doctorConsult from "../../../assets/images/doctorConsult.svg";
import SearchBarMobile from "../../../components/CommonComponents/SearchBarMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  setConsultantCategoryId,
  setConsultantPageNo,
  setConsultantSelectedTab,
} from "../../../redux/SpecialistConsultant/specialistConsultantVarSlice";

const SpecialConsultant = () => {
  const { pincode } = useSelector((state) => state.changePincode);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [consultants, setConsultants] = useState();
  const [totalConsultants, setTotalConsultants] = useState();
  const [fetchedCategories, setfetchedCategories] = useState();
  const [Categories, setCategories] = useState();

  const { pageNo, selectedTab, categoryId } = useSelector(
    (state) => state.SpecialistConsltantVariables
  );

  const [selectedItem, setSelectedItem] = useState("");
  const [open, setOpen] = useState(false);
  const [remove, setRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(9);

  const items = [
    { label: "Tele-consult", value: "virtual", key: "0" },
    { label: "In-Clinic", value: "inclinic", key: "1" },
    { label: "Both", value: "both", key: "2" },
  ];

  useEffect(() => {
    if (state && !state.isFreeConsultant) {
      dispatch(setConsultantSelectedTab(state.name));
      dispatch(setConsultantCategoryId(state.id));
    }
  }, [state]);

  const params = useParams();
  const allfetchedCategories = [{ name: "All", _id: "1234" }];

  const scrollLeft = () => {
    document.getElementById("section").scrollLeft += 400;
  };
  const scrollRight = () => {
    document.getElementById("section").scrollLeft -= 400;
  };

  useEffect(() => {
    getConsultCategoryies();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getConsultant();
  }, [selectedItem, remove, pageNo, limit, categoryId, params, pincode]);

  useEffect(() => {
    fetchedCategories?.map((data) => {
      allfetchedCategories.push(data);
    });

    setCategories(allfetchedCategories);
  }, [fetchedCategories]);

  const getConsultCategoryies = async () => {
    setIsLoading(true);
    try {
      const resp = await getLandingConsultant();
      setfetchedCategories(resp?.data?.payload);
    } catch (error) {}
    setIsLoading(false);
  };

  const onChange = (page, newPageSize) => {
    setLimit(newPageSize);
    dispatch(setConsultantPageNo(page));
  };

  const getConsultant = async () => {
    setIsLoading(true);
    try {
      const response = await getAllConsultant(
        pageNo,
        state?.isFreeConsultant ? state?.id : categoryId,
        selectedItem,
        limit,
        state?.isFreeConsultant ? "FREE_CONSULTANT" : ""
      );
      setConsultants(response?.data?.payload?.consultants);
      setTotalConsultants(response?.data?.payload?.totalConsultants);
    } catch (error) {}
    setIsLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Row>
      <Col className="min-h-[95vh] w-full">
        <NavbarTabs />

        {!state?.isFreeConsultant && (
          <>
            <Row className="pt-6 mb-4 border-t-2">
              <Col
                span={window.innerWidth < 640 ? 22 : 20}
                offset={window.innerWidth < 640 ? 1 : 2}
              >
                <div className=" sm:hidden mb-6">
                  <SearchBarMobile compId="landingpage-location" />
                </div>
                <div className="flex md:h-9 w-full ">
                  <div className="w-[85%] flex justify-between items-center ">
                    <div className="md:flex items-center hidden">
                      <LeftOutlined
                        onClick={scrollRight}
                        className="text-[#1C1B1F] mr-2 border-[#928f8f] border rounded-full p-1 text-[0.7rem]"
                      />
                    </div>

                    <ul
                      className="relative grid grid-flow-col text-center overflow-y-scroll no-scrollbar md:overflow-hidden w-[95%]"
                      id="section"
                    >
                      {Categories?.map((e, key) => (
                        <li
                          className={
                            selectedTab === e.name
                              ? "items-center justify-center flex w-max bg-[#FBF0F2] border-b-2 border-[#F15E7E] rounded-t-md cursor-pointer font-sans px-6 py-2 md:py-1"
                              : "hover:border-b-2 hover:border-black cursor-pointer font-sans px-6 py-2 md:py-1"
                          }
                          key={key}
                          style={{
                            display: "inline-block",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => {
                            dispatch(setConsultantSelectedTab(e.name));
                            dispatch(
                              setConsultantCategoryId(
                                e.name === "All" ? "" : e._id
                              )
                            );
                          }}
                        >
                          <div
                            className="text-[0.875rem] md:text-[1rem] hover:text-black text-[#595959]"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {e.name}
                          </div>
                        </li>
                      ))}
                    </ul>

                    <div className="md:flex items-center hidden">
                      <RightOutlined
                        onClick={scrollLeft}
                        className="text-[#1C1B1F] ml-2 border-[#928f8f] border rounded-full p-1 text-[0.7rem]"
                      />
                    </div>
                  </div>
                  <div className="lg:w-[11.7%] md:w-[20%] w-[11.7%]">
                    {window.innerWidth > 640 ? (
                      <Dropdown
                        menu={{
                          items: items.map((item) => ({
                            ...item,
                            style: {
                              color: "#595959",
                              backgroundColor:
                                item.value === selectedItem ? "#FBF0F2" : "",
                            },
                            onClick: () =>
                              setSelectedItem(
                                item.label === "All" ? "" : item.value
                              ),
                          })),
                          style: { marginTop: -3, border: "1px solid #D9D9D9" },
                        }}
                        trigger={["click"]}
                        className="flex items-center px-3 md:border border-[#D9D9D9] rounded-md absolute right-0"
                      >
                        <div
                          onClick={(e) => e.preventDefault()}
                          className="flex items-center justify-between"
                        >
                          <Space className="text-[#595959] font-semibold text-xs w-32 h-8 cursor-pointer flex justify-between">
                            <div className="flex items-center justify-between gap-2 text-base text-[#595959] font-normal font-sans">
                              Filter
                              {selectedItem !== "" && (
                                <Typography className="rounded-full px-[0.4rem] bg-[#FBF0F2] text-[#F15E7E] text-sm font-normal font-sans">
                                  1
                                </Typography>
                              )}
                            </div>
                            <DownOutlined className=" text-[0.7rem] flex text-center " />
                          </Space>
                        </div>
                      </Dropdown>
                    ) : (
                      <>
                        <div>
                          <FilterOutlined
                            className="mb-1 absolute right-1 bottom-0 text-xl border-l-2 pl-2 text-[#8C8C8C]"
                            onClick={() => setOpen(true)}
                          />
                          {selectedItem !== "" && (
                            <Typography className="rounded-full bg-[#F15E7E] text-5xl border relative w-2 h-2 top-3 -right-7 font-sans"></Typography>
                          )}
                        </div>
                        <Drawer
                          title={
                            <Typography className="font-semibold text-xl">
                              Filter
                            </Typography>
                          }
                          className="rounded-t-lg"
                          open={open}
                          placement={"bottom"}
                          closeIcon={
                            <CloseCircleOutlined
                              alt="Close"
                              className="absolute right-4 top-[3%] text-xl text-[#262626]"
                              onClick={handleCancel}
                            />
                          }
                          height={"53%"}
                          key={"right"}
                          footer={[]}
                        >
                          <div className="flex flex-col items-center justify-between -mt-2">
                            {items.map((e, key) => {
                              return (
                                <div className="w-full">
                                  {e.value === selectedItem ? (
                                    <Typography
                                      className="text-[#595959] cursor-pointer p-3 hover:bg-[#F0F0F0] bg-[#FBF0F2] w-full font-semibold text-base font-sans"
                                      key={key}
                                      onClick={() => {
                                        setSelectedItem(
                                          e.label === "All" ? "" : e.value
                                        );
                                        setOpen(false);
                                      }}
                                    >
                                      {e.label}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      className="text-[#595959] cursor-pointer p-3 hover:bg-[#F0F0F0] w-full text-base font-sans"
                                      key={key}
                                      onClick={() => {
                                        setSelectedItem(
                                          e.label === "All" ? "" : e.value
                                        );
                                        setOpen(false);
                                      }}
                                    >
                                      {e.label}
                                    </Typography>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </Drawer>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {selectedItem !== "" && (
                <Col
                  span={window.innerWidth < 640 ? 22 : 20}
                  offset={window.innerWidth < 640 ? 1 : 2}
                >
                  <Button
                    type="text"
                    className="ml-1 h-6 flex items-center gap-2 text-[#595959] bg-[#F0F0F0] hover:text-[#595959] cursor-auto border border-[#BFBFBF] rounded-sm px-3"
                  >
                    <Typography className="text-sm font-light capitalize">
                      {selectedItem === "inclinic"
                        ? "In-Clinic"
                        : selectedItem === "virtual"
                        ? "Tele-consult"
                        : "Both"}
                    </Typography>
                    <CloseCircleOutlined
                      className="cursor-pointer text-xs pb-1"
                      onClick={() => setSelectedItem("")}
                    />
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
        <Row className="mt-2 mb-4">
          <Col
            span={window.innerWidth < 640 ? 22 : 20}
            offset={window.innerWidth < 640 ? 1 : 2}
            className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 sm:justify-start justify-center"
          >
            {consultants?.length !== 0 ? (
              consultants?.map((e, key) => {
                return (
                  <>
                    <SpecialistConsultCard
                      TestImage={doctorConsult}
                      title={e?.name}
                      CompanyImage={e?.CompanyImage}
                      DoctorTitle={e?.category?.name}
                      content={e?.description}
                      SamplingType={e?.SamplingType}
                      discountPrice={e?.price}
                      price={e?.originalPrice}
                      height={e?.height}
                      width={e?.width}
                      rating={e?.rating}
                      reviewCount={e?.reviewCount}
                      discountPercentage={e?.discountPercentage}
                      key={key}
                      area={e?.address?.area}
                      buildingName={e?.address?.buildingName}
                      city={e?.address.city}
                      sId={e?._id}
                      serviceMode={e?.serviceMode}
                      experience={e?.experiance}
                      language={e?.language}
                      distance={e?.distance}
                      inCart={e?.inCart}
                      productId={e?.productId}
                      remove={remove}
                      setRemove={setRemove}
                    />
                  </>
                );
              })
            ) : (
              <NoLab />
            )}
          </Col>
        </Row>
        <Row className="pt-6 mb-4">
          <Col
            span={window.innerWidth < 640 ? 22 : 20}
            offset={window.innerWidth < 640 ? 1 : 2}
          >
            {totalConsultants > 0 && (
              <Pagination
                current={pageNo}
                onChange={onChange}
                total={totalConsultants}
                className="flex items-end justify-end"
                pageSizeOptions={[9, 15, 30, 60]}
                onShowSizeChange={onChange}
                defaultPageSize={9}
                showSizeChanger={true}
              />
            )}
          </Col>
        </Row>
      </Col>
      {isLoading && <Loader />}
      <Footer />
    </Row>
  );
};

export default SpecialConsultant;
