import React from "react";
import { image72 } from "../../assets/images";
import { useEffect, useState } from "react";
import {
  Typography,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Modal,
  Carousel,
  getCart,
} from "antd";
import moment from "moment";
import "moment/locale/en-gb";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { changeSlot, updatePatient } from "../../service/cartApi";
import { getDate, formateTime } from "../CommonComponents/DateAndTime";
import dayjs from "dayjs";

const EditPatientDetailModal = ({
  openModal,
  handleModal,
  patientDetails,
  availableSlots,
  slotTimeId,
  slotDetailsId,
  type,
  productId,
  getCart,
  isManage,
  getPatient,
  labId,
}) => {
  const [selectedSlot, setSelectedSlot] = useState();

  const handleChangeSlot = async (slot, day) => {
    let payload = {
      date: day?.date,
      newSlotId: day?._id,
      newSlotTimingId: slot?._id,
      startTime: slot?.startTime,
      endTime: slot?.endTime,
      productId: productId,
      type: type,
      labId: labId,
    };
    try {
      const response = await changeSlot(slotDetailsId, payload);
      getCart();
    } catch (error) {}
  };

  useEffect(() => {
    setSelectedSlot(slotTimeId);
  }, [slotTimeId]);

  const [form] = Form.useForm();
  useEffect(() => {
    if (patientDetails) {
      patientDetails.dob = dayjs(patientDetails.dob);
    }
    form.resetFields();
    form.setFieldsValue(patientDetails);
  }, [patientDetails?.firstName]);

  const handleSlot = (dayId, slotId) => {
    setSelectedSlot(slotId);
  };

  // function to update patient api

  const callUpdatePatient = async (patientId, data) => {
    try {
      const response = await updatePatient(patientId, data);
      if (response) {
      }
      if (getPatient) {
        getPatient();
      }
      getCart();
    } catch (error) {}
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
          height: 50,
        }}
        defaultValue={"+91"}
        className="flex justify-center items-center font-sans"
      >
        <Select.Option value="91">+91</Select.Option>
        <Select.Option value="86">+86</Select.Option>
        <Select.Option value="87">+87</Select.Option>
      </Select>
    </Form.Item>
  );

  const ArrowLeft = ({ onClick }) => (
    <button onClick={onClick}>
      <LeftOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 left-4 z-10 text-[0.7rem] border-2" />
    </button>
  );
  const ArrowRight = ({ onClick }) => (
    <button onClick={onClick}>
      <RightOutlined className="text-black  rounded-full p-[0.3rem] absolute top-5 right-5 z-10 text-[0.7rem] border-2" />
    </button>
  );

  const carouselSettings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const submitForm = (values) => {
    callUpdatePatient(patientDetails._id, values);
    handleModal();
  };

  return (
    <Modal
      title="Edit"
      open={openModal}
      centered={true}
      closeIcon={<img src={image72} onClick={handleModal} alt="Close" />}
      footer={
        <button
          className="my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2.8rem] rounded-[4px]"
          type="submit"
          form="myform"
        >
          Save
        </button>
      }
    >
      <div className="py-2 border-t-2 overflow-y-scroll no-scrollbar max-h-[60vh] ">
        <div className="py-[0.6rem] flex items-center">
          <Typography className="font-semibold text-[1rem] sm:text-[0.8rem] ">
            Patient Detail
          </Typography>
          <div className="flex-grow h-[1.5px] bg-[#ececec] m-[0.5rem]"></div>
        </div>
        <Form form={form} onFinish={submitForm} id="myform">
          <div>
            <div className="flex  w-[100%] justify-between ">
              {/* ----------------------- First Name ----------------------*/}
              <div className="w-[48%]">
                <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
                  First Name
                </Typography>

                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                    {
                      min: 3,
                      message: "Must be at least 3 characters long!",
                    },
                    {
                      max: 35,
                      message: "Must not exceed 35 characters!",
                    },
                    {
                      pattern: /^(?!\s*$).+/,
                      message: "cannot be empty",
                    },
                  ]}
                  className="mb-2"
                >
                  <Input
                    placeholder="First Name"
                    className="rounded-md py-[0.5rem] font-sans"
                  />
                </Form.Item>
              </div>
              {/* ----------------------------------------------------------- */}

              {/* ----------------------- Last Name ----------------------*/}
              <div className="w-[48%]">
                <Typography className="text-[#595959] text-[0.85rem] mb-[8px] font-sans">
                  Last Name
                </Typography>

                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                    {
                      min: 3,
                      message: "Must be at least 3 characters long!",
                    },
                    {
                      max: 35,
                      message: "Must not exceed 35 characters!",
                    },
                    {
                      pattern: /^(?!\s*$).+/,
                      message: "cannot be empty",
                    },
                  ]}
                  className="mb-2"
                >
                  <Input
                    placeholder="Last Name"
                    className="rounded-md py-[0.5rem] font-sans"
                  />
                </Form.Item>
              </div>
              {/* ----------------------------------------------------------- */}
            </div>

            {/* ------------------------Date picker DOB------------------------- */}
            <div>
              <Typography className="text-[#595959] text-[0.85rem] font-sans">
                DOB
              </Typography>
              <Form.Item
                name="dob"
                className="mb-2"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <DatePicker
                  className="w-[100%] py-[0.5rem] font-sans"
                  // disabled={true}
                  inputReadOnly={true}
                  defaultValue={moment()}
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </Form.Item>
            </div>

            {/*-------------------- Phone Number -------------------------- */}
            <div>
              <Typography className="text-[#595959] text-[0.85rem] font-sans">
                Phone No.
              </Typography>
              <Form.Item
                name="phoneNumber"
                className="mb-0 font-sans"
                rules={[
                  {
                    required: true,
                    message: "Please input your Phone Number!",
                  },
                  {
                    pattern: new RegExp(/^[6-9]\d{9}$/i),
                    message: "Please Enter a Valid Phone Number",
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  className="w-[100%] py-[0.5rem] rounded-sm font-sans "
                  placeholder="Phone No."
                />
              </Form.Item>
            </div>

            {/*---------------------- Gender -------------------------- */}

            <Form.Item
              className="mb-3 font-sans"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "please select gender",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="male" defaultChecked={true}>
                  {" "}
                  <Typography className="text-[0.85rem] font-sans">
                    Male
                  </Typography>
                </Radio>
                <Radio value="female">
                  <Typography className="text-[0.85rem] font-sans">
                    Female
                  </Typography>{" "}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Form>
        <>
          {!isManage && (
            <>
              <div className="pt-[0.6rem] flex items-center">
                <Typography className="font-semibold text-[1rem] sm:text-[0.8rem] font-sans">
                  Selected Slot
                </Typography>
                <div className="flex-grow h-[2px] bg-[#ececec] m-[0.5rem]"></div>
              </div>
              <div>
                <Carousel slidesToShow={1} {...carouselSettings}>
                  {availableSlots?.map((day) => (
                    <div className="h-[20rem]">
                      <div className="flex justify-center items-center text-[1rem] font-semibold font-sans">
                        {getDate(day?.date)}
                      </div>
                      <div className=" mt-6 w-[100%] flex flex-wrap gap-5 ">
                        {day.timeSlots.map((slot) => {
                          return (
                            <>
                              {slot?.isBooked ? (
                                <>
                                  <button
                                    className="w-[30%] py-3  flex justify-center it ems-center h-[3rem] rounded-[8px] timeSlots font-sans bg-[#F4F4F4] text-[#d7d7d7]   "
                                    id={`${slot?._id}`}
                                  >
                                    {formateTime(slot.startTime)}
                                  </button>
                                </>
                              ) : (
                                <>
                                  {selectedSlot === slot?._id ? (
                                    <button
                                      className="w-[30%] py-3 border-[2px]  flex justify-center items-center h-[3rem] rounded-[8px] timeSlots border-[#F15E7E] text-[#F15E7E] font-sans"
                                      id={`${slot?._id}`}
                                    >
                                      {formateTime(slot.startTime)}
                                    </button>
                                  ) : (
                                    <button
                                      className="w-[30%] py-3 border-[2px]  flex justify-center it ems-center h-[3rem] rounded-[8px] timeSlots font-sans "
                                      onClick={() => {
                                        setSelectedSlot(slot?._id);
                                        handleChangeSlot(slot, day);
                                      }}
                                      id={`${slot?._id}`}
                                    >
                                      {formateTime(slot.startTime)}
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </>
          )}
          <style jsx>
            {`
              .ant-input {
                padding: 9px 11px;
              }
            `}
          </style>
        </>
      </div>
    </Modal>
  );
};

export default EditPatientDetailModal;
