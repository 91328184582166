import { axiosInstance, axiosInstanceTocart } from "../utils/axios";

export const VerifyNumber = async (phoneNumber) => {
  try {
    const response = await axiosInstance.post(
      "/api/v1/account/send-phone-verification-otp",
      phoneNumber
    );
    if (response.success) {
      return response;
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyOTP = async (otp) => {
  try {
    const response = await axiosInstance.post(
      "/api/v1/account/verify-phone-otp",
      otp
    );
    if (response.success) {
      return response;
    }
    return response;
  } catch (error) {
    return error;
  }
};
