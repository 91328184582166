import axios from "axios";
import { BASE_URL } from "../utils/globalVariable";
import { axiosInstance } from "../utils/axios";

/*************** Slot (Diagnostic Test and Package Slots) ***************************/

export const getTestSlots = async (categoryId, labId, type) => {
  try {
    const pincode = localStorage.getItem("pincode");
    const response = await axiosInstance.get(
      `/api/v1/slot/test?category=${categoryId}&lab=${labId}&pincode=${pincode}&type=${type}`
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};

/*************** Slot (Consultant Test) ***************************/
export const getConsultantSlots = async (consultantId) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/slot/consultant?consultant=${consultantId}`
    );
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {}
};
