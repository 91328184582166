import { Modal, Typography } from "antd";
import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

const FreeForYouModal = ({ isOpen, setIsopen, onConfirm }) => {
  return (
    <Modal
      open={isOpen}
      centered={true}
      closeIcon={
        <div onClick={() => setIsopen(false)} className="-mt-4">
          <CloseCircleOutlined />
        </div>
      }
      footer={null}
    >
      <div className="mt-6">
        <Typography className="sm:text-[1rem] font-sans sm:mb-4 ">
          If you’re an employee, book this consultation for free, Call
          7217890075. Else, you may continue with standard charges.
        </Typography>
        <div className="flex gap-4 justify-end ">
          <button
            className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-[#FFFFFF] text-[1rem] text-[#8C8C8C] border-[1.3px] border-[#8C8C8C] py-[0.4rem] sm:py-[0.5rem]  rounded-[4px]"
            type="submit"
            onClick={() => setIsopen(false)}
          >
            Cancel
          </button>
          <button
            className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-[#F15E7E] text-[1rem] text-[#FAFAFA] sm:py-[0.5rem] rounded-[4px]"
            type="submit"
            onClick={onConfirm}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FreeForYouModal;
