import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

const initialState = {
  Offers: [],
  loading: false,
  error: null,
};

//Read Action
export const getOffers = createAsyncThunk(
  "getOffers",
  async (args, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`api/v1/offer/cart`);
        if (response.success) {
          return response;
        }
        return response;
      } catch (error) {
        return rejectWithValue(error); 
      }
    }
);

//cart Action Slice

export const OfferDetail = createSlice({
  name: "Offers",
  initialState,
  reducers: {},
  extraReducers: {
    //show user
    [getOffers.pending]: (state) => {
      state.loading = true;
    },
    [getOffers.fulfilled]: (state, action) => {
      state.loading = false;
      state.Offers = action.payload?.data?.payload?.offers;
    },
    [getOffers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },
  },
});

export default OfferDetail.reducer;

