import { axiosInstance } from "../utils/axios";

/*************** add address***************************/

export const addAddress = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/v1/address`, data);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
  }
};

/*************** get address ***************************/

export const getAddress = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/address`);
    if (response.success) {
      return response.data;
    }
    return response;
  } catch (error) {
  }
};

/*************** update address ***************************/

export const updateAddress = async (addressId, data) => {
  try {
    const response = await axiosInstance.patch(`/api/v1/address/${addressId}`, data);
    if (response.success) {
      return response.message;
    }
    return response;
  } catch (error) {
  }
};

/*************** delete address ***************************/

export const deleteAddress = async (addressId) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/address/${addressId}`);
    if (response.success) {
      return response;
    }
    return response;
  } catch (error) {
  }
};

/*************** delete address ***************************/

export const selectAddress = async (addressId) => {
  try {
    const response = await axiosInstance.post(
      `/api/v1/cart/add-address/${addressId}`
    );
    if (response.success) {
      return response;
    }
    return response;
  } catch (error) {
  }
};
