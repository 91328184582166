import React from "react";
import PagesHeader from "../../../components/CommonComponents/PagesHeader";
import { useLocation } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import {
  AuditOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  StarFilled,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import ContainerWithShadow from "../../../components/CommonComponents/ContainerWithShadow";
import { useState } from "react";
import Footer from "../../../components/CommonComponents/Footer";

const ViewDetailPopularPackageLab = () => {
  const { state } = useLocation();
  const [isViewAllTests, setIsViewAllTests] = useState(false);

  const handleTestView = () => {
    setIsViewAllTests(!isViewAllTests);
  };

  return (
    <div>
      <PagesHeader title="Package Details" />

      <Row className="mt-2 mb-[4rem  ] sm:mb-[12rem]">
        <Col
          span={window.innerWidth < 640 ? 22 : 20}
          offset={window.innerWidth < 640 ? 1 : 2}
          className="flex flex-wrap sm:justify-start gap-4 justify-center"
        >
          <div className="flex flex-col sm:flex-row sm:justify-between w-[100%] mt-4  ">
            <div className="w-[100%] sm:w-[55%] border border-gray-5 p-[1rem] shadow-lg sm:h-[23rem] rounded-md">
              <div className="flex justify-start items-center border-b-2 pb-4">
                <Typography className="text-[1.1rem] font-medium">
                  {state?.d?.lab?.name}
                </Typography>
              </div>
              <Typography className="text-sm my-4">
                {state?.pData?.description}
              </Typography>
              <div className="h-10 mb-2 my-2 flex items-center justify-between">
                <img src="" alt="lab logo" />
                <div className="flex items-center gap-1">
                  <StarFilled className="text-[#F15E7E]" />
                  <Typography className="text-base">
                    {state?.d?.lab?.rating}
                  </Typography>
                  <Typography className="text-[#8C8C8C] text-sm">
                    ({300}+ Reviews)
                  </Typography>
                </div>
              </div>
              <div className="mb-10">
                <div className="flex items-center justify-between mt-6">
                  <div className="flex items-center gap-2">
                    <EnvironmentOutlined className="text-[#0057A3]" />
                    <Typography className="text-sm">
                      Lab Address:{" "}
                      <strong>
                        {" "}
                        {state?.d?.lab?.address?.area},{" "}
                        {state?.d?.lab?.address?.buildingName},{" "}
                        {state?.d?.lab?.address?.city}.
                      </strong>
                    </Typography>
                  </div>
                </div>
                <div className="flex items-center gap-2 mt-4">
                  <ExperimentOutlined className="text-[#0057A3]" />
                  <Typography className="text-sm">
                    Service Type: <strong> Sampling</strong>
                  </Typography>
                </div>
                <div className="flex items-center gap-2 mt-4">
                  <FieldTimeOutlined className="text-[#0057A3]" />
                  <Typography className="text-sm">
                    Test Duration: <strong>1+ hr</strong>
                  </Typography>
                </div>
                <div className="flex items-center gap-2 mt-4">
                  <ExclamationCircleOutlined className="text-[#0057A3]" />
                  <Typography className="text-sm">
                    Requirement: <strong>0 - 12 hrs Fasting</strong>
                  </Typography>
                </div>
                <div className="flex items-center gap-2 mt-4">
                  <AuditOutlined className="text-[#0057A3]" />
                  <Typography className="text-sm">
                    Report Availability: <strong>Within 24hrs</strong>
                  </Typography>
                </div>
              </div>
            </div>

            <div className="w-[100%] sm:w-[43%] border border-gray-5 p-[1rem] shadow-lg rounded-md mt-6 sm:mt-0">
              <div className="flex justify-between border-b-2 pb-4">
                <Typography className="text-[1.1rem] font-medium">
                  Total Included Tests
                </Typography>
                <Typography className="text-[1.1rem] text-[#0057A3] font-medium">
                  {state?.pData?.tests.length} Tests
                </Typography>
              </div>

              {isViewAllTests ? (
                <>
                  {state?.pData?.tests.map((test) => {
                    return (
                      <>
                        <Typography className="my-2 ml-4">
                          {test.name}
                        </Typography>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {state?.pData?.tests.slice(0, 10).map((test) => {
                    return (
                      <>
                        <Typography className="my-2 ml-4">
                          {test.name}
                        </Typography>
                      </>
                    );
                  })}
                </>
              )}

              {state?.pData?.tests.length > 10 && !isViewAllTests ? (
                <>
                  <div className="flex items-center justify-between mt-4">
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      View All Tests
                    </Typography>
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      <DownOutlined />
                    </Typography>
                  </div>
                </>
              ) : (
                <></>
              )}

              {!isViewAllTests ? (
                <></>
              ) : (
                <>
                  <div className="flex items-center justify-between mt-4">
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      View Less Tests
                    </Typography>
                    <Typography
                      className="text-[#0057A3] text-[1rem] font-[500] cursor-pointer"
                      onClick={handleTestView}
                    >
                      <UpOutlined />
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default ViewDetailPopularPackageLab;
