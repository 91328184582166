import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "Offer Applied",
  percentage: 0,
  isDifferentLab: false,
};

const offerNameSlice = createSlice({
  name: "appliedName",
  initialState: initialState,
  reducers: {
    addAppliedName: (state, action) => {
      state.name = action.payload;
    },
    addDiscountedPercentage: (state, action) => {
      state.percentage = action.payload;
    },
    addIsDifferentLab: (state, action) => {
      state.isDifferentLab = action.payload;
    },
  },
});

export const { addAppliedName, addDiscountedPercentage, addIsDifferentLab } =
  offerNameSlice.actions;

export default offerNameSlice.reducer;
