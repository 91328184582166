import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userForgotPassword } from "../../service/authApi";
import { toast } from "react-toastify";
import Loader from "../../components/CommonComponents/Loader";

const ForgotPage = () => {
  const navigate = useNavigate();
  const [animation, setAnimation] = useState(false);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [enable, setEnable] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    setEmail(values.email);
    userForgotPasswordFn({ values });
  };

  //Make function of forgot Password
  const userForgotPasswordFn = async ({ values }) => {
    setIsLoading(true);
    const response = await userForgotPassword({ data: values });
    if (response.success) {
      toast.success("A link to your registered email id has been shared.");
      setMessage(true);
    } else {
      toast.info("The email ID is not registered");
    }
    setIsLoading(false);
  };

  const handleNavigate = () => {
    setAnimation(false);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };
  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 10);
  }, []);

  return (
    <div>
      <div
        className="border-t border-[#D9D9D9] w-[100%] bg-black opacity-50 h-[100vh] absolute top-0"
        onClick={handleNavigate}
      ></div>
      <div
        className={`absolute transition-all duration-500 w-full ${
          animation ? "right-0" : "-right-[100%]"
        } md:w-[60%] lg:w-[40%] border h-[100%] top-0 bg-[#FAFAFA] px-10 py-20 z-50`}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="flex h-[90vh] flex-col">
            <div>
              <Typography className="text-2xl text-[#0057A3] flex flex-col items-left justify-between font-sans">
                <div
                  className="flex items-center gap-2 mb-4 cursor-pointer md:hidden"
                  onClick={handleNavigate}
                >
                  <ArrowLeftOutlined className="text-[#8C8C8C] text-lg mb-1" />
                  <Typography className="text-lg text-[#8C8C8C] font-sans">
                    Back
                  </Typography>
                </div>
                <Typography className="text-2xl text-[#0057A3] flex items-center justify-between font-sans">
                  Forgot Password
                  <CloseCircleOutlined
                    onClick={handleNavigate}
                    className="text-[#8C8C8C] cursor-pointer md:block hidden"
                  />
                </Typography>
              </Typography>
            </div>
            <div>
              {message ? (
                <Typography className="text-[#595959] mb-[8px] font-sans mt-6">
                  We have sent a link to <strong>{email}</strong> to reset your
                  password{" "}
                </Typography>
              ) : (
                <div className="flex flex-col justify-between h-[70vh] md:h-[80vh]">
                  <div>
                    <Typography className="text-[#595959] mb-[8px] font-sans mt-10">
                      Email ID
                    </Typography>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined className="mr-1" />}
                        placeholder="Email Id"
                        className="rounded-sm h-10 font-sans"
                        onChange={(event) => setEnable(event.target.value)}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <Button
                      type="default"
                      htmlType="submit"
                      className="login-form-button w-full disabled:bg-[#BFBFBF] bg-[#0057A3] mt-56 rounded-sm h-10 font-sans"
                      disabled={enable === ""}
                    >
                      <Typography className="text-white font-sans">
                        Continue
                      </Typography>
                    </Button>
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default ForgotPage;
