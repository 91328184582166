import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getOtpForLogin, userLogin } from "../../service/authApi";
import Loader from "../../components/CommonComponents/Loader";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/features/auth/authSlice";
import { setUserPhonenumber } from "../../redux/Auth/AuthSlice";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [animation, setAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpTimer, setOtpTimer] = useState(600);
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [reLogin, setReLogin] = useState(false);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const formValues = form.getFieldsValue();
    const isEmailEmpty = !formValues.email?.trim();
    const isPasswordEmpty = !formValues.password?.trim();
    setDisabledSave(hasErrors || isEmailEmpty || isPasswordEmpty);
  };

  function isValidEmail(email) {
    // Regular expression for validating an email address
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email?.trim());
  }

  const onFinish = (values) => {
    setEmail(values.email);
    userloginFn({ values });
  };

  // Make function of login
  const userloginFn = async ({ values }) => {
    setIsLoading(true);
    const response = await userLogin({ data: values });
    dispatch(setToken(response.data.payload.accessToken));

    if (response.status === 200) {
      if (response.data.success) {
        localStorage.setItem("token", JSON.stringify(response.data.payload));
        localStorage.setItem(
          "refreshToken",
          response.data.payload.refreshToken
        );
        toast.success("Welcome to HCL Healthcare E-Health Cart ");

        const cartItem = JSON.parse(localStorage.getItem("cartItem"));

        let checkItem = [];
        if (cartItem) {
          checkItem = Object.keys(cartItem);
        }
        if (checkItem.length > 0) {
          const pathName = localStorage.getItem("redirectPath");
          navigate(pathName, {
            state: {
              id: localStorage.getItem("CurrentLabId"),
              title: localStorage.getItem("CurrentLabTitle"),
            },
            replace: true,
          });
        } else {
          navigate("/");
        }
      }
    } else if (response.status === 206) {
      navigate("/verify-email", {
        state: {
          userId: response.data.payload.userId,
          email: email,
        },
      });
    } else if (response?.status === 429) {
      toast.error("Your password has expired. Please change your password.");
      navigate("/resetPage");
    } else if (response?.status === 403) {
      toast.info(
        "Try again after " +
          Math.round(response?.data?.payload?.timeleft / 60000) +
          " minutes"
      );
      setOtpTimer(Math.round(response?.data?.payload?.timeleft / 1000));
      setReLogin(true);
    } else if (response?.status === 404) {
      toast.info("User doesn't exist");
    } else {
      toast.error("Invalid Credentials");
    }
    setIsLoading(false);
  };

  const handleNavigate = () => {
    setAnimation(false);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 10);
  }, []);

  useEffect(() => {
    if (otpTimer === 0) {
      setReLogin(false);
    }
    const timer = setInterval(() => {
      if (otpTimer > 0 && reLogin) {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [otpTimer]);

  return (
    <div>
      <div
        className="border-t border-[#D9D9D9] lg:w-[100%] bg-black opacity-50 h-[100vh] absolute top-0"
        onClick={handleNavigate}
      ></div>
      <div
        className={`absolute transition-all duration-500 w-full ${
          animation ? "right-0" : "-right-[100%]"
        } md:w-[60%] lg:w-[40%] border top-0 bg-[#FAFAFA] px-10 py-6 md:py-10 h-[100%] z-50 overflow-hidden`}
      >
        <Typography className="mb-[2.5rem] text-2xl text-[#0057A3] flex flex-col items-left justify-between font-sans">
          {" "}
          <div
            className="flex items-center gap-2 mb-4 cursor-pointer md:hidden"
            onClick={handleNavigate}
          >
            {" "}
            <ArrowLeftOutlined className="text-[#8C8C8C] text-lg mb-1" />{" "}
            <Typography className="text-lg text-[#8C8C8C] font-sans">
              Back
            </Typography>{" "}
          </div>{" "}
          <Typography className="text-2xl text-[#0057A3] flex items-center justify-between font-semibold font-sans">
            Log In
            <CloseCircleOutlined
              onClick={handleNavigate}
              className="text-[#8C8C8C] cursor-pointer md:block hidden"
            />{" "}
          </Typography>{" "}
        </Typography>

        <Form
          name="normal_login"
          className="login-form"
          form={form}
          onFieldsChange={handleFormChange}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="h-[85vh] flex  flex-col">
            <Typography className="text-[#595959] mb-[8px] font-sans">
              Email ID
            </Typography>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="mr-1" />}
                placeholder="Enter Email ID"
                className="rounded h-10 font-sans"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Item>

            <Typography className="text-[#595959] mb-[8px] font-sans">
              Password
            </Typography>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter Password"
                prefix={<LockOutlined className="site-form-item-icon mr-1" />}
                className="rounded h-10 font-sans"
              />
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                {reLogin && (
                  <Typography>
                    Try Again After:{" "}
                    <span className="text-[#0057A3]">{`${Math.floor(
                      otpTimer / 60
                    )}:${otpTimer % 60 < 10 ? "0" : ""}${
                      otpTimer % 60
                    } secs`}</span>
                  </Typography>
                )}
              </Form.Item>
              <div className="flex flex-col md:flex-row justify-between gap-1 items-center">
                <span
                  className="login-form-forgot text-[#0057A3] cursor-pointer font-semibold font-sans"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot password?
                </span>
                <Typography className="mt-2 font-semibold font-sans">
                  Don't have an account?{" "}
                  <span
                    href=""
                    className="ml-1 text-[#0057A3] cursor-pointer"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </span>
                </Typography>
              </div>
            </Form.Item>

            <div className="flex flex-col justify-center items-center w-full">
              <Form.Item className="w-full">
                <Button
                  type="default"
                  htmlType="submit"
                  enableButton
                  className="login-form-button w-full disabled:bg-[#BFBFBF] h-10 lg:mt-20  bg-[#0057A3] font-sans rounded-[4px]"
                  disabled={disabledSave || reLogin}
                >
                  <Typography className="text-white font-sans">
                    Login
                  </Typography>
                </Button>
              </Form.Item>
              <p className="font-semibold">OR</p>
              <p className="mt-4 font-semibold">
                Login with{" "}
                <span
                  className="text-[#0057A3] underline cursor-pointer"
                  onClick={() => {
                    if (isValidEmail(form.getFieldValue("email"))) {
                      getOtpForLogin(form.getFieldValue("email")).then(
                        (phonenumber) =>
                          {
                            dispatch(setUserPhonenumber(phonenumber))}
                      );
                      navigate("/otp-login", {
                        state: {
                          email: form.getFieldValue("email"),
                          isLogin: true,
                        },
                      });
                    } else if (form.getFieldValue("email") === undefined) {
                      toast.info("Please enter email");
                    }
                  }}
                >
                  OTP
                </span>
              </p>
            </div>
          </div>
        </Form>
        <style jsx>
          {`
            .ant-checkbox-checked .ant-checkbox-inner {
              background: #0057a3 !important;

              border-color: #0057a3 !important;
            }
            .ant-checkbox-inner:after {
              background-color: #0057a3 !important;
            }
          `}
        </style>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default LoginPage;
