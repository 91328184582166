import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  LockOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  userLogin,
  userResetForgotPassword,
  userResetForgotPasswordFinal,
} from "../../service/authApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/features/auth/authSlice";

const NewPasswordPage = () => {
  const dispatch = useDispatch();
  const idd = useParams();
  const { id } = idd;
  const navigate = useNavigate();
  const [animation, setAnimation] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (values) => {
    const payload = {
      email: email,
      password: values.password,
    };
    const response = await userResetForgotPasswordFinal(payload, id);
    if (response) {
      toast.success("Password Changed Successfully");
      const response = await userLogin({ data: payload });
      dispatch(setToken(response.data.payload.accessToken));

      if (response.status === 200) {
        if (response.data.success) {
          localStorage.setItem("token", JSON.stringify(response.data.payload));
          localStorage.setItem(
            "refreshToken",
            response.data.payload.refreshToken
          );
          toast.success("Welcome to HCL Healthcare E-Health Cart ");

          const cartItem = JSON.parse(localStorage.getItem("cartItem"));

          let checkItem = [];
          if (cartItem) {
            checkItem = Object.keys(cartItem);
          }
          if (checkItem.length > 0) {
            const pathName = localStorage.getItem("redirectPath");
            navigate(pathName, {
              state: {
                id: localStorage.getItem("CurrentLabId"),
                title: localStorage.getItem("CurrentLabTitle"),
              },
              replace: true,
            });
          } else {
            navigate("/");
          }
        }
      }
      navigate("/");
    }
  };

  useEffect(() => {
    userResetForgotPasswordFn(id);
  }, [id]);

  //Make function for New password
  const userResetForgotPasswordFn = async (id) => {
    setIsLoading(true);
    const response = await userResetForgotPassword(id);
    if (response.success) {
      setIsValid(true);
      setEmail(response.payload.email);
    } else {
      setIsValid(false);
      // link expires
    }
    setIsLoading(false);
  };

  const handleNavigate = () => {
    setAnimation(false);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };
  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 10);
  }, []);

  return (
    <div>
      <div
        className="border-t border-[#D9D9D9] w-[100%] bg-black opacity-50 h-[100vh] absolute top-0"
        onClick={handleNavigate}
      ></div>
      <div
        className={`absolute transition-all duration-500 w-full ${
          animation ? "right-0" : "-right-[100%]"
        } md:w-[60%] lg:w-[40%] border h-[100%] top-0 bg-[#FAFAFA] px-14 py-20 z-50`}
      >
        {isValid === true ? (
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Typography className="mb-[36px] text-2xl text-[#0057A3] flex flex-col items-left justify-between font-sans">
              {" "}
              <div
                className="flex items-center gap-2 mb-4 cursor-pointer md:hidden"
                onClick={handleNavigate}
              >
                {" "}
                <ArrowLeftOutlined className="text-[#8C8C8C] text-lg mb-1" />{" "}
                <Typography className="text-lg text-[#8C8C8C] font-sans">
                  Back
                </Typography>{" "}
              </div>{" "}
              <Typography className="text-2xl text-[#0057A3] flex items-center justify-between font-sans">
                {" "}
                New Password{" "}
                <CloseCircleOutlined
                  onClick={handleNavigate}
                  className="text-[#8C8C8C] cursor-pointer md:block hidden"
                />{" "}
              </Typography>{" "}
            </Typography>
            <Typography className="text-[#595959] mb-[8px] font-sans">
              Password
            </Typography>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  pattern: new RegExp(/.{8,}$/i),
                  message: "Atleast 8 Characters",
                },
                {
                  pattern: /^(?=.*[a-z])/,
                  message: "Must have at least one lowercase character",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*[0-9])(?=.*[!@#$%^&*()_+,\-.:;<=>?@[\\\]^_`{|}~])/g
                  ),
                  message: "Should contain number & symbol",
                },
                {
                  pattern: new RegExp(/^(?=.*[A-Z])/g),
                  message: "Must have Atleast One Uppercase",
                },
              ]}
            >
              <Input.Password
                placeholder="******"
                prefix={<LockOutlined className="site-form-item-icon mr-1" />}
                className="h-10 rounded-sm font-sans"
                minLength={8}
                maxLength={25}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                className="login-form-button w-full h-10 disabled:bg-[#BFBFBF] bg-[#0057A3] mt-56 font-sans rounded-sm absolute top-32"
                disabled={email === ""}
              >
                <Typography className="text-white font-sans">
                  Confirm
                </Typography>
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div className="flex flex-col items-center mt-36">
            <WarningOutlined className="text-red-300 text-[60px]" />
            <Typography className="h-20 text-[30px] text-red-300 font-sans">
              Invalid Link
            </Typography>
            <Button className="font-sans" onClick={() => navigate("/")}>
              Go Back
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewPasswordPage;
