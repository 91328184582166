import { ArrowLeftOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { Button, Form, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getOtpForLogin,
  userLogin,
  userReSendEmailOtp,
  userVerifyAndCreateOtp,
} from "../../service/authApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../components/CommonComponents/Loader";
import { setToken } from "../../redux/features/auth/authSlice";
import { setUserPhonenumber } from "../../redux/Auth/AuthSlice";

const VerifyEmailPage = () => {
  const { state } = useLocation();
  let { userData } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const [animation, setAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const phonenumber = useSelector((state) => state.UserPhoneNumber)


  const [otpValue, setOtpValue] = useState(""); // Store the OTP value entered by the user

  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };

  const UserPhoneNumber = useSelector((state) => state.Auth?.userPhoneNumber)
  const [otpTimer, setOtpTimer] = useState(119);

  useEffect(() => {
    // Start the OTP timer when the component mounts
    const timer = setInterval(() => {
      if (otpTimer > 0) {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [otpTimer]);

  const handleResend = async () => {
    if (otpTimer > 0) return;
    setIsLoading(true);
    if (state?.isLogin) {
      getOtpForLogin(state?.email);
    } else {
      const response = await userReSendEmailOtp({ userId: state.userId });
      if (response.success) {
        toast.success("OTP Sent successfully ");
        setOtpTimer("60");
        setIsLoading(false);
      } else {
        toast.info("User Doesn't Exist!!");
        setIsLoading(false);
      }
    }
  };

  ///////////////////////////////////////////////

  const onFinish = (values) => {
    if (state?.isLogin) {
      userloginFn({ otp: otpValue, email: state?.email });
    } else {
      let payload = {
        otp: otpValue,
        userId: state.userId,
      };

      userData = {
        // ...userData,
        otp: values.password,
        userId: state.userId,
      };
      userVerifyEmailFn(payload);
    }
  };

  const dispatch = useDispatch();

  const userloginFn = async (values) => {
    setIsLoading(true);
    const response = await userLogin({ data: values });
    dispatch(setToken(response.data.payload.accessToken));

    if (response.status === 200) {
      if (response.data.success) {
        localStorage.setItem("token", JSON.stringify(response.data.payload));
        localStorage.setItem(
          "refreshToken",
          response.data.payload.refreshToken
        );
        toast.success("Welcome to HCL Healthcare E-Health Cart ");

        const cartItem = JSON.parse(localStorage.getItem("cartItem"));

        let checkItem = [];
        if (cartItem) {
          checkItem = Object.keys(cartItem);
        }
        if (checkItem.length > 0) {
          const pathName = localStorage.getItem("redirectPath");
          navigate(pathName, {
            state: {
              id: localStorage.getItem("CurrentLabId"),
              title: localStorage.getItem("CurrentLabTitle"),
            },
            replace: true,
          });
        } else {
          navigate("/");
        }
      }
    } else if (response.status === 206) {
      navigate("/verify-email", {
        state: {
          userId: response.data.payload.userId,
          email: state?.email,
        },
      });
    } else if (response?.status === 429) {
      toast.error("Your password has expired. Please change your password.");
      navigate("/resetPage");
    } else if (response?.status === 404) {
      toast.info("User doesn't exist");
    } else {
      toast.error("Invalid Credentials");
    }
    setIsLoading(false);
  };

  const userVerifyEmailFn = async (userData) => {
    setIsLoading(true);
    const response = await userVerifyAndCreateOtp(userData);

    if (response.success) {
      setIsLoading(false);
      localStorage.setItem("token", JSON.stringify(response?.payload));
      localStorage.setItem("accessToken", response?.payload?.accessToken);
      localStorage.setItem("refreshToken", response?.payload?.refreshToken);
      toast.success("Register successfully ");
      const timer = setTimeout(() => {
        navigate("/");
        window.location.reload(true);
      }, 1000);
      timer();
    } else {
      toast.info("Incorrect OTP");
    }
    setIsLoading(false);
  };

  const handleNavigate = () => {
    setAnimation(false);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };
  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 10);
  }, []);

  return (
    <div>
      <div
        className="border-t border-[#D9D9D9] w-[100%] bg-black opacity-50 h-[100vh] absolute top-0"
        onClick={handleNavigate}
      ></div>
      <div
        className={`absolute transition-all duration-500 w-full ${
          animation ? "right-0" : "-right-[100%]"
        } md:w-[60%] lg:w-[40%] border h-[100%] top-0 bg-[#FAFAFA] px-14 py-20 z-50`}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="h-[80vh] flex flex-col justify-between">
            <div>
              <Typography className="mb-[36px] text-2xl text-[#0057A3] flex flex-col items-left justify-between font-sans">
                {" "}
                <div
                  className="flex items-center gap-2 mb-4 cursor-pointer md:hidden"
                  onClick={handleNavigate}
                >
                  {" "}
                  <ArrowLeftOutlined className="text-[#8C8C8C] text-lg mb-1" />{" "}
                  <Typography className="text-lg text-[#8C8C8C] font-sans">
                    Back
                  </Typography>{" "}
                </div>{" "}
                <Typography className="text-2xl text-[#0057A3] flex items-center justify-between font-semibold font-sans">
                  {state?.isLogin ? "Login" : "Verify Email"}{" "}
                  <CloseCircleOutlined
                    onClick={handleNavigate}
                    className="text-[#8C8C8C] cursor-pointer md:block hidden"
                  />{" "}
                </Typography>{" "}
              </Typography>
              {state?.isLogin && (
                <div
                  className="mb-10 flex items-center gap-2 cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  {" "}
                  <ArrowLeftOutlined className="text-[0.9rem]" /> Back
                </div>
              )}

              <Typography className="text-[#161616] mb-[8px] text-sm font-sans">
                OTP sent to {state?.isLogin ? state?.email : userData.email} & {UserPhoneNumber} 
              </Typography>

              <Form.Item name="password" className="font-sans">
                <PinInput
                  className="font-sans "
                  length={6}
                  initialValue=""
                  secret
                  secretDelay={100}
                  onChange={handleOtpChange}
                  onComplete={(value, index) => {
                    setOtpValue(value);
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{
                    paddingRight: "10px",
                    paddingTop: "10px",
                    height: "50px",
                    textAlign: "left",
                    borderRadius: "5px",
                  }}
                  inputStyle={{
                    borderRadius: "3px",
                    borderColor: "#D9D9D9",
                    backgroundColor: "#FFFFFF",
                    height: window.innerWidth > 640 ? "2.5rem" : "2.5rem",
                    width: window.innerWidth > 640 ? "2.5rem" : "2.2rem",
                    marginRight: window.innerWidth > 640 ? "4%" : "0",
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </Form.Item>

              <div className="flex justify-between text-center mt-10 absolute left-16 w-[75%]">
                <span
                  className={` ${
                    otpTimer === 0
                      ? "cursor-pointer text-[#0057A3]"
                      : "cursor-not-allowed text-[#595959]"
                  } font-semibold font-sans`}
                  onClick={handleResend}
                >
                  Resend OTP
                </span>
                <span className="text-[#0057A3]">{`${Math.floor(
                  otpTimer / 60
                )}:${otpTimer % 60 < 10 ? "0" : ""}${
                  otpTimer % 60
                } secs`}</span>
              </div>
            </div>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                className="login-form-button w-full  bg-[#0057A3] mt-56 rounded-sm h-10"
                disabled={otpValue.length !== 6}
              >
                <Typography className="text-white font-sans">
                  {state?.isLogin ? "Login" : "Verify"}
                </Typography>
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default VerifyEmailPage;
