import React from "react";
import Typography from "antd/es/typography/Typography";
import { Card } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const ReaderCornerCardComponent = ({ img, detail, heading, redirect }) => {
  return (
    <Card
      className="drop-shadow"
      bodyStyle={{ padding: "1rem", height: "8rem" }}
    >
      <div className="flex">
        <img
          src={img}
          alt="reader corner image"
          className="w-[25%] h-[6.2rem] object-fill rounded"
        />
        <div className="pl-[5%]">
          <Typography className="text-[1rem] md:text-[1.1rem] font-semibold text-[#1F1F1F] pb-[2%]  w-[100%] font-sans">
            {window.innerWidth < 640 ? (
              <>
                {heading.slice(0, 40)}
                <>...</>
              </>
            ) : (
              <>
                {heading.slice(0, 40)}
                <>...</>
              </>
            )}
          </Typography>
          <div className="flex items-center w-[100%] justify-between">
            <Typography className="text-[0.9rem] md:text-[0.7rem] font-normal text-[#595959] w-[85%] font-sans">
              {window.innerWidth < 640 ? (
                <>
                  {detail.slice(0, 40)}
                  <>...</>
                </>
              ) : (
                <>
                  {detail.slice(0, 80)}
                  <>...</>
                </>
              )}
            </Typography>
            <a href={redirect} target="_blank">
              <ArrowRightOutlined className="w-[1.5rem] h-[1.5rem] text-base bg-[#F15E7E] rounded-full text-white items-center flex justify-center cursor-pointer" />
            </a>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ReaderCornerCardComponent;
