import Typography from "antd/es/typography/Typography";
import React from "react";

const ContainerWithShadow = ({
  children,
  icon,
  testName,
  w,
  labName,
  sideText,
  onclickF,
  headerBottomBorder,
}) => {
  return (
    <div
      className="rounded-[8px] p-[1rem] "
      style={{
        boxShadow: "0px 1.5px 8px rgba(0, 0, 0, 0.1)",
        border: "1px solid #D9D9D9",
        width: `${w}`,
      }}
    >
      <div
        className=" flex justify-between border-[#D9D9D9] pb-[0.3rem]"
        style={headerBottomBorder ? {} : { borderBottom: "1px solid #D9D9D9" }}
      >
        <div className="flex ">
          {icon && (
            <img
              src={icon}
              alt="Icon"
              className="w-[2rem] mb-[0.4rem] mr-[0.7rem]"
            />
          )}
          <div>
            <Typography className="font-semibold text-[1.1rem] font-sans">
              {testName}
            </Typography>
            {labName && (
              <Typography className="text-[#595959] text-[0.8rem] font-sans">
                {labName}{" "}
              </Typography>
            )}
          </div>
        </div>

        <div
          onClick={() => {
            onclickF();
          }}
        >
          <div className="min-w-[4rem]  flex justify-end items-center">
            <Typography className="text-[#F15E7E] mt-[0.2rem] cursor-pointer font-sans ">
              {" "}
              {sideText}
            </Typography>
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ContainerWithShadow;
