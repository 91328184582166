import { Divider, Modal, Typography } from "antd";
import React from "react";
import Order_Confiramtion from "../../assets/images/Order Confirmation.gif";
import { image75 } from "../../assets/images";

const Reshedule = ({ HandleClose, openModal }) => {
  return (
    <Modal
      open={openModal}
      centered={true}
      closeIcon={<></>}
      footer={null}
      width={window.innerWidth < 640 ? "100%" : "28rem"}
    >
      <div className="flex justify-center items-center w-[100%] flex-col py-[0.4rem] sm:py-[1rem] ">
        <div className="flex relative justify-center items-center overflow-hidden w-[100%] sm:h-[15rem] h-[12rem]">
          <img src={Order_Confiramtion} alt="" className="w-[120%]" />
        </div>
        <Typography className="text-[#1F1F1F] sm:text-[1.3rem]  text-[1.2rem] font-semibold font-sans">
          Your Request Sent
        </Typography>
        <Typography className="text-[#434343] sm:text-[1.1rem] text-[0.9rem]  mb-[1rem] font-sans">
          An agent will reach out to you in 15-20 mins
        </Typography>

        <Typography className="text-[#595959] sm:text-[1.1rem] text-[0.9rem]  mb-[1rem] font-sans flex items-center gap-3 ">
          If in hurry Chat with us{" "}
          <a
            className="cursor-pointer"
            href="https://api.whatsapp.com/send/?phone=917834811114&text=Hi+there&type=phone_number&app_absent=0"
            target="_blank"
          >
            <img src={image75} alt="" className="h-7 w-7" />
          </a>
        </Typography>
      </div>
    </Modal>
  );
};

export default Reshedule;
