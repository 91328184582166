import React from "react";
import { Carousel, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoryId,
  setSelectedTab,
} from "../../redux/DiagnosticTests/diagnosticVarSlice";
import {
  setPackageCategoryId,
  setPackageSelectedTab,
} from "../../redux/ProfileAndPackages/profileAndPackagesVarSlice";
import {
  setConsultantCategoryId,
  setConsultantSelectedTab,
} from "../../redux/SpecialistConsultant/specialistConsultantVarSlice";
import { Navigate, useNavigate } from "react-router-dom";

const contentStyle = {
  margin: 0,
  color: "#fff",
  textAlign: "center",
};

const BookMedicalBanner = () => {
  const data = useSelector((state) => state.Banner.banner);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRedirection = (data) => {
    if (data.externalUrl) {
      window.open(data.externalUrl, "_blank");
    } else if (data?.category === "Diagnostic") {
      dispatch(setCategoryId(data?.subcategory?._id));
      dispatch(setSelectedTab(data?.subcategory?.name));
      navigate("/diagnostic-tests");
    } else if (data?.category === "PackagesProfile") {
      dispatch(setPackageCategoryId(data?.subcategory?._id));
      dispatch(setPackageSelectedTab(data?.subcategory?.name));
      navigate("/packages");
    } else if (data.category === "Consultant") {
      dispatch(setConsultantCategoryId(data?.subcategory?._id));
      dispatch(setConsultantSelectedTab(data?.subcategory?.name));
      navigate("/special-consult");
    }
  };

  return (
    <div className="mb-[2.5rem] mt-4 sm:mt-3">
      {/* Mobile Responsive */}
      {window.innerWidth < 640 ? (
        <Carousel autoplay autoplaySpeed={8000} className="w-[100%]" draggable>
          {data?.payload?.banner?.map((data) => (
            <div className="cursor-pointer">
              <Row style={contentStyle}>
                <Col span={24} className="text-black">
                  <div>
                    <img
                      src={data?.mobileUrl}
                      alt="heroImage"
                      className="w-[100%] rounded-[8px] transition-all cursor-pointer"
                      onClick={() => handleRedirection(data)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel
          autoplay
          autoplaySpeed={8000}
          className="rounded-lg"
          draggable
        >
          {data?.payload?.banner?.map((data) => (
            <div>
              <Row style={contentStyle}>
                <Col span={24} className="text-black">
                  <div>
                    <img
                      src={data?.webUrl}
                      alt="heroImage"
                      className="w-[100%] rounded-[8px] transition-all cursor-pointer"
                      onClick={() => handleRedirection(data)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
      )}
      <style jsx>
        {`
          .ant-carousel .slick-dots-bottom {
            bottom: -15px;
          }
        `}
      </style>
    </div>
  );
};

export default BookMedicalBanner;
