import React, { useEffect, useState } from "react";
import ReaderCornerCardComponent from "./ReaderCornerCardComponent";
import { Typography, Row, Col } from "antd";
import ReaderCorner_1 from "../../assets/images/ReaderCorner 1.png";
import ReaderCorner_2 from "../../assets/images/ReaderCorner 2.png";
import ReaderCorner_3 from "../../assets/images/ReaderCorner 3.png";
import ReaderCorner_4 from "../../assets/images/ReaderCorner 4.png";
import { getAllBlogs } from "../../service/homeApi";

const ReaderCorner = () => {
  const [blogList, setBlogList] = useState("");

  //Api
  const landingBlogData = async () => {
    const response = await getAllBlogs();
    setBlogList(response?.data);
  };

  useEffect(() => {
    landingBlogData();
  }, []);

  return (
    <div className="mt-[1.5rem] sm:mt-0 mb-[8%]">
      <div className="w-100 flex justify-center items-center flex-col mt-2 mb-[3%]">
        <Typography className="text-center font-semibold text-[1.4rem] sm:text-[2rem] font-sans">
          Reader&apos;s Corner
        </Typography>
        <Typography className="text-center text-[#434343] text-[0.9rem] w-[60%] m-[auto] sm:text-[1.1rem] font-sans mb-[1.3rem] ">
          Covering a variety of healthcare topics and perspectives
        </Typography>
      </div>
      <Row gutter={[15, 15]}>
        {blogList?.payload?.map((el) => (
          <Col md={12}>
            <ReaderCornerCardComponent
              img={el?.imageUrl}
              detail={el?.description}
              heading={el?.title}
              redirect={el?.blogUrl}
            />
          </Col>
        ))}
      </Row>
      <div className="w-full flex justify-end  items-center">
        {" "}
        <a
          href="https://www.healthyhcl.in/blog/"
          target="_blank"
          class="underline text-[0.8rem] text-[#0057A3] hover:text-[#0057A3] hover:underline font-[500]  mt-6"
        >
          View All Blogs.
        </a>
      </div>
    </div>
  );
};

export default ReaderCorner;
