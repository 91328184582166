import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isOfferApplied:false
};



export const alertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    offerTrue: (state)=>{
      state.isOfferApplied=true;
    },
    offerFalse:(state)=>{
      state.isOfferApplied=false;
    },
  },
});

export const { showLoading, hideLoading,offerTrue ,offerFalse} = alertSlice.actions;

export default alertSlice.reducer;
