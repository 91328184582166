import React from "react";
import Logo from "../../assets/images/Logo.png";
import { PhoneOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import ProfileDropdown from "../DropDown/ProfileDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getcount, setCartData } from "../../redux/Cart/cartCountSlice";
import SearchBarMobile from "./SearchBarMobile";

const Navbar = ({ isUp }) => {
  const navigate = useNavigate();
  const [showRightDetails, setShowRightDetails] = useState(false);
  const path = useLocation();

  //// for cart value we will do this

  const dispatch = useDispatch();

  const countOfcart = useSelector((state) => state.count.count);
  useEffect(() => {
    dispatch(setCartData());
    dispatch(getcount());
  }, []);

  useEffect(() => {
    if (path.pathname !== "/health-check") setShowRightDetails(true);
    else setShowRightDetails(false);
  }, [path]);

  return (
    <>
      <div
        className="z-40 w-full sticky bg-[#ffffff] top-0 left-0"
        style={isUp ? { zIndex: "50" } : { zIndex: "0" }}
      >
        <div className="h-16 flex items-center mx-4 justify-between">
          {/* ****************************Logo*************************** */}
          <div className="flex h-7">
            <img
              src={Logo}
              alt="Logo"
              // className="lg:border-r cursor-pointer"
              className={`lg:border-r ${
                showRightDetails
                  ? "cursor-pointer  hover:text-white hover:text-[0.9rem]"
                  : "cursor-pointer"
              }`}
              // onClick={() => navigate("/")}
              onClick={() => {
                if (showRightDetails) {
                  navigate("/");
                } else {
                  window.open("https://www.healthyhcl.in/");
                }
                window.scrollTo(0, 0);
              }}
            />
            <Tooltip
              title="Need assistance?"
              color="#0057A3"
              className="transition-all "
            >
              <div className="lg:flex ml-3 items-center gap-2 hidden">
                {" "}
                <PhoneOutlined className="text-[#0057A3] rotate-90" />
                <Typography className="font-semibold text-lg font-sans">
                  {" "}
                  721-7890-075{" "}
                </Typography>{" "}
              </div>{" "}
            </Tooltip>
          </div>
          {showRightDetails && (
            <div className="flex items-center flex-end lg:w-[50%] h-auto justify-end gap-6">
              {/* *****************************Search Bar******************************** */}
              <div className="hidden sm:block">
                <SearchBarMobile compId="navbar-location" />
              </div>

              {/* ************************ Cart and Profile************************** */}
              <div className=" flex items-center justify-between gap-4 ">
                <div
                  className="lg:flex lg:flex-col text-sm text-[#0057A3] cursor-pointer w-[2rem]"
                  onClick={() => navigate("/cart")}
                >
                  <div className=" relative flex justify-center items-center flex-col ">
                    {countOfcart > 0 && (
                      <div className="absolute top-[-40%] sm:top-[-20%] right-[-20%] bg-[#F15E7E] w-[1.1rem] h-[1.1rem] flex justify-center items-center rounded-full p-[]">
                        <Typography className=" z-10 text-white font-semibold text-[0.8rem]">
                          {countOfcart}
                        </Typography>
                      </div>
                    )}
                    <ShoppingCartOutlined className="font-semibold text-[1.1rem]" />
                    <Typography className="text-xs text-[#0057A3] font-semibold -mb-1 hidden sm:flex ">
                      Cart
                    </Typography>
                  </div>
                </div>
                <ProfileDropdown />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Navbar;
