import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/CommonComponents/Footer";
import EditProfileForm from "./EditProfileForm";

const EditProfileDetail = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Row className="border-b border-[#BFBFBF] pb-2">
        <Col span={20} offset={window.innerWidth < 640 ? 1 : 2}>
          <div className="h-7 sm:h-10 flex items-center justify-start text-[1.2rem] md:text-[1.4rem] ">
            <ArrowLeftOutlined onClick={() => navigate("/profile-setting")} />
            <div className="flex items-baseline">
              <Typography className="ml-[1.4rem] md:ml-[2rem] text-[1.2rem] md:text-[1.5rem] font-semibold mr-[0.2rem] font-sans ">
                Edit Profile Detail
              </Typography>
            </div>
          </div>
        </Col>
      </Row>
      {window.innerWidth > 640 ? (
        <Row className="flex justify-center mt-3 p-3 min-h-[82vh]">
          <Col sm={22} md={11}>
            <div className="w-[100%]  sm:border sm:border-[#D9D9D9] sm:shadow-md sm:rounded-md px-4 py-4 pb-0 mb-10 ">
              <div className="flex justify-between border-b  pb-3 mb-6 ">
                <Typography className="font-semibold text-[1.1rem] text-black font-sans">
                  Edit
                </Typography>
              </div>
              <EditProfileForm />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <div className="p-4 min-h-[86vh]">
            <EditProfileForm />
          </div>
        </>
      )}
      {/* <Footer /> */}

    </div>
  );
};

export default EditProfileDetail;
