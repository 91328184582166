import axios from "axios";
import { BASE_URL } from "../utils/globalVariable";
import { axiosInstance } from "../utils/axios";
import ErrorList from "antd/es/form/ErrorList";
import { toast } from "react-toastify";

/********************************** User Login (Signin User)**********************************/

export const userLogin = async ({ data }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/signin`,
      data
    );

    if (response.status === 200) {
      return response;
    }

    if (response.status === 206) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    if (error.response.status === 429) {
      return error.response;
    }
    if (error.response.status === 403) {
      return error.response;
    }
    if (error.response.status === 400) {
      return error.response;
    }
    if (error.response.status === 404) {
      return error.response;
    }
    return error;
  }
};

/********************************** Update password **********************************/

export const userChangePassword = async ({ data }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/expire-password`,
      data
    );

    if (response.status === 200) {
      return response;
    }

    if (response.status === 206) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    if (error.response.status === 429) {
      return error.response;
    }

    return error;
  }
};

/**********************************SEND EMAIL OTP  **********************************/

export const userSendEmailOtp = async ({ data }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/verify-email-otp`,
      data
    );
    if (response.status === 200) {
      return { ...response.data };
    }
  } catch (error) {
    return error;
  }
};

/**********************************RESEND EMAIL OTP  **********************************/

export const userReSendEmailOtp = async (userId) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/api/v1/account/resend-email-otp`,
      userId
    );
    if (response.status === 200) {
      return { ...response.data };
    }
  } catch (error) {
    return error;
  }
};

/********************************** Verify and Create (Verify Account) **********************************/

export const userVerifyAndCreateOtp = async (userData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/verify-email-otp`,
      userData
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

/********************************** Forgot Password (Init Forgot Password)**********************************/

export const userForgotPassword = async ({ data }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/reset/init-forgot-password`,
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

/********************************** Reset Forgot Password (Verify forgot password token) **********************************/
export const userResetForgotPassword = async (id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/account/reset/verify-token/${id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) { }
};

/********************************** Reset Forgot Password Final  (Forgot Password Final)**********************************/

export const userResetForgotPasswordFinal = async (data, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/reset/forgot-password-final/${token}`,
      data
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    toast.info(error.response.data.message);
  }
};

/********************************** Logout (Logout) **********************************/
export const userLogout = async () => {
  const REFRESH_TOKEN = localStorage.getItem("refreshToken");
  const ACCESS_TOKEN = localStorage.getItem("accessToken");

  try {
    const AuthStr = "Bearer ".concat(ACCESS_TOKEN);

    const url = `${BASE_URL}/api/v1/account/logout?refreshToken=${REFRESH_TOKEN}`;
    const response = await axios.get(url, {
      headers: { Authorization: AuthStr },
    });
    if (response.status === 200) {
      localStorage.clear();
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

/********************************** Generate Refresh Token (Generate New Access Token) **********************************/
export const generateRefreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axiosInstance.post(
      `${BASE_URL}/api/v1/account/generaterefreshtoken`,
      {
        refreshToken,
      }
    );
    if (response.data.success) {
      return response.data.payload;
    }
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
};

/********************************** USER Verify Token (verify forgot password token) **********************************/
export const userVerifyToken = async ({ token }) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/account/reset/verify-token/:${token}`
    );
    if (response.success) {
      return response.data.data;
    }
  } catch (error) { }
};

/************************************* Create User *************************************/

export const createUser = async ({ data }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/create-user`,
      data
    );

    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return error.response;
  }
};

/////----------------------------------- Login with otp---------------------

export const getOtpForLogin = async (userEmail) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/account/send-login-otp`,
      {
        email: userEmail,
      }
    );
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return response.data.payload.phoneNumber;
    }
  } catch (error) { }
};
/////----------------------------------- Download Consent ---------------------

export const getCurrentActiveConsent = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/adminRoutes/consent`);
    if (response?.status === 200) {
      // toast.success(response?.data?.message);
      return response.data.payload;
    }
  } catch (error) {
    toast.error('Something went wrong, please try again later');
   }
};
