import { Typography } from "antd";
import React from "react";

import { useNavigate } from "react-router-dom";

const dummyData = [
  {
    img: "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/DiagnosticDiscount.svg",
    Title: "Diagnostic Tests",
    route: "/diagnostic-tests",
  },
  {
    img: "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/consultantDiscount.svg",
    Title: "Specialist Consult",
    route: "/special-consult",
  },
  {
    img: "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/PackageDiscount.svg",
    Title: "Package & Profile",
    route: "/packages",
  },
  {
    img: "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/MedicineDiscount.svg",
    Title: "Medicine",
    link: "https://pharmeasy.in/online-medicine-order?utm_source=alz-hclepharma&utm_medium=alliance&utm_campaign=alz-medicine-07nov2023",
  },
  {
    img: "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/vacc.png",
    Title: "Vaccination",
    route: "/vaccination",
  },
  {
    img: "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/GST%20CAM%20939-10.jpg",
    Title: "Pharmacy",
    route: "mannual",
  },
  {
    img: "https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/freeConsultantImage.jpg",
    Title: "Corporate Sponsored",
    route: "freeConsultant",
  },
];

const OurSolution = () => {
  const navigate = useNavigate();

  return (
    <div className="xl:w-full xl:flex xl:justify-center xl:items-center xl:flex-col mt-8 mb-10 sm:mt-12">
      {/* Our Solution */}
      <div className=" mb-[1.5rem] sm:mb-[1.4rem]">
        <Typography className="text-center font-semibold text-[1.4rem] sm:text-[2rem] font-sans">
          {" "}
          Book your Healthcare Services
        </Typography>
        <Typography className="text-center text-[#434343] text-[0.9rem] w-[80%] md:w-[100%] m-[auto] sm:text-[1.1rem] mb-[0.2rem] sm:mb-[1rem] font-normal font-sans">
          Preventive healthcare with quality and excellence
        </Typography>
      </div>
      {/* Make card */}

      <div className="flex gap-4 sm:gap-10 relative overflow-x-scroll sm:overflow-hidden no-scrollbar scrollbar-hide">
        {dummyData.map((data) => {
          return (
            <div
              onClick={() => {
                if (data.route) {
                  if (data.route === "mannual") {
                    navigate("/pharmacy-voucher", {
                      state: {
                        id: process.env.REACT_APP_PHARMACY_VOUCHER_ID,
                        name: "Pharmacy voucher",
                        isPharmacy: true,
                      },
                    });
                  } else if (data.route === "freeConsultant") {
                    navigate("/free-special-consult", {
                      state: {
                        id: process.env.REACT_APP_FREE_SPECIALIST_ID,
                        name: "Free Specialist Consultant",
                        isFreeConsultant: true,
                      },
                    });
                  } else {
                    navigate(data.route);
                  }
                } else {
                  window.open(data?.link, "_blank");
                }
              }}
              className={`cursor-pointer`}
            >
              {window.innerWidth > 850 ? (
                <div className="w-full flex justify-center items-center ">
                  <div className=" transition-all">
                    <img
                      src={data.img}
                      alt={data.Title}
                      className={`rounded-md max-w-[100%] max-h-[8rem] object-cover ${
                        data?.route !== "/" ? "hover:shadow-lg" : ""
                      }`}
                    />
                    <div className="text-center flex items-center justify-center mt-2">
                      <Typography className="font-semibold text-sm text-center   font-sans">
                        {data.Title}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-[22vw] ">
                  <img
                    src={data.img}
                    alt={data.Title}
                    className="rounded-md w-full"
                  />
                  <div className="text-center flex items-center justify-center">
                    <Typography className="font-semibold lg:text-base text-sm text-center w-40 pt-2 font-sans">
                      {data.Title}
                    </Typography>
                  </div>
                </div>
              )}{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurSolution;
