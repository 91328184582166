import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CustomerServiceOutlined,
  ExceptionOutlined,
  ExperimentOutlined,
  FileSearchOutlined,
  ForkOutlined,
  HeartOutlined,
  HomeFilled,
  IdcardOutlined,
  LogoutOutlined,
  MedicineBoxOutlined,
  MenuFoldOutlined,
  RightOutlined,
  SafetyCertificateOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Select, Option } from "antd";
import Typography from "antd/es/typography/Typography";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../service/authApi";
import { toast } from "react-toastify";

import { Collapse } from "antd";
import LogInWarning from "../CommonComponents/LogInWarning";
import { useSelector } from "react-redux";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();
  const [showDropdown, setDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [isWarning, setIsWarning] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [message, setMessage] = useState("");
  const name = JSON.parse(localStorage.getItem("token"));

  const privateRouting = (route, message) => {
    if (!token) {
      setIsWarning(true);
      setMessage(message);
    } else {
      // setDropdown(false);
      document.body.style.overflow = "unset";
      navigate(route);
    }
    setDropdown(false);
  };

  const handleLogin = () => {
    setDropdown(false);
    navigate("/login");
  };

  const { Panel } = Collapse;
  const userLogoutFn = async () => {
    const response = await userLogout();
    if (response.success) {
      toast.success("You have Logged Out Successfully");
      const timer = setTimeout(() => {
        navigate("/login");
        window.location.reload(true);
      }, 1000);
      timer();
    }
  };

  const handleLogout = () => {
    setDropdown(false);
    userLogoutFn();
  };

  useEffect(() => {
    const userData = localStorage.getItem("token");
    const data = JSON.parse(userData);
    setCurrentUser(
      data?.user?.firstName ? data?.user?.firstName : data?.userData?.firstName
    );
  }, [showDropdown]);

  const DesktopDropdownData = [
    {
      title: "Profile Settings",
      icon: <UserOutlined className="text-[#0057A3] mr-2" />,
      route: "/profile-setting",
    },
    {
      title: "Reports",
      icon: <FileSearchOutlined className="text-[#0057A3] mr-2 pt-0.5" />,
      route: "/",
    },
    {
      title: "Order Review",
      icon: <ShoppingOutlined className="text-[#0057A3] mr-2 pt-0.5" />,
      route: "/order-review",
    },
    // {
    //   title: "Notification",
    //   icon: <BellOutlined className="text-[#0057A3] mr-2 pt-0.5" />,
    //   route: "/notification",
    // },
  ];

  const MobileDropdownData = [
    {
      title: "Home",
      icon: (
        <HomeFilled className="text-[#0057A3] text-[0.9rem] mr-3 items-center flex justify-center rounded-full p-1 bg-[#e5f5ff]" />
      ),
      route: "/",
    },
    {
      title: "Diagnostic Tests",
      icon: <ExperimentOutlined className="text-[#0057A3] mr-3 pt-0.5" />,
      route: "/diagnostic-tests",
    },
    {
      title: "Specialist Consultant",
      icon: <MedicineBoxOutlined className="text-[#0057A3] mr-3 pt-0.5" />,
      route: "/special-consult",
    },
    {
      title: "Packages & Profile",
      icon: <ExceptionOutlined className="text-[#0057A3] mr-3 pt-0.5" />,
      route: "/packages",
    },
    {
      title: "Vaccination",
      icon: <ForkOutlined className="text-[#0057A3] mr-3 pt-0.5" />,
      route: "/vaccination",
    },
    {
      title: "Other Services",
      icon: <AppstoreOutlined className="text-[#0057A3] mr-3 pt-0.5" />,
      route: "/",
    },
    {
      title: "Corporate Sponsored",
      icon: (
        <SafetyCertificateOutlined className="text-[#0057A3] text-base mr-3 ml-1 items-center flex justify-center" />
      ),
      route: "/",
    },
  ];
  const dropdownRef = useRef(null);

  // Function to handle clicks outside the drop-down
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className="flex flex-col text-sm text-[#0057A3] cursor-pointer mt-1"
        ref={window?.innerWidth > 1020 ? dropdownRef : null}
        onClick={() => {
          window.innerWidth < 1020 &&
            (document.body.style.overflow = showDropdown ? "unset" : "hidden");
          setDropdown(!showDropdown);
        }}
      >
        <UserOutlined className="hidden lg:inline font-semibold text-[1.1rem]" />
        <span className="text-xs hidden lg:inline font-semibold font-sans capitalize ">
          {name?.user?.firstName ? name?.user?.firstName : "Profile"}
        </span>
        <MenuFoldOutlined className="lg:hidden text-2xl rotate-180" />
      </div>
      {showDropdown && (
        <div className="fixed lg:right-4 lg:top-14 right-0 top-0  bottom-0 w-[100%] z-50 lg:bg-transparent bg-black bg-opacity-50">
          <div className="absolute right-0 top-0 h-[100%] lg:h-fit md:w-[65%] w-[87%] lg:w-[16rem]  bg-white shadow-lg">
            <CloseCircleOutlined
              className="xl:hidden text-xl text-[#0057A3] mt-6 mr-4 flex justify-end"
              onClick={() => {
                document.body.style.overflow = "unset";
                setDropdown(false);
              }}
            />
            {/* *********** User Name ********** */}
            <div className="flex items-center pb-3 md:pt-3 px-5 justify-between border-b-[1.5px] border-[#D9D9D9]">
              <div className="flex items-center gap-2">
                <UserOutlined className="border border-[#0057A3] rounded-full p-2 text-[#0057A3]  text-[1rem]" />
                <div>
                  <span className="text-[#989898]">Hi,</span>
                  {currentUser === undefined ? (
                    <div className="flex">
                      <Typography
                        onClick={handleLogin}
                        className="cursor-pointer font-semibold text-[0.9rem] font-sans"
                      >
                        Sign Up/Log In
                      </Typography>
                      <RightOutlined className="xl:hidden text-xs absolute right-4" />
                    </div>
                  ) : (
                    <Typography className="capitalize  font-semibold text-[0.9rem] font-sans">
                      {currentUser}
                    </Typography>
                  )}
                </div>
              </div>
            </div>

            {/* *****************Dropdown Data**************** */}
            {/* <Divider className="mt-0 mb-1" />{" "} */}
            <div className="lg:overflow-y-auto overflow-y-scroll lg:no-scrollbar scrollbar h-[72%]">
              {window.innerWidth < 1020
                ? MobileDropdownData.map((e, key) => {
                    return e.title === "Home" ? (
                      <div className="flex items-center cursor-pointer p-2 pl-6">
                        <Collapse
                          expandIconPosition={"end"}
                          bordered={false}
                          className="bg-white mt-[0.8rem] -ml-6 w-full"
                          defaultActiveKey={isOpen ? 0 : 1}
                        >
                          <Fragment className="bg-white">
                            <Panel
                              header={
                                <div className="flex ml-1 w-full">
                                  {e.icon}
                                  <Typography className="text-[#0057A3] font-semibold cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                                    {e.title}
                                  </Typography>
                                </div>
                              }
                              key={"1"}
                            >
                              <div className="pl-2">
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2"
                                  onClick={() =>
                                    privateRouting(
                                      "/order-review",
                                      "Please Login to see your orders"
                                    )
                                  }
                                >
                                  <ShoppingOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <Typography className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                                    Order Review
                                  </Typography>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-7"
                                  onClick={() =>
                                    privateRouting(
                                      "/cart",
                                      "Please Login to see your Cart"
                                    )
                                  }
                                >
                                  <ShoppingCartOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <Typography className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                                    Cart
                                  </Typography>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-7"
                                  onClick={() =>
                                    privateRouting(
                                      "/profile-setting",
                                      "Please Login to see your profile"
                                    )
                                  }
                                >
                                  <UserOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <Typography className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                                    Profile Settings
                                  </Typography>
                                </div>
                                <a
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-7"
                                  href="https://hclhealthcare.karexpert.com/account-management/login"
                                  target="_blank"
                                >
                                  <AuditOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <Typography className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                                    Report Portal
                                  </Typography>
                                </a>
                              </div>
                            </Panel>
                          </Fragment>
                        </Collapse>
                      </div>
                    ) : e.title === "Corporate Sponsored" ? (
                      <div className="flex items-center cursor-pointer p-2 pl-6">
                        <Collapse
                          expandIconPosition={"end"}
                          bordered={false}
                          className="bg-white -mt-3 -ml-6 w-full"
                        >
                          <Fragment className="bg-white">
                            <Panel
                              header={
                                <div className="flex ml-1 w-full">
                                  {e.icon}
                                  <Typography className="text-[#0057A3] font-semibold cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                                    {e.title}
                                  </Typography>
                                </div>
                              }
                              key={"2"}
                            >
                              <div className="pl-2">
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2 mb-7"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <MedicineBoxOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <Typography
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    onClick={() => {
                                      navigate("/free-special-consult", {
                                        state: {
                                          id: process.env.REACT_APP_FREE_SPECIALIST_ID,
                                          name: "Free Specialist Consultant",
                                          isFreeConsultant: true,
                                        },
                                      });
                                    }}
                                  >
                                    Free Specialist consult
                                  </Typography>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2 mb-7"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <AppstoreAddOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <a
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    href="https://www.healthyhcl.in/"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Our Services
                                  </a>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2 mb-7"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <HeartOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <a
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    href="https://app.hclhealthcare.in/ehc/register.php"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Book your Health Checks
                                  </a>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2 mb-7"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <BankOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <a
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    href="https://www.healthyhcl.in/managed-care-plans/"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Care Plans
                                  </a>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2 mb-7"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <UsergroupAddOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <a
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    href="https://www.healthyhcl.in/dental-care-services/"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Dental Services
                                  </a>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2 mb-7"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <MedicineBoxOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <a
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    href="https://www.healthyhcl.in/doctor-consults-life-coach/"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Life Coach Services
                                  </a>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-2"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <ShoppingOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <a
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    href="https://hclhealthcare.karexpert.com/account-management/login"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Patient Portal
                                  </a>
                                </div>
                                <div
                                  className="text-[#918f8f] leading-[1.1rem] font-sans flex items-center mt-7"
                                  onClick={() => {
                                    document.body.style.overflow = "unset";
                                    setDropdown(false);
                                  }}
                                >
                                  <CheckCircleOutlined className="text-[#0057A3] mr-3 text-lg -mt-1" />
                                  <a
                                    className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans"
                                    href="https://app.hclhealthcare.in/ehc/register.php"
                                  >
                                    Register for Health Check
                                  </a>
                                </div>
                              </div>
                            </Panel>
                          </Fragment>
                        </Collapse>
                      </div>
                    ) : (
                      <div
                        className="flex items-center cursor-pointer p-2 pl-6 mb-4"
                        onClick={() => {
                          setDropdown(false);
                          document.body.style.overflow = "unset";
                          navigate(e?.route);
                        }}
                      >
                        <>
                          {e?.icon}
                          <Typography className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                            {e?.title}
                          </Typography>
                        </>
                      </div>
                    );
                  })
                : DesktopDropdownData.map((e, key) => {
                    return (
                      <div
                        className="flex items-center cursor-pointer p-2 pl-6 mb-3 mt-2"
                        onClick={() => {
                          if (e?.title !== "Reports") {
                            privateRouting(
                              e?.route,
                              `Please Login to visit ${e?.title}`
                            );
                          }
                        }}
                        key={key}
                      >
                        {e?.icon}
                        {e?.title === "Reports" ? (
                          <>
                            <a
                              className="text-[#1f1f1f] cursor-pointer hover:text-black   font-sans"
                              href="https://hclhealthcare.karexpert.com/account-management/login"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {e?.title}
                            </a>
                          </>
                        ) : (
                          <>
                            <Typography className="text-[#1f1f1f] cursor-pointer hover:text-black  font-sans">
                              {e?.title}
                            </Typography>
                          </>
                        )}
                      </div>
                    );
                  })}

              <div
                className="flex items-center cursor-pointer p-2 pl-6 mb-4"
                onClick={() => {
                  setDropdown(false);
                  document.body.style.overflow = "unset";
                  navigate("/pharmacy-voucher", {
                    state: {
                      id: process.env.REACT_APP_PHARMACY_VOUCHER_ID,
                      name: "Pharmacy voucher",
                      isPharmacy: true,
                    },
                  });
                }}
              >
                <>
                  <IdcardOutlined className="text-[#0057A3] text-base mr-3 ml-1 items-center flex justify-center" />
                  <Typography className="text-[#1f1f1f] cursor-pointer hover:text-black hover:text-[0.85rem] hover:font-semibold font-sans">
                    Pharmacy Voucher
                  </Typography>
                </>
              </div>

              {/* ************************************** */}
              {/* *********** Logout ********** */}
              {currentUser !== undefined ? (
                <div
                  className="flex mt-4 pl-6 cursor-pointer items-center mb-8 sm:mb-6"
                  onClick={handleLogout}
                >
                  <LogoutOutlined className="text-[red] mr-2" />{" "}
                  <Typography className="text-[#F5222D] font-semibold font-sans">
                    {" "}
                    Logout{" "}
                  </Typography>{" "}
                </div>
              ) : (
                ""
              )}
            </div>

            {/* *********** Number ********** ******/}
            {window.innerWidth < 1020 ? (
              <div className="absolute bottom-2">
                <div className="ml-6 mr-6 mb-2 mt-10 w-full border-b-[1.5px] border-[#D9D9D9]">
                  <Typography className="text-base font-sans">
                    Toll Free Helpline No.
                  </Typography>
                </div>
                <div className="flex ml-6 items-center gap-2 ">
                  {" "}
                  <CustomerServiceOutlined className="text-[#0057A3] text-xl mb-2" />
                  <Typography className="text-[#0057A3] font-semibold text-xl font-sans">
                    {" "}
                    721-7890-075{" "}
                  </Typography>{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}{" "}
      {isWarning && (
        <LogInWarning message={message} setIsWarning={setIsWarning} />
      )}
    </>
  );
};
export default ProfileDropdown;
