import React from "react";
import { Modal, Typography } from "antd";
import { image73, OfferAppliedgif } from "../../assets/images";
import { useSelector } from "react-redux";

const OfferAppliedModal = ({ openOfferAppliedModal }) => {
  const offerName = useSelector((state) => state.offerName.name);
  const offerDiscount = useSelector((state) => state.offerName.percentage);

  return (
    <Modal
      open={openOfferAppliedModal}
      centered={true}
      closeIcon={<></>}
      footer={null}
      width={window.innerWidth < 640 ? "70%" : "28rem"}
    >
      <div className="flex justify-center items-center w-[100%] flex-col py-[0.4rem] sm:py-[1rem]">
        <div className="flex relative justify-center items-center overflow-hidden w-[100%] sm:h-[15rem] h-[12rem]">
          <img src={OfferAppliedgif} alt="" className="w-[120%]" />
          <img src={image73} alt="" className="w-[45%] absolute" />
        </div>
        <Typography className="text-[#737373] sm:text-[1.1rem] text-[0.95rem]  sm:mt-[1.5rem] mt-[0.4rem] font-sans">
          {offerName} Applied
        </Typography>
        <Typography className="sm:text-[1.3rem] mt-[0.3rem] text-[1.2rem] semibold font-sans">
          Saved{" "}
          <span className="text-[#F15E7E] font-semibold">{offerDiscount}%</span>{" "}
          on this order{" "}
        </Typography>
      </div>
    </Modal>
  );
};

export default OfferAppliedModal;
