import React, { useEffect, useState } from "react";
import { Badge, Col, Pagination, Row } from "antd";
import NavbarTabs from "../../../components/CommonComponents/NavbarTabs";
import Footer from "../../../components/CommonComponents/Footer";
import RadiologyImage from "../../../assets/images/Radiology.jpg";
import CardiologyImage from "../../../assets/images/Cardiology.jpg";
import PathologyImage from "../../../assets/images/Pathology.jpg";
import {
  getAllTest,
  getTestById,
  getdiagnosticNavbar,
} from "../../../service/homeApi";
import CardComponent from "../../../components/CommonComponents/CardComponent";
import { useLocation, useParams } from "react-router-dom";
import NoLab from "./NoLab";
import Loader from "../../../components/CommonComponents/Loader";
import SearchBarMobile from "../../../components/CommonComponents/SearchBarMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoryId,
  setPageNo,
  setSelectedTab,
} from "../../../redux/DiagnosticTests/diagnosticVarSlice";

const DiagnosticTest = () => {
  const location = useLocation();
  const { state } = useLocation();

  const { pincode } = useSelector((state) => state.changePincode);
  const { pageNo, categoryId, selectedTab } = useSelector(
    (state) => state.diagnosticVariables
  );

  const { itemIds } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [diagnostictest, setDiagnostictest] = useState([]);
  const params = useParams();
  const [totalTest, setTotalTest] = useState(1);
  const [fetchedCategories, setfetchedCategories] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const [Categories, setCategories] = useState([]);
  const allfetchedCategories = [{ name: "All", _id: "1234" }];
  const [remove, setRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(9);

  useEffect(() => {
    if (state) {
      dispatch(setCategoryId(state.id));
      dispatch(setSelectedTab(state.name));
    }
  }, [state]);

  const getDiagnosticTest = async (pageNo, categoryId, selectedItem) => {
    setIsLoading(true);
    try {
      if (Object.keys(params).length === 0) {
        const response = await getAllTest(
          pageNo,
          categoryId,
          selectedItem,
          limit
        );
        if (response?.data?.payload?.tests) {
          setDiagnostictest(response?.data?.payload?.tests);
          setTotalTest(response?.data?.payload?.totalTest);
        } else {
          setDiagnostictest([]);
          setTotalTest([]);
        }
      } else {
        const response = await getTestById(params?.id);
        const tests = [];
        tests.push(response?.data?.payload);
        setDiagnostictest(tests);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDiagnosticTest(pageNo, categoryId, selectedItem);
  }, [pageNo, categoryId, selectedItem, params, limit, pincode, itemIds]);

  useEffect(() => {
    getCategorys();
  }, []);

  useEffect(() => {
    fetchedCategories?.map((data) => {
      allfetchedCategories.push(data);
    });
    allfetchedCategories.pop();
    setCategories(allfetchedCategories);
  }, [fetchedCategories]);

  const getCategorys = async () => {
    try {
      const resp = await getdiagnosticNavbar();
      setfetchedCategories(resp?.data?.payload?.tests);
    } catch (error) {}
  };

  const onChange = (page, newPageSize) => {
    setLimit(newPageSize);
    dispatch(setPageNo(page));
  };

  return (
    <div>
      <NavbarTabs />
      <>
        <Row className="pt-6 mb-4 border-t-2">
          <Col
            span={window.innerWidth < 640 ? 22 : 20}
            offset={window.innerWidth < 640 ? 1 : 2}
          >
            <div className=" sm:hidden mb-6">
              <SearchBarMobile compId="landingpage-location" />
            </div>
            <div className="w-full flex justify-between items-center">
              <ul
                className="relative grid grid-flow-col text-center overflow-y-scroll no-scrollbar lg:overflow-hidden w-[98%] "
                id="section"
              >
                {Categories?.map((e, key) => {
                  return (
                    <>
                      {selectedTab === e.name ? (
                        <li
                          className="items-center justify-center flex w-max bg-[#FBF0F2] border-b-2 border-[#F15E7E] rounded-t-md cursor-pointer font-sans px-6 py-2 md:py-1"
                          key={key}
                        >
                          <div className="text-[0.875rem] md:text-[1rem] hover:text-black text-[#595959]">
                            {e.name}
                          </div>
                        </li>
                      ) : (
                        <li
                          className="items-center justify-center flex w-max hover:border-b-2 hover:border-black cursor-pointer font-sans px-6 py-2 md:py-1"
                          key={key}
                          onClick={() => {
                            dispatch(setPageNo(1));
                            dispatch(setSelectedTab(e.name));
                            dispatch(
                              setCategoryId(e.name === "All" ? "" : e._id)
                            );
                          }}
                        >
                          <div className="text-[0.875rem] md:text-[1rem] hover:text-black text-[#595959]">
                            {e.name}
                          </div>
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="mt-2 mb-4">
          <Col
            span={window.innerWidth < 640 ? 22 : 20}
            offset={window.innerWidth < 640 ? 1 : 2}
            className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 sm:justify-start justify-center"
          >
            {diagnostictest.length !== 0
              ? diagnostictest?.map((e, key) => {
                  return (
                    <>
                      <CardComponent
                        TestImage={
                          e?.category?.name === "Radiology"
                            ? RadiologyImage
                            : selectedTab === "Cardiology"
                            ? CardiologyImage
                            : PathologyImage
                        }
                        title={e?.name}
                        providers={e?.partners}
                        content={e?.description}
                        features={e?.features[0]}
                        id={e?._id}
                        duration={e?.duration}
                        route="diagnostic-tests"
                        key={key}
                        showSampling={true}
                        serviceType={e?.serviceMode}
                        reportTime={e?.reportTime}
                        requirments={e?.requirements}
                        discountPrice={e?.startingPrice}
                        inCart={e?.inCart}
                        productId={e?.productId}
                        startingOriginalPrice={e?.startingOriginalPrice}
                      />
                    </>
                  );
                })
              : !isLoading && <NoLab />}
          </Col>
        </Row>
        <Row className="pt-6 mb-4">
          <Col
            span={window.innerWidth < 640 ? 22 : 20}
            offset={window.innerWidth < 640 ? 1 : 2}
          >
            {totalTest > 0 && (
              <Pagination
                current={pageNo}
                onChange={onChange}
                total={totalTest}
                className="flex items-end justify-end"
                pageSizeOptions={[9, 15, 30, 60]}
                onShowSizeChange={onChange}
                defaultPageSize={9}
              />
            )}
          </Col>
        </Row>
      </>
      {isLoading && <Loader />}
      {!isLoading && <Footer />}
    </div>
  );
};

export default DiagnosticTest;
