import { Space, Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

const SlotsLoader = () => {
    const antIcon = (
        <LoadingOutlined
            className='text-[24px] absolute md:top-48 md:left-56 top-24 left-40 !z-40'
            spin
        />
    );
    return (
        <Spin indicator={antIcon} />
    )
}

export default SlotsLoader