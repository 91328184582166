import { Drawer, Modal, Typography } from "antd";
import React from "react";
import {
  CloseCircleOutlined,
  ShoppingOutlined,
  FieldTimeOutlined,
  HomeOutlined,
  CustomerServiceOutlined,
  UserOutlined,
} from "@ant-design/icons";

const Details = ({
  HandleClose,
  openModal,
  setOpenModal,
  orderId,
  dateAndTime,
  title,
  price,
  serviceType,
  patients,
  address,
  transactionID,
  netPrice,
}) => {
  return (
    <div>
      {window.innerWidth > 640 ? (
        <Modal
          open={openModal}
          title={
            <div className="md:border-b-[1.5px] md:border-[#D9D9D9] ">
              <div className="flex justify-between">
                <div>
                  <Typography className="text-[1rem] sm:text-[1.2rem]  text-[#161616] font-semibold font-sans">
                    Order Review{" "}
                  </Typography>{" "}
                  <Typography className="text-[0.8rem] sm:text-[0.95rem] text-[#595959] font-normal mb-2 font-sans h-6">
                    {" "}
                    {title}
                  </Typography>{" "}
                </div>
                <div>
                  <CloseCircleOutlined
                    onClick={() => setOpenModal(false)}
                    className="text-black text-[1.4rem] mt-[0.75rem] mr-[0.5rem] hover:bg-[#f0f0f0] cursor-pointer "
                  />
                </div>
              </div>
            </div>
          }
          onCancel={HandleClose}
          closable={false}
          footer={[]}
          centered={true}
        >
          <div className="mb-2">
            <div className="flex items-center gap-2 mb-4  mt-2">
              <ShoppingOutlined className="text-[#0057A3] text-[1rem]" />
              <Typography className="text-[1rem] font-normal font-sans w-[32%]">
                Order ID:{" "}
              </Typography>
              <Typography className="text-[1rem] font-semibold font-sans">
                {orderId}
              </Typography>
            </div>
            <div className="flex items-center gap-2 mb-4">
              <FieldTimeOutlined className="text-[#0057A3] text-[1rem] font-sans" />
              <Typography className="text-[1rem] font-normal w-[32%]">
                Date & Time:{" "}
              </Typography>
              <Typography className="text-[1rem] font-semibold font-sans">
                {dateAndTime}
              </Typography>
            </div>
            <div className="flex items-center gap-2 mb-4">
              <Typography className="text-[#0057A3] text-[1rem] mx-[0.2rem] font-sans">
                ₹
              </Typography>
              <Typography className="text-[1rem] font-normal font-sans w-[32%]">
                Total Price:{" "}
              </Typography>
              <Typography className="text-[1rem] font-semibold font-sans">
                ₹{price}
              </Typography>
            </div>
            <div className="flex items-center gap-2 mb-4">
              <Typography className="text-[#0057A3] text-[1rem] mx-[0.2rem] font-sans">
                ₹
              </Typography>
              <Typography className="text-[1rem] font-normal font-sans w-[32%]">
                Net Price:{" "}
              </Typography>
              <Typography className="text-[1rem] font-semibold font-sans">
                ₹{netPrice}
              </Typography>
            </div>
            <div className="flex items-center gap-2 mb-4">
              <UserOutlined className="text-[#0057A3] text-[1rem]" />
              <Typography className="text-[1rem] font-normal font-sans w-[32%]">
                Patient Name:{" "}
              </Typography>
              <Typography className="text-[1rem] font-semibold font-sans">
                {patients &&
                  patients?.map((e) => {
                    return <span>{e?.firstName + " " + e?.lastName}, </span>;
                  })}
              </Typography>
            </div>
            <div className="flex items-center gap-2">
              <CustomerServiceOutlined className="text-[#0057A3] text-[1rem] " />
              <Typography className="text-[1rem] font-normal font-sans w-[32%]">
                Service Type:{" "}
              </Typography>
              <Typography className="text-[1rem] font-semibold font-sans capitalize">
                {" "}
                {serviceType === "test" ? "Diagnostic Tests" : serviceType}
              </Typography>
            </div>
            <div className="flex items-center gap-2 mt-4">
              <HomeOutlined className="text-[#0057A3] text-[1rem] w-[3%]" />
              <Typography className="text-[1rem] font-normal font-sans w-[32%]">
                Service Address:{" "}
              </Typography>
              <Typography className="text-[1rem] font-semibold font-sans w-[60%]">
                {address?.buildingName ? address?.buildingName + ", " : ""}
                {address?.area ? address?.area + ", " : " "}
                {address?.district ? address?.district + ", " : ""}
                {address?.locality ? address?.locality + ", " : ""}
                {address?.state ? address?.state + ", " : ""}
                {address?.pincode}  
              </Typography>
            </div>
            <div className="flex items-center gap-2 m-2 mt-4">
              <Typography className="font-normal font-sans w-[32%] text-[1rem]">
                Transaction Id:{" "}
              </Typography>
              <Typography className=" font-semibold font-sans w-[65%] ">
                {transactionID}
              </Typography>
            </div>
          </div>
        </Modal>
      ) : (
        <>
          <Drawer
            open={openModal}
            title={
              <div>
                <Typography className="text-[1rem] text-[#161616] font-semibold font-sans">
                  Order Review{" "}
                </Typography>{" "}
                <Typography className="text-[0.8rem] text-[#595959] font-normal font-sans">
                  {" "}
                  {title}
                </Typography>{" "}
              </div>
            }
            closeIcon={
              <CloseCircleOutlined
                className="absolute top-[-2%] right-2 text-lg text-black mt-[1.2rem] mr-[0.2rem]"
                onClick={HandleClose}
              />
            }
            placement={"bottom"}
            key={"right"}
            // className="rounded-t-lg"
          >
            <div>
              <div className="flex items-center gap-2 mb-4 ">
                <ShoppingOutlined className="text-[#0057A3] text-[1rem]" />
                <Typography className="font-normal font-sans w-[30%]">
                  Order ID:{" "}
                </Typography>
                <Typography className=" font-semibold font-sans">
                  {orderId}
                </Typography>
              </div>
              <div className="flex items-center gap-2 mb-4">
                <FieldTimeOutlined className="text-[#0057A3] font-sans" />
                <Typography className="font-normal w-[30%]">
                  Date & Time:{" "}
                </Typography>
                <Typography className=" font-semibold font-sans">
                  {dateAndTime}
                </Typography>
              </div>
              <div className="flex items-center gap-2 mb-4">
                <Typography className="text-[#0057A3] text-[1rem] mx-[0.2rem] font-sans">
                  ₹
                </Typography>
                <Typography className="font-normal font-sans w-[30%]">
                  Total Price:{" "}
                </Typography>
                <Typography className="font-semibold font-sans">
                  ₹{price}
                </Typography>
              </div>
              <div className="flex items-center gap-2 mb-4">
                <Typography className="text-[#0057A3] text-[1rem] mx-[0.2rem] font-sans">
                  ₹
                </Typography>
                <Typography className="font-normal font-sans w-[30%]">
                  Net Price:{" "}
                </Typography>
                <Typography className="font-semibold font-sans">
                  ₹{netPrice}
                </Typography>
              </div>
              <div className="flex items-center gap-2 mb-4">
                <UserOutlined className="text-[#0057A3] " />
                <Typography className=" font-normal font-sans w-[30%]">
                  Patient Name:{" "}
                </Typography>
                <Typography className="font-semibold font-sans">
                  {patients &&
                    patients.map((e) => {
                      return <span>{e?.firstName + " " + e?.lastName}, </span>;
                    })}
                </Typography>
              </div>
              <div className="flex items-center gap-2">
                <CustomerServiceOutlined className="text-[#0057A3] " />
                <Typography className="font-normal font-sans w-[30%]">
                  Service Type:{" "}
                </Typography>
                <Typography className=" font-semibold font-sans">
                  {" "}
                  {serviceType}
                </Typography>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <HomeOutlined className="text-[#0057A3]  w-[3%]" />
                <Typography className="font-normal font-sans w-[32%]">
                  Service Address:{" "}
                </Typography>
                <Typography className=" font-semibold font-sans w-[65%]">
                  {address?.buildingName ? address?.buildingName + ", " : ""}
                  {address?.area ? address?.area + ", " : " "}
                  {address?.district ? address?.district + ", " : ""}
                  {address?.locality ? address?.locality + ", " : ""}
                  {address?.state ? address?.state + ", " : ""}
                  {address?.pincode}
                </Typography>
              </div>
              <div className="flex items-center gap-2 m-2 mt-4">
                <Typography className="font-normal font-sans w-[32%] text-[1rem]">
                  Transaction Id:{" "}
                </Typography>
                <Typography className=" font-semibold font-sans w-[65%] ">
                  {transactionID}
                </Typography>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default Details;
