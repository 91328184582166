import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/CommonComponents/Footer";
import AdressForm from "../../components/Checkout/AdressForm";
import { useState } from "react";
import DeleteModal from "../../components/CommonComponents/DeleteModal";
import manage_address from "../../assets/images/home.svg";
import { deleteAddress, getAddress } from "../../service/AddressApi";

const ManageAddress = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const [address, setAddress] = useState([]);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [addressId, setaddressId] = useState();

  const onEdit = () => {
    setIsModalOpenEdit(!isModalOpenEdit);
  };
  const redirectToEditPage = (data) => {
    navigate("/checkout/edit-address", {
      state: {
        data: data,
      },
    });
  };
  const redirectToAddPage = () => {
    navigate("/checkout/add-new-address");
  };


  const HandleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  const fetchAddress = async () => {
    try {
      const response = await getAddress();
      if (response) {
        setAddress(response?.data?.payload);
      }
    } catch (error) {
    }
  };

  const callDeleteAddress = async (addresId) => {
    try {
      const response = await deleteAddress(addressId);
    } catch (error) {
    }
    fetchAddress();
    setOpenDeleteModal(!openDeleteModal);
  };

  const [detailtsToEdit, setDetailtsToEdit] = useState();

  const showModalEdit = () => {
    setIsModalOpenEdit(!isModalOpenEdit);
  };

  return (
    <div>
      <div className="border-b-2">
        <Row>
          <Col span={20} offset={2}>
            <div className="h-10 flex items-center justify-start text-[1rem] sm:text-[1.4rem] sm:py-[2rem] -ml-[0.3rem] sm:ml-0">
              <ArrowLeftOutlined onClick={() => navigate("/profile-setting")} />
              <div className="flex items-baseline">
                <Typography className=" ml-[1rem] sm:ml-[2rem] text-[1.2rem] sm:text-[1.5rem] font-semibold mr-[0.2rem] font-sans">
                  Manage Address
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="flex justify-center mt-3 px-2 sm:p-3 min-h-[85vh]">
        <Col sm={22} md={11}>
          <div className="w-[100%] sm:border sm:border-[#D9D9D9] sm:shadow sm:rounded-md px-4 py-4 pb-0 max-h-[82%] overflow-y-scroll overflow-x-hidden">
            {address.length < 1 ? (
              <div className="flex items-center flex-col justify-center pt-[2.7rem] pb-[2rem] border">
                <div className="bg-[#FBF0F2] !rounded-full h-[10rem] w-[10rem] flex items-center justify-center mb-[1.5rem]">
                  <img
                    src={manage_address}
                    alt="manage patient"
                    className=" object-contain h-[6.5rem] w-[6.5rem]"
                  />
                </div>
                <Typography className="text-[black] font-normal text-[1rem] sm:text-[1.1rem] font-sans">
                  Add your address and enjoy faster checkout
                </Typography>
                <Button
                  onClick={
                    window.innerWidth > 640 ? showModal : redirectToAddPage
                  }
                  htmlType="submit"
                  className="font-sans border-[#F15E7E] text-[#F15E7E] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] mt-[1.5rem]"
                >
                  + Add New Address
                </Button>
                <Modal
                  open={isModalOpen}
                  footer={null}
                  closeIcon={
                    <CloseCircleOutlined
                      className="text-black mr-2"
                      onClick={showModal}
                    />
                  }
                  centered={true}
                  title={
                    <Typography className="font-sans font-semibold text-[1.1rem] pb-2 sm:border-b-[1.5px] sm:border-[#D9D9D9]">
                      Add New Address
                    </Typography>
                  }
                >
                  <div className="max-h-[35rem] relative">
                    <AdressForm setIsModalOpen={setIsModalOpen} />
                  </div>
                </Modal>
              </div>
            ) : (
              <>
                {window.innerWidth < 640 ? (
                  <Button
                    onClick={redirectToAddPage}
                    htmlType="submit"
                    className="font-sans border-[#F15E7E] text-[#F15E7E] w-[88vw] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E] mb-4"
                  >
                    + Add New Address
                  </Button>
                ) : (
                  <></>
                )}
                <div className="flex justify-between sm:border-b  pb-3 ">
                  <Typography className="font-sans font-semibold text-[1.1rem] text-black">
                    Saved Address
                  </Typography>
                  {window.innerWidth > 640 ? (
                    <Button
                      onClick={showModal}
                      htmlType="submit"
                      className="font-sans h-[2.5rem] border-[#F15E7E] text-[#F15E7E] !rounded-sm text-xs font-semibold hover:!text-white hover:bg-[#F15E7E] hover:!border-[#F15E7E]"
                    >
                      + Add New Address
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Modal
                    open={isModalOpen}
                    footer={null}
                    closeIcon={
                      <CloseCircleOutlined
                        className="text-black mr-2"
                        onClick={showModal}
                      />
                    }
                    centered={true}
                    title={
                      <Typography className="font-semibold text-[1.1rem] pb-2 md:border-b-[1.5px] md:border-[#D9D9D9] font-sans">
                        Add New Address
                      </Typography>
                    }
                  >
                    <div className="max-h-[35rem] relative">
                      <AdressForm
                        setIsModalOpen={setIsModalOpen}
                        isEdit={false}
                        fetchAddress={fetchAddress} />
                    </div>
                  </Modal>
                </div>
              </>
            )}
            {address &&
              address?.map((data, index) => {
                return (
                  <>
                    <div className="w-[91vw] md:w-[44vw] flex justify-between pr-3 py-2 sm:pt-2 md:pt-5 sm:pb-4">
                      <div className="md:w-[85%] w-[75%]">
                        <Typography className="text-[0.85rem] text-black font-normal font-sans">
                          {data.area}, {data.locality} {data.landmark}
                        </Typography>
                        <Typography className="text-[0.85rem] text-black font-normal font-sans">
                          {data.district}, {data.state} - {data.pincode}
                        </Typography>
                      </div>

                      <div className="mr-[0.5rem] sm:mr-[1rem] md:w-[15%] w-[20%]">
                        <EditOutlined
                          className="text-[#0057A3] text-[1.2rem] px-2"
                          onClick={() => {
                            setDetailtsToEdit(data);
                            if (window.innerWidth > 640) {
                              showModalEdit();
                            } else {
                              redirectToEditPage(data);
                            }
                          }}
                        />

                        <DeleteOutlined
                          className="text-[#F15E7E] text-[1.2rem] p-1"
                          onClick={() => {
                            setaddressId(data?._id);
                            HandleDeleteModal();
                          }}
                        />
                      </div>
                    </div>

                    {address.length - index === 1 ? (
                      <></>
                    ) : (
                      <Divider className="mt-0 mb-2 min-w-[40vw] md:w-[43vw] w-[88vw] " />
                    )}
                  </>
                );
              })}
          </div>
        </Col>
      </Row>
      <Modal
        open={isModalOpenEdit}
        footer={null}
        closeIcon={
          <CloseCircleOutlined
            className="text-black "
            onClick={showModalEdit}
          />
        }
        centered={true}
        title={
          <Typography className="  font-semibold text-[1.1rem] pb-2 md:border-b-[1.5px] md:border-[#D9D9D9] font-sans">
            Edit Address
          </Typography>
        }
      >
        <div className="max-h-[35rem] relative">
          <AdressForm
            setIsModalOpenEdit={setIsModalOpenEdit}
            isEdit={true}
            detailtsToEdit={detailtsToEdit}
            fetchAddress={fetchAddress}
          />
        </div>
      </Modal>
      <Footer />
      <DeleteModal
        openDeleteModal={openDeleteModal}
        HandleDeleteModal={HandleDeleteModal}
        deleteAddress={callDeleteAddress}
        isAddressDelete={true}
        addressId={addressId}
      />
    </div>
  );
};

export default ManageAddress;