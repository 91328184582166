import React from "react";
import { Modal, Space, Button, Input, Form } from "antd";
import { image72 } from "../../assets/images";
import CardOfferAndDiscount from "./CardOfferAndDiscount";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../utils/axios";
import { toast } from "react-toastify";
import { addAppliedName } from "../../redux/Offers/offerNameSlice";

const ViewAllOffersModal = ({ isOffersOpen, manageModal, getData }) => {
  const offers = useSelector((state) => state.Offers.Offers);

  const dispatch = useDispatch();

  const pasteCodeApply = async (e) => {
    try {
      const response = await axiosInstance.post(`/api/v1/offer/apply-coupon`, {
        couponName: e.code,
      });
      dispatch(addAppliedName(e.code));

      if (response.success) {
        getData();
        return response.data;
      }
      getData();
      manageModal(false);
      return response;
    } catch (error) {
      toast.info("This offer is not applicable on these services");
      manageModal(false);
    }
  };

  return (
    <div>
      <Modal
        title="Offers & Discounts"
        open={isOffersOpen}
        centered={true}
        closeIcon={<img src={image72} onClick={manageModal} />}
        footer={null}
        width={"40rem"}
      >
        <div className="border-t-2 h-[30rem] overflow-y-scroll my-[0.5rem] pt-[1rem] scrollbar">
          <Form onFinish={pasteCodeApply}>
            <div className="flex justify-between items-center">
              <div className="w-[85%]">
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: "Please paste your code." },
                  ]}
                >
                  <Input
                    placeholder="Coupon code"
                    className="bg-transparent bg-opacity-10 border-[1.5px]  placeholder-black font-light"
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  className="!text-[#0057A3] border-[1.5px] bg-white semibold px-5"
                  htmlType="submit"
                >
                  Apply
                </Button>
              </Form.Item>
            </div>
          </Form>
          {offers.map((data) => (
            <>
              <CardOfferAndDiscount
                name={data?.name}
                title={data?.title}
                description={data?.description}
                discountPercentage={data?.discount}
                id={data?._id}
                width={`100%`}
                isAllOffers={true}
                manageViewAllOfferModal={manageModal}
                getData={getData}
                tc={data?.tc}
                discountType={data?.discountType}
              />
              <br />
            </>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ViewAllOffersModal;
