import React from "react";
import noLab from "../../../assets/images/noLab.svg";
import Typography from "antd/es/typography/Typography";
import { Col, Row } from "antd";

const NoLab = () => {
  return (
    <Row className="mt-2 mb-4">
      <Col span={window.innerWidth < 640 ? 22 : 20}
        offset={window.innerWidth < 640 ? 1 : 2}>
        <div className="flex items-center justify-center w-[83vw]">
          <div className="w-full h-[80vh] flex flex-col justify-center items-center ">
            <img src={noLab} className="h-[70%]" alt="" />

            <Typography className="font-semibold text-[2rem] mt-5"> No result found</Typography>
            {/*
            {
              Object.keys(message).length === 0 &&
              <Typography className=" text-[1rem] ">Please enter a different nearby location for selected service</Typography>
            } */}
          </div>
          {/* <div className="h-[40vh] w-full"></div> */}
        </div>
      </Col>
    </Row>
  );
};

export default NoLab;
