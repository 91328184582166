import Typography from "antd/es/typography/Typography";
import React, { useState, useEffect } from "react";
import { image66 } from "../../assets/images";
import Marquee from "react-fast-marquee";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import CustomerSlide from "./CustomerSlide";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Health_and_wellness from "../../assets/images/health_and_wellness.svg";
import { getLandingPartner } from "../../service/homeApi";
import dentalImage from "../../assets/images/dentalbanner.png";
import mobDentalImage from "../../assets/images/Exciting_mobile.png";

const ExcitementSection = () => {
  const [partnerList, setPartnerList] = useState([]);
  const ref = React.useRef();
  // const data = [{}, {}, {}, {}, {}, {}, {}, {}];

  const data = [
    {
      name: "Miss Nikita Sharma",
      location: "Pune, India",
      description:
        "The lab diagnosis was booked timely and the doctor consultation was wonderful. The doctors explained the condition and treatment in the best way. I am very happy with the service.",
    },
    {
      name: "Mr Ankush Batra",
      location: "Noida, India",
      description:
        "I cannot thank the HCL Healthcare team enough for the best healthcare services their team has provided. At every step, I was guided, and the clinical team cleared all my doubts very patiently. It is very easy to book services and avail. Now I will book services for my family. I will also refer HCL Healthcare to all my friends and colleagues.",
    },
    {
      name: "Mr Manu Sebastian",
      location: "Chennai, India",
      description:
        "I must say HCL Healthcare services are optimum and the best experience I have received from booking my labs to taking doctor consults and treatment. It is very convenient and time efficient. The team takes great care of their patients, and I am very happy that I chose HCL Healthcare as my healthcare provider.",
    },
    {
      name: "Ms Patil Harshala",
      location: "Noida, India",
      description:
        "I have received great discounts on all the health services and found the prices to be lower than the market price. The service is also very good and timely. I had a very good experience taking lab tests from HCL Healthcare. Appreciate the accessibility of health services so convenient and affordable.",
    },
    {
      name: "Laxmikant Rastogi",
      location: "Kolkata, India",
      description:
        "I am impressed by HCL Healthcare's hospitality and care in delivering value added services. Kudos to the efforts! The clinic team ensured seamless execution from blood sample collection and digital reports to specialist doctor consultations. It has been a valuable experience.",
    },
    {
      name: "R Ramalakshmi",
      location: "Noida, India",
      description:
        "Great support from the team in coordinating lab tests, understanding the customers' needs, providing cost information, and coordinating the timelines with the service provider. Keep up the great work.",
    },
  ];

  const getPartners = async () => {
    try {
      const response = await getLandingPartner();
      setPartnerList(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <div className="mt-8 w-[99%]">
      <div className="pt-[5rem] sm:py-[2.5rem]">
        <Typography className="text-center font-semibold text-[1.4rem] sm:text-[2rem] font-sans">
          Customer Reviews
        </Typography>
        <Typography className="text-center text-[#434343] text-[0.9rem] w-[50%] m-[auto] sm:text-[1.1rem] mb-[1.8rem] sm:mb-[3rem] font-sans">
          Customer reviews from all over India
        </Typography>

        <div className="w-[100%] relative ">
          <ResponsiveContainer
            carouselRef={ref}
            render={(parentWidth, carouselRef) => {
              let currentVisibleSlide = 3;
              if (parentWidth <= 1280) currentVisibleSlide = 3;
              if (parentWidth <= 640) currentVisibleSlide = 1;
              return (
                <>
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={CustomerSlide}
                    slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
                    carouselWidth={parentWidth}
                    data={data}
                    currentVisibleSlide={currentVisibleSlide}
                    maxVisibleSlide={3}
                    useGrabCursor
                    fadeDistance={0.1}
                    height={290}
                  />
                  <button
                    onClick={() => {
                      ref.current?.goBack();
                    }}
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "-2%",
                      zIndex: 10,
                    }}
                  >
                    <ArrowLeftOutlined className="text-black bg-[#F0F0F0] rounded-full p-2  " />
                  </button>

                  <button
                    onClick={() => {
                      ref.current?.goNext(6);
                    }}
                    style={{
                      position: "absolute",
                      top: "40%",
                      right: "-2%",
                      zIndex: 10,
                    }}
                  >
                    <ArrowRightOutlined className="text-black bg-[#F0F0F0] rounded-full p-2  " />
                  </button>
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="pt-[2rem] sm:py-[2rem] mt-[0.5rem] sm:mt-[3rem]">
        <div className=" flex items-center mb-[0.8rem]">
          <Typography className="font-semibold text-[1rem] sm:text-[1.2rem] font-sans">
            What's Exciting at HCL Healthcare
          </Typography>
          <div className="flex-grow h-[1px] bg-[#BFBFBF] m-[0.5rem] "></div>
        </div>

        {window.innerWidth < 640 ? (
          <a
            href="https://app.hclhealthcare.in/dental/dentalForm.php"
            target="_blank"
          >
            <img
              src={mobDentalImage}
              className="w-[100%] mt-[2rem] cursor-pointer "
              alt="Failed to load User Count"
              // onClick={()=> }
            />
          </a>
        ) : (
          <a
            href="https://app.hclhealthcare.in/dental/dentalForm.php"
            target="_blank"
          >
            <img
              src={dentalImage}
              className="mt-[3.2rem] rounded-xl cursor-pointer"
              alt="Failed to load User Count"
            />
          </a>
        )}
      </div>

      <div className="pt-[3rem] sm:py-[3rem] mt-[0.5rem] w-full flex flex-col">
        {window.innerWidth < 640 ? (
          <img
            src="https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/Frame%202610045.svg"
            className="w-[100%] mt-[2rem]"
            alt="Failed to load User Count"
          />
        ) : (
          <div className="w-full overflow-hidden flex justify-center items-center">
            <img
              src="https://ecom-healthcare.s3.ap-south-1.amazonaws.com/upload/image/consultant/Frame%202609974.svg"
              className="mt-[3.2rem] min-w-[100vw] -px-10"
              alt="Failed to load User Count"
            />
          </div>
        )}
      </div>
      <div className="pt-[3rem] sm:py-[3rem] w-full flex flex-col">
        <Typography className="text-center font-semibold text-[1.4rem] sm:text-[2rem] font-sans">
          Our Partners
        </Typography>
        <Typography className="text-center text-[#434343] text-[0.9rem] w-[60%] m-[auto] sm:text-[1.1rem] mb-[2rem] sm:mb-[3rem] font-sans">
          Our lab partners spreading health, wellness and happiness{" "}
        </Typography>

        <Marquee duration={20} direction="left" speed={70}>
          {partnerList?.payload?.partners?.map((data) => {
            return (
              <div
                key={data?._id}
                className="flex flex-col items-center w-[12rem] sm:w-[16rem]"
              >
                <img src={data?.image} alt={data?.name} className="w-[58%]" />
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default ExcitementSection;
