import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  consultantSlots: {},
  otherServicesSlots: {},
  labInCart: "",
};

const slotSlice = createSlice({
  name: "SlotSlice",
  initialState: initialState,
  reducers: {
    setConsultantSlots: (state, action) => {
      state.consultantSlots = action.payload;
    },
    setOtherServicesSlots: (state, action) => {
      state.otherServicesSlots = action.payload;
    },
    setLabInCart: (state, action) => {
      state.labInCart = action.payload;
    },
  },
});

export const { setConsultantSlots, setOtherServicesSlots, setLabInCart } =
  slotSlice.actions;

export default slotSlice.reducer;
