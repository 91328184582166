import React from "react";

import { CustomerSecIcon } from "../../assets/images";
import { Typography } from "antd";

const CustomerSlide = React.memo(function (props) {
  const { data, dataIndex } = props;

  // const { cover } = data[dataIndex];
  const dummy = data[dataIndex];
  return (
    <>
      <div
        className=" drop-shadow p-[1.5rem] bg-white flex flex-col items-center rounded-lg 
         w-[90%] sm:w-[53%] ml-[5%]   sm:ml-[23.5%] mt-[0.2rem] h-[15rem] "
      >
        <img
          src={CustomerSecIcon}
          alt="Our Costumer"
          className="w-[1.6rem] h-[1.6rem] mb-[1rem]"
        />
        <Typography className="font-semibold text-sm sm:text-[0.9rem] text-[#595959] font-sans">
          {dummy.name}
        </Typography>
        <Typography className="text-[#595959] text-xs sm:text-[0.8rem] font-sans mb-2">
          {dummy.location}
        </Typography>
        <Typography className="text-[#262626] font-semibold text-sm sm:text-[0.8rem] text-center mt-[0.8rem] mb-4 font-sans hideLongTypography">
          {dummy.description}
        </Typography>
      </div>
    </>
  );
});

export default CustomerSlide;
