import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const PagesHeader = ({ title, text, route }) => {
  const navigate = useNavigate();

  return (
    <div className="border-b-2">
      <Row>
        <Col span={20} offset={2}>
          <div className="h-10 flex items-center justify-start text-[1rem] sm:text-[1.4rem] sm:py-[2rem] -ml-[0.3rem] sm:ml-0">
            <ArrowLeftOutlined
              onClick={() => {
                if (route) {
                  navigate(route);
                } else {
                  navigate(-1);
                }
              }}
            />
            <div className="flex items-baseline">
              {window.innerWidth > 640 ? (
                <Typography className=" ml-[1rem] sm:ml-[2rem] text-[1.2rem] sm:text-[1.5rem] font-semibold mr-[0.2rem] font-sans">
                  {title}
                </Typography>
              ) : (
                <Typography className=" ml-[1rem] sm:ml-[2rem] text-[1.2rem] sm:text-[1.5rem] font-semibold hideLongTypography1 font-sans">
                  {title}
                </Typography>
              )}
              <Typography className="font-bold text-[0.8rem] ml-1 sm:ml-0 sm:text-[1rem] font-sans ">
                {text}
              </Typography>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PagesHeader;
