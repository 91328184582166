import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/CommonComponents/Navbar";
import PageNotFound from "./pages/Auth/PageNotFound";
import NewPasswordPage from "./pages/Auth/NewPasswordPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./pages/Home/LandingPage";
import Cart from "./pages/Cart/Cart";
import DiagnosticTest from "./pages/Home/OurSolutions/DiagnosticTest";
import CheckOut from "./components/Checkout/CheckOut";
import EditAddress from "./components/Checkout/EditAddress";
import AddNewAddress from "./components/Checkout/AddNewAddress";
import EditPatientDetailMobile from "./components/CartComponents/EditPatientDetailMobile";
import ViewAllOffersMobile from "./components/CartComponents/ViewAllOffersMobile";
import SpecialConsultant from "./pages/Home/OurSolutions/SpecialConsultant";
import PackagesAndProfile from "./pages/Home/OurSolutions/PackagesAndProfile";
import OrderReview from "./pages/Profile/OrderReview";
import Notification from "./pages/Profile/Notification";
import FeedbackPage from "./components/Profile/FeedbackPage";
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings";
import EditProfileDetail from "./pages/ProfileSettings/EditProfileDetail";
import ManageAddress from "./pages/ProfileSettings/ManageAddress";
import ManagePatients from "./pages/ProfileSettings/ManagePatients";
import MyCoupon from "./pages/ProfileSettings/MyCoupon";
import AddNewPatient from "./pages/ProfileSettings/AddNewPatient";
import EditPatient from "./pages/ProfileSettings/EditPatient";
import FAQPage from "./pages/Home/FAQPage";
import SelectLabDiagnostic from "./pages/Home/OurSolutions/SelectLabDiagnostic";
import SelectLabPackage from "./pages/Home/OurSolutions/SelectLabPackage";
import PopularPackageSeclectLab from "./pages/Home/OurSolutions/PopularPackageSeclectLab";
import ViewDetailPopularPackageLab from "./pages/Home/OurSolutions/ViewDetailPopularPackageLab";
import ViewDetailPackagePage from "./pages/Home/OurSolutions/ViewDetailPackagePage";
import HealthCheck from "./pages/AboutHealthCheck/HealthCheck";
import NavbarHealthCheck from "../src/components/CommonComponents/NavbarHealthCheck";
import CancellationRefund from "./pages/AboutHealthCheck/CancellationRefund";
import ResetPassword from "./pages/Auth/ResetPassword";
import PrivateRoute from "./RouteGuards/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import LoginPage from "./pages/Auth/LoginPage";
import Logo from "./assets/images/Logo.png";
import { getBanners } from "./redux/Banner/BannerSlice";
import { getAddOnsDiagnostic } from "./redux/AddOnsDiagnostic/AddOnsDiagnosticSlice";
import { setIsClicked } from "./redux/LandingPage/landingPageSlice";
import VerifyEmailPage from "./pages/Auth/VerifyEmailPage";
import VaccineList from "./pages/Home/OurSolutions/VaccineList";
import CartIcon from "./components/CommonComponents/CartIcon";
import WhatsAppIcon from "./components/CommonComponents/WhatsAppIcon";

function App() {
  const { token } = useSelector((state) => state.auth);

  const [isLoad, setIsLoad] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
      if (!token) {
        toast("Please login", {
          onClick: () => {
            dispatch(setIsClicked(true));
          },
        });
      }
    }, 1800);
    dispatch(getBanners());
    dispatch(getAddOnsDiagnostic());
  }, []);

  const NavLayout = () => (
    <>
      <>
        <Navbar isUp={true} />
        <CartIcon />
        <WhatsAppIcon />
        <Outlet />
      </>
    </>
  );
  const NavDownLayout = () => (
    <>
      <>
        <Navbar isUp={false} />
        <Outlet />
      </>
    </>
  );

  const NavDownLayoutHealth = () => (
    <>
      <>
        <NavbarHealthCheck />
        <Outlet />
      </>
    </>
  );

  return (
    <BrowserRouter>
      {" "}
      <ToastContainer />{" "}
      {isLoad && (
        <>
          <div className="fixed z-[100] bg-white w-[100vw] h-[100vh] top-0 left-0 flex flex-col  justify-center items-center   transition-all">
            <div className="w-[80%] sm:w-[16rem] ">
              <div className="shine"></div>
              <img src={Logo} className="w-full " />
              <div className="loader rounded mt-1"></div>
            </div>
          </div>
        </>
      )}
      <>
        <Routes>
          {" "}
          <Route path="/login" element={<NavDownLayout />}>
            <Route
              index
              element={
                <>
                  <LandingPage />
                  <LoginPage />
                </>
              }
            />
          </Route>
          <Route path="/otp-login" element={<NavDownLayout />}>
            <Route
              index
              element={
                <>
                  <LandingPage />
                  <VerifyEmailPage />
                </>
              }
            />
          </Route>
          <Route path="/" element={<NavLayout />}>
            <Route index element={<LandingPage />} />
          </Route>
          <Route path="/forgot-password" element={<NavDownLayout />}>
            <Route
              index
              element={token ? <Navigate to={"/"} /> : <LandingPage />}
            />
          </Route>
          <Route path="/signup" element={<NavDownLayout />}>
            <Route
              index
              element={token ? <Navigate to={"/"} /> : <LandingPage />}
            />
          </Route>
          <Route path="/account/reset-password/:id" element={<NavDownLayout />}>
            <Route index element={<NewPasswordPage />} />
          </Route>
          <Route path="/verify-email" element={<NavDownLayout />}>
            <Route index element={<LandingPage />} />
          </Route>
          <Route path="/" element={<NavLayout />}>
            <Route index element={<LandingPage />} />
          </Route>
          <Route
            path="/cart"
            element={
              <>
                <NavLayout />
                <Cart />
              </>
            }
          ></Route>
          <Route path="/cart/edit-patient-detail" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <EditPatientDetailMobile />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/cart/view-all-offers" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <ViewAllOffersMobile />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="*" element={<NavLayout />}>
            <Route index element={<PageNotFound />} />
          </Route>
          <Route path="popular-package-select-lab" element={<NavLayout />}>
            <Route index element={<PopularPackageSeclectLab />} />
          </Route>
          <Route
            path="popular-package-select-lab/view-details"
            element={<NavLayout />}
          >
            <Route index element={<ViewDetailPopularPackageLab />} />
          </Route>
          <Route path="/diagnostic-tests" element={<NavLayout />}>
            <Route index element={<DiagnosticTest />} />
          </Route>
          <Route path="/diagnostic-tests/:id" element={<NavLayout />}>
            <Route index element={<DiagnosticTest />} />
          </Route>
          <Route
            path="/diagnostic-tests/select-lab"
            element={
              <>
                <NavLayout />
                <SelectLabDiagnostic />
              </>
            }
          />
          <Route
            path="/vaccination/select-lab"
            element={
              <>
                <NavLayout />
                <SelectLabDiagnostic />
              </>
            }
          />
          <Route path="/suggested-tests/select-lab" element={<NavLayout />}>
            <Route index element={<SelectLabDiagnostic />} />
          </Route>
          <Route path="/packages/select-lab" element={<NavLayout />}>
            <Route index element={<SelectLabPackage />} />
          </Route>
          <Route path="/special-consult" element={<NavLayout />}>
            <Route index element={<SpecialConsultant />} />
          </Route>
          <Route path="/free-special-consult" element={<NavLayout />}>
            <Route index element={<SpecialConsultant />} />
          </Route>
          <Route path="/special-consult/:category" element={<NavLayout />}>
            <Route index element={<SpecialConsultant />} />
          </Route>
          <Route path="/packages" element={<NavLayout />}>
            <Route index element={<PackagesAndProfile />} />
          </Route>
          <Route path="/packages/:id" element={<NavLayout />}>
            <Route index element={<PackagesAndProfile />} />
          </Route>
          <Route path="/packages/details" element={<NavLayout />}>
            <Route index element={<ViewDetailPackagePage />} />
          </Route>
          <Route path="/vaccination" element={<NavLayout />}>
            <Route index element={<VaccineList />} />
          </Route>
          <Route path="/pharmacy-voucher" element={<NavLayout />}>
            <Route index element={<PackagesAndProfile />} />
          </Route>
          <Route path="/order-review" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <OrderReview />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/notification" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <Notification />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/faq" element={<NavLayout />}>
            <Route index element={<FAQPage />} />
          </Route>
          <Route path="/checkout" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <CheckOut />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/checkout/edit-address" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <EditAddress />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/checkout/add-new-address" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <AddNewAddress />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/order-review/feedback" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <FeedbackPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/profile-setting" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <ProfileSettings />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/profile-setting/edit-profile-detail"
            element={<NavLayout />}
          >
            <Route
              index
              element={
                <PrivateRoute>
                  <EditProfileDetail />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/profile-setting/manage-address" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <ManageAddress />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/profile-setting/manage-patient" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <ManagePatients />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/profile-setting/my-coupon" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <MyCoupon />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/add-new-patients" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <AddNewPatient />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/edit-patients" element={<NavLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <EditPatient />
                </PrivateRoute>
              }
            />
          </Route>
          {/* <Route path="/resetPage"  element={<ResetPassword />} */}
          <Route path="/resetPage" element={<ResetPassword />} />
          {/* <Route path="/resetPage" element={<NavLayout />}>
          <Route
            index
            element= {<Navigate to={"/login"} />}
          />
        </Route> */}
          <Route path="/health-check" element={<NavDownLayoutHealth />}>
            <Route index element={<HealthCheck />} />
          </Route>
          <Route
            path="/health-check/EHC_<40>_Male_Female"
            element={<NavDownLayoutHealth />}
          >
            <Route index element={<HealthCheck />} />
          </Route>
          <Route
            path="/health-check/Platinum"
            element={<NavDownLayoutHealth />}
          >
            <Route index element={<HealthCheck />} />
          </Route>
          <Route
            path="/health-check/EHC_>40_Male_Female"
            element={<NavDownLayoutHealth />}
          >
            <Route index element={<HealthCheck />} />
          </Route>
          <Route
            path="/health-check/EHC_>60_Male_Female"
            element={<NavDownLayoutHealth />}
          >
            <Route index element={<HealthCheck />} />
          </Route>
          <Route
            path="/health-check/cancellation-and-refund-policy/"
            element={<NavDownLayoutHealth />}
          >
            <Route index element={<CancellationRefund />} />
          </Route>
        </Routes>{" "}
      </>
    </BrowserRouter>
  );
}
export default App;
