import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pincode: "000000",
};

const changePincode = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    updatePincode: (state, action) => {
      state.pincode = action.payload;
    },
  },
});

export const { updatePincode } = changePincode.actions;

export default changePincode.reducer;
